import { Box } from '@chakra-ui/react';
import { CardHolderDetailsForm } from '@melio/ap-widgets';
import { Text, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { CardHolderDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type CardHolderDetailsScreenProps = {
  isSaving?: boolean;
  defaultValues?: Partial<CardHolderDetails>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: CardHolderDetails) => void;
};

export const CardHolderDetailsScreen: React.VFC<CardHolderDetailsScreenProps> = forwardRef<
  CardHolderDetailsScreenProps,
  'div'
>(({ defaultValues, isSaving, onBack, onClose, onDone, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
    useFormSubmissionController<CardHolderDetails>();

  useAnalyticsView('AddCCHolderDetails');

  return (
    <NewSinglePaymentStepLayout
      data-component="AddCardFundingSourceActivity.CardHolderDetailsScreen"
      data-testid="add-card-funding-source-activity-card-holder-details-screen"
      ref={ref}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton {...cancelButtonProps} onClick={onClose} />
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      isStickyFooter
      footerContent={
        <NewSinglePaymentStepLayout.Footer>
          <NewSinglePaymentStepLayout.NextButton
            data-testid="create-card-holder-details-cancel-button"
            variant="tertiary"
            label={formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.cancel')}
            onClick={onClose}
          />
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            label={formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.save.and.continue')}
          />
        </NewSinglePaymentStepLayout.Footer>
      }
    >
      <NewSinglePaymentStepLayout.Title textAlign="justify">
        {formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.title')}
      </NewSinglePaymentStepLayout.Title>
      <Box width="100%">
        <NewSinglePaymentStepLayout.Content>
          <CardHolderDetailsForm
            headerContent={
              <Text color="global.neutral.900" textStyle="body4">
                {formatMessage('widgets.cardHolderDetailsForm.requiredFields')}
              </Text>
            }
            onSubmit={onDone}
            defaultValues={defaultValues}
            isSaving={isSaving}
            onSubmissionStateChange={onSubmissionStateChange}
          />
        </NewSinglePaymentStepLayout.Content>
      </Box>
    </NewSinglePaymentStepLayout>
  );
});

CardHolderDetailsScreen.displayName = 'AddCardFundingSourceActivity.CardHolderDetailsScreen';
