import { Link, Text } from '@melio/penny';
import { usePlanFeeWidgetLink } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PlanPricingInfoLink = () => {
  const { formatMessage } = useMelioIntl();
  const { data: link } = usePlanFeeWidgetLink();

  return (
    <Text textStyle="body4" color="global.neutral.700" data-testid="plan-pricing-info-link">
      {formatMessage('activities.settings.editablePlanCardFiserv.pricingCatalogDisclaimer.description', {
        link: (
          <Text as="span" textStyle="body4Semi">
            <Link
              href="#"
              color="secondary"
              label={formatMessage('activities.settings.editablePlanCardFiserv.pricingCatalogDisclaimer.link')}
              onClick={() => window.open(link, '_blank', 'width=600,height=400,left=300,top=200')}
            />
          </Text>
        ),
      })}
    </Text>
  );
};
