import { getSubscriptionAllowedActions } from '../utils';
import { usePlanInfo } from './usePlanInfo';
import { usePlansTiers } from './usePlansTiers';
import { useSubscription } from './useSubscription';

export const usePendingSubscription = () => {
  const { getIsFreePlan } = usePlansTiers();
  const subscription = useSubscription();
  const nextSubscription = subscription?.nextSubscription;
  const isReactivationAllowed = !!subscription && getSubscriptionAllowedActions(subscription).reactivate;

  const nextSubscriptionPlanId = nextSubscription?.planId;
  const pendingPlanInfo = usePlanInfo(nextSubscriptionPlanId);

  const isPendingPlanFree = isReactivationAllowed && !!nextSubscriptionPlanId && getIsFreePlan(nextSubscriptionPlanId);

  return {
    pendingPlanInfo,
    nextSubscriptionPlanId,
    isReactivationAllowed,
    isPendingPlanFree,
  };
};
