import { Button, Form, Group, Link, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { useTrackFormError } from '../hooks/useTrackFormError';
import { FXFormSectionCardBaseProps, TermsAndConditionsForm } from '../types';

export type TermsAndConditionsSectionProps = Omit<
  FXFormSectionCardBaseProps<TermsAndConditionsForm>,
  'isCompleted' | 'onExpandChange' | 'isExpanded'
>;

export const TermsAndConditionsSection = ({ form, isLoading, isDisabled }: TermsAndConditionsSectionProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const { links: linksConfig } = useConfig();

  useAnalyticsView('Organization', !isLoading, true, {
    PageName: 'terms-and-conditions',
    Intent: 'set-terms-and-conditions',
  });

  const onClickDone = () => {
    track('Organization', 'Click', {
      Cta: 'Done',
      Flow: 'UBO',
      PageName: 'terms-and-conditions',
      Intent: 'set-terms-and-conditions-details',
    });
    form.submitButtonProps.onClick();
  };

  useTrackFormError<TermsAndConditionsForm>({
    form,
    pageName: 'terms-and-conditions',
    intent: 'set-cterms-and-conditions-details',
    viewContext: 'Organization',
  });

  return (
    <Group variant="vertical">
      <Group variant="vertical" spacing="xs">
        <Form {...form.formProps} columns={6}>
          <Form.Checkbox
            {...form.registerField('termsAndConditions')}
            colSpan={6}
            label={formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.termsAndConditionsCard.termsAndConditions.label',
              {
                link: (
                  <Link
                    newTab
                    href={linksConfig['fx.legal.termsAndConditions']}
                    label={formatMessage(
                      'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.termsAndConditionsCard.termsAndConditions.label.link'
                    )}
                  />
                ),
              }
            )}
          />
        </Form>
        <Text color="global.neutral.700">
          {formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.termsAndConditionsCard.termsAndConditions.helper'
          )}
        </Text>
      </Group>

      <Button
        {...form.submitButtonProps}
        isLoading={isLoading}
        isDisabled={isDisabled}
        data-testid="ubo-form-submit-button"
        label={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.termsAndConditionsCard.doneButton.label'
        )}
        isFullWidth
        onClick={onClickDone}
      />
    </Group>
  );
};
