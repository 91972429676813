/* eslint-disable */
import { Group, Skeleton } from '@melio/penny';

export function FormSkeleton() {
  return (
    <Group data-testid="form-skeleton" variant="vertical" spacing="xl">
      <FundingSourceSkeleton />
      <DeliveryDetailsSkeleton />
      <FeeSkeleton />
    </Group>
  );
}

function FundingSourceSkeleton() {
  return (
    <Group variant="vertical" spacing="xs">
      <SkeletonWithWidth width="220px" height="20px" />

      <Group variant="vertical" spacing="s">
        <Group spacing="xs">
          <SkeletonWithWidth width={'100px'} height="20px" />
          <SkeletonWithWidth width={'100px'} height="20px" />
          <SkeletonWithWidth width={'100px'} height="20px" />
        </Group>

        <SkeletonWithWidth width={'100%'} height="76px" borderRadii={'10px'} />
      </Group>
    </Group>
  );
}

function DeliveryDetailsSkeleton() {
  return (
    <Group variant="vertical" spacing="s">
      <SkeletonWithWidth width={'220px'} height="20px" />
      <Group variant="vertical" spacing="xs">
        <SkeletonWithWidth width={'120px'} height="20px" />
        <SkeletonWithWidth width={'100%'} height="76px" borderRadii={'10px'} />
      </Group>
      <Group variant="vertical" spacing="xs">
        <SkeletonWithWidth width={'120px'} height="20px" />
        <SkeletonWithWidth width={'100%'} height="76px" borderRadii={'10px'} />
      </Group>
    </Group>
  );
}

function FeeSkeleton() {
  return (
    <Group variant="vertical">
      <SkeletonWithWidth width={'220px'} height="20px" />
      <Group variant="vertical" spacing="xs">
        <SkeletonWithWidth width={'120px'} height="20px" />
        <SkeletonWithWidth width={'100px'} height="20px" />
      </Group>
    </Group>
  );
}

function SkeletonWithWidth({ width, height, borderRadii }: { width: string; height: string; borderRadii?: string }) {
  return (
    <Skeleton
      height={height}
      // @ts-ignore
      style={{ borderRadius: borderRadii, width }}
    />
  );
}
