import { ApiQueryKeyV2, createQueryKeyV2 } from '@melio/api-client/src/core';
import { EntityQueryKeyV2 } from '@melio/api-client/src/core/types';
import { PaginationCursor, PaginationResponse } from '@melio/javascript-sdk';
// eslint-disable-next-line no-restricted-imports
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useCallback, useLayoutEffect, useState } from 'react';

import { defaultScope, UsePaginatedCollection } from '.';
import { ApiError, MelioUseQueryOptions, Scope } from './types';

type Paging = {
  paging?: {
    limit: number;
  };
};

type PagePrams = {
  cursor?: PaginationCursor;
  limit?: number;
};

export type PaginatedQueryFunctionContext = QueryFunctionContext<ApiQueryKeyV2, PagePrams>;

export type PaginatedQueryQueryFunction<T extends PaginationResponse = PaginationResponse> = (
  context: PaginatedQueryFunctionContext
) => T | Promise<T>;

export type UsePaginatedCollectionOptions<TData extends PaginationResponse> = MelioUseQueryOptions<TData> &
  Paging & {
    scope?: Scope;
  };

export function usePaginatedCollectionApi<
  TData extends PaginationResponse,
  TQueryKey extends EntityQueryKeyV2 = EntityQueryKeyV2
>(
  queryKey: TQueryKey,
  queryFn: PaginatedQueryQueryFunction<TData>,
  options?: UsePaginatedCollectionOptions<TData>
): UsePaginatedCollection<TData> {
  const [pageParam, setPageParam] = useState<PagePrams>();
  const _queryKey = createQueryKeyV2({
    queryKey,
    role: 'paginated-collection',
    scope: defaultScope,
    params: [pageParam],
  });

  const query = useQuery<TData, ApiError, TData, ApiQueryKeyV2>(
    _queryKey,
    (context: PaginatedQueryFunctionContext) => queryFn({ ...context, pageParam }),
    {
      ...options,
      keepPreviousData: true,
    }
  );

  const queryKeyDependency = JSON.stringify(queryKey);
  useLayoutEffect(() => {
    setPageParam(undefined);
  }, [queryKeyDependency]);

  const nextCursor = query.data?.pagination.cursors.next;
  const prevCursor = query.data?.pagination.cursors.prev;

  const fetchNextPage = useCallback(() => {
    if (!nextCursor) {
      return;
    }

    setPageParam({
      cursor: {
        next: nextCursor,
      },
      limit: options?.paging?.limit,
    });
  }, [nextCursor, options?.paging?.limit]);
  const fetchPreviousPage = useCallback(() => {
    if (!prevCursor) {
      return;
    }

    setPageParam({
      cursor: {
        prev: prevCursor,
      },
      limit: options?.paging?.limit,
    });
  }, [prevCursor, options?.paging?.limit]);
  const fetchFirstPage = useCallback(() => {
    setPageParam(undefined);
  }, []);

  return {
    ...query,
    isLoading: query.isInitialLoading,
    queryKey: _queryKey,
    hasNextPage: Boolean(nextCursor),
    hasPreviousPage: Boolean(prevCursor),
    fetchNextPage,
    fetchPreviousPage,
    fetchFirstPage,
  } as UsePaginatedCollection<TData>;
}
