import { ReceivablePayment } from '@melio/ar-domain';
import { Group, Table, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type PayerCellProps = Parameters<typeof Table.Cell>[0] & {
  row: ReceivablePayment;
};

export const PayerCell = forwardRef<PayerCellProps>(({ row }, ref) => (
  <Table.Cell ref={ref} data-testid="payer-name">
    <Group spacing="xxs" variant="vertical">
      {row.payorDetails?.companyName}
      <Text textStyle="body4" color="semantic.text.secondary" data-testid="payer-email">
        {row.payorDetails?.email}
      </Text>
    </Group>
  </Table.Cell>
));
