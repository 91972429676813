import { FormattedCurrency, FormattedMessage } from '@melio/ar-domain';
import { FileAttachment, Group, LoadingContainer, StatusIconSolid, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { BaseLayout, BaseLayoutProps } from '../../../layout';

export type InvoicePaidScreenProps = {
  amount?: number;
  invoicePreviewUrl?: string;
  isMarkedAsPaid: boolean;
  isLoading?: boolean;
  onViewInvoice: VoidFunction;
} & Pick<BaseLayoutProps, 'backgroundColor'>;

export const InvoicePaidScreen = forwardRef<InvoicePaidScreenProps>(
  ({ amount = 0, isMarkedAsPaid, invoicePreviewUrl, onViewInvoice, isLoading, ...props }, ref) => {
    useAnalyticsView('PaymentRequest', true, true, {
      PageName: isMarkedAsPaid ? 'payment-request-marked-paid' : 'payment-request-already-paid',
    });

    return (
      <BaseLayout
        data-component={InvoicePaidScreen.displayName}
        data-testid="invoice-paid-screen"
        data-source={isMarkedAsPaid ? 'marked-as-paid' : 'paid'}
        {...props}
        ref={ref}
      >
        <LoadingContainer isLoading={isLoading}>
          <Group justifyContent="space-between">
            <Group variant="vertical" justifyContent="space-between">
              <StatusIconSolid variant="success" size="extra-large" />
              <Text textStyle="heading3Semi" color="global.neutral.1000">
                <FormattedMessage
                  id={
                    isMarkedAsPaid
                      ? 'ar.guestPayment.invoicePaid.markedAsPaid.title'
                      : 'ar.guestPayment.invoicePaid.alreadyScheduled.title'
                  }
                />
              </Text>
              <Text data-testid="payment-confirmation-amount" textStyle="heading1Semi">
                <FormattedCurrency amount={amount} />
              </Text>
            </Group>
            {invoicePreviewUrl && (
              <FileAttachment onViewModeClick={onViewInvoice} value={invoicePreviewUrl} isViewMode width={90} />
            )}
          </Group>
        </LoadingContainer>
      </BaseLayout>
    );
  }
);

InvoicePaidScreen.displayName = 'InvoicePaidScreen';
