import { ApiKeys, DeliveryMethodsApiClient, Vendor } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export type UseDeliveryMethodTypeOptionsProps = UseQueryOptions<
  typeof DeliveryMethodsApiClient.getDeliveryMethodsTypeOptions
> & {
  vendorId?: Vendor['id'];
};
export const useDeliveryMethodTypeOptions = (props?: UseDeliveryMethodTypeOptionsProps) => {
  const { vendorId, onError, suspense } = props ?? {};
  const { data, ...rest } = useQuery({
    queryKey: [ApiKeys.DeliveryMethodsApi, 'options', vendorId],
    queryFn: () => DeliveryMethodsApiClient.getDeliveryMethodsTypeOptions(undefined, vendorId),
    onError,
    suspense,
  });

  return {
    data: data?.data?.data,
    ...rest,
    isLoading: rest.isInitialLoading,
  };
};
