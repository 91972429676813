import { Box } from '@chakra-ui/react';
import { BrandSymbol, BrandSymbolKey, Card, Collapse, Container, Divider, Group, Icon, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { max, range } from 'lodash';
import { useState } from 'react';

type Entity = {
  brandSymbolKey: BrandSymbolKey;
  title: string;
  subTitle?: string;
  bodyItems: (string | undefined)[];
};

export type EntityMatchingCardWidgetProps = {
  entityA: Entity;
  entityB: Entity;
  isHighlighted?: boolean;
  onCardOpen?: () => void;
};

export const EntityMatchingCardWidget = forwardRef<EntityMatchingCardWidgetProps>(
  ({ isHighlighted = false, entityA, entityB, onCardOpen, ...props }, ref) => {
    const [isOpened, setIsOpened] = useState(false);

    const titleAlignSelf = entityA.subTitle || entityB.subTitle ? 'stretch' : 'center';

    const getTitle = (titleText: string, brandSymbolKey: BrandSymbolKey, subTitle?: string) => {
      const titleTextColor = isHighlighted ? 'semantic.text.primary' : 'semantic.text.secondary';

      return (
        <Group.Item grow={1} shrink={1} basis={0} alignSelf={titleAlignSelf}>
          <Group spacing="xs" height="full">
            <Group.Item shrink={0}>
              <Container paddingTop="xxxs">
                <BrandSymbol type={brandSymbolKey} aria-hidden size="small" />
              </Container>
            </Group.Item>
            <Group variant="vertical" spacing="none" justifyContent="space-between">
              <Text textStyle="body2Semi" color={titleTextColor}>
                {titleText}
              </Text>
              {subTitle && (
                <Text textStyle="body3" color={titleTextColor}>
                  {subTitle}
                </Text>
              )}
            </Group>
          </Group>
        </Group.Item>
      );
    };

    const getBodyLine = (itemA = '—', itemB = '—') => (
      <Group>
        <Container paddingLeft="m" paddingRight="l">
          <Box wordBreak="break-word">
            <Text textStyle="body3" color="semantic.text.secondary">
              {itemA}
            </Text>
          </Box>
        </Container>
        <Container paddingLeft="m" paddingRight="l">
          <Box wordBreak="break-word">
            <Text textStyle="body3" color="semantic.text.secondary">
              {itemB}
            </Text>
          </Box>
        </Container>
      </Group>
    );

    const maxIndex = max([entityA.bodyItems.length, entityB.bodyItems.length]) ?? 0;

    const bodyItems = range(maxIndex).map((i) => (
      <Group.Item key={i}>{getBodyLine(entityA.bodyItems[i], entityB.bodyItems[i])}</Group.Item>
    ));

    return (
      <Card
        ref={ref}
        variant="flat"
        onClick={() => {
          !isOpened && onCardOpen?.();
          setIsOpened(!isOpened);
        }}
        paddingX="s"
        paddingY="s"
        data-component="EntityMatchingCardWidget"
        {...props}
      >
        <Container width="full">
          <Group width="full" justifyContent="space-between" alignItems="center">
            {getTitle(entityA.title, entityA.brandSymbolKey, entityA.subTitle)}

            <Group.Item shrink={0}>
              <Container color="global.neutral.800" paddingTop="xs">
                <Icon type="sync" aria-label="icon-button-naked-up" color="inherit" />
              </Container>
            </Group.Item>

            {getTitle(entityB.title, entityB.brandSymbolKey, entityB.subTitle)}

            <Group.Item shrink={0}>
              <Container color="global.neutral.800" paddingTop="xs">
                <Icon
                  type={isOpened ? 'chevron-up' : 'chevron-down'}
                  aria-label="icon-button-naked-up"
                  color="inherit"
                />
              </Container>
            </Group.Item>
          </Group>

          <Collapse in={isOpened}>
            <Container paddingY="s">
              <Divider />
            </Container>
            <Group variant="vertical" spacing="xxs">
              {bodyItems}
            </Group>
          </Collapse>
        </Container>
      </Card>
    );
  }
);

EntityMatchingCardWidget.displayName = 'EntityMatchingCardWidget';
