import { addWildcardToRoutes, useNavigate, UseNavigateOptions } from '@melio/ar-domain';

import { InvoiceDashboardQueryParams } from '../../../activities';

const Paths = {
  Invoices: 'invoices',
  Customers: 'customers',
  Payments: 'payments',
};

type DashboardFlowPathsResult = {
  RedirectPaths: typeof Paths;
  Paths: typeof Paths;
  DefaultPath: string;
  goToCustomersDashboard: VoidFunction;
  goToInvoicesDashboard: (params?: InvoiceDashboardQueryParams) => void;
};

export function useDashboardFlowNavigation(options?: UseNavigateOptions): DashboardFlowPathsResult;
export function useDashboardFlowNavigation(options?: UseNavigateOptions) {
  const navigate = useNavigate(options);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    DefaultPath: Paths.Invoices,
    goToCustomersDashboard: () => navigate(Paths.Customers),
    goToInvoicesDashboard: ({ searchTerm, customerId }: InvoiceDashboardQueryParams = {}) => {
      const queryParams: InvoiceDashboardQueryParams = {
        ...(searchTerm && { searchTerm }),
        ...(customerId && { customerId }),
      };

      navigate(Paths.Invoices, { queryParams });
    },
  };
}
