import { useLegalDocuments } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useLocation } from '@melio/platform-utils';
import { Navigate, Outlet } from 'react-router-dom';

import { isRouteAllowedIfTermsAndConditionsNotSigned, TERMS_AND_CONDITIONS_ROUTE } from './TermsAndConditions.utils';

export const TermsAndConditions = () => {
  const { pathname } = useLocation();
  const [signTermsAndConditionsEnabled] = usePartnerFeature('TermsAndConditions', false);

  const { data: legalDocuments, isFetching } = useLegalDocuments({ signTermsAndConditionsEnabled });

  return !isRouteAllowedIfTermsAndConditionsNotSigned(pathname) &&
    !isFetching &&
    legalDocuments?.shouldSignTermsAndConditions ? (
    <Navigate to={TERMS_AND_CONDITIONS_ROUTE} replace />
  ) : (
    <Outlet /> // Render children
  );
};
