import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { FilesApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from '@tanstack/react-query';

type QueryFn = typeof FilesApiClient.getFilesFileId;

export type UseFileProps = UseModelApiProps<QueryFn>;

export const useFile = (props: UseFileProps) => {
  const queryClient = useQueryClient();
  const query = useModelApi<QueryFn>({
    ...props,
    queryKey: ['FilesApi', props.id],
    queryFn: () => FilesApiClient.getFilesFileId(props.id as string),
  });

  const getOCRData = useMutation(
    ['FilesApi', 'getOCRData'],
    async (id?: string) => {
      const rest = await FilesApiClient.getFilesFileIdData(id ?? (props.id as string));
      return rest.data.data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['FilesApi', 'getOCRData']),
    }
  );

  return {
    ...query,
    getOCRData: getOCRData.mutateAsync,
  };
};
