import { Button, Group, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback, useEffect } from 'react';

type StepperButtonTourProps = {
  currentStep: number;
  setStep: (step: number) => void;
  numberOfSteps: number;
  onDone: () => void;
};

export const StepperButtonTour = withAnalyticsContext<StepperButtonTourProps>(
  ({ currentStep, numberOfSteps, setStep, onDone }: StepperButtonTourProps) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    useEffect(() => {
      setTimeout(() => {
        //we need to do it for ada react joiride support focus only if you use there tool tip
        const tooltipButton = document.getElementById('stepper-buttons-tour-button');
        if (tooltipButton) {
          tooltipButton.focus();
        }
      }, 200);
    }, []);

    const sendAnalytics = useCallback(
      (Cta: 'Done' | 'Previous' | 'Next') => {
        track('Dashboard', 'Click', {
          StepOfTotal: `${currentStep + 1}/${numberOfSteps}`,
          Cta,
        });
      },
      [currentStep, numberOfSteps, track]
    );

    return (
      <Group
        data-testid="stepper-buttons-tour"
        variant="horizontal"
        spacing="s"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text data-testid="stepper-buttons-tour-pagination" textStyle="body4Semi" color="semantic.text.inverse">
          {formatMessage('activities.migrationUserDashboardTour.stepper.pagination.separator', {
            stepNumber: currentStep + 1,
            numberOfSteps,
          })}
        </Text>
        <Group variant="horizontal" spacing="s">
          {currentStep > 0 && (
            <Button
              data-testid="stepper-buttons-tour-back-button"
              onClick={() => {
                sendAnalytics('Previous');
                setStep(currentStep - 1);
              }}
              label={formatMessage('activities.migrationUserDashboardTour.stepper.prevButton.label')}
              variant="secondary-inverse"
              size="small"
            />
          )}
          {currentStep < numberOfSteps - 1 && (
            <Button
              id="stepper-buttons-tour-button"
              data-testid="stepper-buttons-tour-next-button"
              data-action="primary"
              onClick={() => {
                sendAnalytics('Next');
                setStep(currentStep + 1);
              }}
              label={formatMessage('activities.migrationUserDashboardTour.stepper.nextButton.label')}
              variant="secondary-inverse"
              size="small"
            />
          )}
          {currentStep === numberOfSteps - 1 && (
            <Button
              id="stepper-buttons-tour-button"
              data-testid="stepper-buttons-tour-done-button"
              data-action="primary"
              onClick={() => {
                sendAnalytics('Done');
                setStep(currentStep + 1);
                onDone();
              }}
              label={formatMessage('activities.migrationUserDashboardTour.stepper.doneButton.label')}
              variant="secondary-inverse"
              size="small"
            />
          )}
        </Group>
      </Group>
    );
  }
);
