import { useFundingSourceFeeValueFormatting } from '@melio/ap-widgets/src/components/funding-sources/utils';
import { Card, Group, Icon, IconKey, Pill, Text } from '@melio/penny';
import { FundingSourceType } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onClick: VoidFunction;
  type: FundingSourceType;
};

export const FundingSourceSectionEmptyState = ({ onClick, type }: Props) => {
  const { formatMessage } = useMelioIntl();

  const iconKey = getIconKey(type);
  const messages = getMessagesByType(type);

  const { formatFeeValue } = useFundingSourceFeeValueFormatting();
  const feeValue = formatFeeValue(type);
  return (
    <Card
      data-testid="payment-flow-form-funding-source-empty-state"
      variant="flat"
      paddingX="s"
      paddingY="s"
      onClick={onClick}
    >
      <Group width="full" variant="horizontal" alignItems="center" justifyContent="space-between">
        <Group variant="horizontal" alignItems="center">
          <Icon type={iconKey} />
          <Group width="full" variant="vertical" spacing="xxxs">
            <Group>
              <Text textStyle="body2Semi" data-testid="payment-flow-form-funding-source-empty-state-title">
                {formatMessage(messages.title)}
              </Text>
              {feeValue && (
                <Pill
                  label={feeValue}
                  status="brand"
                  type="secondary"
                  data-testid="payment-flow-form-funding-source-empty-state-fee-pill"
                />
              )}
            </Group>
            <Text
              data-testid="payment-flow-form-funding-source-empty-state-description"
              textStyle="body3"
              color="global.neutral.800"
            >
              {formatMessage(messages.description)}
            </Text>
          </Group>
        </Group>
        <Icon type="chevron-right" />
      </Group>
    </Card>
  );
};

const getMessagesByType = (type: FundingSourceType): { title: MessageKey; description: MessageKey } => {
  switch (type) {
    default:
    case FundingSourceType.Card:
      return {
        title: 'activities.paymentFlow.form.content.fundingSourceCard.empty.card.title',
        description: 'activities.paymentFlow.form.content.fundingSourceCard.empty.card.description',
      };
    case FundingSourceType.BankAccount:
      return {
        title: 'activities.paymentFlow.form.content.fundingSourceCard.empty.bank.title',
        description: 'activities.paymentFlow.form.content.fundingSourceCard.empty.bank.description',
      };
  }
};

const getIconKey = (type: FundingSourceType): IconKey => {
  switch (type) {
    default:
    case FundingSourceType.Card:
      return 'credit-card';
    case FundingSourceType.BankAccount:
      return 'bank';
  }
};
