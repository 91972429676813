import { createQueryKey, useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import {
  AccountingPlatformsApiClient,
  PutAccountingPlatformsSyncActivateRequestSyncActivationTypeEnum,
} from '@melio/platform-api-axios-client';

export { PutAccountingPlatformsSyncActivateRequestSyncActivationTypeEnum as SyncActivationTypeEnum };

export const useAccountingPlatformSyncActivation = () => {
  const queryClient = useMelioQueryClient();

  const onSuccess = async () => queryClient.invalidateQueries('AccountingPlatformsApi');

  return useNonEntityMutation(
    (params: {
      accountingPlatformId: string;
      syncActivationType: PutAccountingPlatformsSyncActivateRequestSyncActivationTypeEnum;
    }) =>
      AccountingPlatformsApiClient.accountingPlatformSyncActivation(params.accountingPlatformId, {
        syncActivationType: params.syncActivationType,
      }),
    createQueryKey({ queryKey: 'AccountingPlatformsApi', role: 'model', scope: 'default' }),
    {
      onSuccess,
    }
  );
};
