import { Icon, LoadingContainer, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export const AccountingClientPlanSelectionStepDiscountBanner = ({
  discountPercent,
  isLoading,
}: {
  discountPercent?: number;
  isLoading: boolean;
}) => {
  const formattedDiscount = String(discountPercent);
  const { formatMessage } = useMelioIntl();

  return (
    <LoadingContainer isLoading={isLoading}>
      <SectionBanner
        title={formatMessage('activities.accountants.manageClient.form.plan.discount.banner.title', {
          discount: formattedDiscount,
        })}
        description={formatMessage('activities.accountants.manageClient.form.plan.discount.banner.description', {
          discount: formattedDiscount,
        })}
        variant="brand"
        leftElement={<Icon type="discount" color="brand" aria-hidden />}
      />
    </LoadingContainer>
  );
};
