import { useInternationalPaymentCardRestrictionsText } from '@melio/ap-domain';
import { Tooltip } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  FundingSource,
  OrganizationBusinessType,
  UnsupportedDeliveryMethodTypeReason,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getSupportedInternationalType, SupportedInternationalType } from '../../utils/delivery-method-utils';
import { AddDeliveryMethodCard } from '../cards';

type InternationalAddDmCardProps = {
  supported: boolean;
  reason: UnsupportedDeliveryMethodTypeReason | undefined;
  fundingSource?: FundingSource;
  vendorCurrency?: Vendor['currency'];
  businessType?: OrganizationBusinessType | null;
  enabledInternationalUSDWise: boolean;
  isInternationalFxEnabled: boolean;
  onAdd: (isWise: boolean) => void;
};

export const InternationalAddDmCard = ({
  supported,
  reason,
  fundingSource,
  vendorCurrency,
  businessType,
  enabledInternationalUSDWise,
  isInternationalFxEnabled,
  onAdd,
}: InternationalAddDmCardProps) => {
  const supportedInternationalType = getSupportedInternationalType({
    isInternationalFxEnabled,
    vendorCurrency,
    businessType,
  });

  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const internationalPaymentRestrictionsLabelProvider = useInternationalPaymentCardRestrictionsText();

  const getTooltip = (
    supported: boolean,
    reason: UnsupportedDeliveryMethodTypeReason | undefined,
    fundingSource?: FundingSource
  ): string | false => {
    if (supported) {
      return false;
    }
    switch (reason) {
      case 'cardFundingSourceNotSupported':
        return internationalPaymentRestrictionsLabelProvider(fundingSource);
      case 'deliveryMethodNotSupportedForState':
        return formatMessage('app.blockedInternationalPayments.tooltip');
      default:
        return false;
    }
  };

  const getAddDeliveryMethodCard = (isWise: boolean, internationalCurrency: SupportedInternationalType) => {
    const newDeliveryMethodCard = (
      <AddDeliveryMethodCard
        type="international-account"
        supported={supported}
        reason={reason}
        supportedInternationalType={internationalCurrency}
        fundingSourceType={fundingSource?.type}
        onClick={() => {
          track('DeliveryMethod', 'Click', {
            DeliveryMethodChosen: internationalCurrency,
            PageName: 'choose-delivery-method',
            Cta: internationalCurrency,
          });
          onAdd(isWise);
        }}
      />
    );

    const tooltip = getTooltip(supported, reason, fundingSource);
    if (tooltip) {
      return (
        <Tooltip data-testid="new-delivery-method-tooltip-international-account" content={tooltip}>
          {newDeliveryMethodCard}
        </Tooltip>
      );
    }

    return newDeliveryMethodCard;
  };

  switch (supportedInternationalType) {
    case 'international-usd':
      return getAddDeliveryMethodCard(enabledInternationalUSDWise, 'international-usd');
    case 'international-fx':
      return getAddDeliveryMethodCard(true, 'international-fx');
    case 'international':
  }

  if (enabledInternationalUSDWise) {
    return getAddDeliveryMethodCard(true, 'international');
  }
  return (
    <>
      {getAddDeliveryMethodCard(false, 'international-usd')}
      {getAddDeliveryMethodCard(true, 'international-fx')}
    </>
  );
};
