import { useAccountingPlatformsPermissions } from '@melio/ap-domain';
import { SyncNowWidget } from '@melio/ap-widgets';
import { AccountingPlatform } from '@melio/platform-api';

import { AccountingPlatformConnectButton } from './AccountingPlatformConnectButton';

type Props = {
  accountingPlatform?: AccountingPlatform;
  analyticsProperties: Record<string, string>;
  goToSettingsAccountSoftware: VoidFunction;
};

export const SyncNowButton = ({ accountingPlatform, analyticsProperties, goToSettingsAccountSoftware }: Props) => {
  const { canConnect } = useAccountingPlatformsPermissions();

  if (accountingPlatform) {
    return <SyncNowWidget accountingPlatform={accountingPlatform} analyticsProperties={analyticsProperties} />;
  }

  return canConnect ? (
    <AccountingPlatformConnectButton
      analyticsProperties={analyticsProperties}
      onAccountingPlatformConnect={goToSettingsAccountSoftware}
    />
  ) : null;
};
