import { Plan, PlanInfo, useMelioIntl } from '@melio/ar-domain';
import { Grid } from '@melio/penny';
import { PlanCardView } from '@melio/subscriptions';

export type SubscriptionPlanCardProps = {
  planInfo: PlanInfo;
  plan: Plan;
};

export const SubscriptionPlanCard = ({ plan, planInfo }: SubscriptionPlanCardProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Grid
      padding="4px"
      justifyContent="center"
      gap="m"
      gridTemplateColumns="1fr"
      data-testid="firm-client-subscription-plan-selection-screen"
    >
      <PlanCardView
        id={plan.id}
        headerProps={{
          planName: formatMessage('ar.onboarding.activities.subscription.plan.name'),
          planDescription: formatMessage('ar.onboarding.activities.subscription.plan.description'),
        }}
        priceProps={{
          finalPrice: plan.cycles.monthly.basePrice,
          isFreePlan: false,
          selectedBillingCycle: 'monthly',
          unitPrice: plan.cycles.monthly.unitPrice,
        }}
        featureListProps={{
          capabilities: planInfo.capabilities,
        }}
      />
    </Grid>
  );
};
