import { AccountsLimitationsV2ApiClient, ApiKeys } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@tanstack/react-query';

export const useAccountMeLimitationsV2 = (stage: 'dashboard' | 'payment', isInitiator?: boolean, enabled?: boolean) => {
  const queryKey = [ApiKeys.AccountsLimitationsV2Api];

  const query = useQuery({
    queryKey,
    enabled,
    queryFn: async () => {
      const result = await AccountsLimitationsV2ApiClient.getAccountsMeLimitationsV2(
        stage,
        stage === 'payment' ? isInitiator : undefined
      );

      return result.data.data;
    },
  });

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
