/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  getFastDeliveryPreference,
  getRegularDeliveryPreference,
  isDeliveryPreferenceTypeCheck,
} from '@melio/ap-domain';
import { FundsDebitDateSection } from '@melio/ap-widgets';
import { Link, SectionBanner, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod, DeliveryPreference, FundingSource } from '@melio/platform-api';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import React, { useCallback, useEffect } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { EndOfYearCheckBanner } from '../EndOfYearCheckBanner';
import { MultipleDeliveryOptionsForm } from './components/MultipleDeliveryOptionsForm';
import { SingleDeliveryOptionForm } from './components/SingleDeliveryOptionForm';
import { DeliveryDateFormFields } from './types';
import { buildDeliveryPreferencesWithEarliestFastDates } from './utils';

export type DeliveryDateScreenProps = {
  selectedDate: Date;
  selectedDeliveryPreferenceType: DeliveryPreference['type'];
  dueDate: Date;
  fundingSource: FundingSource;
  deliveryMethod: DeliveryMethod;
  deliveryPreferences: DeliveryPreference[];
  isLoading?: boolean;
  onSelectDate: (date: Date) => unknown;
  onDone: (type: DeliveryPreference['type'], date: Date) => unknown;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
  step: number;
  totalSteps: number;
};

export const DeliveryDateScreen = ({
  dueDate,
  onBack,
  onClose,
  selectedDate,
  isLoading,
  fundingSource,
  deliveryMethod,
  deliveryPreferences,
  selectedDeliveryPreferenceType,
  step,
  totalSteps,
  onDone,
  onSelectDate,
  ...props
}: DeliveryDateScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { settings, links } = useConfig();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<DeliveryDateFormFields>();
  const [eoyCheckEnabled] = usePartnerFeature('eoyCheck', false);

  const deliveryPreferencesWithEarliestFastDates = buildDeliveryPreferencesWithEarliestFastDates(deliveryPreferences);
  const regularDeliveryOption = getRegularDeliveryPreference(deliveryPreferencesWithEarliestFastDates);
  const fastDeliveryOption = getFastDeliveryPreference(deliveryPreferencesWithEarliestFastDates);
  const isCheck = isDeliveryPreferenceTypeCheck(selectedDeliveryPreferenceType);
  const hasFastCheck = isCheck && !!fastDeliveryOption;
  const isUnverifiedBankAccount = fundingSource.type === 'bank-account' && !fundingSource.isVerified;
  const isUnilateral = deliveryMethod.type === 'virtual-account';
  const { track } = useAnalytics();
  const [isFofEnabled] = usePartnerFeature('FofImprovements', false);
  const showFundsDebitDateSection = isFofEnabled && !isUnilateral;

  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
    endAction('delivery_method_selection');
    endAction('payment_purpose');
  }, [endAction]);

  useEffect(() => {
    track('Payment', 'View', {
      PageName: 'choose-a-delivery-date',
    });
  }, []);

  const handleSelectDate = (date: Date) => {
    onSelectDate(date);
    track('Payment', 'Click', {
      PageName: 'choose-a-delivery-date',
      Cta: 'edit-date',
    });
  };

  const handleSubmit = ({ deliveryPreferenceType }: DeliveryDateFormFields) => {
    startAction('delivery_date_selection');

    const selectedDeliveryPreference = deliveryPreferencesWithEarliestFastDates.find(
      (deliveryPreference) => deliveryPreference.type === deliveryPreferenceType
    );

    if (selectedDeliveryPreference) {
      onDone(deliveryPreferenceType, selectedDeliveryPreference.minDeliveryDate);
      track('Payment', 'Click', {
        PageName: 'choose-a-delivery-date',
        Cta: 'continue',
      });
    }
  };

  if (!regularDeliveryOption) {
    return null;
  }

  return (
    <NewSinglePaymentStepLayout
      data-component="DeliveryDateActivity.DeliveryDateScreen"
      data-testid="delivery-date-activity-delivery-date-screen"
      ref={endActionMonitoring}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          <NewSinglePaymentStepLayout.BackButton onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            label={formatMessage('activities.deliveryDate.screens.deliveryDate.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        <FormattedMessage id="activities.deliveryDate.screens.deliveryDate.title" />
      </NewSinglePaymentStepLayout.Title>
      {isCheck && (
        <NewSinglePaymentStepLayout.Description>
          {formatMessage(
            hasFastCheck
              ? 'activities.deliveryDate.screens.deliveryDate.check.multi.description'
              : 'activities.deliveryDate.screens.deliveryDate.check.single.description',
            {
              regularBusinessDays: regularDeliveryOption.totalBusinessDays,
              fastBusinessDays: fastDeliveryOption?.totalBusinessDays,
            }
          )}
        </NewSinglePaymentStepLayout.Description>
      )}
      {isCheck && eoyCheckEnabled && settings.eoyCheck.enabled ? (
        <NewSinglePaymentStepLayout.Content>
          <EndOfYearCheckBanner
            link={settings.eoyCheck.link}
            isFastCheck={hasFastCheck}
            promoteFastCheck={settings.eoyCheck.promoteFastCheck}
          />
        </NewSinglePaymentStepLayout.Content>
      ) : null}
      {isUnverifiedBankAccount ? (
        <NewSinglePaymentStepLayout.Content>
          <SectionBanner
            data-testid="delivery-screen-unverified-bank-account-banner"
            description={formatMessage('activities.deliveryDate.screens.deliveryDate.unverifiedBankAccountDisclaimer', {
              accountNumber: fundingSource.details.accountNumberLast4Digits,
            })}
            variant="informative"
          />
        </NewSinglePaymentStepLayout.Content>
      ) : null}
      {isUnilateral ? (
        <NewSinglePaymentStepLayout.Content>
          <SectionBanner
            data-testid="delivery-screen-unilateral-banner"
            description={formatMessage('activities.deliveryDate.screens.deliveryDate.unilateralDisclaimer', {
              learnMoreLink: (
                <Link
                  href={links?.unilateralDeliveryLearnMore}
                  label={formatMessage('activities.deliveryDate.screens.deliveryDate.endOfYearCheck.learnMoreLink')}
                  newTab
                />
              ),
              totalBusinessDays: regularDeliveryOption.totalBusinessDays,
              totalMaxBusinessDays: regularDeliveryOption.totalMaxBusinessDays,
            })}
            variant="informative"
          />
        </NewSinglePaymentStepLayout.Content>
      ) : null}

      <NewSinglePaymentStepLayout.Content>
        {deliveryPreferencesWithEarliestFastDates.length > 1 ? (
          <MultipleDeliveryOptionsForm
            fundingSource={fundingSource}
            deliveryPreferences={deliveryPreferencesWithEarliestFastDates}
            minDate={regularDeliveryOption.earliestDeliveryDate}
            onSelectDate={handleSelectDate}
            onSubmit={handleSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            isLoading={isLoading}
            defaultValues={{ deliveryDate: selectedDate, deliveryPreferenceType: selectedDeliveryPreferenceType }}
          />
        ) : (
          <SingleDeliveryOptionForm
            paymentFee={regularDeliveryOption.fee}
            debitDate={regularDeliveryOption.effectiveScheduleDate}
            minDate={regularDeliveryOption.earliestDeliveryDate}
            onSelectDate={handleSelectDate}
            onSubmit={handleSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            isLoading={isLoading}
            showHelperText={!showFundsDebitDateSection}
            values={{ deliveryDate: selectedDate, deliveryPreferenceType: selectedDeliveryPreferenceType }}
          />
        )}
      </NewSinglePaymentStepLayout.Content>
      {showFundsDebitDateSection && (
        <FundsDebitDateSection
          isDmCheck={isCheck}
          showTitle
          daysToDebit={regularDeliveryOption.totalBusinessDays}
          debitDate={regularDeliveryOption.effectiveScheduleDate}
        />
      )}
    </NewSinglePaymentStepLayout>
  );
};
DeliveryDateScreen.displayName = 'DeliveryDateActivity.DeliveryDateScreen';
