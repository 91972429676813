/* eslint-disable no-restricted-imports */
import { useModelApi, UseModelApiProps } from '@melio/api-client';
/* eslint-disable no-restricted-imports */
import {
  AccountingFirmOrganizationBillingFeeApiClient,
  PutAccountingFirmBillingFeesRequest,
} from '@melio/platform-api-axios-client';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';

const queryFn = () => AccountingFirmOrganizationBillingFeeApiClient.getAccountingFirmBillingFees();
export type UseOrgBillingFeeSettingsProps = UseModelApiProps<typeof queryFn>;

export const useAccountingFirmBillingFees = (props: UseOrgBillingFeeSettingsProps = {}) => {
  const queryClient = useQueryClient();
  const query = useModelApi({
    ...props,
    id: 'me',
    queryKey: 'AccountingFirmOrganizationBillingFeeApi',
    queryFn,
    createFn: AccountingFirmOrganizationBillingFeeApiClient.postAccountingFirmBillingFees,
  });

  const updateAccountingFirmBillingFees = useMutation(
    query.queryKey,
    ({ id, data }: { id: string; data: PutAccountingFirmBillingFeesRequest }) =>
      AccountingFirmOrganizationBillingFeeApiClient.putAccountingFirmBillingFees(id, data),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['AccountingFirmOrganizationBillingFeeApi']);
      },
    }
  );

  const deleteAccountingFirmBillingFees = useMutation(
    query.queryKey,
    ({ id }: { id: string }) => AccountingFirmOrganizationBillingFeeApiClient.deleteAccountingFirmBillingFees(id),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['AccountingFirmOrganizationBillingFeeApi']);
      },
    }
  );

  const isMutating = useIsMutating(['AccountingFirmOrganizationBillingFeeApi']) > 0;

  return {
    ...query,
    isMutating,
    update: updateAccountingFirmBillingFees.mutateAsync,
    delete: deleteAccountingFirmBillingFees.mutateAsync,
  };
};
