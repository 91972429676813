import { Fade } from '@melio/penny';
import { PropsWithChildren } from 'react';

import { FormSkeleton } from './components/FormSkeleton';

type Props = PropsWithChildren<{ collapsedState: 'collapsed' | 'open' | 'loading' }>;
export function CollapsedFormContent(props: Props) {
  if (props.collapsedState === 'collapsed') {
    return <RelativeContainer>{null}</RelativeContainer>;
  }

  return (
    <RelativeContainer>
      <div style={{ position: 'absolute', width: '100%' }}>
        <Fade in={props.collapsedState === 'loading'} unmountOnExit>
          <FormSkeleton />
        </Fade>
      </div>
      <Fade in={props.collapsedState === 'open'}>{props.children}</Fade>
    </RelativeContainer>
  );
}

const RelativeContainer = (props: PropsWithChildren<unknown>) => (
  <div data-testid="collapsed-container" style={{ position: 'relative' }}>
    {props.children}
  </div>
);
