import { Params } from '@melio/javascript-sdk';
// eslint-disable-next-line no-restricted-imports
import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, UseCollectionApiResult } from './types';

export function useBatchCreateMutation<TData, TVariables>(
  mutationFn: MutationFunction<TData[], { data: TVariables; params?: Params }>,
  query: Omit<UseCollectionApiResult<TData>, 'batchCreate'>
) {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries(query.queryKey);
  const mutation = useMutation<TData[], ApiError, { data: TVariables; params?: Params }>(query.queryKey, mutationFn, {
    onSuccess,
  });

  return (data: TVariables, params?: Params) => mutation.mutateAsync({ data, params });
}
