import { object } from 'yup';

import { ManageClientFormSchema, useManageClientFormSchemaValidators } from '../../../components/ManageClientForm';

export const useAssignFirmClientPlanFormSchema = () => {
  const { whoPays, fundingSourceId, subscriptionPlanId, organizationId } = useManageClientFormSchemaValidators();

  return object().shape({
    whoPays,
    fundingSourceId,
    subscriptionPlanId,
    organizationId,
  }) as unknown as ManageClientFormSchema;
};
