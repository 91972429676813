import { useInvoice } from '@melio/ar-domain';
import { useEffect } from 'react';

export type GenerateInvoiceFileActivityProps = {
  invoiceId: string;
  onDone: VoidFunction;
  onError?: ARErrorFunction;
};

export const GenerateInvoiceFileActivity = ({ invoiceId, onDone, onError }: GenerateInvoiceFileActivityProps) => {
  const { generateInvoiceFile } = useInvoice({
    id: invoiceId,
    onError,
    enabled: false,
  });
  useEffect(() => {
    generateInvoiceFile().then(onDone).catch(onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
