import {
  addWildcardToRoutes,
  useDownloadInvoice,
  useFlowRouting,
  useNavigate,
  withOutlet,
  withRouteId,
} from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  CancelInvoiceModalActivity as _CancelInvoiceModalActivity,
  DeleteInvoiceModalActivity as _DeleteInvoiceModalActivity,
  InvoiceDrawerActivity as _InvoiceDrawerActivity,
  InvoiceTableTabActivity,
  MarkInvoiceAsPaidActivity as _MarkInvoiceAsPaidActivity,
  MarkInvoiceAsUnpaidModalActivity as _MarkInvoiceAsUnpaidModalActivity,
  UpdateMarkInvoiceAsPaidActivity,
} from '../../activities';

const CancelInvoiceModalActivity = withRouteId(_CancelInvoiceModalActivity, 'invoiceId');
const DeleteInvoiceModalActivity = withRouteId(_DeleteInvoiceModalActivity, 'invoiceId');
const InvoiceDrawerActivity = withRouteId(_InvoiceDrawerActivity, 'invoiceId');
const MarkInvoiceAsPaidActivity = withRouteId(_MarkInvoiceAsPaidActivity, 'invoiceId');
const UpdateMarkInvoiceAsPaidModalActivity = withRouteId(UpdateMarkInvoiceAsPaidActivity, 'invoiceId');
const MarkInvoiceAsUnpaidModalActivity = withRouteId(_MarkInvoiceAsUnpaidModalActivity, 'invoiceId');

export type InvoicesTableFlowProps = {
  onError?: ARErrorFunction;
  onPreviewInvoicePDF: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  createdInvoiceId?: string;
};

export const InvoicesTableFlow = forwardRef<InvoicesTableFlowProps>(
  ({ onPreviewInvoicePDF, createdInvoiceId, onEditInvoice, onSendReminder, onError, ...props }, ref) => {
    const {
      Paths,
      goHome,
      goToCancelInvoice,
      goToDeleteInvoice,
      goToPreviewInvoice,
      goToMarkInvoiceAsPaid,
      goToUpdateMarkAsPaid,
      goToMarkInvoiceAsUnpaid,
    } = useInvoicesTableFlow({ withSearchparams: true });

    const { downloadFile } = useDownloadInvoice();

    const onCopyLink = (link: string) => navigator.clipboard.writeText(link);

    return (
      <Routes>
        <Route
          path={Paths.Home}
          element={withOutlet(
            <InvoiceTableTabActivity
              onPreviewInvoicePDF={onPreviewInvoicePDF}
              onDeleteInvoice={goToDeleteInvoice}
              onUpdateMarkAsPaid={goToUpdateMarkAsPaid}
              onCancelInvoice={goToCancelInvoice}
              onDownloadInvoice={downloadFile}
              onSendReminder={onSendReminder}
              onCopyLink={onCopyLink}
              onEditInvoice={onEditInvoice}
              onPreviewInvoice={goToPreviewInvoice}
              onRowClick={({ rowId }) => goToPreviewInvoice(rowId)}
              selectedInvoiceId={createdInvoiceId}
              onMarkInvoiceAsPaid={goToMarkInvoiceAsPaid}
              {...props}
              ref={ref}
            />
          )}
        >
          <Route
            path={Paths.DeleteInvoice}
            element={<DeleteInvoiceModalActivity onClose={goHome} onError={onError} />}
          />
          <Route
            path={Paths.CancelInvoice}
            element={
              <CancelInvoiceModalActivity
                onClose={() => goHome({ keepSystemMessage: true })}
                onDone={() => goHome({ keepSystemMessage: true })}
                onError={onError}
              />
            }
          />
          <Route
            path={Paths.MarkInvoiceAsPaid}
            element={
              <MarkInvoiceAsPaidActivity
                isOpen
                onClose={() => goHome({ keepSystemMessage: true })}
                onDone={() => goHome({ keepSystemMessage: true })}
                onError={onError}
              />
            }
          />
          <Route
            path={Paths.UpdateMarkAsPaid}
            element={
              <UpdateMarkInvoiceAsPaidModalActivity
                isOpen
                onClose={() => goHome({ keepSystemMessage: true })}
                onDone={() => goHome({ keepSystemMessage: true })}
                onError={onError}
              />
            }
          />
          <Route
            path={Paths.MarkInvoiceAsUnpaid}
            element={
              <MarkInvoiceAsUnpaidModalActivity
                onClose={() => goHome({ keepSystemMessage: true })}
                onDone={() => goHome({ keepSystemMessage: true })}
                onError={onError}
              />
            }
          />
          <Route
            path={Paths.ViewInvoiceDrawer}
            element={
              <InvoiceDrawerActivity
                onPreviewInvoicePDF={onPreviewInvoicePDF}
                onDeleteInvoice={goToDeleteInvoice}
                onUpdateMarkAsPaid={goToUpdateMarkAsPaid}
                onCancelInvoice={goToCancelInvoice}
                onDownloadInvoice={downloadFile}
                onSendReminder={onSendReminder}
                onCopyLink={onCopyLink}
                onEditInvoice={onEditInvoice}
                onMarkInvoiceAsUnpaid={goToMarkInvoiceAsUnpaid}
                onMarkInvoiceAsPaid={goToMarkInvoiceAsPaid}
                onClose={() => goHome({ keepSystemMessage: true })}
              />
            }
          />
        </Route>
      </Routes>
    );
  }
);

enum Paths {
  Home = '',
  DeleteInvoice = ':id/delete',
  CancelInvoice = ':id/cancel',
  ViewInvoiceDrawer = ':id',
  MarkInvoiceAsPaid = ':id/mark-as-paid',
  UpdateMarkAsPaid = ':id/mark-as-paid/edit',
  MarkInvoiceAsUnpaid = ':id/mark-as-unpaid',
}

export const useInvoicesTableFlow = (...args: Parameters<typeof useNavigate<Paths>>) => {
  const { navigate, createCallback } = useFlowRouting<Paths>(...args);
  const createNavigateWithId = (path: Paths) => (id: string) =>
    navigate(path, { pathParams: { id }, keepSystemMessage: true });

  const goToPreviewInvoice = createNavigateWithId(Paths.ViewInvoiceDrawer);
  const goToDeleteInvoice = createNavigateWithId(Paths.DeleteInvoice);
  const goToCancelInvoice = createNavigateWithId(Paths.CancelInvoice);
  const goHome = createCallback(Paths.Home);
  const goToMarkInvoiceAsPaid = createNavigateWithId(Paths.MarkInvoiceAsPaid);
  const goToUpdateMarkAsPaid = createNavigateWithId(Paths.UpdateMarkAsPaid);
  const goToMarkInvoiceAsUnpaid = createNavigateWithId(Paths.MarkInvoiceAsUnpaid);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToPreviewInvoice,
    goToDeleteInvoice,
    goToCancelInvoice,
    goToMarkInvoiceAsUnpaid,
    goHome,
    goToMarkInvoiceAsPaid,
    goToUpdateMarkAsPaid,
  };
};
