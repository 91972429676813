import { useDisclosure } from '@chakra-ui/react';
import { usePaymentActions } from '@melio/ap-domain';
import { ActionsDropdownMenuItemProps, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Payment, PostApprovalDecisionEnum, useBill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';

import { SelectedEntity } from '../../../../delete-payment-modal';
import { PaymentApprovalWorkflowDeclineModalActivity } from '../../../../payment-approval-workflow';

type Props = {
  payment: Payment;
  onEditPayment: (id: Payment['id']) => void;
  onCancelPayment: (id: Payment['id'], currentEntity: SelectedEntity) => void;
  onMarkFailedPaymentAsPaid: (id: Payment['id']) => void;
  onSuccessMarkAsUnpaid: (payment: Payment) => void;
  onSuccessApprovalDecision: (payment: Payment['id'][], approvalDecision: PostApprovalDecisionEnum) => void;
};

export const PaymentsTabMoreActionsCell = ({
  payment,
  onEditPayment,
  onCancelPayment,
  onMarkFailedPaymentAsPaid,
  onSuccessMarkAsUnpaid,
  onSuccessApprovalDecision,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const allowedActions = usePaymentActions(payment);
  const { markAsPaid: markAsUnpaid } = useBill({ id: payment.bills?.[0]?.id, enabled: false });
  const { track } = useAnalytics();

  const {
    isOpen: isDeclinePaymentModalOpen,
    onOpen: onDeclinePaymentModalOpen,
    onClose: onDeclinePaymentModalClose,
  } = useDisclosure();

  const handleMarkAsUnpaid = async () => {
    await markAsUnpaid({ isPaid: false });
    onSuccessMarkAsUnpaid(payment);
  };

  let options: ActionsDropdownMenuItemProps[] = [];
  if (allowedActions) {
    const { type, actions } = allowedActions;
    if (type === 'scheduled') {
      options = compact([
        actions.edit && {
          label: formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.scheduled.edit'),
          onClick: () => onEditPayment(payment.id),
          dataTestId: 'edit-payment-btn',
        },
        actions.delete && {
          label: formatMessage(
            payment.subscriptionOccurrenceId
              ? 'activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.scheduled.cancel.recurringPayment'
              : 'activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.scheduled.cancel.singlePayment'
          ),
          onClick: () => onCancelPayment(payment.id, 'payment'),
          dataTestId: 'cancel-payment-btn',
          variant: 'critical',
        },
        actions.cancelBillSubscription && {
          label: formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.scheduled.cancelAllPayments'
          ),
          onClick: () => onCancelPayment(payment.id, 'subscription'),
          dataTestId: 'cancel-bill-subscription-btn',
          variant: 'critical',
        },
      ]);
    } else if (type === 'completed') {
      options = compact([
        actions.markAsUnpaid && {
          label: formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.completed.markAsUnpaid'
          ),
          onClick: () => {
            track('Dashboard', 'Click', {
              Intent: 'mark-as-unpaid',
              Cta: 'more-mark-as-unpaid',
              PaymentId: payment.id,
            });
            handleMarkAsUnpaid();
          },
          dataTestId: 'mark-as-unpaid-btn',
        },
      ]);
    } else if (type === 'blocked') {
      options = compact([
        actions.delete && {
          label: formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.blocked.cancelPayment'
          ),
          onClick: () => onCancelPayment(payment.id, 'payment'),
          dataTestId: 'cancel-payment-btn',
          variant: 'critical',
        },
        actions.approvalDecision && {
          label: formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.blocked.decline'),
          onClick: () => onDeclinePaymentModalOpen(),
          dataTestId: 'decline-payment-btn',
          variant: 'critical',
        },
      ]);
    } else if (type === 'failed') {
      options = compact([
        actions.markAsPaid && {
          label: formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.failed.markAsPaid'),
          onClick: () => {
            track('Dashboard', 'Click', {
              Intent: 'mark-as-paid',
              Cta: 'more-mark-as-paid',
              OriginAction: 'resolve',
            });
            onMarkFailedPaymentAsPaid(payment.id);
          },
          dataTestId: 'mark-as-paid-btn',
        },
        actions.delete && {
          label: formatMessage(
            payment.subscriptionOccurrenceId
              ? 'activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.failed.cancel.recurringPayment'
              : 'activities.payDashboard.paymentsTab.table.cells.actions.actionsMenu.failed.cancel.singlePayment'
          ),
          onClick: () => onCancelPayment(payment.id, 'payment'),
          dataTestId: 'cancel-payment-btn',
          variant: 'critical',
        },
      ]);
    }
  }

  const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Table.Cell onClick={handleCellClick}>
      <Table.ActionsCell
        isDisabled={!options.length}
        data-testid={`payments-tab-row-${payment.id}-more-actions-dropdown-menu`}
        options={options}
      />
      {isDeclinePaymentModalOpen && payment.id && (
        <PaymentApprovalWorkflowDeclineModalActivity
          isOpen
          paymentsIds={[payment.id]}
          onClose={onDeclinePaymentModalClose}
          onSuccess={onSuccessApprovalDecision}
        />
      )}
    </Table.Cell>
  );
};
