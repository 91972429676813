import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isObject } from 'lodash';
import { useMemo } from 'react';

type CreateQueryFn = typeof AccountingPlatformsApiClient.createAccountingPlatformBankAccount;

export const useAccountingPlatformBankAccounts = () => {
  const queryClient = useQueryClient();
  const queryKey = ['AccountingPlatformsApi', 'bankAccounts', 'collection', 'default'];

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await AccountingPlatformsApiClient.getAccountingPlatformBankAccounts();
      return data.data.bankAccounts;
    },
    onSuccess: (bankAccounts) => {
      bankAccounts
        ?.filter((entity) => isObject(entity) && 'id' in entity)
        .forEach((entity) => {
          const targetQueryKey = [queryKey[0], 'model', entity.id, 'default', ...queryKey.slice(3)];
          queryClient.setQueryDefaults(targetQueryKey, { placeholderData: entity });
        });
    },
  });

  const create = useMutation(
    [...queryKey, 'create'],
    (data: Parameters<CreateQueryFn>[0]) => AccountingPlatformsApiClient.createAccountingPlatformBankAccount(data),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKey),
    }
  );
  const isFetching = useIsFetching(queryKey) > 0;
  const isCreating = useIsFetching([...queryKey, 'create']) > 0;

  const isMutating = useMemo(() => isFetching || isCreating, [isFetching, isCreating]);

  return {
    ...query,
    isLoading: query.isInitialLoading,
    isFetching,
    isCreating,
    isMutating,
    create: (data: Parameters<CreateQueryFn>[0]) => create.mutateAsync(data),
  };
};
