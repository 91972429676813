import { Link } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  GetContractorsInfo1099Response,
  useOrganizationPreference,
  useVendorsContractorsInfo1099,
} from '@melio/platform-api';
import { Logger } from '@melio/platform-logger';
import { useConfig, useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';

export const usePreSeason1099SectionBanner = () => {
  const [isImport1099ContractorsEnabled] = usePartnerFeature('Import1099Contractors', false);
  const [isFile1099PreSeasonBannerEnabled] = usePartnerFeature('File1099PreSeasonBanner', false);
  const { createDate } = useDateUtils();
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: '1099-message-exit' | '1099-message-learn-more';
    UserMessage: 'tax-season-is-just-in-the-corner';
  }>('Dashboard', 'Click', {
    UserMessage: 'tax-season-is-just-in-the-corner',
  });
  const { data: contractorsInfo1099 = {}, isLoading } = useVendorsContractorsInfo1099({
    enabled: isFile1099PreSeasonBannerEnabled,
  });
  const { formatMessage } = useMelioIntl();
  const {
    settings: { preSeason1099BannerLearnMoreLink },
  } = useConfig();

  const { totalContractors1099Count = 0, pendingW9RequestsCount = 0 }: GetContractorsInfo1099Response['data'] =
    contractorsInfo1099 || {};

  const {
    data: nextPreSeason1099StartDate,
    isLoading: isLoadingOrgPreferences,
    create,
  } = useOrganizationPreference({
    enabled: isFile1099PreSeasonBannerEnabled,
    id: 'nextPreSeason1099StartDate',
    retryOnMount: false,
  });

  const shouldHideBanner =
    !isFile1099PreSeasonBannerEnabled ||
    isImport1099ContractorsEnabled ||
    isLoading ||
    isLoadingOrgPreferences ||
    !totalContractors1099Count ||
    (!!nextPreSeason1099StartDate && nextPreSeason1099StartDate >= createDate().toISOString());

  const getNextPreSeasonDate = () => {
    const today = createDate();
    return `${today.getUTCFullYear() + 1}-11-01T00:00:00Z`;
  };

  const onCloseBanner = async () => {
    trackAndHandleClick({ Cta: '1099-message-exit' });
    const date = getNextPreSeasonDate();
    try {
      await create({
        key: 'nextPreSeason1099StartDate',
        value: date,
      });
    } catch (error) {
      Logger.handleException(error);
    }
  };

  const bannerContent = {
    title: formatMessage(
      pendingW9RequestsCount > 0
        ? 'widgets.preSeason1099SectionBanner.withPendingW9Request.title'
        : 'widgets.preSeason1099SectionBanner.withoutPendingW9Request.title'
    ),
    description: formatMessage(
      pendingW9RequestsCount > 0
        ? 'widgets.preSeason1099SectionBanner.withPendingW9Request.description'
        : 'widgets.preSeason1099SectionBanner.withoutPendingW9Request.description',
      {
        learnMoreLink: (
          <Link
            newTab
            onClick={() => trackAndHandleClick({ Cta: '1099-message-learn-more' })}
            href={preSeason1099BannerLearnMoreLink}
            data-testid="pre-season-1099-section-banner-learn-more-link"
            label={formatMessage('widgets.preSeason1099SectionBanner.links.learnMore')}
          />
        ),
      }
    ),
  };

  return {
    onCloseBanner,
    bannerContent,
    isLoading: isLoading || isLoadingOrgPreferences,
    isBannerVisible: !shouldHideBanner,
  };
};
