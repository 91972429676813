import { IconKey } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useBillDetailsButtonProps = (params: {
  bill: Bill | undefined;
  onClick: VoidFunction;
}): { icon: IconKey; label: string; handleClick: VoidFunction } => {
  const { formatMessage } = useMelioIntl();
  const { createTrackHandler } = useAnalytics();

  const { bill, onClick } = params;

  const trackAndHandleClick = createTrackHandler<{
    Intent: 'edit-bill-details' | 'add-bill-details';
    Cta: 'Edit bill details' | 'Add bill details';
  }>('Payment', 'Click', {}, onClick);

  return bill
    ? {
        icon: 'edit',
        label: formatMessage('activities.paymentFlow.form.content.bill.edit.label'),
        handleClick: () => trackAndHandleClick({ Intent: 'edit-bill-details', Cta: 'Edit bill details' }),
      }
    : {
        icon: 'add-bill',
        label: formatMessage('activities.paymentFlow.form.content.bill.add.label'),
        handleClick: () => trackAndHandleClick({ Intent: 'add-bill-details', Cta: 'Add bill details' }),
      };
};
