import { DeliveryMethodType, Vendor } from '@melio/platform-api';

import { DeliveryMethodCardOnSubmit } from './types';

export const vendorDeliveryMethodsToWidgetState = (vendor?: Vendor) => {
  const deliveryMethods = vendor?.deliveryMethods || [];
  const deliveryMethodsMap = new Map<'bank-account' | 'paypal-balance' | 'paper-check', DeliveryMethodCardOnSubmit>();
  deliveryMethods.forEach((dm) => {
    switch (dm.type) {
      case DeliveryMethodType.BankAccount:
        deliveryMethodsMap.set(DeliveryMethodType.BankAccount, dm.details);
        break;
      case DeliveryMethodType.PaperCheck:
        deliveryMethodsMap.set(DeliveryMethodType.PaperCheck, {
          printName: dm.details.printName,
          line1: dm.details.address.line1,
          line2: dm.details.address.line2,
          city: dm.details.address.city,
          state: dm.details.address.state,
          postalCode: dm.details.address.postalCode,
        });
        break;

      case DeliveryMethodType.PaypalBalance:
        deliveryMethodsMap.set(DeliveryMethodType.PaypalBalance, { email: dm.details.paypalAccountEmail });
        break;

      default:
        break;
    }
  });
  return deliveryMethodsMap;
};
