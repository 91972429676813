import { Container, Loader } from '@melio/penny';
import { useAnalyticsContext } from '@melio/platform-analytics';
import {
  InternationalAccountDeliveryMethod,
  OrganizationVerifiedPersona,
  OrganizationVerifiedPersonaType,
  useVendor,
  useVerifiedPersonas,
  Vendor,
} from '@melio/platform-api';
import { useEffect, useMemo, useState } from 'react';

import { DeliveryMethodFlowOrigin } from '../../delivery-methods/types';
import { useGetFxBankDetailsDefaultValue } from './hooks/useGetFxBankBankDetailsDefaultValue';
import { AddFxDeliveryMethodBusinessDetailsActivity } from './steps/add-fx-business-details';
import { FxVendorAccountActivity } from './steps/fx-vendor-account';
import { AddFxDeliveryMethodAnalyticsDataType, InternationalFxStepsEnum, SubmissionTargetType } from './types';
import { getCurrencyCloudInternationalDeliveryMethod } from './utils';

const useFxDMSteps = (verifiedPersonas: OrganizationVerifiedPersona[] | undefined) => {
  const [currentStep, setCurrentStep] = useState<InternationalFxStepsEnum>();

  useEffect(() => {
    if (verifiedPersonas) {
      const isFxAccountHolder = verifiedPersonas.some((verifiedPerson) =>
        verifiedPerson.types.includes(OrganizationVerifiedPersonaType.FxAccountHolder)
      );
      if (isFxAccountHolder) {
        setCurrentStep(InternationalFxStepsEnum.VendorAccount);
      } else {
        setCurrentStep(InternationalFxStepsEnum.Ubo);
      }
    }
  }, [verifiedPersonas]);
  return { currentStep, setCurrentStep };
};

export type AddFxDeliveryMethodActivityProps = {
  vendorId: Vendor['id'];
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
  onDone: (data: InternationalAccountDeliveryMethod, target?: SubmissionTargetType) => void;
  origin?: DeliveryMethodFlowOrigin;
};

export const AddFxDeliveryMethodActivity = ({
  vendorId,
  onBack: onFirstStepBack,
  onClose,
  onDone,
  origin,
}: AddFxDeliveryMethodActivityProps) => {
  const [analyticsData, setAnalyticsData] = useState<AddFxDeliveryMethodAnalyticsDataType>();
  useAnalyticsContext({ globalProperties: { DeliveryMethodType: 'fx', ...analyticsData } });

  const onVendorAccountDone = (data: InternationalAccountDeliveryMethod, target?: SubmissionTargetType) => {
    setAnalyticsData({
      foreignCurrency: data.details.currency,
      fxCountrySelection: data.details.address.countryCode,
    });
    onDone(data, target);
  };

  const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: vendorId });
  const { data: verifiedPersonas, isLoading: isUboLoading } = useVerifiedPersonas();
  const { currentStep, setCurrentStep } = useFxDMSteps(verifiedPersonas);
  const notInternationalFxAccount = useMemo(
    () => vendor && getCurrencyCloudInternationalDeliveryMethod(vendor),
    [vendor]
  );
  const _fxBankDetailsDefaultValues = useGetFxBankDetailsDefaultValue({
    vendor,
    currencyCloudInternationalAccount: notInternationalFxAccount,
  });

  const onUboDone = () => {
    setCurrentStep(InternationalFxStepsEnum.VendorAccount);
  };

  const isLoading = isUboLoading || isVendorLoading;
  if (isLoading || !currentStep) {
    return (
      <Container height="full" alignItems="center" justifyContent="center">
        <Loader />
      </Container>
    );
  }

  switch (currentStep) {
    default:
    case InternationalFxStepsEnum.Ubo:
      return (
        <AddFxDeliveryMethodBusinessDetailsActivity
          onDone={onUboDone}
          onClose={onClose}
          showExistingInternationalAccountBanner={!!notInternationalFxAccount}
        />
      );
    case InternationalFxStepsEnum.VendorAccount:
      return (
        <FxVendorAccountActivity
          fxBankDetailsDefaultValues={_fxBankDetailsDefaultValues}
          onBack={onFirstStepBack}
          onClose={onClose}
          vendorId={vendorId}
          onDone={onVendorAccountDone}
          vendorName={vendor?.name}
          origin={origin}
        />
      );
  }
};
