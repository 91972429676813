import { Card, DeliveryMethod, formatAddress } from '@melio/platform-api';
import { FormatMessage, useMelioIntl } from '@melio/platform-i18n';

import { getInternationalFormattedIdentifierData, getManagedAccountDeliveryMethodType } from '../../functions';

export const useGetDeliveryMethodDisplayText = (
  deliveryMethod: DeliveryMethod,
  vendorName: string,
  countryDisplayName?: string
): string => {
  const { formatMessage } = useMelioIntl();

  return getDeliveryMethodDisplayText(deliveryMethod, vendorName, formatMessage, countryDisplayName);
};

export const getDeliveryMethodDisplayText = (
  deliveryMethod: DeliveryMethod,
  vendorName: string,
  formatMessage: FormatMessage,
  countryDisplayName?: string
): string => {
  if (deliveryMethod.type === 'managed-account') {
    const deliveryMethodType = getManagedAccountDeliveryMethodType(deliveryMethod.details);
    switch (deliveryMethodType) {
      case 'bank-account':
        return formatMessage('utils.deliveryMethod.bankAccount.managedDescription', { vendorName });
      case 'paper-check':
        return formatMessage('utils.deliveryMethod.paperCheck.managedDescription', { vendorName });
    }
  }

  switch (deliveryMethod.type) {
    case 'bank-account': {
      if (deliveryMethod.isManaged) {
        return formatMessage('utils.deliveryMethod.bankAccount.managedDescription');
      }
      return formatMessage('utils.deliveryMethod.bankAccount.description', {
        accountNumber: deliveryMethod.details.accountNumberLast4Digits || '',
        accountName: deliveryMethod.details.accountType,
      });
    }
    case 'paper-check':
      return formatMessage('utils.deliveryMethod.paperCheck.description', {
        address: formatAddress(deliveryMethod.details.address),
      });
    case 'domestic-wire-account':
      return formatMessage('utils.deliveryMethod.domesticWireAccount.description', {
        accountName: vendorName,
        accountNumber: deliveryMethod.details.accountNumber.slice(-4) || '',
      });
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${
          (deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'
        }.description`,
        { vendorName }
      );

    case 'virtual-account':
      return formatMessage('utils.deliveryMethod.unilateral.description', { email: deliveryMethod.details.email });
    case 'virtual-card':
      return formatMessage('utils.deliveryMethod.virtualCard.description', {
        email: deliveryMethod.details.accountEmail,
      });
    case 'biller-account':
    case 'managed-account':
      return formatMessage('utils.deliveryMethod.billerAccount.description');
    case 'international-account': {
      return formatMessage(
        'utils.deliveryMethod.internationalAccount.description',
        getInternationalFormattedIdentifierData(deliveryMethod, countryDisplayName)
      );
    }
    default:
      return '';
  }
};
