/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useSystemMessage } from '@melio/platform-utils';

import { AsyncModalDialog } from '@/cl/components/AsyncModalDialog/AsyncModalDialog.component';
import { payDashboardForceSelectFirstSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';

type CancelPaymentDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  deleteEntity: (deleteId?: string) => void;
  origin: 'singlePayment' | 'recurringPayment' | 'billSubscription' | 'inProgressPayment' | 'recurringSeries';
  bodyMessage: string;
  vendorName?: string;
};

export const CancelPaymentDialog = ({
  isOpen,
  onClose,
  deleteEntity,
  origin,
  bodyMessage,
  vendorName,
}: CancelPaymentDialogProps) => {
  const { formatMessage } = usePlatformIntl();

  const setForceSelectCard = useSetRecoilState(payDashboardForceSelectFirstSelector);
  const { track } = useAnalytics();

  React.useEffect(() => {
    if (isOpen) {
      track('PayDashboardCancelBill', 'Viewed');
    }
  }, [isOpen]);

  const { showMessage } = useSystemMessage();

  const onConfirm = async () => {
    try {
      track('PayDashboardCancelBill', 'Submitted');
      await deleteEntity();
      setForceSelectCard(true);
      showMessage({
        type: 'informative',
        title: formatMessage(`widgets.paymentDetails.cancelPaymentDialog.${origin}.toast.success`, { vendorName }),
      });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage(`widgets.paymentDetails.cancelPaymentDialog.${origin}.toast.error`),
      });
    } finally {
      onClose();
    }
  };

  const modalBody = () => (
    <Stack flexDirection="column" gridGap="8px">
      <Text>{bodyMessage}</Text>
    </Stack>
  );

  const onCloseClicked = React.useCallback(() => {
    track('PayDashboardCancelBillGoBack', 'Chose');
    onClose();
  }, [onClose]);
  return (
    <AsyncModalDialog
      isOpen={isOpen}
      type="cancel"
      title={formatMessage(`widgets.paymentDetails.cancelPaymentDialog.${origin}.title`)}
      body={modalBody()}
      confirmText={formatMessage(`widgets.paymentDetails.cancelPaymentDialog.${origin}.confirm`)}
      cancelText={formatMessage(`widgets.paymentDetails.cancelPaymentDialog.${origin}.cancel`)}
      onClose={onCloseClicked}
      onConfirm={onConfirm}
      hideSecondaryButton
    />
  );
};
