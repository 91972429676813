import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const ThirdPartyConnectPopupCallback = () => {
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    window.opener.postMessage(
      {
        redirectPath: atob(token || ''),
        searchParams: Object.fromEntries(searchParams),
      },
      window.location.origin,
    );

    window.close();
  }, [token]);

  return null;
};
