import { Group, Icon, Text } from '@melio/penny';
import { useCallback, useState } from 'react';

import { useMelioIntl } from './i18n';

export const useCopyToClipboard = (link: string, onCopyPaymentLink?: () => unknown) => {
  const [isCopied, setIsCopied] = useState(false);

  const { formatMessage } = useMelioIntl();

  const copyLink = useCallback(() => {
    if (onCopyPaymentLink) {
      void Promise.all([onCopyPaymentLink()]).then(() => setIsCopied(true));
    } else {
      void navigator.clipboard.writeText(link).then(() => setIsCopied(true));
    }
  }, [link, onCopyPaymentLink]);

  const reset = useCallback(() => {
    setIsCopied(false);
  }, []);

  const content = isCopied ? (
    <Group spacing="xs">
      <Icon size="small" color="inverse" type="checked" aria-hidden />
      <Text textStyle="body4" color="semantic.text.inverse">
        {formatMessage('ar.domain.hooks.useCopyToClipboard.action.copied')}
      </Text>
    </Group>
  ) : (
    <Text textStyle="body4" color="semantic.text.inverse">
      {formatMessage('ar.domain.hooks.useCopyToClipboard.action.copy')}
    </Text>
  );

  return { copyLink, reset, content };
};
