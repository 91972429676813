import { useReceivablePayments, useSearch } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { PaymentActionsHandlers } from '../../utils';
import { ExportReportModal, PaymenstTabScreen } from './screens';

export type PaymentsTabActivityProps = {
  onViewPayment: (id: string) => void;
  onError?: ARErrorFunction;
};

export const PaymentsTabActivity = forwardRef<PaymentsTabActivityProps>(({ onError, onViewPayment, ...props }, ref) => {
  const { searchTerm, setSearch } = useSearch();

  const {
    data: payments,
    isLoading,
    isFetching,
    error,
    pagination,
  } = useReceivablePayments({ params: { searchTerm } });
  const paymentActions: PaymentActionsHandlers = {
    onViewPayment,
  };

  useEffect(() => {
    if (error) {
      onError?.(error);
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PaymenstTabScreen
        pagination={pagination}
        payments={payments ?? []}
        onSearch={setSearch}
        searchTerm={searchTerm}
        tableActions={paymentActions}
        isLoading={isFetching || isLoading}
        ref={ref}
        onError={onError}
        {...props}
      />
      <ExportReportModal />
    </>
  );
});
