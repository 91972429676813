import { useMelioIntl } from '@melio/ar-domain';
import { Group, SearchBar as DSSearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type FiltersToolBarProps = {
  search?: string;
  onSearch: (value: string) => void;
};

export const FiltersToolBar = forwardRef<FiltersToolBarProps>(({ onSearch, search, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group
      variant="horizontal"
      justifyContent="flex-start"
      alignItems="center"
      width="full"
      spacing="s"
      data-component="FiltersToolBar"
      {...props}
      ref={ref}
    >
      <DSSearchBar
        data-testid="payments-table-search-input"
        value={search}
        onChange={(e) => onSearch(e.target.value)}
        label={formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.label')}
        placeholder={formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.placeholder')}
        onClear={() => onSearch('')}
        aria-label={formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.aria-label')}
        instructionsText={formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.instructions')}
      />
    </Group>
  );
});
FiltersToolBar.displayName = 'FiltersToolBar';
