import { Group, Icon, Pill, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { MigratedUserExperienceRow } from './MigratedUserExperienceRow';

type MigratedUserExperiencePillRowProps = {
  isMobile?: boolean;
};
export const MigratedUserExperiencePillRow = ({ isMobile }: MigratedUserExperiencePillRowProps) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Group variant="horizontal" spacing="s">
      <MigratedUserExperienceRow
        description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row3')}
        iconType="checked"
        isMobile={isMobile}
      />
      <Pill
        type="secondary"
        rightElement={
          <Text textStyle="body4" color="semantic.text.brand.onColor">
            {formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row3.pill')}
          </Text>
        }
        leftElement={<Icon type="premium" color="brand" />}
        status="brand"
        label=""
      />
    </Group>
  );
};
