import { EventPropertiesCardType } from '@melio/platform-analytics';
import { CardNetwork, CardType, FundingSource } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { FundingSourceGroupName, FundingSourcePolicy } from '@melio/platform-provider';
import _ from 'lodash';

export type FundingSourceCardListOfListsGroup = { type: FundingSourceGroupName; fundingSources: FundingSource[] };

export const getBiCardType = (cardType?: CardType): EventPropertiesCardType => {
  if (!cardType) {
    return 'credit_debit';
  }

  return cardType;
};

export const filterFundingSourcesByType = (
  fundingSources: FundingSource[] = [],
  type: FundingSourceGroupName
): FundingSource[] => {
  switch (type) {
    case 'credit':
      return fundingSources.filter(
        (fundingSource) => fundingSource.type == 'card' && fundingSource.details.type == 'credit'
      );
    case 'debit':
      return fundingSources.filter(
        (fundingSource) => fundingSource.type == 'card' && fundingSource.details.type == 'debit'
      );

    default: {
      return fundingSources.filter((fs) => fs.type === type);
    }
  }
};

const getSortingOrderByGroupName = ({
  fundingSourceConfig,
  fundingSourceGroup,
}: {
  fundingSourceConfig: FundingSourcePolicy;
  fundingSourceGroup: FundingSourceCardListOfListsGroup;
}) => {
  const fundingSourceOrder = fundingSourceConfig[fundingSourceGroup.type]?.order;
  if (fundingSourceOrder) {
    return fundingSourceOrder;
  }

  const fundingSourceConfigItems = Object.values(fundingSourceConfig);
  const fundingSourceItemOfGroup = fundingSourceConfigItems.find(
    (config) => config.fundingSourceGroup === fundingSourceGroup.type
  );

  return fundingSourceItemOfGroup?.order ?? 0;
};
const sortFundingSourceGroups =
  (fundingSourceConfig: FundingSourcePolicy) =>
  (groupA: FundingSourceCardListOfListsGroup, groupB: FundingSourceCardListOfListsGroup) => {
    const groupAFundingSourceIsEmpty = !groupA.fundingSources.length;
    const groupBFundingSourceIsEmpty = !groupB.fundingSources.length;
    if (groupAFundingSourceIsEmpty && groupB.fundingSources.length > 0) {
      return 1;
    }

    if (groupBFundingSourceIsEmpty && groupA.fundingSources.length > 0) {
      return -1;
    }

    const group1Order = getSortingOrderByGroupName({ fundingSourceConfig, fundingSourceGroup: groupA });
    const group2Order = getSortingOrderByGroupName({ fundingSourceConfig, fundingSourceGroup: groupB });

    return group1Order - group2Order;
  };

export const getGroupedFundingSources = ({
  fundingSources,
  fundingSourcePolicy,
}: {
  fundingSources: FundingSource[];
  fundingSourcePolicy: FundingSourcePolicy;
}): FundingSourceCardListOfListsGroup[] => {
  const supportedFundingSources = Object.entries(fundingSourcePolicy)
    .filter(([, policy]) => policy.read)
    .map(([key]) => key as FundingSourceGroupName);

  const configGroups = _.groupBy(
    supportedFundingSources,
    (fundingSource) => fundingSourcePolicy[fundingSource]?.fundingSourceGroup ?? fundingSource
  );

  return Object.keys(configGroups)
    .map((groupName) => ({
      type: groupName as FundingSourceGroupName,
      fundingSources: filterFundingSourcesByType(fundingSources, groupName as FundingSourceGroupName),
    }))
    .sort(sortFundingSourceGroups(fundingSourcePolicy));
};

export const calculateFundingSourcePermissionForGroup = ({
  groupName,
  fundingSourceConfig,
}: {
  groupName: FundingSourceGroupName;
  fundingSourceConfig: FundingSourcePolicy;
}) => {
  const groupMembers = Object.entries(fundingSourceConfig).filter(([fundingSourceType, fundingSourceConfigforType]) => {
    if (fundingSourceConfigforType.fundingSourceGroup) {
      return fundingSourceConfigforType.fundingSourceGroup === groupName;
    }
    return fundingSourceType === groupName;
  });

  return {
    isReadAllowed: groupMembers.some(([, policy]) => policy.read),
    isWriteAllowed: groupMembers.some(([, policy]) => policy.write),
  };
};

export const getCardNetworkLabelKey = (cardNetwork: CardNetwork): MessageKey => {
  switch (cardNetwork) {
    case CardNetwork.Amex:
      return 'app.cardNetworks.amex';
    case CardNetwork.Discover:
      return 'app.cardNetworks.discover';
    case CardNetwork.Visa:
      return 'app.cardNetworks.visa';
    case CardNetwork.Mastercard:
      return 'app.cardNetworks.mastercard';
    case CardNetwork.Other:
      return 'app.cardNetworks.other';
  }
};
