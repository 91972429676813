import { useMelioIntl } from '@melio/ar-domain';
import { NakedButton } from '@melio/penny';
import { usePartnerFeature } from '@melio/platform-provider';

type UsePayOptionDescriptionLabelProps = {
  isLinkDisabled: boolean;
  isFeeAbsorbedByPayor?: boolean;
  payOptionType: 'bankTransfer' | 'card';
};

export const usePayOptionDescriptionLabel = ({
  isLinkDisabled,
  isFeeAbsorbedByPayor,
  payOptionType,
}: UsePayOptionDescriptionLabelProps) => {
  const { formatMessage } = useMelioIntl();
  const [isFeeAbsorptionSettingsEnabled] = usePartnerFeature('IsArFeeAbsorptionSettingsEnabled', false);
  const cardFeeSettingsLink = (
    <NakedButton
      isDisabled={isLinkDisabled}
      label={formatMessage(
        'ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.card.description.link.edit.text'
      )}
      variant="secondary"
    />
  );

  if (isFeeAbsorptionSettingsEnabled && payOptionType === 'card') {
    return formatMessage(
      `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.card.description.fees.${
        isFeeAbsorbedByPayor ? 'payor' : 'vendor'
      }.text`,
      { link: cardFeeSettingsLink }
    );
  }

  return formatMessage(
    `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.${payOptionType}.description.text`
  );
};
