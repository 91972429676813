import { useNonEntityMutation } from '@melio/api-client';
import { apiClient, PostPlaidLinkTokenResponseData } from '@melio/javascript-sdk';
import {
  ApiKeys,
  ApiRequest,
  DeliveryMethodsApiClient,
  PostDeliveryMethodsPlaidLinkTokenRequest,
  PostPlaidLinkTokenRequest,
  PostPlaidLinkTokenResponse,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import get from 'lodash/get';

import { useModelApi, UseModelApiProps } from '../../core';
import { PlaidLinkTokenModel } from './types';

export type {
  PostPlaidLinkTokenRegistrationRequest,
  PostPlaidLinkTokenVerificationRequest,
} from '@melio/platform-api-axios-client';

type PostDeliveryMethodsPlaidLinkTokenFn = ApiRequest<PostPlaidLinkTokenResponse>;

export const useDeliveryMethodPlaidLinkToken = () =>
  useNonEntityMutation<PostDeliveryMethodsPlaidLinkTokenFn, unknown, PostPlaidLinkTokenResponse>(
    (props: PostDeliveryMethodsPlaidLinkTokenRequest) =>
      DeliveryMethodsApiClient.postDeliveryMethodsPlaidLinkToken(props),
    [ApiKeys.DeliveryMethodsApi, 'collection', 'postDeliveryMethodsPlaidLinkToken']
  );

export const useIsDeliveryMethodPlaidLinkTokenLoading = () =>
  useIsMutating([ApiKeys.DeliveryMethodsApi, 'collection', 'postDeliveryMethodsPlaidLinkToken']) > 0;

export const useFundingSourcePlaidLinkToken = (
  props: UseModelApiProps<PostPlaidLinkTokenResponseData> & { params: PostPlaidLinkTokenRequest }
): PlaidLinkTokenModel => {
  const client = apiClient.plaidLinkTokens();

  const fundingSourceId = get(props.params.details, 'fundingSourceId');
  const query = useModelApi<PostPlaidLinkTokenResponseData>({
    ...props,
    id: 'my-token-id', // trick the system to fetch without an actual id
    queryKey: ['plaid-link-token', props.params.type, fundingSourceId],
    queryFn: () => client.create(props.params),
  });

  return query;
};

export const useIsPlaidLinkVerificationTokenFetching = (fundingSourceId: string): boolean => {
  const { queryKey } = useFundingSourcePlaidLinkToken({
    params: { type: 'verification', details: { fundingSourceId } },
    enabled: false,
  });
  return !!useIsFetching(queryKey);
};
