import { OverviewField, ReportsApiGetInvoiceReportRequest, ReportsApiInstance } from '@melio/ar-api-axios-client';
import { useQuery } from '@tanstack/react-query';

export type UseInvoiceReportProps = ReportsApiGetInvoiceReportRequest;

export const useInvoiceReport = (requestParams: ReportsApiGetInvoiceReportRequest, enabled = true) => {
  const query = useQuery<OverviewField, ARPlatformError>(
    ['invoiceReport', requestParams],
    async () => {
      const response = await ReportsApiInstance.getInvoiceReport(requestParams);
      return response.data.data;
    },
    { enabled }
  );

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
