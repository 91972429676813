import { TIERS_ORDER } from '../utils';
import { usePlansTiers } from '.';
import { useSubscription } from './useSubscription';

export const useShouldConfirmSubscriptionDowngrade = () => {
  const subscription = useSubscription();
  const { getIsFreePlan, getPlanTier } = usePlansTiers();

  const lastPaidSubscription = subscription?.lastPaidSubscription;

  if (!lastPaidSubscription) {
    return false;
  }

  const isCurrentPlanFree = getIsFreePlan(subscription.planId);
  const isCurrentPlanDowngradedFromLastPlan =
    TIERS_ORDER[lastPaidSubscription.planTier] > getPlanTier(subscription.planId);

  return (
    isCurrentPlanFree && !lastPaidSubscription.doesNextSubscriptionChangesApplied && isCurrentPlanDowngradedFromLastPlan
  );
};
