import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';

import { AccountingPlatformConfirmConnectScreen } from '../screens';
import { ExternalOrganizations, SyncFlowEnum } from '../types';

type Props = {
  flow?: SyncFlowEnum;
  isLoading: boolean;
  isRegistering: boolean;
  companyName?: string | null;
  isQuickBooksDesktop?: boolean;
  externalOrganizations: ExternalOrganizations;
  accountingPlatformName: string;
  accountingPlatformNameForAnalytics: string;
  onCancel: VoidFunction;
  onSubmitOrganization: (externalOrganizationId: string) => void;
};

export const ConfirmConnectStep = ({
  flow,
  isLoading,
  isRegistering,
  companyName,
  isQuickBooksDesktop,
  externalOrganizations = [],
  accountingPlatformName,
  accountingPlatformNameForAnalytics,
  onCancel,
  onSubmitOrganization,
}: Props) => {
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'exit' | 'cancel' | 'connect';
  }>('SyncAccountingSoftware', 'Click');
  const defaultAnalyticsFields = {
    PageName: `connecting-to-${accountingPlatformNameForAnalytics}`,
    Flow: `connecting-${accountingPlatformNameForAnalytics}`,
    Intent: `sync`,
    EntryPoint: flow === SyncFlowEnum.Setting ? 'settings-page' : 'dashboard',
    OrganizationCount: externalOrganizations.length,
  };

  useAnalyticsView('SyncAccountingSoftware', !isLoading, true, {
    ...defaultAnalyticsFields,
  });

  const onCloseClick = () => {
    trackAndHandleClick({ Cta: 'exit', ...defaultAnalyticsFields }, onCancel);
  };

  const onCancelClick = () => {
    trackAndHandleClick({ Cta: 'cancel', ...defaultAnalyticsFields }, onCancel);
  };

  const onSubmitClick = (externalOrganizationId: string) => {
    trackAndHandleClick({ Cta: 'connect', ...defaultAnalyticsFields }, onSubmitOrganization(externalOrganizationId));
  };

  return (
    <AccountingPlatformConfirmConnectScreen
      isLoading={isLoading}
      isRegistering={isRegistering}
      companyName={companyName}
      isQuickBooksDesktop={isQuickBooksDesktop}
      externalOrganizations={externalOrganizations}
      accountingPlatformName={accountingPlatformName}
      onClose={onCloseClick}
      onCancel={onCancelClick}
      onSubmitOrganization={onSubmitClick}
    />
  );
};
