import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Card, Container, Group, IconButton } from '@melio/penny';
import * as React from 'react';

const HEIGHT = '174px';

type Props = {
  'data-testid'?: string;
  onClick?: VoidFunction;
  onToggle?: (value: boolean) => void;
  top: React.ReactNode;
  bottom: React.ReactNode;
  actions?: ActionsDropdownMenuItemProps[];
  isMenuDisabled?: boolean;
};

export const CardLayout = ({
  actions = [],
  top,
  bottom,
  onClick,
  onToggle,
  'data-testid': testId,
  isMenuDisabled,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleDropdown = (value: boolean) => {
    setIsOpen(value);
    onToggle?.(value);
  };

  return (
    <Container border="regular" style={{ maxHeight: HEIGHT }}>
      <Card data-testid={testId} onClick={onClick}>
        <Container style={{ minHeight: HEIGHT }}>
          <Group width="full" variant="vertical" spacing="s">
            <Group variant="horizontal" width="full" alignItems="flex-start" justifyContent="space-between">
              <Group
                variant="horizontal"
                width="full"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing="s-m"
              >
                {top}
              </Group>
              {actions.length ? (
                <ActionsDropdownMenu
                  isOpen={isOpen}
                  isDisabled={isMenuDisabled}
                  onOpenChange={onToggleDropdown}
                  isFullWidthTrigger
                  items={actions}
                  size="large"
                  trigger={
                    <IconButton
                      data-component="ActionsDropdownMenu"
                      aria-expanded={isOpen}
                      size="large"
                      icon="more"
                      data-testid="actions-dropdown-menu"
                      aria-haspopup="menu"
                      variant="primary"
                      isDisabled={isMenuDisabled}
                    />
                  }
                />
              ) : null}
            </Group>
            {bottom}
          </Group>
        </Container>
      </Card>
    </Container>
  );
};
