import { useMelioIntl } from '@melio/ar-domain';
import { Container, Group, Icon, IconKey, Switch, Text } from '@melio/penny';
import React from 'react';

export type PaymentOptionSwitcherProps = {
  iconType: IconKey;
  title: string;
  value: boolean;
  onChange: (enabled: boolean) => void;
  isUpdating: boolean;
  paymentOptionType: 'card' | 'ACH';
};

export const PaymentOptionSwitcher = ({
  iconType,
  title,
  value,
  onChange,
  isUpdating,
  paymentOptionType,
}: PaymentOptionSwitcherProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Container border="regular" paddingX="s" paddingY="s">
      <Group justifyContent="space-between">
        <Group alignItems="center">
          <Icon type={iconType} />
          <Group spacing="none" variant="vertical">
            <Text textStyle="body2Semi">{title}</Text>
          </Group>
        </Group>
        <Switch
          data-testid={`invoice-payment-option-${paymentOptionType}`}
          aria-hidden
          value={value}
          onChange={onChange}
          label={formatMessage(
            value
              ? 'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.container.switch.on.label'
              : 'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.container.switch.off.label'
          )}
          aria-label={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.container.switch.ariaLabel',
            { switchTitle: title }
          )}
          isDisabled={isUpdating}
        />
      </Group>
    </Container>
  );
};
