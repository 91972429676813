import { ApiKeys, VendorsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@tanstack/react-query';

export const useEBillsSubscriptionPaperSuppressionTerms = ({ vendorId }: { vendorId: string }) => {
  const queryKey = [ApiKeys.EBillsSubscriptionsApi, 'model', vendorId, 'paperSuppressionTerms'];

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await VendorsApiClient.getVendorEbillsSubscriptionPaperSuppressionTerms(vendorId);
      return result.data.data;
    },
  });

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
