/* eslint-disable max-lines */
import { DeliveryPreferenceType, PaymentsExtendedStatusesEnum } from '@melio/platform-api';
import { DebitDateFilterValues } from '@melio/platform-api/src/entities-v2/payment/types';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { addDays, endOfToday, startOfToday, subDays } from 'date-fns';
import compact from 'lodash/compact';
import { useMemo } from 'react';

import { CalcQueryParamsResult, TabFilter } from '../Filters/types';
import { PaymentsTabFrequencyFilter } from './types';
export const supportedSearchParamKeys = [
  'payment.status',
  'payment.scheduledDate',
  'payment.frequency',
  'payment.deliveryMethod',
] as const;
export type SupportedSearchParamKeysType = (typeof supportedSearchParamKeys)[number];
export const useNewPaymentFilters = () => {
  const { formatMessage } = useMelioIntl();
  const startOfTodayDate = startOfToday();
  const endOfTodayDate = endOfToday();
  const startOfTodayTimestamp = startOfTodayDate.getTime();
  const endOfTodayTimestamp = endOfTodayDate.getTime();
  const [isPaymentsTabNewFiltersPhase2Enabled] = usePartnerFeature('PaymentsTabFiltersPhase2', false);
  const [isApprovalWorkflowsV2Enabled] = usePartnerFeature('PlatformApprovalWorkflowsV2', false);
  return useMemo(() => {
    const paymentsNewFilters: TabFilter<SupportedSearchParamKeysType>[] = compact([
      {
        key: 'payment.status',
        label: formatMessage('activities.payDashboard.paymentsTab.filters.status.title'),
        type: 'multi',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          const statusValues = values.filter((value) =>
            Object.values(PaymentsExtendedStatusesEnum).includes(value as PaymentsExtendedStatusesEnum)
          );
          if (statusValues.length) {
            return { filterQueryParams: { 'payment.extendedStatus': statusValues } };
          }
          return {};
        },
        options: compact([
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.scheduled.label'),
            value: PaymentsExtendedStatusesEnum.Scheduled,
            backgroundColor: 'informative',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.pending.label'),
            value: PaymentsExtendedStatusesEnum.Pending,
            backgroundColor: 'warning',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.inProgress.label'),
            value: PaymentsExtendedStatusesEnum.InProgress,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.paid.label'),
            value: PaymentsExtendedStatusesEnum.Paid,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.markedAsPaid.label'),
            value: PaymentsExtendedStatusesEnum.MarkedAsPaid,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.refunded.label'),
            value: PaymentsExtendedStatusesEnum.Refunded,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.failed.label'),
            value: PaymentsExtendedStatusesEnum.Failed,
            backgroundColor: 'critical',
          },
          isApprovalWorkflowsV2Enabled && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.declined.label'),
            value: PaymentsExtendedStatusesEnum.Declined,
            backgroundColor: 'critical',
          },
        ]),
      },
      {
        key: 'payment.scheduledDate',
        label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.title'),
        type: 'single',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          switch (values[0]) {
            case DebitDateFilterValues.Today:
              return {
                filterQueryParams: {
                  'payment.scheduledDate': {
                    $between: [startOfTodayTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            case DebitDateFilterValues.Last7Days: {
              const last7DaysTimestamp = subDays(startOfTodayDate, 6).getTime();
              return {
                filterQueryParams: {
                  'payment.scheduledDate': {
                    $between: [last7DaysTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            }
            case DebitDateFilterValues.Last30Days: {
              const last30DaysTimestamp = subDays(startOfTodayDate, 29).getTime();
              return {
                filterQueryParams: {
                  'payment.scheduledDate': {
                    $between: [last30DaysTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            }
            case DebitDateFilterValues.Next7Days: {
              const next7DaysTimestamp = addDays(endOfTodayDate, 6).getTime();
              return {
                filterQueryParams: {
                  'payment.scheduledDate': {
                    $between: [startOfTodayTimestamp, next7DaysTimestamp],
                  },
                },
              };
            }
            case DebitDateFilterValues.Next30Days: {
              const next30DaysTimestamp = addDays(endOfTodayDate, 29).getTime();
              return {
                filterQueryParams: {
                  'payment.scheduledDate': {
                    $between: [startOfTodayTimestamp, next30DaysTimestamp],
                  },
                },
              };
            }
            case DebitDateFilterValues.Last90Days: {
              const last90DaysTimestamp = subDays(startOfTodayDate, 89).getTime();
              return {
                filterQueryParams: {
                  'payment.scheduledDate': {
                    $between: [last90DaysTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            }
            case DebitDateFilterValues.Next90Days: {
              const next90DaysTimestamp = addDays(endOfTodayDate, 89).getTime();
              return {
                filterQueryParams: {
                  'payment.scheduledDate': {
                    $between: [startOfTodayTimestamp, next90DaysTimestamp],
                  },
                },
              };
            }
          }
          return {};
        },
        options: [
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.any'),
            value: 'all',
            selectWhenNothingSelected: true,
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.today'),
            value: 'today',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.last7Days'),
            value: 'last7Days',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.last30Days'),
            value: 'last30Days',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.next7Days'),
            value: 'next7Days',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.next30Days'),
            value: 'next30Days',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.last90Days'),
            value: 'last90Days',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.next90Days'),
            value: 'next90Days',
          },
        ],
      },
      {
        key: 'payment.frequency',
        label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.title'),
        type: 'single',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          const billSubOccKey = 'billSubscriptionOccurrence.id';
          const paymentMethodKey = 'payment.createMethod';
          if (values.includes(PaymentsTabFrequencyFilter.Recurring)) {
            return {
              filterQueryParams: {
                $or: [{ [billSubOccKey]: { $ne: null } }, { [paymentMethodKey]: 'auto_payment' }],
              },
            };
          } else if (values.includes(PaymentsTabFrequencyFilter.OneTime)) {
            return { filterQueryParams: { [billSubOccKey]: null, [paymentMethodKey]: { $ne: 'auto_payment' } } };
          }
          return {};
        },
        options: [
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.all.label'),
            value: PaymentsTabFrequencyFilter.All,
            selectWhenNothingSelected: true,
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.oneTime.label'),
            value: PaymentsTabFrequencyFilter.OneTime,
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.recurring.label'),
            value: PaymentsTabFrequencyFilter.Recurring,
          },
        ],
      },
      isPaymentsTabNewFiltersPhase2Enabled
        ? {
            key: 'payment.deliveryMethod',
            label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.title'),
            type: 'multi',
            calcQueryParams: (values: string[]): CalcQueryParamsResult => {
              const deliveryMethodTypesValues = values.filter((value) =>
                Object.values(DeliveryPreferenceType).includes(value as DeliveryPreferenceType)
              );
              const managedConditions = deliveryMethodTypesValues.filter(
                (value) => value === DeliveryPreferenceType.Check || value === DeliveryPreferenceType.Ach
              );
              if (deliveryMethodTypesValues.length) {
                return {
                  filterQueryParams: {
                    $or: {
                      $or: [{ 'deliveryMethod.deliveryType': deliveryMethodTypesValues }],
                      ...(managedConditions.length
                        ? {
                            $and: [
                              { 'deliveryMethod.deliveryType': 'managed' },
                              { 'managedAccount.deliveryType': managedConditions },
                            ],
                          }
                        : {}),
                    },
                  },
                };
              }
              return {};
            },
            options: [
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.bankTransfer.label'),
                value: DeliveryPreferenceType.Ach,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.paperCheck.label'),
                value: DeliveryPreferenceType.Check,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.international.label'),
                value: DeliveryPreferenceType.International,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.virtualCard.label'),
                value: DeliveryPreferenceType.VirtualCard,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.wireTransfer.label'),
                value: DeliveryPreferenceType.DomesticWire,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.card.label'),
                value: DeliveryPreferenceType.Card,
              },
            ],
          }
        : undefined,
    ]);
    return paymentsNewFilters;
  }, [
    formatMessage,
    isApprovalWorkflowsV2Enabled,
    isPaymentsTabNewFiltersPhase2Enabled,
    startOfTodayTimestamp,
    endOfTodayTimestamp,
    startOfTodayDate,
    endOfTodayDate,
  ]);
};
