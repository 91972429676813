/* eslint-disable max-lines */
import { getIsChangingDeliveryMethodIsDisabled, isEbill as isBillEbill, useBillActions } from '@melio/ap-domain';
import { useAccountingPlatformName, useShowMemoToVendor, useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import {
  Button,
  Container,
  Divider,
  Form,
  Group,
  IconButton,
  Layout,
  Link,
  SectionBanner,
  useBreakpointValue,
  UseMelioFormResults,
} from '@melio/penny';
import {
  AccountingPlatform,
  ApprovalRequirementStatus,
  Bill,
  BillSubscription,
  CreateVendorAnalyticsMetadataFlow,
  FeeCatalog,
  FileInfo,
  FundingSource,
  FundingSourceType,
  Payment,
  PaymentCalendarCalculation,
  PaymentSettingsCalculations,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { PageTitle, useDateUtils, useFormBanner, withFormBannerAnnouncementProvider } from '@melio/platform-utils';
import { SystemMessageDisplay } from '@melio/platform-utils/system-message';
import { isToday } from 'date-fns';
import { useState } from 'react';

import { useActivitiesNavigate } from '../../../../utils';
import { AddCardAccountDrawerActivity } from '../../../funding-sources/AddCardAccountDrawer';
import { PayDashboardTabs } from '../../../PayDashboard';
import { getSkipVendorEmail } from '../../../single-payment/utils/scheduleFlowStepsUtils';
import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../types';
import { AmountInputContainer } from './components/AmountInput/AmountInput';
import { BillDetailsButton } from './components/BillDetailsButton/BillDetailsButton';
import { CollapsedFormContent } from './components/CollapsedContent/CollapsedFormContent';
import { DeliveryDateInput } from './components/DeliveryDateInput/DeliveryDateInput';
import { DeliveryMethodSection } from './components/DeliveryMethodSection/DeliveryMethodSection';
import { FeesSection } from './components/FeesSection/FeesSection';
import { FrequencySelector } from './components/FrequencySelector';
import { FundingSourceSection, useFundingSourceSection } from './components/FundingSourceSection';
import { MandatoryFieldDisclaimer } from './components/MandatoryFieldDisclaimer';
import { VendorBannersOnPayFlow } from './components/modals/VendorBannersOnPayFlow/VendorBannersOnPayFlow';
import { NoteToVendorInput } from './components/NoteToVendorInput';
import { RecurringFields } from './components/RecurringFields/RecurringFields';
import { SectionHeader } from './components/SectionHeader';
import { AriaLive } from './components/util/AriaLive';
import { VendorInput } from './components/VendorInput';

export type PaymentFlowFormProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  handlers: PaymentFlowOnChangeHandlers;
  onBillButtonClick: VoidFunction;
  fundingSources: FundingSource[];
  selectedVendor?: Vendor;
  paymentCalendarDates?: PaymentCalendarCalculation['dates'];
  payment?: Payment;
  billSubscription?: BillSubscription;
  feeCatalog?: FeeCatalog[];
  bill?: Bill;
  file?: FileInfo;
  paymentSettings?: PaymentSettingsCalculations;
  frequencySelectorSettings: {
    isVisible: boolean;
    isDisabled: boolean;
    recurringDisabledText?: string;
  };
  isDatesLoading: boolean;
  isFundingSourcesLoading: boolean;
  isSubmitting: boolean;
  isSubmitButtonDisabled: boolean;
  onClose: VoidFunction;
  title: string;
  activeAccountingPlatform?: AccountingPlatform;
  shouldRunReconciliation: (fundingSource: FundingSource | undefined) => boolean;
  runReconciliationFlow: VoidFunction;
  approvalRequirementStatus?: ApprovalRequirementStatus;
  collapsedState: 'open' | 'collapsed' | 'loading';
};

export const PaymentFlowForm = withFormBannerAnnouncementProvider(
  ({
    form,
    handlers: {
      onVendorChange,
      onAmountChange,
      onFundingSourceChange,
      onDeliveryMethodChange,
      onRecurrenceTypeChange,
      onDeliveryDateChange,
      onStartDateChange,
    },
    collapsedState,
    feeCatalog,
    onBillButtonClick,
    fundingSources,
    selectedVendor,
    paymentCalendarDates,
    payment,
    bill,
    paymentSettings,
    frequencySelectorSettings,
    isDatesLoading,
    isFundingSourcesLoading,
    isSubmitting,
    isSubmitButtonDisabled,
    onClose,
    title,
    activeAccountingPlatform,
    shouldRunReconciliation,
    runReconciliationFlow,
    approvalRequirementStatus,
    billSubscription,
  }: PaymentFlowFormProps) => {
    const {
      settings: {
        payment: {
          scheduling: { initialFormCollapsedState },
          editing: { disableEditDeliveryMethod },
        },
      },
    } = useConfig();
    const { formatMessage, formatDate } = useMelioIntl();
    const { createDate } = useDateUtils();
    const { getBillActions } = useBillActions();
    const { shouldDisplayMemoToVendor, allowSendingEmailToVendorWhenAddingMemo } = useShowMemoToVendor();

    const { generateNPEDashboardLink } = useActivitiesNavigate();

    const linkToUnpaidBills = selectedVendor?.name
      ? generateNPEDashboardLink(PayDashboardTabs.Bills, {
          urlParams: [
            {
              key: 'search',
              value: selectedVendor.name,
            },
          ],
        })
      : undefined;

    const isMobile = useBreakpointValue({ xs: true, s: false });

    const { formProps, submitButtonProps, watch, formState } = form;
    const [isAddCardDrawerOpen, setIsAddCardDrawerOpen] = useState(false);
    const [
      vendorId,
      deliveryMethodId,
      fundingSourceId,
      recurrenceType,
      deliveryDate,
      scheduleDate,
      amountToPay,
      lastAmount,
      deliveryPreference,
    ] = watch([
      'vendorId',
      'deliveryMethodId',
      'fundingSourceId',
      'recurrenceType',
      'deliveryDate',
      'scheduleDate',
      'amountToPay',
      'lastAmount',
      'deliveryPreference',
    ]);

    const { announceBanner } = useFormBanner();

    const onSubmitButtonClick: typeof submitButtonProps.onClick = (...args) => {
      if (!isVendorDirectoryInfoCompleted) {
        announceBanner();
        return;
      }

      submitButtonProps.onClick(...args);
    };

    const selectedFundingSource = fundingSources.find((fundingSource) => fundingSource.id === fundingSourceId);
    const selectedDeliveryMethod = selectedVendor?.deliveryMethods.find(
      (deliveryMethod) => deliveryMethod.id === deliveryMethodId
    );

    const isOneTimePayment = recurrenceType === 'one_time';
    const isEditFlow = Boolean(payment || billSubscription);
    const isPendingApproval = approvalRequirementStatus === ApprovalRequirementStatus.Pending;
    const isEarliestAsPossible = scheduleDate && !isPendingApproval ? isToday(scheduleDate) : false;
    const isEbill = !!bill && isBillEbill(bill);
    const isBillDetailsButtonDisabled =
      !!payment || !isOneTimePayment || (!!bill && !getBillActions(bill).actions.edit);
    const isDeliveryMethodDisabled =
      (!!payment && disableEditDeliveryMethod) ||
      (!!selectedDeliveryMethod && getIsChangingDeliveryMethodIsDisabled(selectedDeliveryMethod));

    const showVendorEmail =
      allowSendingEmailToVendorWhenAddingMemo && selectedVendor && !getSkipVendorEmail(selectedVendor);
    const showMemoToVendor =
      selectedVendor && selectedDeliveryMethod && shouldDisplayMemoToVendor(selectedDeliveryMethod);

    const showFeeSection = deliveryPreference && deliveryMethodId && fundingSourceId && amountToPay;

    const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(selectedVendor);

    const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
    const shouldShowReconciliationBanner = shouldRunReconciliation(selectedFundingSource);
    const vendorInputAnalyticsProperties = {
      PageName: 'pay',
      Flow: 'vendor',
      Intent: 'add-a-vendor',
      VendorCreatedOrigin: 'single-payment',
    };

    const shouldShowDmSection = !!vendorId && isVendorDirectoryInfoCompleted;

    const fundingSourceSection = useFundingSourceSection({
      formContext: { isEditFlow, submitting: isSubmitting },
      onFundingSourceChange,
      onOpenAddFundingSourceDrawer: () => setIsAddCardDrawerOpen(true),
      paymentSettings,
      fundingSources,
      isLoading: isFundingSourcesLoading,
      feeCatalog,
      fundingSourceId,
    });

    return (
      <Layout
        data-testid="payment-flow-form"
        maxWidth="600px"
        header={
          isMobile
            ? {
                isSticky: true,
                content: (
                  <Group variant="vertical" spacing="none" data-testid="payment-flow-form-header">
                    <Container width="full" paddingX="s" paddingY="s">
                      <Group variant="horizontal" spacing="s" alignItems="center">
                        <IconButton
                          data-testid="payment-flow-form-header-cancel"
                          icon="arrow-left"
                          size="medium"
                          variant="naked"
                          aria-label={formatMessage('activities.paymentFlow.form.header.back')}
                          onClick={onClose}
                        />
                        <PageTitle textStyle="heading2Semi" data-testid="payment-flow-form-header-title">
                          {title}
                        </PageTitle>
                      </Group>
                    </Container>
                    <Divider variant="horizontal" />
                  </Group>
                ),
              }
            : undefined
        }
        footer={{
          isSticky: true,
          content: (
            <Group variant="vertical" spacing="none" data-testid="payment-flow-form-footer">
              <Divider variant="horizontal" />
              <Container width="full" paddingX="s" paddingY="s">
                <Group variant="horizontal" spacing="s" justifyContent="space-between">
                  {!isMobile && (
                    <Button
                      data-testid="payment-flow-form-footer-cancel"
                      label={formatMessage('activities.paymentFlow.form.footer.cancel')}
                      size="medium"
                      variant="tertiary"
                      onClick={onClose}
                    />
                  )}
                  <Button
                    {...submitButtonProps}
                    onClick={onSubmitButtonClick}
                    isDisabled={submitButtonProps.isDisabled || isSubmitButtonDisabled}
                    data-testid="payment-flow-form-footer-submit"
                    label={
                      isEditFlow
                        ? formatMessage('activities.paymentFlow.form.footer.update')
                        : formatMessage('activities.paymentFlow.form.footer.submit')
                    }
                    size="medium"
                    isFullWidth={isMobile}
                  />
                </Group>
              </Container>
            </Group>
          ),
        }}
      >
        <Group variant="vertical" spacing={useBreakpointValue({ xs: 'm', s: 'l', xl: 'l' })}>
          <SystemMessageDisplay data-testid="payment-flow-form-top-error-banner" />
          <Group variant="vertical" spacing={useBreakpointValue({ xs: 'm', s: 'l', xl: 'xl' })}>
            {!isMobile && (
              <PageTitle textStyle="heading1Semi" data-testid="payment-flow-form-content-title">
                {title}
              </PageTitle>
            )}
            <Group variant="vertical" spacing="l">
              <VendorBannersOnPayFlow selectedVendor={selectedVendor} linkToUnpaidBills={linkToUnpaidBills} />
              <Group variant="vertical" spacing="xs" width="full">
                <MandatoryFieldDisclaimer />
                <Group variant="vertical" spacing="l" data-testid="payment-flow-form-content">
                  <Form {...formProps}>
                    <Group width="full" variant="vertical" spacing="l">
                      <Group width="full" variant="vertical" spacing="s">
                        <SectionHeader data-testid="payment-flow-form-content-pay-to">
                          {formatMessage('activities.paymentFlow.form.content.pay.to.label')}
                        </SectionHeader>
                        <Group width="full" variant="vertical" spacing="m">
                          <VendorInput
                            form={form}
                            onChange={onVendorChange}
                            isReadOnly={isEditFlow || Boolean(bill)}
                            analyticsProperties={vendorInputAnalyticsProperties}
                            createVendorFlow={CreateVendorAnalyticsMetadataFlow.Payment}
                            isSubmitted={formState.isSubmitted}
                          />
                          <Group variant="vertical" spacing="s">
                            <AmountInputContainer form={form} bill={bill} payment={payment} onChange={onAmountChange} />
                            {!isEbill && (
                              <BillDetailsButton
                                bill={bill}
                                onClick={onBillButtonClick}
                                isDisabled={isBillDetailsButtonDisabled}
                              />
                            )}
                          </Group>
                          {frequencySelectorSettings.isVisible && initialFormCollapsedState === 'partial' && (
                            <FrequencySelector
                              form={form}
                              onChange={onRecurrenceTypeChange}
                              isDisabled={frequencySelectorSettings.isDisabled}
                              disabledText={{
                                recurring: frequencySelectorSettings.recurringDisabledText,
                              }}
                            />
                          )}
                        </Group>
                      </Group>
                      <CollapsedFormContent collapsedState={collapsedState}>
                        <Group width="full" variant="vertical" spacing="l">
                          <Group width="full" variant="vertical" spacing="s">
                            <SectionHeader id="pay-from" data-testid="payment-flow-form-content-pay-from">
                              {formatMessage('activities.paymentFlow.form.content.pay.from.label')}
                            </SectionHeader>
                            <AriaLive shown={shouldShowReconciliationBanner}>
                              <SectionBanner
                                data-testid="payment-flow-form-reconciliation-banner"
                                variant="warning"
                                description={formatMessage(
                                  'activities.paymentFlow.form.content.reconciliationBanner.description',
                                  {
                                    accountingPlatformName,
                                    link: (
                                      <Link
                                        data-testid="payment-flow-form-reconciliation-banner-link"
                                        href="#"
                                        label={formatMessage(
                                          'activities.paymentFlow.form.content.reconciliationBanner.link'
                                        )}
                                        onClick={runReconciliationFlow}
                                      />
                                    ),
                                  }
                                )}
                              />
                            </AriaLive>
                            <FundingSourceSection {...fundingSourceSection} />
                          </Group>
                          <Group width="full" variant="vertical" spacing="s">
                            <SectionHeader data-testid="payment-flow-form-content-deliver-to">
                              {formatMessage('activities.paymentFlow.form.content.deliver.to.label')}
                            </SectionHeader>
                            <Group width="full" variant="vertical" spacing="m">
                              {shouldShowDmSection && (
                                <DeliveryMethodSection
                                  vendorId={vendorId}
                                  deliveryMethodId={deliveryMethodId}
                                  paymentSettings={paymentSettings}
                                  isDisabled={isDeliveryMethodDisabled || isSubmitting}
                                  onDeliveryMethodChange={onDeliveryMethodChange}
                                  feesCatalog={feeCatalog}
                                />
                              )}
                              {frequencySelectorSettings.isVisible && initialFormCollapsedState === 'full' && (
                                <FrequencySelector
                                  form={form}
                                  onChange={onRecurrenceTypeChange}
                                  isDisabled={frequencySelectorSettings.isDisabled}
                                  disabledText={{
                                    recurring: frequencySelectorSettings.recurringDisabledText,
                                  }}
                                />
                              )}
                              <Group width="full" variant="vertical" spacing="s">
                                <AriaLive shown={!!selectedVendor && isEarliestAsPossible}>
                                  <SectionBanner
                                    data-testid="payment-flow-form-content-banners-earliestAsPossible"
                                    description={
                                      <div>
                                        {formatMessage(
                                          selectedFundingSource?.type === FundingSourceType.Card
                                            ? 'activities.paymentFlow.form.content.banners.earliestAsPossible.card'
                                            : 'activities.paymentFlow.form.content.banners.earliestAsPossible.bank',
                                          {
                                            date: formatDate(deliveryDate ?? undefined, { dateStyle: 'medium' }),
                                          }
                                        )}
                                      </div>
                                    }
                                    variant="informative"
                                  />
                                </AriaLive>
                                <AriaLive shown={!!selectedVendor && isPendingApproval}>
                                  <SectionBanner
                                    data-testid="payment-flow-form-content-banners-pendingApproval"
                                    description={formatMessage(
                                      'activities.paymentFlow.form.content.banners.pendingApproval',
                                      {
                                        date: formatDate(createDate(scheduleDate?.toISOString()), {
                                          dateStyle: 'medium',
                                        }),
                                      }
                                    )}
                                    variant="informative"
                                  />
                                </AriaLive>
                                {isOneTimePayment ? (
                                  <DeliveryDateInput
                                    form={form}
                                    paymentCalendarDates={paymentCalendarDates}
                                    vendor={selectedVendor}
                                    fundingSource={selectedFundingSource}
                                    deliveryMethod={selectedDeliveryMethod}
                                    onChange={onDeliveryDateChange}
                                    isLoading={isDatesLoading}
                                  />
                                ) : (
                                  <RecurringFields
                                    form={form}
                                    paymentCalendarDates={paymentCalendarDates}
                                    onStartDateChange={onStartDateChange}
                                    isLoading={isDatesLoading}
                                    isEditFlow={isEditFlow}
                                  />
                                )}
                              </Group>
                              {showMemoToVendor ? <NoteToVendorInput form={form} /> : null}
                              {showVendorEmail ? (
                                <Form.TextField
                                  {...form.registerField('vendorEmail')}
                                  labelProps={{
                                    label: formatMessage('activities.paymentFlow.form.content.vendorEmail.label'),
                                  }}
                                  helperTextProps={{
                                    label: formatMessage('activities.paymentFlow.form.content.vendorEmail.helperText'),
                                  }}
                                />
                              ) : null}
                            </Group>
                          </Group>
                          <AriaLive shown={!!showFeeSection}>
                            {showFeeSection ? (
                              <FeesSection
                                amount={amountToPay}
                                deliveryPreferenceType={deliveryPreference}
                                deliveryMethodId={deliveryMethodId}
                                fundingSourceId={fundingSourceId}
                                lastAmount={lastAmount}
                                billId={bill?.id}
                              />
                            ) : null}
                          </AriaLive>
                        </Group>
                      </CollapsedFormContent>
                    </Group>
                  </Form>
                  <AddCardAccountDrawerActivity
                    onDone={(createdFundingSource) => {
                      setIsAddCardDrawerOpen(false);
                      onFundingSourceChange(createdFundingSource.id);
                    }}
                    isOpen={isAddCardDrawerOpen}
                    onCancel={() => setIsAddCardDrawerOpen(false)}
                    onClose={() => setIsAddCardDrawerOpen(false)}
                  />
                </Group>
              </Group>
            </Group>
          </Group>
        </Group>
      </Layout>
    );
  }
);
