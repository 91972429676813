import { Button, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { MigratedUserExperiencePillRow } from './MigratedUserExperiencePillRow';
import { MigratedUserExperienceRow } from './MigratedUserExperienceRow';

type MigratedUserExperienceBodyProps = {
  onClose: () => void;
};
export const MigratedUserExperienceBodyDesktop = ({ onClose }: MigratedUserExperienceBodyProps) => {
  const { formatMessage } = useMelioIntl();

  //we need to do it for ada currently penny support focus only on the x button which we don't have in this modal so we need to focus the welcome button
  useEffect(() => {
    setTimeout(() => {
      const migratedTourButton = document.getElementById('pay-dashboard-migrated-user-experience-modal-button');
      if (migratedTourButton) {
        migratedTourButton.focus();
      }
    }, 100);
  }, []);

  return (
    <Container width="full" paddingX="m" paddingY="m" data-testid="pay-dashboard-migrated-user-experience-body">
      <Group variant="vertical" spacing="xl">
        <Text textStyle="heading2Semi" color="semantic.text.primary">
          {formatMessage('activities.migrationUserDashboardTour.modal.title')}
        </Text>
        <Text textStyle="body2" color="semantic.text.primary">
          {formatMessage('activities.migrationUserDashboardTour.modal.description')}
        </Text>
        <Group variant="vertical" spacing="s">
          <MigratedUserExperienceRow
            description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row1')}
            iconType="checked"
          />
          <MigratedUserExperienceRow
            description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row2')}
            iconType="checked"
          />

          <MigratedUserExperiencePillRow />
        </Group>
        <Button
          data-testid="pay-dashboard-migrated-user-experience-modal-button"
          id="pay-dashboard-migrated-user-experience-modal-button"
          onClick={onClose}
          label={formatMessage('activities.migrationUserDashboardTour.modal.button')}
          variant="primary"
        />
      </Group>
    </Container>
  );
};
