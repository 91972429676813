import { UseMelioFormResults } from '@melio/penny';
import { EventProperties, useAnalytics, WithAnalyticsContextProps } from '@melio/platform-analytics';
import { Plan, usePlanInfo } from '@melio/subscriptions';
import { useEffect } from 'react';

import { ManageClientFormFields, ManageClientFormStep } from './manage-client-form.types';

type ManageClientFormAnalyticsCta = 'invite-client' | 'invite-client-uncheck' | 'confirm-and-submit';

type ManageClientFormAnalyticsIntent = ManageClientFormAnalyticsCta | 'submit' | 'add-client-basic-details';

export const useManageClientFormAnalytics = ({
  activeStep,
  watch,
  setAnalyticsProperties,
  currentPlan,
}: {
  activeStep: ManageClientFormStep;
  watch: UseMelioFormResults<ManageClientFormFields>['watch'];
  formState: UseMelioFormResults<ManageClientFormFields>['formState'];
  setAnalyticsProperties: WithAnalyticsContextProps['setAnalyticsProperties'];
  currentPlan?: Plan | undefined;
}) => {
  const currentPlanInfo = usePlanInfo(currentPlan?.id);
  const { track } = useAnalytics();
  const hasPaymentMethod = !!watch('fundingSourceId');

  const stepToEventProps: Record<ManageClientFormStep, EventProperties> = {
    [ManageClientFormStep.BasicDetails]: {
      Intent: 'add-client-basic-details',
    },
    [ManageClientFormStep.BillingDetails]: {
      Intent: 'add-client-billing-details',
    },
    [ManageClientFormStep.PlanSelection]: {
      Intent: 'select-client-plan',
      PlanId: currentPlan?.id || 'post-onboarding',
      CurrentPlan: currentPlanInfo?.planName?.toLowerCase() || 'post-onboarding',
    },
    [ManageClientFormStep.ReviewAndConfirm]: {},
  };

  setAnalyticsProperties({
    IsPMExist: hasPaymentMethod,
    Intent: activeStep,
    PageName: activeStep,
    ...stepToEventProps[activeStep],
  });

  useEffect(() => {
    track('Organization', 'View');
  }, [activeStep, currentPlan?.id, currentPlanInfo?.planName, track]);

  const trackClick = (
    action: ManageClientFormAnalyticsCta,
    intent: ManageClientFormAnalyticsIntent = action,
    additionalProps = {}
  ) => track('Organization', 'Click', { Cta: action, Intent: intent, ...additionalProps });

  return { trackClick };
};
