import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { TermsAndConditionsForm } from '../types';

export const useTermsAndConditionsFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const schema = yup.object().shape({
    termsAndConditions: yup
      .boolean()
      .oneOf(
        [true],
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.termsAndConditionsCard.termsAndConditionsField.required'
        )
      )
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.termsAndConditionsCard.termsAndConditionsField.required'
        )
      ),
  }) as yup.SchemaOf<TermsAndConditionsForm>;

  return schema;
};
