import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { useCallback } from 'react';

export const WarningBanner = () => {
  const { formatMessage } = useMelioIntl();
  const description = formatMessage(
    'activities.accountingPlatformConnectCallback.screens.confirmOrganization.sectionBanner.warning.description'
  );

  const onMessageShown = useCallback((ref: HTMLDivElement | null): void => {
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth' });
      ref.focus();
    }
  }, []);

  return (
    <section aria-label={formatMessage('app.notifications.ariaLabel')}>
      <SectionBanner
        ref={onMessageShown}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabIndex={-1}
        description={description}
        variant="warning"
        data-testid="confirm-connect-warning-banner"
      />
    </section>
  );
};
