import { LegalDocumentsApiClient, LegalDocumentTypeEnum } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching, useQuery } from '@tanstack/react-query';

export const useLegalDocuments = ({ signTermsAndConditionsEnabled }: { signTermsAndConditionsEnabled: boolean }) => {
  const queryKey = ['LegalDocumentsApi', 'details'];
  const query = useQuery({
    enabled: true,
    queryKey,
    queryFn: async () => {
      if (signTermsAndConditionsEnabled) {
        const response = await LegalDocumentsApiClient.getLegalDocuments();
        const termsAndConditions = response.data.data.find(
          (it) => it.legalDocumentType === LegalDocumentTypeEnum.TermsAndConditions
        );
        return {
          shouldSignTermsAndConditions: termsAndConditions?.agreementRequired || false,
          isFirstTime: termsAndConditions?.firstTimeAcceptance || false,
        };
      }
      return {
        shouldSignTermsAndConditions: false,
        isFirstTime: false,
      };
    },
  });

  const isFetching = useIsFetching(queryKey) > 0;
  return {
    ...query,
    isFetching,
    isLoading: query.isInitialLoading,
  };
};
