import { Navigate, useLocation } from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useIsMissingKycComplianceInformation } from '@melio/platform-kyc';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { TabsLayout } from '../../layouts';
import { CustomersTableFlow } from './CustomersTable.flow';
import { InvoicesTableFlow } from './InvoicesTable.flow';
import { PaymentsTableFlow } from './PaymentsTable.flow';
import { useArDashboardTabs, useDashboardFlowNavigation } from './utils';

export type DashboardFlowProps = {
  onError?: ARErrorFunction;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  onCreateInvoice: (props?: { customerId?: string }) => unknown;
  onPreviewInvoicePDF: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  createdInvoiceId?: string;
  onVisitSupportSettingsPage: VoidFunction;
  onVisitSettingsPage: VoidFunction;
  onCreateCustomer: VoidFunction;
  isMtlKycUpliftEnabled?: boolean;
  onCompleteKycDetails?: (returnUrl?: string) => unknown;
};

export const DashboardFlow = withAnalyticsContext<DashboardFlowProps>(
  forwardRef(
    (
      {
        onVisitSupportSettingsPage,
        onVisitSettingsPage,
        onEditInvoice,
        onError,
        onCreateInvoice,
        onPreviewInvoicePDF,
        onCreateCustomer,
        onSendReminder,
        createdInvoiceId,
        isMtlKycUpliftEnabled,
        onCompleteKycDetails: _onCompleteKycDetails,
        setAnalyticsProperties,
        ...props
      },
      ref
    ) => {
      const { tabs, activeTab, handleTabChange } = useArDashboardTabs();
      const [isPaymentsTabEnabled] = useDevFeature(FeatureFlags.ARPaymentsTab, false);
      const { pathname } = useLocation();

      const { Paths, DefaultPath, goToInvoicesDashboard } = useDashboardFlowNavigation();

      const { isMissingKycComplianceInformation, isVerificationFailed } = useIsMissingKycComplianceInformation({
        enabled: isMtlKycUpliftEnabled,
      });

      const onCompleteKycDetails = _onCompleteKycDetails && (() => _onCompleteKycDetails(pathname));

      const kycBannerVariation = isMissingKycComplianceInformation
        ? 'complete-details'
        : isVerificationFailed
        ? 'verification-failed'
        : undefined;

      setAnalyticsProperties({
        PageName: 'dashboard',
        Flow: 'dashboard',
        TabName: activeTab,
      });

      return (
        <Routes>
          <Route
            element={
              <TabsLayout
                onVisitSupportSettingsPage={onVisitSupportSettingsPage}
                onVisitSettingsPage={onVisitSettingsPage}
                onCreateInvoice={onCreateInvoice}
                onCreateCustomer={onCreateCustomer}
                tabs={tabs}
                activeTab={activeTab}
                onChange={handleTabChange}
                kycBannerVariation={kycBannerVariation}
                onCompleteKycDetails={onCompleteKycDetails}
              />
            }
          >
            <Route index element={<Navigate to={DefaultPath} withSearchparams replace closeToast={false} />} />

            <Route
              path={Paths.Invoices}
              element={
                <InvoicesTableFlow
                  onPreviewInvoicePDF={onPreviewInvoicePDF}
                  createdInvoiceId={createdInvoiceId}
                  onEditInvoice={onEditInvoice}
                  onSendReminder={onSendReminder}
                  onError={onError}
                  {...props}
                  ref={ref}
                />
              }
            />

            <Route
              path={Paths.Customers}
              element={
                <CustomersTableFlow
                  onCreateInvoice={(customerId: string) => onCreateInvoice({ customerId })}
                  onDisplayCustomerInvoices={goToInvoicesDashboard}
                  onError={onError}
                  {...props}
                  ref={ref}
                />
              }
            />

            {isPaymentsTabEnabled ? (
              <Route path={Paths.Payments} element={<PaymentsTableFlow onError={onError} />} />
            ) : null}
          </Route>
        </Routes>
      );
    }
  )
);
