import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { Button, CalendarLegendProps, Container, Group, Table } from '@melio/penny';
import { PaymentIntent, usePaymentCalendar } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isSameDay } from 'date-fns';
import { first, last } from 'lodash';
import { useState } from 'react';

import { CellError } from '../../../../useSubmitBatchPaymentsErrors/types';
import { DeliveryDateValue } from './components/DeliveryDateValue';

type DeliveryDateSelectCellProps = {
  paymentIntentId: PaymentIntent['id'];
  vendorId: PaymentIntent['billInfo']['vendorId'];
  amountToPay: PaymentIntent['amountToPay'];
  fundingSourceId: PaymentIntent['fundingSourceId'];
  deliveryMethodId: PaymentIntent['deliveryMethodId'];
  selectedDeliveryPreferenceType: PaymentIntent['selectedDeliveryPreferenceType'];
  effectiveDeliveryDate: PaymentIntent['effectiveDeliveryDate'];
  effectiveScheduledDate: PaymentIntent['effectiveScheduledDate'];
  onDeliveryDateSelect: (
    paymentIntentId: PaymentIntent['id'],
    date: NonNullable<PaymentIntent['deliveryDate']>
  ) => void;
  isLate: boolean;
  isDisabled: boolean;
  error?: CellError;
};

export const DeliveryDateSelectCell = ({
  paymentIntentId,
  amountToPay,
  fundingSourceId,
  vendorId,
  deliveryMethodId,
  selectedDeliveryPreferenceType,
  effectiveDeliveryDate,
  onDeliveryDateSelect,
  isLate,
  isDisabled,
  effectiveScheduledDate,
  error,
}: DeliveryDateSelectCellProps) => {
  const { formatMessage } = useMelioIntl();
  const { minDeliveryDate, maxDeliveryDate } = usePaymentSchedulingPreference();

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    deliveryDate: effectiveDeliveryDate,
    scheduleDate: effectiveScheduledDate,
  });

  const legendItems: CalendarLegendProps[] = [
    {
      label: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.legend.today`),
      variant: 'today',
    },
    {
      label: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.legend.secondary`),
      variant: 'secondary',
    },
    {
      label: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.legend.primary`),
      variant: 'primary',
    },
  ];

  const { data: paymentCalendar } = usePaymentCalendar({
    payload: {
      startDate: minDeliveryDate,
      endDate: maxDeliveryDate,
      vendorId,
      amount: amountToPay || undefined,
      fundingSourceId: fundingSourceId || undefined,
      deliveryMethodId: deliveryMethodId || undefined,
      deliveryPreference: selectedDeliveryPreferenceType || undefined,
    },
  });

  return (
    <Table.DateCell
      onOpenChange={(open, event) => {
        if (event === 'content') {
          return;
        }
        if (open) {
          setSelectedDate({
            deliveryDate: effectiveDeliveryDate,
            scheduleDate: effectiveScheduledDate,
          });
        }
        setCalendarOpen(open);
      }}
      legendItems={legendItems}
      showToday
      excludeHolidays
      isOpen={isCalendarOpen}
      weekDays={[1, 2, 3, 4, 5]}
      minDate={first(paymentCalendar?.dates)?.minDeliveryDate}
      maxDate={last(paymentCalendar?.dates)?.minDeliveryDate}
      onSelect={(date) => {
        const dates = date ? paymentCalendar?.dates.find((dates) => isSameDay(dates.minDeliveryDate, date)) : null;
        if (dates) {
          setSelectedDate({
            deliveryDate: dates.minDeliveryDate,
            scheduleDate: dates.scheduleDate,
          });
        }
      }}
      placeholder={formatMessage('activities.batchPayments.screens.paymentIntentsTable.scheduledDateCell.placeholder')}
      data-testid={`${paymentIntentId}-delivery-date-cell`}
      isReadOnly={isDisabled}
      displayValue={
        <DeliveryDateValue
          paymentIntentId={paymentIntentId}
          deliveryDate={effectiveDeliveryDate}
          selectedDeliveryPreferenceType={selectedDeliveryPreferenceType}
          isLate={isLate}
          isDeliveryMethodSelected={!!deliveryMethodId}
          error={error}
        />
      }
      secondarySelectedDate={selectedDate?.scheduleDate || undefined}
      selectedDate={selectedDate?.deliveryDate || undefined}
      footer={
        <Container paddingX="s" paddingY="s" data-testid="deliveryDate-calendar-footer">
          <Group variant="horizontal" justifyContent="space-between">
            <Button
              data-testid="batch-payments-deliveryDate-calendar-footer-close"
              variant="tertiary"
              size="small"
              label={formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.footer.close'
              )}
              onClick={() => setCalendarOpen(false)}
            />
            <Button
              data-testid="batch-payments-deliveryDate-calendar-footer-apply"
              variant="primary"
              size="small"
              label={formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.footer.apply'
              )}
              onClick={() => {
                if (!selectedDate?.deliveryDate) {
                  return;
                }
                setCalendarOpen(false);
                onDeliveryDateSelect(paymentIntentId, selectedDate.deliveryDate);
              }}
            />
          </Group>
        </Container>
      }
    />
  );
};
