// eslint-disable-next-line no-restricted-imports
import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, MutationCallbackOptions, MutationContext, UseCollectionApiResult } from './types';

export function useCreateMutation<TData, TVariables = void>(
  mutationFn: MutationFunction<TData, TVariables>,
  query: Pick<UseCollectionApiResult<TData>, 'queryKey'>,
  options: MutationCallbackOptions<TData, TVariables> = {}
) {
  const queryClient = useQueryClient();
  const mutation = useMutation<TData, ApiError, TVariables, MutationContext<TData>>(query.queryKey, mutationFn, {
    onSuccess: (...args) => {
      void queryClient.invalidateQueries(query.queryKey);
      void options.onSuccess?.(...args);
    },
    onError: options.onError,
    onMutate: options.onMutate,
  });

  return mutation.mutateAsync;
}
