import { ReceivablePaymentsApiInstance } from '@melio/ar-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseReceivablePaymentProps = UseCollectionProps<typeof ReceivablePaymentsApiInstance.getReceivablePayments>;

export const useReceivablePayments = (props: UseReceivablePaymentProps = {}) =>
  useCollection({
    ...props,
    isPaginated: true,
    queryKey: 'ReceivablePaymentsApi',
    queryFn: ReceivablePaymentsApiInstance.getReceivablePayments,
  });
