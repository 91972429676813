import { Box } from '@chakra-ui/react';
import { useToast } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethod, usePaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useOneTimeEffect, useWizardSimple, UseWizardSimpleArgs } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { MonitoredAction } from '../../../monitoring';
import { useActivitiesNavigate } from '../../../utils';
import { getPaymentAnalyticsFields } from '../../../utils/analytics/paymentAnalytics';
import { EditVendorBankDetailsActivity } from '../../delivery-methods';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { PayDashboardTabs } from '../../PayDashboard';
import { ReviewAndConfirmActivity } from '../ReviewAndConfirm';
import { InitPaymentFlowActivityStep } from './InitPaymentFlowActivityStep';
import { useFailedToDeliverACHData } from './useFailedToDeliverACHData';

type FailedToDeliverACHActivityProps = {
  paymentId: string;
  paymentIntentId?: string;
};

type Steps = 'init' | 'editAchDetails' | 'confirm';
const firstStep: Steps = 'init';
const locationsMap: UseWizardSimpleArgs<Steps>['locationsMap'] = {
  init: 'init',
  editAchDetails: 'edit-details',
  confirm: 'confirm',
};

export const FailedToDeliverACHActivity = withAnalyticsContext<FailedToDeliverACHActivityProps>(
  ({ paymentId, paymentIntentId, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { endAction } = useMonitoring<MonitoredAction>();
    const { toast } = useToast();
    const { navigateToTab } = useActivitiesNavigate();
    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message });
      cancelFlow();
    };
    const { payment, isLoading, vendorBankAccountDMId, retryDeliver, isUpdatingPayment } = useFailedToDeliverACHData(
      paymentId,
      handleFail
    );

    const {
      data: paymentIntent,
      update: updatePaymentIntent,
      isUpdating: isUpdatingPaymentIntent,
    } = usePaymentIntent({
      id: paymentIntentId,
    });

    const { goBack, goNext, cancelFlow, completeFlow, currentStep } = useWizardSimple({
      firstStep,
      flowName: 'retry-failed-to-deliver-payment',
      locationsMap,
      navigationSequence: ['init', 'editAchDetails', 'confirm'],
      cancelUrlFallback: '/',
      enableMidFlowEntry: !!paymentIntentId,
    });

    const onEditDone = async (updatedDeliveryMethod: DeliveryMethod) => {
      if (updatedDeliveryMethod.id === paymentIntent?.deliveryMethodId) {
        goNext();
        return;
      }

      try {
        await updatePaymentIntent({ deliveryMethodId: updatedDeliveryMethod.id });
        goNext();
      } catch (e) {
        handleFail(e as PlatformError);
      }
    };

    if (payment) {
      setAnalyticsProperties({
        ...getPaymentAnalyticsFields(payment),
        Intent: 'retry-failed-payment',
        Flow: 'single-payment',
        EntryPoint: 'dashboard_inbox',
        FailureCode: payment.paymentFailure?.failureCode,
        FailedDestination: 'deliver-failure',
      });
    }

    useOneTimeEffect(
      () => {
        const updatePaymentIntentIfVendorAlreadyUpdated = async () => {
          const vendorDeliveryMethodAlreadyUpdated = vendorBankAccountDMId !== payment?.deliveryMethodId;
          if (vendorDeliveryMethodAlreadyUpdated) {
            await updatePaymentIntent({ deliveryMethodId: vendorBankAccountDMId });
            goNext();
          }
        };
        updatePaymentIntentIfVendorAlreadyUpdated();
      },
      () =>
        !!(vendorBankAccountDMId && payment?.deliveryMethodId && paymentIntentId && currentStep === 'editAchDetails'),
      [vendorBankAccountDMId, payment?.deliveryMethodId, currentStep, paymentIntentId]
    );

    const shouldShowLoader = isLoading || isUpdatingPaymentIntent || !payment || !vendorBankAccountDMId;
    if (shouldShowLoader) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const onCompleteFlow = async () => {
      await retryDeliver({ deliveryMethodId: vendorBankAccountDMId });
      endAction('review_and_confirm');
      toast({
        type: 'success',
        title: formatMessage('activities.failedToDeliverACH.successToast'),
        id: 'failed-to-deliver-success',
      });
      completeFlow(onDone);
    };

    const onDone = () => {
      navigateToTab(PayDashboardTabs.Payments, { entityId: paymentId });
    };

    const onInitDone = () => {
      goNext(true);
    };

    return (
      <Box data-testid="failed-to-deliver-activity" height="100%">
        <Routes>
          <Route
            path={locationsMap.init}
            element={
              <InitPaymentFlowActivityStep
                paymentId={paymentId}
                paymentIntentId={paymentIntentId}
                startActivity={onInitDone}
              />
            }
          />
          <Route
            path={locationsMap.editAchDetails}
            element={
              <EditVendorBankDetailsActivity
                onBack={cancelFlow}
                vendorId={payment.vendorId}
                deliveryMethodId={vendorBankAccountDMId}
                onDone={onEditDone}
                onClose={cancelFlow}
                texts={{ continue: 'activities.failedToDeliverACH.screens.editVendorBankDetails.continue' }}
              />
            }
          />
          <Route
            path={locationsMap.confirm}
            element={
              <ReviewAndConfirmActivity
                step={2}
                totalSteps={2}
                paymentIntentId={paymentIntentId || ''}
                onEditDeliveryMethod={goBack}
                onDone={onCompleteFlow}
                onClose={cancelFlow}
                onBack={goBack}
                onError={handleFail}
                isLoadingButton={isUpdatingPayment}
                enableLateTag
                hideMemoSection
                hideFromSection
                updatedFields={['deliveryMethod']}
                texts={{ continue: 'activities.failedToDeliverACH.screens.reviewAndConfirm.confirm' }}
              />
            }
          />
        </Routes>
      </Box>
    );
  }
);
