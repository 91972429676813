/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { useDisclosure } from '@chakra-ui/react';
import { useNavigationWithQueryParams } from '@melio/ap-domain';
import { Container, Group, IconButton, Tooltip, useBreakpoint, useTable } from '@melio/penny';
import { OriginFlow, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { BillSubscription, Payment, PostApprovalDecisionEnum, usePaginatedPayments } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useLocation, useSystemMessage } from '@melio/platform-utils';
import { kebabCase, lowerCase } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom';

import { MonitoredAction } from '../../../../monitoring';
import { DeletePaymentModal, SelectedEntity } from '../../../delete-payment-modal';
import { MarkAsPaidModal } from '../../../mark-as-paid-modal/MarkAsPaidModal';
import { PaymentDrawerActivity } from '../../../payment-drawer';
import { useBatchPaymentApprovalDecision } from '../../../payment-drawer/hooks/useBatchPaymentApprovalDecision';
import { CreateReportModal } from '../../../payment-report-modal';
import { Filter } from '../../Filter/Filter';
import { FilterChips as NewFilterChips } from '../../FilterChips';
import { emitFocusEvent, FocusEvents } from '../../FocusSkipToComponent';
import { useFiltersNewFailedPayments } from '../../hooks/useGetNewFailedPayments';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useNewPaymentsTabItems } from '../../hooks/useNewPaymentsTabItems';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import { NestedFilters } from '../../NestedFilters';
import { PayDashboardFilterProvider, usePayDashboardFilters } from '../../PayDashboardFilterProvider';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PayDashboardTabs } from '../../types';
import { APTable } from '../APTable';
import { useItemsTabHighlightedRows } from '../BillsTab/useItemsTabHighlightedRows';
import { Filters } from '../Filters/Filters';
import { MobileSortMenu } from '../MobileSortMenu/MobileSortMenu';
import { PayDashboardPagination, PayDashboardPaginationProvider, usePayDashboardPagination } from '../Pagination';
import { EmptySearchResult, SearchBar } from '../Search';
import { FilterChips } from './components/FilterChips';
import { PaymentsTabEmptyState } from './components/PaymentsTabEmptyState';
import { PaymentsTabListItem } from './components/PaymentsTabListItem';
import { getSchedulerFullName } from './components/PaymentsTabScheduledByCell';
import { usePaymentStatus } from './components/PaymentsTabStatusCell';
import { PaymentsTabFilter } from './types';
import { supportedSearchParamKeys, useNewPaymentFilters } from './useNewPaymentFilters';
import { usePaymentFilters } from './usePaymentFilters';
import { usePaymentsTabAnalytics } from './usePaymentsTabAnalytics';
import { usePaymentsTabFiltersQueryParams } from './usePaymentsTabFiltersQueryParams';
import { usePaymentsTableColumns } from './usePaymentsTableColumns';
import { DEFAULT_SORT_BY_TAB_FILTER, usePaymentsTabQueryParams } from './usePaymentsTabQueryParams';
import { usePaymentsTabSortableColumns } from './usePaymentsTabSortableColumns';
import { convertPaymentsTabSortToApiSort } from './utils';

const reportModalAnalytics = {
  name: 'Dashboard',
  properties: { Flow: 'dashboard', EntryPoint: 'dashboard-payments' },
};

export type PaymentsTabProps = {
  onEditPayment: ({ id, returnUrl }: { id: Payment['id']; returnUrl: string }) => void;
  onAddNewPayment: (params: { returnUrl: string; originFlow: OriginFlow }) => void;
  onRetryFailedToDeliverPayment: (paymentId: Payment['id']) => void;
  onRetryFailedToCollectPayment: (paymentId: Payment['id']) => void;
  onRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndResendPayment: (paymentId: Payment['id']) => void;
  onEditBillSubscription: ({ id, returnUrl }: { id: BillSubscription['id']; returnUrl: string }) => void;
};

const PaymentsTabComponent = withAnalyticsContext(
  ({
    onEditPayment,
    onAddNewPayment,
    onRetryFailedToDeliverPayment,
    onRetryFailedToCollectPayment,
    onRefundPayment,
    onVoidAndRefundPayment,
    onVoidAndResendPayment,
    onEditBillSubscription,
  }: PaymentsTabProps) => {
    const { isExtraSmallScreen } = useBreakpoint();
    const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
    const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>(null);
    const { track } = useAnalytics();
    const { pathname } = useLocation();
    const resolvedPathUrl = useResolvedPath('');
    const [selectedRow, setSelectedRow] = useState<Payment>();
    const { searchTerm } = useSearchTerm();
    const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
    const { sortableColumnsMobile: paymentsTabSortableItems } = usePaymentsTabSortableColumns();
    const { showSuccessMessage: showApprovalSuccessMessage } = useBatchPaymentApprovalDecision();
    const filterContext = usePayDashboardFilters();
    const paymentsTabFilters = useNewPaymentFilters();

    const sorting = usePayDashboardSorting();
    const navigate = useNavigate();
    const { showMessage } = useSystemMessage();
    const { formatMessage, formatCurrency } = useMelioIntl();
    const [isMobileSortEnabled] = useDevFeature<boolean>(FeatureFlags.NpeSortMobile, false);
    const [isPaymentsTabNewFiltersEnabled] = usePartnerFeature('PaymentsTabFilters', false);
    const [isPaymentsTabNewFiltersPhase2Enabled] = usePartnerFeature('PaymentsTabFiltersPhase2', false);
    const {
      settings: { payDashboardSearchLabelDisabled },
    } = useConfig();

    const {
      isOpen: isCancelPaymentModalOpen,
      onOpen: onCancelPaymentModalOpen,
      onClose: onCancelPaymentModalClose,
    } = useDisclosure();

    const {
      isOpen: isMarkAsPaidModalOpen,
      onOpen: onMarkAsPaidModalOpen,
      onClose: onMarkAsPaidClose,
    } = useDisclosure();

    const handleMarkedFailedPaymentAsPaid = (id: string) => {
      setSelectedRow(paymentsResult?.data.find(({ id: paymentId }) => id === paymentId));
      onMarkAsPaidModalOpen();
    };

    const handelOnSuccessMarkAsPaid = () => {
      if (activeFilter === PaymentsTabFilter.Failed && !isPaymentsTabNewFiltersEnabled) {
        navigate(`../${PayDashboardTabs.Payments}`);
      }
      refetchPayments();
    };

    const handleOnSuccessApprovalDecision = (ids: string[], decision: PostApprovalDecisionEnum) => {
      refetchPayments();
      showApprovalSuccessMessage({ ids, decision });
    };

    const handleOnSuccessMarkAsUnpaid = async (payment: Payment) => {
      await refetchPayments();
      track('Dashboard', 'Status', {
        Intent: 'mark-as-unpaid',
        Status: 'success',
        PaymentId: payment.id,
      });
      showMessage({
        type: 'success',
        title: formatMessage('activities.payDashboard.billsTab.markAsUnpaid.toast.success', {
          amount: formatCurrency(payment.foreignAmount || payment.amount, payment.currency),
          vendorName: payment.vendor?.name || '',
        }),
        action: {
          type: 'button',
          text: formatMessage('activities.payDashboard.billsTab.markAsUnpaid.toast.success.single.action'),
          onAction: (closeToast) => {
            track('Dashboard', 'Click', {
              Intent: 'mark-as-unpaid',
              Cta: 'view-bill',
              Status: 'success',
              PaymentId: payment.id,
            });
            closeToast();
            return navigate(`../${PayDashboardTabs.Bills}`);
          },
        },
      });
    };

    const { pageSize, resetToFirstPage } = usePayDashboardPagination();

    const { queryParams, activeFilter, applyFilter } = usePaymentsTabQueryParams();
    const { filtersQueryParams, activeFilters, hasActiveFilters } = usePaymentsTabFiltersQueryParams();

    const paginationResponse = usePaginatedPayments({
      cacheTime: 0,
      params: {
        ...(searchTerm ? { searchTerm } : {}),
        search:
          isPaymentsTabNewFiltersEnabled && Object.keys(filtersQueryParams).length
            ? filtersQueryParams
            : queryParams.search,
        sort: sorting?.sortingState ? convertPaymentsTabSortToApiSort(sorting.sortingState) : undefined,
        limit: pageSize,
        expand: [
          'vendor',
          'deliveryMethod',
          'bills',
          'bills.file',
          'bills.files',
          'subscriptionOccurrence',
          'subscriptionOccurrence.billSubscription',
          'paymentActions',
          'loan',
          'paymentPending',
          'fundingSource',
        ],
      },
      enabled: !!sorting?.isLoaded,
    });
    const {
      data: paymentsResult,
      refetch: refetchPayments,
      isLoading: isLoadingPayments,
      isPreviousData,
      isFetching: isFetchingPayments,
    } = paginationResponse;

    const { newPayments, markAsSeen } = useNewPaymentsTabItems();
    const paymentsToHighlights = useMemo(() => newPayments?.map((payment) => payment.id), [newPayments]);
    const highlightedRowIds = useItemsTabHighlightedRows(paymentsToHighlights, !!paymentsResult, markAsSeen);

    const paymentFilters = usePaymentFilters();
    const { markAsSeen: markFailedIndicatorAsSeen } = useFiltersNewFailedPayments();
    const { isOpen: isReportModalOpen, onOpen: onReportModalOpen, onClose: onReportModalClose } = useDisclosure();

    const { routeReady, endAction } = useMonitoring<MonitoredAction>();

    useEffect(() => {
      endAction('payment_cancel');
      endAction('recurring-payment-cancel');
    }, []);

    usePaymentsTabAnalytics({
      payments: paymentsResult?.data ?? [],
      activeFilter: isPaymentsTabNewFiltersEnabled ? undefined : activeFilter,
    });
    const {
      isEmptyState,
      isEmptySearchResult,
      isInitialLoading,
      shouldShowTabHeader,
      isTableLoading,
      shouldShowPaginationControls,
    } = useLoadingState({
      isLoading: isLoadingPayments || !sorting?.isLoaded,
      items: paymentsResult?.data ?? [],
      searchTerm,
      isFiltered: isPaymentsTabNewFiltersEnabled ? hasActiveFilters : activeFilter !== PaymentsTabFilter.All,
      paginationConfig: {
        isFetching: isFetchingPayments,
        isPreviousData,
        totalCount: paymentsResult?.pagination.totalCount,
      },
    });
    useEffect(() => {
      if (isLoadingPayments) {
        return;
      }
      track('Dashboard', 'View', {
        Intent: 'view-payment',
        SortColumn: sorting?.sortingState?.id,
        SortDirection: sorting?.sortingState?.sortDirection,
      });
    }, [isLoadingPayments]);

    useEffect(() => {
      if (isPaymentsTabNewFiltersEnabled && filterContext?.filters) {
        const activeFiltersLabels = Object.keys(activeFilters).map(
          (key) => paymentsTabFilters.find((filter) => filter.key === key)?.label
        );
        track('Dashboard', 'View', {
          TotalFilters: Object.keys(activeFilters).length,
          FilterValue: Object.keys(activeFilters).length
            ? activeFiltersLabels.map((label) => kebabCase(lowerCase(label))).sort((a, b) => a.localeCompare(b))
            : ['none'],
        });
      }
    }, [filterContext?.filters]);

    const handleCloseDrawer = () => {
      handleNavigationWithQueryParams({ newPath: resolvedPathUrl.pathname });
    };

    const handleCancelPayment = (id: string, currentEntity: SelectedEntity) => {
      track('Dashboard', 'Click', { Intent: 'cancel-payment', Cta: 'cancel-payment' });
      setSelectedRow(paymentsResult?.data.find(({ id: paymentId }) => id === paymentId));
      setSelectedEntity(currentEntity);
      onCancelPaymentModalOpen();
    };

    const handelOnSuccessCancelPayment = () => {
      refetchPayments();
    };

    const handleEditPayment = (id: Payment['id']) => {
      track('Dashboard', 'Click', { Intent: 'edit-payment', Cta: 'edit-payment' });
      onEditPayment({ id, returnUrl: pathname });
    };

    const handleEditBillSubscription = (id: Payment['id']) => {
      track('Dashboard', 'Click', { Intent: 'edit-bill-subscription', Cta: 'edit-bill-subscription' });
      onEditBillSubscription({ id, returnUrl: resolvedPathUrl.pathname });
    };

    const handleViewPayment = (id: Payment['id']) => {
      handleNavigationWithQueryParams({ newPath: id });
    };

    const handleFilterChange = (filter: PaymentsTabFilter) => {
      track('Dashboard', 'Click', { Intent: 'filter-list', Cta: filter });
      applyFilter(filter);
      resetToFirstPage();
      if (filter === PaymentsTabFilter.Failed) {
        markFailedIndicatorAsSeen();
      }
    };

    const columns = usePaymentsTableColumns({
      filter: isPaymentsTabNewFiltersEnabled ? undefined : activeFilter,
      onSortChange: resetToFirstPage,
    });

    const mobileSortMenuOptions = Object.values(paymentsTabSortableItems(activeFilter));

    const mobileRowRenderer = useCallback((row: Payment) => <PaymentsTabListItem payment={row} />, []);
    const getItemAriaLabelContext = usePaymentAriaLabel();

    const tableProps = useTable({
      isLoading: isTableLoading,
      data: paymentsResult?.data ?? [],
      columns,
      getRowId: (row) => row.id,
      onRowClick: ({ rowData }) => handleViewPayment(rowData.id),
      ...sorting,
      headerVariant: 'dark',
      hideHeaderWhileLoading: isInitialLoading,
      getRowSelectionAriaLabel: getItemAriaLabelContext,
      mobileRowRenderer,
      captionId: formatMessage(`activities.payDashboard.tabs.payments.caption`),
      meta: {
        getItemAriaLabelContext,
        onViewClick: handleViewPayment,
        onEditPayment: handleEditPayment,
        onCancelPayment: handleCancelPayment,
        onMarkFailedPaymentAsPaid: handleMarkedFailedPaymentAsPaid,
        onSuccessMarkAsUnpaid: handleOnSuccessMarkAsUnpaid,
        onSuccessApprovalDecision: handleOnSuccessApprovalDecision,
      },
      highlightedRowIds,
    });

    const renderTableContent = () => (
      <Group alignItems="flex-end" variant="vertical" spacing="m">
        <APTable
          {...tableProps}
          captionLabel={
            shouldShowTabHeader ? formatMessage('activities.payDashboard.tabs.payments.captionLabel') : undefined
          }
        />
        {isEmptySearchResult && !isFetchingPayments ? (
          <EmptySearchResult onClear={() => emitFocusEvent(FocusEvents.TAB_TOP_SEARCH)} />
        ) : null}
        <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
      </Group>
    );

    const hideSearchLabel = isExtraSmallScreen || payDashboardSearchLabelDisabled;

    const renderSearchBar = () => (
      <SearchBar
        onSearchSubmitted={resetToFirstPage}
        placeholderOnFocus="activities.payDashboard.paymentsTab.searchPlaceholder"
        label={hideSearchLabel ? undefined : formatMessage('activities.payDashboard.paymentsTab.searchLabel')}
        pagination={!paginationResponse.isFetching ? paginationResponse.data?.pagination : undefined}
        focusOnEvent={FocusEvents.TAB_TOP_SEARCH}
        placeholder={hideSearchLabel ? 'activities.payDashboard.paymentsTab.search.placeholder' : undefined}
      />
    );

    const renderContent = () => {
      const isEmptyStateWithFilter = isPaymentsTabNewFiltersEnabled
        ? isEmptyState && hasActiveFilters
        : isEmptyState && activeFilter !== PaymentsTabFilter.All;
      const shouldRenderTableContent = !isEmptyState || isEmptySearchResult || isEmptyStateWithFilter;
      const shouldRenderEmptyState = isEmptyState && !isEmptySearchResult;
      const isFiltered = isPaymentsTabNewFiltersEnabled ? hasActiveFilters : activeFilter !== PaymentsTabFilter.All;
      return (
        <>
          {shouldRenderTableContent && renderTableContent()}
          <PaymentsTabEmptyState
            hidden={!shouldRenderEmptyState}
            isFiltered={isFiltered}
            onAddNewPayment={() => onAddNewPayment({ returnUrl: pathname, originFlow: OriginFlow.JustPay })}
          />
        </>
      );
    };

    const renderNestedFilters = () => {
      if (!filterContext) {
        return null;
      }
      return (
        <NestedFilters
          title={formatMessage('activities.payDashboard.filter.title')}
          filters={paymentsTabFilters}
          activeFilters={filterContext.filters ?? {}}
          applyFilter={filterContext.applyFilter}
          applyAllFilters={filterContext.applyAllFilters}
        />
      );
    };

    const renderFilter = () => {
      if (isPaymentsTabNewFiltersPhase2Enabled) {
        return renderNestedFilters();
      }
      if (isExtraSmallScreen) {
        if (isPaymentsTabNewFiltersEnabled && filterContext) {
          return renderNestedFilters();
        }
        return (
          <Filter<PaymentsTabFilter>
            options={paymentFilters}
            defaultFilter={PaymentsTabFilter.All}
            activeFilter={activeFilter}
            title={formatMessage('activities.payDashboard.filter.title')}
            onChange={handleFilterChange}
          ></Filter>
        );
      }
      return (
        <Container overflow="auto">
          {isPaymentsTabNewFiltersEnabled ? (
            <Filters tabFilters={paymentsTabFilters} />
          ) : (
            <FilterChips options={paymentFilters} activeFilter={activeFilter} onChange={handleFilterChange} />
          )}
        </Container>
      );
    };

    const renderDownloadReport = () => (
      <Tooltip content={formatMessage('activities.payDashboard.paymentsTab.paymentsReport.btnText')}>
        <IconButton
          variant="primary"
          size="large"
          icon="download"
          aria-label={formatMessage('activities.payDashboard.paymentsTab.paymentsReport.btnText')}
          onClick={() => {
            track('Dashboard', 'Click', { Intent: 'download-report', Cta: 'download-payment-report' });
            onReportModalOpen();
          }}
          data-testid="download-report-btn"
        />
      </Tooltip>
    );

    return (
      <Container data-testid="pay-dashboard-payments-tab" overflow="initial">
        {!isLoadingPayments && <span ref={routeReady} />}
        <Group variant="vertical" width="full" spacing={isExtraSmallScreen ? 's' : 'm'}>
          <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
            {shouldShowTabHeader ? (
              <Group
                variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                justifyContent="flex-start"
                width="full"
                spacing="s"
                data-testid="pay-dashboard-payments-sub-and-search-tab"
              >
                {isExtraSmallScreen ? (
                  <Group variant="horizontal" alignItems="center">
                    <Group.Item>{renderSearchBar()}</Group.Item>

                    <Group.Item shrink={0}>
                      <Group allowOverflowX={false} spacing="s">
                        {isMobileSortEnabled && (
                          <Group.Item>
                            <MobileSortMenu
                              items={mobileSortMenuOptions}
                              onSortChange={resetToFirstPage}
                              title="activities.payDashboard.paymentsTab.sort.title"
                              showTriggerText={false}
                            />
                          </Group.Item>
                        )}
                        <Group.Item>{renderFilter()}</Group.Item>
                        <Group.Item>{renderDownloadReport()}</Group.Item>
                      </Group>
                    </Group.Item>
                  </Group>
                ) : (
                  <Group variant="horizontal" alignItems="center" justifyContent="space-between" width="full">
                    <Container justifyContent="flex-start" overflow="visible">
                      <Group spacing="s" width="full">
                        {renderSearchBar()}
                        {renderFilter()}
                      </Group>
                    </Container>
                    {renderDownloadReport()}
                  </Group>
                )}
              </Group>
            ) : null}
          </Container>
          {shouldShowTabHeader && isPaymentsTabNewFiltersEnabled && filterContext?.filters && (
            <Container paddingX={isExtraSmallScreen ? 'm' : undefined}>
              <NewFilterChips filterContext={filterContext} tabFilters={paymentsTabFilters} />
            </Container>
          )}
          <Container overflow="initial">{renderContent()}</Container>
          {selectedRow && (
            <DeletePaymentModal
              isOpen={isCancelPaymentModalOpen}
              onClose={onCancelPaymentModalClose}
              payment={selectedRow}
              selectedEntity={selectedEntity}
              onSuccess={handelOnSuccessCancelPayment}
            />
          )}
          {isMarkAsPaidEnabled && selectedRow && (
            <MarkAsPaidModal
              id={selectedRow.id}
              isOpen={isMarkAsPaidModalOpen}
              onClose={onMarkAsPaidClose}
              onSuccess={handelOnSuccessMarkAsPaid}
              type="payment"
            />
          )}
          {isReportModalOpen ? (
            <CreateReportModal
              isOpen={isReportModalOpen}
              onClose={onReportModalClose}
              analytics={reportModalAnalytics}
            />
          ) : null}
        </Group>
        <Routes>
          <Route
            path=":paymentId"
            element={
              <PaymentDrawerActivity
                onClose={handleCloseDrawer}
                onEditPayment={handleEditPayment}
                onRetryFailedToDeliverPayment={onRetryFailedToDeliverPayment}
                onRetryFailedToCollectPayment={onRetryFailedToCollectPayment}
                onRefundPayment={onRefundPayment}
                onVoidAndRefundPayment={onVoidAndRefundPayment}
                onVoidAndResendPayment={onVoidAndResendPayment}
                onMarkAsUnpaid={handleOnSuccessMarkAsUnpaid}
                onApprovalDecision={handleOnSuccessApprovalDecision}
                onEditBillSubscription={handleEditBillSubscription}
              />
            }
          />
        </Routes>
      </Container>
    );
  }
);

export const PaymentsTab = (props: PaymentsTabProps) => {
  const [searchParams] = useSearchParams();
  const [isPaymentsTabNewFiltersEnabled] = usePartnerFeature('PaymentsTabFilters', false);
  const activeFilter = (searchParams.get('status') as PaymentsTabFilter) ?? PaymentsTabFilter.All;

  const defaultSort = isPaymentsTabNewFiltersEnabled
    ? DEFAULT_SORT_BY_TAB_FILTER[PaymentsTabFilter.All]
    : DEFAULT_SORT_BY_TAB_FILTER[activeFilter];

  const tableId =
    activeFilter === PaymentsTabFilter.All || isPaymentsTabNewFiltersEnabled
      ? undefined
      : `${PayDashboardTabs.Payments}${activeFilter}`;

  return (
    <PayDashboardPaginationProvider>
      <PayDashboardFilterProvider
        urlSearchParams={searchParams}
        shouldInitializeProvider={isPaymentsTabNewFiltersEnabled}
        supportedSearchParamKeys={supportedSearchParamKeys}
      >
        <PayDashboardSortingProvider defaultSort={defaultSort} tableId={tableId}>
          <PaymentsTabComponent {...props} />
        </PayDashboardSortingProvider>
      </PayDashboardFilterProvider>
    </PayDashboardPaginationProvider>
  );
};

export function usePaymentAriaLabel() {
  const getPaymentStatus = usePaymentStatus();
  const { formatDate, formatMessage, formatCurrency } = useMelioIntl();

  return useCallback(
    (payment: Payment) => {
      const { label: statusLabel } = getPaymentStatus(payment);
      return formatMessage('activities.payDashboard.approvalsTab.table.aria.row', {
        vendorName: payment.vendor?.name,
        name: getSchedulerFullName(payment),
        date: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
        status: statusLabel,
        amount: formatCurrency(payment.foreignAmount || payment.amount, payment.currency),
      });
    },
    [formatCurrency, formatDate, formatMessage, getPaymentStatus]
  );
}
