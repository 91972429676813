import { useConfig } from '@melio/platform-provider';
import { createContext, PropsWithChildren, useContext } from 'react';

import { LoadingStates, TriggerField } from '../../types';

type LoadingDependants = LoadingStates[TriggerField];
const LoadingStateContext = createContext<LoadingDependants>([]);

export function LoadingStateContextProvider(
  props: PropsWithChildren<{ triggerField: TriggerField | undefined; isLoading: boolean }>
) {
  const {
    settings: {
      payment: { loadingStates },
    },
  } = useConfig();

  const loadingDependants = props.triggerField && props.isLoading ? loadingStates[props.triggerField] : [];
  return <LoadingStateContext.Provider value={loadingDependants}>{props.children}</LoadingStateContext.Provider>;
}

export function useLoadingStateContext() {
  return useContext(LoadingStateContext);
}
