import { useAccount } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { differenceInCalendarDays } from '@melio/platform-utils';

import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';

type SubscriptionGracePeriod = {
  isFetchingGracePeriod: boolean;
  isOrgCreatedBeforeGracePeriodStart: boolean;
  isUserCreatedBeforeGracePeriodStart: boolean;
  isNotSubscribedPostGracePeriod: boolean;
};

const DEFAULT_RESPONSE = {
  isFetchingGracePeriod: false,
  isOrgCreatedBeforeGracePeriodStart: false,
  isUserCreatedBeforeGracePeriodStart: false,
  isNotSubscribedPostGracePeriod: false,
};

const GRACE_PERIOD_START_DATE = '2024-09-23';

/**
 * Hook to check if the org is eligible for the subscription post grace period flow.
 * The org is in post grace period flow if the organization was created prior to 2024-09-23 (subscription launch)
 * and the org is not subscribed.
 */
export const useSubscriptionGracePeriod = ({
  hasSubscription,
}: {
  hasSubscription: boolean;
}): SubscriptionGracePeriod => {
  const {
    settings: { subscriptions: subscriptionsConfig },
  } = useConfig();

  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: account, isFetching: isFetchingAccount } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });

  if (!isSubscriptionsEnabled || !subscriptionsConfig?.isGracePeriodSupported) {
    return DEFAULT_RESPONSE;
  }

  if (isFetchingAccount || !account) {
    return {
      isFetchingGracePeriod: true,
      isOrgCreatedBeforeGracePeriodStart: false,
      isUserCreatedBeforeGracePeriodStart: false,
      isNotSubscribedPostGracePeriod: false,
    };
  }

  // eslint-disable-next-line no-restricted-syntax
  const subscriptionsGracePeriodStart = new Date(`${GRACE_PERIOD_START_DATE}T00:00:00`);

  const {
    organizationCreatedAt,
    history: { createdAt: userCreatedAt },
  } = account;

  const isOrgCreatedBeforeGracePeriodStart =
    differenceInCalendarDays(organizationCreatedAt, subscriptionsGracePeriodStart) < 0;

  const isUserCreatedBeforeGracePeriodStart =
    differenceInCalendarDays(userCreatedAt, subscriptionsGracePeriodStart) < 0;

  const isNotSubscribedPostGracePeriod = !hasSubscription && isOrgCreatedBeforeGracePeriodStart;

  return {
    isNotSubscribedPostGracePeriod,
    isOrgCreatedBeforeGracePeriodStart,
    isUserCreatedBeforeGracePeriodStart,
    isFetchingGracePeriod: false,
  };
};
