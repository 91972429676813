import { DashboardCustomerOverviewField, FormattedCurrency } from '@melio/ar-domain';
import { Group, Table, Text } from '@melio/penny';

export type AmountWithInvoicesCountCellProps = {
  overview: DashboardCustomerOverviewField;
  fieldName: string;
};

export const AmountWithInvoicesCountCell = ({
  overview: { totalAmount },
  fieldName,
  ...props
}: AmountWithInvoicesCountCellProps) => (
  <Table.Cell data-component={AmountWithInvoicesCountCell.displayName} data-testid={`customer-${fieldName}`} {...props}>
    <Group spacing="xxs" variant="vertical">
      <Text data-testid={`customer-${fieldName}-cell-text`}>
        <FormattedCurrency amount={totalAmount} />
      </Text>
    </Group>
  </Table.Cell>
);

AmountWithInvoicesCountCell.displayName = 'AmountWithInvoicesCountCell';
