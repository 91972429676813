import { Box } from '@chakra-ui/react';
import { Control, FormField, input } from '@melio/penny';
import { FieldError } from 'react-hook-form';

import { CaratFormWidgetFields } from './types';

type CaratFormInputFieldProps = {
  control: Control<CaratFormWidgetFields>;
  name: string;
  id: string;
  'data-focus': boolean;
  labelProps: { label: string };
  'data-invalid': boolean;
  error: FieldError | undefined;
  isRequired: boolean;
  colSpan: number;
};

export const CaratFormInputField = ({
  control,
  id,
  name,
  'data-focus': isFocus,
  labelProps,
  'data-invalid': dataInvalid,
  error,
  isRequired,
  colSpan,
  ...otherProps
}: CaratFormInputFieldProps) => (
  <FormField
    render={({ size, ...props }) => (
      <Box
        {...props}
        {...otherProps}
        className={size}
        sx={input.field}
        aria-required={undefined}
        role="presentation"
        data-focus={isFocus || void 0}
        data-invalid={dataInvalid || void 0}
      />
    )}
    control={control}
    id={id}
    name={name}
    colSpan={colSpan}
    labelProps={labelProps}
    error={error}
    isRequired={isRequired}
  />
);
