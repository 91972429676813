import { Box } from '@chakra-ui/react';
import { useMelioIntl } from '@melio/ar-domain';
import { ActionBar } from '@melio/penny';

import { InvoiceReport } from '../types';

export type InvoiceSummaryFooterProps = {
  invoiceSummary?: InvoiceReport;
  isOpen: boolean;
  isLoading?: boolean;
};

export const InvoiceSummaryFooter = ({ invoiceSummary, isOpen, isLoading }: InvoiceSummaryFooterProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <>
      {/* Fills space on the DOM equals to gap required by pagination controls in order for ActionBar footer to respect it due to it's fixed position  */}
      {isOpen ? <Box height="70px" /> : null}
      <ActionBar
        id="invoice-summary-footer"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        maxWidth="calc(100% - 136px)"
        isLoading={isLoading}
        isOpen={isOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        position="fixed"
        summaryItems={[
          {
            label: formatMessage('ar.dashboard.activities.invoiceTable.footer.totalCount.label'),
            value: invoiceSummary?.totalCount.toString() || '',
            testId: 'invoice-summary-footer-total-count',
          },
          {
            label: formatMessage('ar.dashboard.activities.invoiceTable.footer.totalAmount.label'),
            value: invoiceSummary?.totalAmount ? formatCurrency(invoiceSummary.totalAmount).toString() : '',
            testId: 'invoice-summary-footer-total-amount',
          },
        ]}
      />
    </>
  );
};
