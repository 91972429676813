import { Button, Container, Grid, Illustration, Layout, LoadingContainer, Text } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useLegalDocuments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { PageTitle } from '@melio/platform-utils';

export const TermsAndConditionsNonOwnerScreen = withAnalyticsContext(({ onClose }: { onClose: () => void }) => {
  const { formatMessage } = useMelioIntl();
  const [signTermsAndConditionsEnabled] = usePartnerFeature('TermsAndConditions', false);

  const { data: legalDocuments, isLoading: legalDocumentsLoading } = useLegalDocuments({
    signTermsAndConditionsEnabled,
  });

  const tabTitle = formatMessage('activities.termsAndConditions.title');
  useSetDocumentTitle(tabTitle);

  if (legalDocumentsLoading || !legalDocuments) {
    return <LoadingContainer aria-live="polite" isLoading />;
  }

  return (
    <Layout maxWidth="600px" backgroundColor="white">
      <Container
        paddingX="xl"
        paddingY="xxs"
        height="full"
        textAlign="center"
        data-testid="terms-and-conditions-non-owner"
      >
        <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="l">
          <Illustration size="large" type="no-items" />

          <Grid gap="s" width="full">
            {legalDocuments.isFirstTime ? (
              <PageTitle textStyle="heading1Semi" data-testid="terms-and-conditions-non-owner-title">
                {formatMessage('activities.termsAndConditions.nonOwner.title')}
              </PageTitle>
            ) : (
              <PageTitle textStyle="heading1Semi" data-testid="terms-and-conditions-non-owner-updated-title">
                {formatMessage('activities.termsAndConditions.nonOwner.updated.title')}
              </PageTitle>
            )}

            <Grid gap="none">
              {legalDocuments.isFirstTime ? (
                <Text data-testid="terms-and-conditions-non-owner-description">
                  {formatMessage('activities.termsAndConditions.nonOwner.description')}
                </Text>
              ) : (
                <Text data-testid="terms-and-conditions-non-owner-updated-description">
                  {formatMessage('activities.termsAndConditions.nonOwner.updated.description')}
                </Text>
              )}
            </Grid>
          </Grid>

          <Button
            data-testid="terms-and-conditions-non-owner-close"
            label={formatMessage('activities.termsAndConditions.nonOwner.close')}
            size="medium"
            onClick={onClose}
          />
        </Grid>
      </Container>
    </Layout>
  );
});
