import { Group, Text } from '@melio/penny';
import { LazyAnimation } from '@melio/platform-utils';

type MigrationUserTourStepProps = {
  title: string;
  description: string;
  animationSrc: string;
  stepNumber: number;
};

export const MigrationUserTourStep = ({ description, animationSrc, title, stepNumber }: MigrationUserTourStepProps) => (
  <Group variant="vertical" spacing="m">
    <Text
      data-testid={`migration-user-tour-step-title-${stepNumber}`}
      textStyle="body3Semi"
      color="semantic.text.inverse"
    >
      {title}
    </Text>
    <LazyAnimation
      id={`migration-user-tour-step-animation-${stepNumber}`}
      src={animationSrc}
      loop
      width="264px"
      height="142"
    />
    <Text
      data-testid={`migration-user-tour-step-description-${stepNumber}`}
      textStyle="body3Semi"
      color="semantic.text.inverse"
    >
      {description}
    </Text>
  </Group>
);
