import { useMelioIntl, useServiceChargeCatalog, useSystemMessage } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { DeleteIEntityDialogScreen } from '../shared';

type DeleteServiceChargeDialogActivityProps = {
  serviceChargeId?: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const DeleteServiceChargeDialogActivity = forwardRef<DeleteServiceChargeDialogActivityProps>(
  ({ serviceChargeId, onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const {
      data,
      delete: deleteServiceCharge,
      isLoading,
      isMutating,
    } = useServiceChargeCatalog({ id: serviceChargeId, onError });

    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();

    const onSubmit = () => {
      deleteServiceCharge()
        .then(() => {
          onSuccess();
          triggerMessage({
            type: 'informative',
            title: formatMessage('ar.settings.activities.addServiceCharge.toast.onDelete.label', { name: data?.name }),
          });
        })
        .catch(onError);
    };

    return (
      <DeleteIEntityDialogScreen
        data-testid="delete-service-charge-dialog"
        id={serviceChargeId}
        onSubmit={onSubmit}
        onClose={onClose}
        isDeleting={isMutating}
        isLoading={isLoading}
        header={formatMessage('ar.settings.activities.deleteServiceCharge.dialog.header')}
        description={formatMessage('ar.settings.activities.deleteRaxRate.dialog.description', { name: data?.name })}
        primaryButtonLabel={formatMessage('ar.settings.activities.deleteServiceCharge.dialog.primaryButton.label')}
        secondaryButtonLabel={formatMessage('ar.settings.activities.deleteServiceCharge.dialog.secondaryButton.label')}
        {...props}
        ref={ref}
      />
    );
  }
);
DeleteServiceChargeDialogActivity.displayName = 'DeleteTaxRateActivity';
