import { useMelioIntl } from '@melio/ar-domain';
import { NakedButton } from '@melio/penny';
import React from 'react';

type AddCustomPaymentDetailsButtonProps = {
  onEditCustomPaymentDetails: VoidFunction;
};

export const AddCustomPaymentDetailsButton = ({ onEditCustomPaymentDetails }: AddCustomPaymentDetailsButtonProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NakedButton
      data-testid="add-invoice-custom-payment-details-button"
      variant="secondary"
      onClick={onEditCustomPaymentDetails}
      label={formatMessage('ar.settings.activities.acceptedPaymentMethods.addCustomPaymentDetailsButton.label')}
    />
  );
};
