/* eslint-disable max-lines */
import { useInternationalFxFeature } from '@melio/ap-domain';
import { findDeliveryMethodByType, getSupportedInternationalType, useCheckFeePromotions } from '@melio/ap-widgets';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  Address,
  DeliveryMethodByPayor,
  DeliveryMethodType,
  useAccount,
  useDeliveryMethods,
  useFileOCRData,
  useFundingSource,
  useVendor,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AddFxDeliveryMethodActivity, AddInternationalDeliveryMethodActivity } from '../../international-payment';
import { AddVendorBankDetailsActivity } from '../AddVendorBankDetails';
import { AddVendorCheckDetailsActivity } from '../AddVendorCheckDetails';
import { AddVendorDomesticWireDetailsActivity } from '../AddVendorDomesticWireDetails';
import { AddVendorPaypalBalanceAccountActivity } from '../AddVendorPaypalAccountActivity/AddVendorPaypalBalanceAccountActivity';
import { AddVendorUnilateralDetailsActivity } from '../AddVendorUnilateralDetails';
import { AddVendorVirtualCardDetailsActivity } from '../AddVendorVirtualCardDetails';
import { EditVendorBankDetailsActivity } from '../EditVendorBankDetails';
import { EditVendorCheckDetailsActivity } from '../EditVendorCheckDetails';
import { EditVendorDomesticWireDetailsActivity } from '../EditVendorDomesticWireDetails';
import { RequestVendorUnilateralDetailsActivity } from '../RequestVendorUnilateralDetails';
import { filterAndGroupDeliveryMethodTypeOptionsByPartner } from './filterAndSplitDeliveryMethodTypeOptionsIntoGroups';
import { useExtractAddress } from './hooks/useExtractAddress';
import { DeliveryMethodSelectionScreen } from './screens';
import { DeliveryMethodSelectionActivityProps } from './types';
import { useDeliveryMethodSelectionActivityStep } from './useDeliveryMethodSelectionActivityStep';

export const DeliveryMethodSelectionActivity = withAnalyticsContext<DeliveryMethodSelectionActivityProps>(
  ({
    vendorId,
    selectedId,
    fundingSourceId,
    step,
    totalSteps,
    onSkip,
    onBack: onFirstStepBack,
    onClose,
    onError,
    onDone,
    origin = 'payment',
    isLoading,
    deliveryMethodTypeOptions = [],
    setAnalyticsProperties,
    fileId,
    billCurrency,
  }) => {
    const { track } = useAnalytics();
    const deliveryMethodCollection = useDeliveryMethods({ vendorId });
    const { freeChecks, isLoading: isFreeChecksLoading } = useCheckFeePromotions();
    const [isPrefillVendorOcrDeliveryMethodEnabled] = useDevFeature(
      FeatureFlags.PrefillVendorOcrDeliveryMethod,
      false,
      {
        shouldTrack: true,
      }
    );
    const isInternationalFxEnabled = useInternationalFxFeature();
    const [addressExtractValue, setAddressExtractValue] = useState<Address[] | null>(null);
    const [isAddressExtractLoading, setIsAddressExtractLoading] = useState(false);
    const vendorModel = useVendor({ id: vendorId });
    const { data: account } = useAccount({ id: 'me' });
    const vendor = vendorModel.data;
    const fundingSourceModel = useFundingSource({ id: fundingSourceId });
    const { settings } = useConfig();

    const { currentStep, goToStep, goToPreviousStep } = useDeliveryMethodSelectionActivityStep({
      onFirstStepBack,
      vendor,
      billCurrency,
    });
    const { data: billFileOcrData, isLoading: isLoadingBillFileOcrData } = useFileOCRData({
      id: fileId ?? undefined,
    });

    const vendorAddress = useMemo(
      () => billFileOcrData?.vendorInfo?.vendorAddress,
      [billFileOcrData?.vendorInfo?.vendorAddress]
    );
    const { addressExtract } = useExtractAddress();

    const ocrAddressDefaultValues = useMemo(() => {
      if (addressExtractValue && addressExtractValue.length > 0) {
        return {
          printName: billFileOcrData?.vendorInfo?.vendorName ?? undefined,
          line1: addressExtractValue[0]?.line1,
          line2: addressExtractValue[0]?.line2,
          city: addressExtractValue[0]?.city,
          postalCode: addressExtractValue[0]?.postalCode,
          state: addressExtractValue[0]?.state,
        };
      }

      return {
        printName: billFileOcrData?.vendorInfo?.vendorName ?? undefined,
      };
    }, [addressExtractValue, billFileOcrData?.vendorInfo?.vendorName]);

    const getSearchAddress = useCallback(async () => {
      try {
        const result = await addressExtract(vendorAddress);
        setIsAddressExtractLoading(false);
        if (result && result.length > 0) {
          track('VendorAddressExtracted', 'Success');
        }
        setAddressExtractValue(result);
      } catch (e) {
        setIsAddressExtractLoading(false);
      }
    }, [addressExtract, vendorAddress, track]);

    const ocrBankAccountDefaultValues = useMemo(
      () => ({
        accountNumber: billFileOcrData?.vendorInfo?.vendorAccountNumber ?? undefined,
        routingNumber: billFileOcrData?.vendorInfo?.vendorBankNumber ?? undefined,
      }),
      [billFileOcrData]
    );
    const supportedInternationalType = getSupportedInternationalType({
      isInternationalFxEnabled,
      vendorCurrency: vendor?.currency,
      businessType: account?.company.businessType,
    });

    const { domesticDeliveryMethodTypeOptions, internationalDeliveryMethodTypeOptions } = useMemo(
      () =>
        filterAndGroupDeliveryMethodTypeOptionsByPartner(
          deliveryMethodTypeOptions,
          settings.deliveryMethodTypeOptionDisabledReasons
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [deliveryMethodTypeOptions]
    );

    const concatDmTypesAlphabetical = (deliveryMethods: Array<{ type: string }>) =>
      deliveryMethods
        .map((d) => d.type)
        .sort()
        .join('_');

    useEffect(() => {
      setAnalyticsProperties({
        DeliveryMethodShown: concatDmTypesAlphabetical([
          ...domesticDeliveryMethodTypeOptions,
          { type: supportedInternationalType },
        ]),
        DeliveryMethodDisabled: concatDmTypesAlphabetical(
          [...domesticDeliveryMethodTypeOptions, ...internationalDeliveryMethodTypeOptions].filter(
            (dm) => !dm.supported
          )
        ),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domesticDeliveryMethodTypeOptions, supportedInternationalType]);

    useEffect(() => {
      if (!addressExtractValue && isPrefillVendorOcrDeliveryMethodEnabled && vendorAddress) {
        getSearchAddress();
      }
    }, [getSearchAddress, isPrefillVendorOcrDeliveryMethodEnabled, vendorAddress, addressExtractValue]);

    useEffect(() => {
      if (ocrBankAccountDefaultValues.accountNumber || ocrBankAccountDefaultValues.routingNumber) {
        track('VendorBankAccountExtracted', 'Success');
      }
    }, [ocrBankAccountDefaultValues, track]);

    const isDeliveryMethodSelectionLoading =
      !currentStep ||
      deliveryMethodCollection.isLoading ||
      vendorModel.isLoading ||
      fundingSourceModel.isLoading ||
      isFreeChecksLoading ||
      isLoadingBillFileOcrData ||
      isAddressExtractLoading ||
      isLoading;

    switch (currentStep) {
      case 'DELIVERY_METHOD_SELECTION':
      default:
        return (
          <DeliveryMethodSelectionScreen
            account={account}
            isLoading={isDeliveryMethodSelectionLoading}
            fundingSource={fundingSourceModel.data}
            freeChecks={freeChecks}
            step={step}
            totalSteps={totalSteps}
            data={(deliveryMethodCollection.data as DeliveryMethodByPayor[]) || []}
            selectedId={selectedId}
            domesticDeliveryMethodTypeOptions={domesticDeliveryMethodTypeOptions}
            internationalDeliveryMethodTypeOptions={internationalDeliveryMethodTypeOptions}
            vendor={vendor as Vendor}
            onBack={goToPreviousStep}
            onClose={onClose}
            onEdit={(type) => {
              switch (type) {
                case 'bank-account':
                  goToStep('EDIT_VENDOR_BANK_DETAILS');
                  break;
                case 'paper-check':
                  goToStep('EDIT_VENDOR_CHECK_DETAILS');
                  break;
                case 'domestic-wire-account':
                  goToStep('EDIT_VENDOR_DOMESTIC_WIRE_DETAILS');
                  break;
                case 'virtual-card':
                  goToStep('EDIT_VENDOR_VIRTUAL_CARD_DETAILS');
                  break;
              }
            }}
            onAdd={({ type, isWise }) => {
              switch (type) {
                case 'bank-account':
                  goToStep('ADD_VENDOR_BANK_DETAILS');
                  break;
                case 'paper-check':
                  goToStep('ADD_VENDOR_CHECK_DETAILS');
                  break;
                case 'domestic-wire-account':
                  goToStep('ADD_VENDOR_DOMESTIC_WIRE_DETAILS');
                  break;
                case 'virtual-account':
                  goToStep('ADD_VENDOR_UNILATERAL_DETAILS');
                  break;
                case 'international-account':
                  if (isWise) {
                    goToStep('ADD_VENDOR_INTERNATIONAL_FX_DETAILS');
                    break;
                  }
                  goToStep('ADD_VENDOR_INTERNATIONAL_DETAILS');
                  break;
                case 'virtual-card':
                  goToStep('ADD_VENDOR_VIRTUAL_CARD_DETAILS');
                  break;
                case 'paypal-balance':
                  goToStep('ADD_VENDOR_PAYPAL_BALANCE_DETAILS');
                  break;
              }
            }}
            onDone={(deliveryMethodId) =>
              onDone(deliveryMethodCollection.data?.find((d) => d.id === deliveryMethodId) as DeliveryMethodByPayor)
            }
            onSkip={onSkip}
          />
        );

      case 'ADD_VENDOR_BANK_DETAILS':
        return (
          <AddVendorBankDetailsActivity
            vendorId={vendorId}
            origin={origin}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
            defaultValues={isPrefillVendorOcrDeliveryMethodEnabled ? ocrBankAccountDefaultValues : undefined}
          />
        );

      case 'EDIT_VENDOR_VIRTUAL_CARD_DETAILS':
        return (
          <AddVendorVirtualCardDetailsActivity
            vendorId={vendorId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
            origin={origin}
            isEdit
          />
        );

      case 'EDIT_VENDOR_BANK_DETAILS': {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const deliveryMethodId = findDeliveryMethodByType(deliveryMethodCollection, DeliveryMethodType.BankAccount)!.id;

        return (
          <EditVendorBankDetailsActivity
            vendorId={vendorId}
            deliveryMethodId={deliveryMethodId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
          />
        );
      }
      case 'ADD_VENDOR_CHECK_DETAILS':
        return (
          <AddVendorCheckDetailsActivity
            vendorId={vendorId}
            origin={origin}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
            defaultValues={isPrefillVendorOcrDeliveryMethodEnabled ? ocrAddressDefaultValues : undefined}
          />
        );

      case 'EDIT_VENDOR_CHECK_DETAILS': {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const deliveryMethodId = findDeliveryMethodByType(deliveryMethodCollection, DeliveryMethodType.PaperCheck)!.id;
        return (
          <EditVendorCheckDetailsActivity
            vendorId={vendorId}
            deliveryMethodId={deliveryMethodId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
          />
        );
      }

      case 'ADD_VENDOR_DOMESTIC_WIRE_DETAILS':
        return (
          <AddVendorDomesticWireDetailsActivity
            vendorId={vendorId}
            origin={origin}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
          />
        );

      case 'EDIT_VENDOR_DOMESTIC_WIRE_DETAILS': {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const deliveryMethodId = findDeliveryMethodByType(
          deliveryMethodCollection,
          DeliveryMethodType.DomesticWireAccount
        )!.id;
        return (
          <EditVendorDomesticWireDetailsActivity
            vendorId={vendorId}
            deliveryMethodId={deliveryMethodId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
          />
        );
      }

      case 'ADD_VENDOR_UNILATERAL_DETAILS':
        return origin === 'vendor' ? (
          <RequestVendorUnilateralDetailsActivity
            vendorId={vendorId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
          />
        ) : (
          <AddVendorUnilateralDetailsActivity
            vendorId={vendorId}
            fundingSourceId={fundingSourceId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
          />
        );
      case 'ADD_VENDOR_VIRTUAL_CARD_DETAILS':
        return (
          <AddVendorVirtualCardDetailsActivity
            vendorId={vendorId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
            origin={origin}
          />
        );
      case 'ADD_VENDOR_INTERNATIONAL_DETAILS':
        return (
          <AddInternationalDeliveryMethodActivity
            vendorId={vendorId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
            origin={origin}
          />
        );
      case 'ADD_VENDOR_INTERNATIONAL_FX_DETAILS':
        return (
          <AddFxDeliveryMethodActivity
            vendorId={vendorId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={onError}
            onDone={onDone}
            origin={origin}
          />
        );

      case 'ADD_VENDOR_PAYPAL_BALANCE_DETAILS':
        return (
          <AddVendorPaypalBalanceAccountActivity
            vendorId={vendorId}
            onDone={onDone}
            onBack={goToPreviousStep}
            onClose={onClose}
          />
        );
    }
  }
);
