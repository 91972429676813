import { useDisclosure } from '@chakra-ui/react';
import {
  useBankAccountFundingSources,
  useIsTestMode,
  useMelioIntl,
  useNavigate,
  usePreloadApiHooks,
  useReceivingMethods,
  useSystemMessage,
  withMemoryRouter,
  withOutlet,
} from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  AddReceivingMethodModalActivity,
  GenerateInvoiceFileActivity,
  ShareInvoiceActivity as _ShareInvoiceActivity,
  ShareTestInvoiceModalActivity,
  UploadCompanyLogoModalActivity,
} from '../activities';
import { ShareMode } from '../types';
import { useReceivingMethodsDialogActions } from './useReceivingMethodsDialogActions';
import { useShareTestInvoiceDialogActions } from './useShareTestInvoiceDialogActions';

const ShareInvoiceActivity = withOutlet(_ShareInvoiceActivity);

export type ShareInvoiceFlowProps = {
  onClose: VoidFunction;
  onDone: (id: string) => void;
  onBack?: VoidFunction;
  onError?: ARErrorFunction;
  onEditInvoice: (id: string) => unknown;
  invoiceId: string;
  shareMode: ShareMode;
};

export const ShareInvoiceFlow = withMemoryRouter(
  withAnalyticsContext<ShareInvoiceFlowProps>(
    forwardRef(
      ({ setAnalyticsProperties, onClose, onDone, onError, onEditInvoice, invoiceId, shareMode, ...props }, ref) => {
        const isTestMode = useIsTestMode();
        const { goToShareInvoiceScreen, goToUploadLogo, goToGenerateInvoiceFile } = useShareInvoiceFlow();
        const receivingMethodsDialog = useReceivingMethodsDialogActions();
        const shareTestInvoiceDialog = useShareTestInvoiceDialogActions();

        usePreloadApiHooks([useBankAccountFundingSources, useReceivingMethods]);

        const { triggerMessage } = useSystemMessage();
        const { formatMessage } = useMelioIntl();
        const uploadCompanyLogoDialog = useDisclosure({ onOpen: goToUploadLogo, onClose: goToShareInvoiceScreen });

        setAnalyticsProperties({
          InvoiceId: invoiceId,
          Flow: shareMode === 'reminder' ? 'send-reminder' : 'send-invoice',
        });

        return (
          <Routes>
            <Route
              path={Paths.Home}
              element={
                <>
                  <ShareInvoiceActivity
                    invoiceId={invoiceId}
                    onClose={onClose}
                    onBeforeDone={isTestMode ? shareTestInvoiceDialog.onOpen : receivingMethodsDialog.onOpen}
                    onUploadCompanyLogo={uploadCompanyLogoDialog.onOpen}
                    onDone={onDone}
                    shareMode={shareMode}
                    onEditInvoice={onEditInvoice}
                    {...props}
                    ref={ref}
                  />
                  <AddReceivingMethodModalActivity
                    isOpen={receivingMethodsDialog.isOpen}
                    onDone={receivingMethodsDialog.onDone}
                    onClose={receivingMethodsDialog.onClose}
                    onError={onError}
                  />
                  <ShareTestInvoiceModalActivity
                    isOpen={shareTestInvoiceDialog.isOpen}
                    onDone={shareTestInvoiceDialog.onDone}
                    onClose={shareTestInvoiceDialog.onClose}
                  />
                </>
              }
            >
              <Route
                path={Paths.UploadLogo}
                element={
                  <UploadCompanyLogoModalActivity
                    isOpen
                    onDone={() => {
                      uploadCompanyLogoDialog.onClose();
                      goToGenerateInvoiceFile();
                    }}
                    onClose={uploadCompanyLogoDialog.onClose}
                    onError={(error) => {
                      uploadCompanyLogoDialog.onClose();
                      triggerMessage({
                        type: 'critical',
                        title: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.errors.failedFileUpload'),
                      });
                      onError?.(error);
                    }}
                  />
                }
              />
              <Route
                path={Paths.GenerateInvoiceFile}
                element={
                  <GenerateInvoiceFileActivity
                    invoiceId={invoiceId}
                    onDone={goToShareInvoiceScreen}
                    onError={onError}
                  />
                }
              />
            </Route>
          </Routes>
        );
      }
    )
  )
);
ShareInvoiceFlow.displayName = 'ShareInvoiceFlow';

enum Paths {
  Home = '',
  UploadLogo = 'upload-logo',
  GenerateInvoiceFile = 'generate-invoice-file',
}

const useShareInvoiceFlow = () => {
  const navigate = useNavigate<Paths>();
  const goToShareInvoiceScreen = () => navigate(Paths.Home);
  const goToUploadLogo = () => navigate(Paths.UploadLogo);
  const goToGenerateInvoiceFile = () => navigate(Paths.GenerateInvoiceFile);

  return { goToShareInvoiceScreen, goToUploadLogo, goToGenerateInvoiceFile };
};
