import { ServicesApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@tanstack/react-query';

export const usePlanFeeWidgetLink = () =>
  useQuery({
    queryKey: ['ServicesApi', 'PlanFeeWidgetLink'],
    queryFn: async () => {
      const result = await ServicesApiClient.getServicesExternalLinksFeeWidget();
      return result.data.data.feeWidgetLink;
    },
  });
