import { useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { useAnalytics, WithAnalyticsContextProps } from '@melio/platform-analytics';
import { Bill, BillSubscription, Payment, Vendor } from '@melio/platform-api';
import { useEffect } from 'react';

import { PaymentFlowFormFields } from '../types';

type PaymentFlowAnalyticsParams = PaymentFlowFormFields & {
  setAnalyticsProperties: WithAnalyticsContextProps['setAnalyticsProperties'];
  payment: Payment | undefined;
  bill: Bill | undefined;
  billSubscription: BillSubscription | undefined;
  vendor: Vendor | undefined;
};

export const usePaymentFlowAnalytics = (params: PaymentFlowAnalyticsParams) => {
  const { track } = useAnalytics();
  const {
    setAnalyticsProperties,
    payment,
    bill,
    billSubscription,
    vendor,
    vendorId,
    fundingSourceId,
    deliveryMethodId,
    deliveryPreference,
    amountToPay,
    recurrenceType,
    numOfOccurrences,
    intervalType,
    endPolicy,
    scheduleDate,
    deliveryDate,
    startDate,
    endDate,
  } = params;

  const scheduleDateForAnalytics = scheduleDate?.toISOString();
  const deliveryDateForAnalytics = deliveryDate?.toISOString();
  const startDateForAnalytics = startDate?.toISOString();
  const endDateForAnalytics = endDate?.toISOString();

  const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);

  useEffect(() => {
    const globalAnalyticProperties = {
      PageName: 'pay',
      Flow: 'single-payment',
      ProductName: 'ap',
      PaymentId: payment?.id,
      BillId: bill?.id,
      BillSubscriptionId: billSubscription?.id,
      VendorId: vendorId,
      PaymentAmount: amountToPay,
      PaymentMethodId: fundingSourceId,
      DeliveryMethodId: deliveryMethodId,
      DeliveryPreference: deliveryPreference,
      IsPartialPayment: !!bill && !!amountToPay && Number(amountToPay) !== bill.amount,
      IsRecurring: recurrenceType === 'recurring',
      DeductionDate: scheduleDateForAnalytics,
      DeliveryDate: deliveryDateForAnalytics,
      RecurringStartDate: startDateForAnalytics,
      EndDate: endDateForAnalytics,
      PaymentFrequency: intervalType,
      PaymentDuration: endPolicy,
      NumOfOccurrences: numOfOccurrences,
      UserMessage: !isVendorDirectoryInfoCompleted ? 'missing-vendor-details' : undefined,
    };

    setAnalyticsProperties(globalAnalyticProperties);
    track('Payment', 'View', globalAnalyticProperties);
  }, [
    payment?.id,
    billSubscription?.id,
    bill?.id,
    bill,
    vendorId,
    amountToPay,
    fundingSourceId,
    deliveryMethodId,
    deliveryPreference,
    recurrenceType,
    scheduleDateForAnalytics,
    deliveryDateForAnalytics,
    startDateForAnalytics,
    endDateForAnalytics,
    intervalType,
    endPolicy,
    numOfOccurrences,
    isVendorDirectoryInfoCompleted,
    setAnalyticsProperties,
    track,
  ]);
};
