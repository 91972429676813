import { useMelioQueryClient } from '@melio/api-client';
import { InvoicesApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';
import { useInvoiceMutations } from './mutations';
import { PostShareInvoiceRequest } from './types';

// FIXME - this is a workaround for the issue with the return type of putInvoicesInvoiceId
// Remove this when the issue is fixed on the ar-api-axios-client
const putInvoicesInvoiceId = (...args: Parameters<typeof InvoicesApiClient.putInvoicesInvoiceId>) =>
  InvoicesApiClient.putInvoicesInvoiceId(...args) as ReturnType<typeof InvoicesApiClient.postInvoices>;

export type UseInvoiceProps = UseModelProps<typeof InvoicesApiClient.getInvoicesInvoiceId, typeof putInvoicesInvoiceId>;

export const useInvoice = ({ onUpdate, ...props }: UseInvoiceProps = {}) => {
  const queryClient = useMelioQueryClient();
  const query = useModel({
    ...props,
    queryKey: 'InvoicesApi',
    queryFn: InvoicesApiClient.getInvoicesInvoiceId,
    deleteFn: InvoicesApiClient.deleteInvoicesInvoiceId,
    updateFn: putInvoicesInvoiceId,
    onUpdate: (...args) => {
      void queryClient.invalidateQueries('InvoicesApi', 'paginated-collection');
      void queryClient.invalidateQueries('InvoiceExpandedApi', 'model', props.id as string);
      void queryClient.invalidateQueries('invoicePreviewPDF' as never);
      void queryClient.invalidateQueries('invoicePreviewEmail' as never);
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void queryClient.invalidateQueries('invoiceReport' as never);
      void onUpdate?.(...args);
    },
    onDelete: () => {
      void queryClient.invalidateQueries('InvoiceExpandedApi', 'model', props.id);
      void queryClient.invalidateQueries('InvoicesApi', 'paginated-collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void queryClient.invalidateQueries('invoiceReport' as never);
    },
  });

  const id = props.id as string;

  const { cancelMutation, shareMutation, markInvoiceAsPaid, markInvoiceAsUnpaid, generateFileMutation } =
    useInvoiceMutations(query.queryKey);

  return {
    ...query,
    cancel: cancelMutation.createCallback(id),
    share: (postShareInvoiceRequest: PostShareInvoiceRequest) =>
      shareMutation.mutateAsync({ id, postShareInvoiceRequest }),
    markAsPaid: markInvoiceAsPaid.createCallback(id),
    markInvoiceAsUnpaid: () => markInvoiceAsUnpaid.mutateAsync({ id, params: {} }),
    generateInvoiceFile: () => generateFileMutation.mutateAsync({ id, params: '' }),
  };
};

export type InvoiceModel = ReturnType<typeof useInvoice>;
