import { InternationalAddressInput } from '@melio/ap-widgets';
import { Button, CollapsibleCard, Form, Typography, useWatch } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { FeatureFlags, useAnonymousFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { useDecisionMakerOptions } from '../hooks/useDecisionMakerOptions';
import { useDecisionMakerRoleOptions } from '../hooks/useDecisionMakerRoleOpetions';
import { useTrackFormError } from '../hooks/useTrackFormError';
import {
  AccountHolderForm,
  DecisionMakerForm,
  FXFormSectionCardBaseProps,
  OTHER_DECISION_MAKER_KEY,
  OwnershipDetails,
} from '../types';
import { BirthdateField } from './BirthdateField';
import { ResidencyFields } from './ResidencyComponent';
import { VerifiedPersonaTaxIdField } from './VerifiedPersonaTaxIdField';

export type DecisionMakerCardProps = FXFormSectionCardBaseProps<DecisionMakerForm> & {
  owners: OwnershipDetails[];
  accountHolder: AccountHolderForm;
  isDisabled?: boolean;
};

export const FXDecisionMakerCard = ({
  form,
  isExpanded,
  owners,
  accountHolder,
  isDisabled,
  isCompleted,
  onExpandChange,
}: DecisionMakerCardProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const ownersOptions = useDecisionMakerOptions({ owners, accountHolder });
  const roleOptions = useDecisionMakerRoleOptions();
  const uboId = useWatch({
    control: form.control,
    name: 'uboId',
  });

  const [showUboResidencyNewFields] = useAnonymousFeature<boolean>(FeatureFlags.UboResidencyNewFields, false);
  useAnalyticsView('Organization', isExpanded, true, {
    PageName: 'control-person',
    Intent: 'set-control-person-details',
  });
  const onClickDone = () => {
    track('Organization', 'Click', {
      Cta: 'save-and-continue',
      Flow: 'UBO',
      PageName: 'control-person',
      Intent: 'set-control-person-details',
      CompanyRole: form.getValues('companyRole'),
    });
    form.submitButtonProps.onClick();
  };

  useTrackFormError<DecisionMakerForm>({
    form,
    pageName: 'control-person',
    intent: 'set-control-person-details',
    viewContext: 'Organization',
  });

  const isOtherDecisionMaker = uboId === OTHER_DECISION_MAKER_KEY;
  return (
    <CollapsibleCard
      isDisabled={isDisabled || (!isExpanded && !isCompleted)}
      data-testid="fx-decision-maker-card"
      isExpanded={isExpanded}
      onExpandChange={onExpandChange}
      status={isCompleted ? 'success' : undefined}
      title={formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.title'
      )}
      description={
        isExpanded
          ? formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.subtitle'
            )
          : undefined
      }
    >
      <Form {...form.formProps} columns={6}>
        <Form.SelectNew
          shouldHideClearButton
          colSpan={6}
          {...form.registerField('uboId')}
          options={ownersOptions}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.decisionMakerField.label'
            ),
          }}
        />
        <Form.SelectNew
          shouldHideClearButton
          colSpan={6}
          {...form.registerField('companyRole')}
          options={roleOptions}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.companyRoleField.label'
            ),
          }}
        />

        {isOtherDecisionMaker ? (
          <Form.ContentBox colSpan={6}>
            <Typography.SectionLabel
              label={formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.primaryContactDetails'
              )}
            />
          </Form.ContentBox>
        ) : null}
        <Form.TextField
          {...form.registerField('decisionMakerFirstName')}
          isRequired
          isHidden={!isOtherDecisionMaker}
          colSpan={3}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.firstNameField.label'
            ),
          }}
        />
        <Form.TextField
          {...form.registerField('decisionMakerLastName')}
          isRequired
          isHidden={!isOtherDecisionMaker}
          colSpan={3}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.lastNameField.label'
            ),
          }}
        />
        <BirthdateField
          {...form.registerField('decisionMakerDayOfBirth')}
          isRequired
          isHidden={!isOtherDecisionMaker}
          colSpan={showUboResidencyNewFields ? 6 : 3}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.dateOfBirthField.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.dateOfBirthField.placeholder'
          )}
        />
        {!showUboResidencyNewFields && (
          <VerifiedPersonaTaxIdField
            {...form.registerField('decisionMakerSsn')}
            colSpan={3}
            isRequired
            isHidden={!isOtherDecisionMaker}
          />
        )}
        {isOtherDecisionMaker && showUboResidencyNewFields && (
          <ResidencyFields
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error*
            form={form}
            usResidencyFieldName="decisionMakerResidencyDetails.usResidence"
            taxIdNumberFieldName="decisionMakerResidencyDetails.taxId"
            taxIdTypeFieldName="decisionMakerResidencyDetails.taxIdType"
            idTypeFieldName="decisionMakerResidencyDetails.idType"
            idNumberFieldName="decisionMakerResidencyDetails.idNumber"
          />
        )}
        {isOtherDecisionMaker && (
          <InternationalAddressInput
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            form={form}
            addressLine1FieldName="decisionMakerAddress.line1"
            cityFieldName="decisionMakerAddress.city"
            countryCodeFieldName="decisionMakerAddress.countryCode"
            stateFieldName="decisionMakerAddress.state"
            postalCodeFieldName="decisionMakerAddress.postalCode"
            label="activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.address.label"
          />
        )}
      </Form>
      <Button
        {...form.submitButtonProps}
        data-testid="decision-maker-form-submit-button"
        label={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.submitButton.label'
        )}
        onClick={onClickDone}
      />
    </CollapsibleCard>
  );
};
