import { useAnalytics } from '@melio/platform-analytics';
import {
  ApiError,
  FxQuote,
  Payment,
  PaymentDate,
  PaymentErrorCode,
  PostApprovalDecisionEnum,
  usePayments,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useEnrichedTodos, useTodosEnabled } from '../../todos-drawer';
import { useConfirmPendingPayments } from './useConfirmPendingPayments';

type BatchApprovalDecisionProps = {
  payments: Payment[];
  decision: PostApprovalDecisionEnum;
  reason?: string;
  overrides?: Record<Payment['id'], { quote?: FxQuote; date?: PaymentDate }>;
};

export const useBatchPaymentApprovalDecision = () => {
  const { showMessage, hideMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { batchApprovalDecision: batchApprovalDecisionMutate } = usePayments({ enabled: false });
  const { isEnabled: isTodosEnabled } = useTodosEnabled();
  const { refetch: refetchTodos } = useEnrichedTodos({ enabled: false });
  const { getRequiredConfirmationPayments } = useConfirmPendingPayments();

  const { isLoading, mutateAsync } = useMutation(
    async ({ payments, decision, reason, overrides }: BatchApprovalDecisionProps) => {
      if (decision === PostApprovalDecisionEnum.Declined) {
        await batchApprovalDecisionMutate({
          approvalPayments: payments.map(({ id }) => ({ id })),
          approvalDecision: { decision, reason },
        });
        return { approvedPayments: payments, latePayments: [], fxPayments: [] };
      }
      const { notRequiredConfirmation, latePayments, fxPayments } = await getRequiredConfirmationPayments({
        payments,
        overrides,
      });
      if (notRequiredConfirmation.length > 0) {
        await batchApprovalDecisionMutate({
          approvalPayments: notRequiredConfirmation.map(({ id }) => ({
            id,
            quoteId: overrides?.[id]?.quote?.fxQuoteToken,
          })),
          approvalDecision: { decision, reason },
        });
        track('Dashboard', 'Status', {
          PageName: decision === 'approved' ? 'payment-approved' : 'payment-declined',
          Intent: 'view-approvals',
          Status: 'success',
        });
      }
      return { approvedPayments: notRequiredConfirmation, latePayments, fxPayments };
    }
  );

  const batchApprovalDecision = useCallback(
    async (
      props: BatchApprovalDecisionProps
    ): Promise<{ approvedPayments: Payment[]; latePayments: Payment[]; fxPayments: Payment[] }> => {
      try {
        const res = await mutateAsync(props);

        if (isTodosEnabled) {
          refetchTodos();
        }
        return res;
      } catch (e) {
        const error = e as ApiError;
        const errorsMap: Record<string, string> = {
          [PaymentErrorCode.UnauthorizedPayment]: formatMessage(
            'activities.payDashboard.approvalsTab.errors.unauthorized'
          ),
          [PaymentErrorCode.PaymentEditingLocked]: formatMessage(
            'activities.payDashboard.approvalsTab.errors.paymentEditingLocked'
          ),
          [PaymentErrorCode.FailedPaymentEditingLocked]: formatMessage(
            'activities.payDashboard.approvalsTab.errors.failedPaymentEditingLocked'
          ),
        };
        const errorMessage =
          error.errorCode && errorsMap[error.errorCode]
            ? errorsMap[error.errorCode]
            : props.decision === PostApprovalDecisionEnum.Approved
            ? formatMessage('activities.payDashboard.approvalsTab.approveErrorToast')
            : formatMessage('activities.payDashboard.approvalsTab.declineErrorToast');

        showMessage({
          type: 'error',
          title: errorMessage,
          id: 'payment-approval-decision-error',
          dataTestId: 'payment-approval-decision-error',
        });

        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showSuccessMessage = useCallback(
    ({
      ids,
      decision,
      onToastActionClick,
    }: {
      ids: string[];
      decision: PostApprovalDecisionEnum;
      onToastActionClick?: VoidFunction;
    }) => {
      const action = onToastActionClick
        ? {
            type: 'link' as const,
            text: formatMessage('activities.payDashboard.approvalsTab.approvalSuccessToastAction', {
              count: ids.length,
            }),
            onAction: () => {
              onToastActionClick();
              hideMessage();
            },
          }
        : undefined;

      showMessage({
        type: 'success',
        title: formatMessage(
          decision === PostApprovalDecisionEnum.Approved
            ? 'activities.payDashboard.approvalsTab.approveSuccessToast'
            : 'activities.payDashboard.approvalsTab.declineSuccessToast',
          { count: ids.length }
        ),
        id: 'payment-approval-decision-success',
        dataTestId: 'payment-approval-decision-success',
        action,
      });
    },
    [showMessage, formatMessage, hideMessage]
  );

  return { batchApprovalDecision, showSuccessMessage, isMutating: isLoading };
};
