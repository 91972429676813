import { Button, Group } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type Props = {
  onClose: VoidFunction;
  onSubmit: () => void;
  isLoading: boolean;
};

export const FooterContent = ({ onClose, onSubmit, isLoading }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout.Footer padding="s">
      <Group width="full" justifyContent="space-between">
        <Group.Item>
          <Button
            variant="tertiary"
            onClick={onClose}
            label={formatMessage('activities.accountingPlatformConnectCallback.screens.vendorLinking.cancel')}
            size="medium"
            isLoading={isLoading}
            data-testid="layout-close-button"
            isFullWidth
          />
        </Group.Item>
        <Group.Item>
          <NewSinglePaymentStepLayout.NextButton
            size="medium"
            isFullWidth
            onClick={onSubmit}
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.vendorLinking.confirmAndContinue'
            )}
            isLoading={isLoading}
            aria-label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.vendorLinking.confirmAndContinue'
            )}
          />
        </Group.Item>
      </Group>
    </NewSinglePaymentStepLayout.Footer>
  );
};
