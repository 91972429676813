import { Box } from '@chakra-ui/react';
import { BottomSheet, Button, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { LazyAnimation } from '@melio/platform-utils';

import { MigratedUserExperiencePillRow } from './MigratedUserExperiencePillRow';
import { MigratedUserExperienceRow } from './MigratedUserExperienceRow';

type MigratedUserExperienceBodyMobileProps = {
  onClose: () => void;
  animationSrc: string;
};
export const MigratedUserExperienceBodyMobile = ({ onClose, animationSrc }: MigratedUserExperienceBodyMobileProps) => {
  const { formatMessage } = useMelioIntl();
  return (
    <>
      <BottomSheet.Header>
        <Text textStyle="heading2Semi" color="semantic.text.primary">
          {formatMessage('activities.migrationUserDashboardTour.modal.mobile.title')}
        </Text>
      </BottomSheet.Header>

      <BottomSheet.Body>
        <Container width="full" paddingX="s" data-testid="pay-dashboard-migrated-user-experience-body">
          <Group variant="vertical" spacing="xs">
            <Box
              backgroundColor="gray"
              display="flex"
              justifyContent="center"
              height="280px"
              alignItems="center"
              borderRadius="8px"
            >
              <LazyAnimation
                id="pay-dashboard-migrated-user-experience-modal-animation"
                src={animationSrc}
                loop
                height="240px"
              />
            </Box>
            <MigratedUserExperienceRow
              isMobile
              description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row1')}
              iconType="checked"
            />
            <MigratedUserExperienceRow
              isMobile
              description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row2')}
              iconType="checked"
            />
            <MigratedUserExperiencePillRow isMobile />
          </Group>
        </Container>
      </BottomSheet.Body>
      <BottomSheet.Footer>
        <Button
          data-testid="pay-dashboard-migrated-user-experience-modal-button"
          onClick={onClose}
          label={formatMessage('activities.migrationUserDashboardTour.modal.button')}
          variant="primary"
          isFullWidth
        />
      </BottomSheet.Footer>
    </>
  );
};
