import { useDebounceCallback } from '@melio/platform-utils';
import { isNil } from 'lodash';

import { useSearchParams } from './useSearchParams';

type QueryParams = {
  searchTerm: string;
};

const DEBOUNCE_TIME = 200;

export const useSearch = () => {
  const [searchParams, _setSearchParams] = useSearchParams<QueryParams>();

  const setSearchParams = useDebounceCallback<typeof _setSearchParams>(
    (params) =>
      _setSearchParams((prev) =>
        Object.entries({ ...prev, ...params }).reduce(
          (acc, [key, value]) => (value === '' ? acc : { ...acc, [key]: value }),
          {} as Partial<QueryParams>
        )
      ),
    DEBOUNCE_TIME
  );

  const { searchTerm } = searchParams;

  return {
    searchTerm,
    setSearch: (searchTerm: string) => {
      if (!isNil(searchTerm) && searchTerm !== searchParams.searchTerm) {
        setSearchParams({ searchTerm });
      }
    },
  };
};
