// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@tanstack/react-query';
import { useVendor, UseVendorProps } from '@melio/platform-api';
// eslint-disable-next-line no-restricted-imports
import { PatchVendorsVendorIdRequest } from '@melio/platform-api-axios-client';

import { updateVendor } from '@/api/vendors/vendors.api';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';

export const useVendorEnrichedByIdQuery = (vendorId?: string, props?: UseVendorProps) => {
  return useVendor({ id: vendorId, ...props });
};

type CreateVendorUpdateProps = {
  vendorId: string;
  body: PatchVendorsVendorIdRequest;
};
export const useVendorUpdate = () => {
  const { mutateAsync, ...rest } = useMutation(
    ({ vendorId, body }: CreateVendorUpdateProps) => updateVendor(vendorId, body),
    {
      onSuccess: (vendor) => {
        emitAppEvent(APP_EVENTS.VENDOR_UPDATED, { vendor });
      },
    },
  );

  return {
    updateVendor: mutateAsync,
    ...rest,
  };
};
