import { useMtlDateUtils } from '@melio/ap-domain';
import { useInternationalAddressSchema } from '@melio/ap-widgets';
import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import type { Path } from 'react-hook-form';
import * as yup from 'yup';

import { phoneIsValid } from '../../../../../../utils/validation/phone.valdiation.utils';
import { IdType, Residency, TaxIdType } from '../types';

const getDirtyValues = <T extends FieldValues, DirtyFields extends Record<string, unknown>>({
  dirtyFields,
  formValues,
}: {
  dirtyFields: DirtyFields;
  formValues: T;
}): Partial<T> =>
  Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) {
      return prev;
    }

    return {
      ...prev,
      [key as keyof T]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues({ dirtyFields: dirtyFields[key] as DirtyFields, formValues: formValues[key] as T })
          : (formValues[key] as T),
    };
  }, {});
export const getDirtyValuesFromForm = <T extends FieldValues>({
  form,
}: {
  form: UseMelioFormResults<T>;
}): Partial<T> => {
  const formValues = form.getValues();
  const dirtyFields = form.formState.dirtyFields;

  return getDirtyValues({ dirtyFields, formValues });
};

export const setValueAndMakeDirty = <T extends FieldValues, K extends Path<T>>({
  fieldKey,
  form,
  value,
}: {
  form: UseMelioFormResults<T>;
  fieldKey: K;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: T[K];
}) => form.setValue(fieldKey, value, { shouldDirty: true });

export const useFxCommonValidations = () => {
  const MIN_AGE = 18;

  const { formatMessage } = useMelioIntl();
  const { addressSchema, countryCodeSchema } = useInternationalAddressSchema();
  const { isValidAge } = useMtlDateUtils();
  const [showUboResidencyNewFields] = useDevFeature<boolean>(FeatureFlags.UboResidencyNewFields, false);

  const firstNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.invalid'
      )
    )
    .trim();

  const lastNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.invalid'
      )
    )
    .trim();

  const ssnSchema = yup
    .string()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.ssn.required')
    )
    .trim();

  const dateOfBirthSchema = yup
    .date()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    )
    .typeError(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    )
    .test(
      'validMinAge',
      formatMessage(
        `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.minAge`,
        {
          age: MIN_AGE,
        }
      ),
      (v) => isValidAge(v, MIN_AGE, 'min')
    );

  const emailSchema = yup
    .string()
    .email(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.invalid')
    )
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.required')
    )
    .trim();

  const phoneSchema = yup
    .string()
    .trim()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.required')
    )
    .test(
      'phoneIsValid',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.invalid'),
      phoneIsValid
    );
  const residencyConditionalRequired =
    <T extends yup.AnySchema>(isCondition: 'usResidence' | 'nonUsResidence', message: string) =>
    (schema: T): T =>
      schema.when('usResidence', {
        is: isCondition,
        then: (s) => s.required(message) as T,
        otherwise: (s) => s.notRequired() as T,
      });

  const residencySchema = yup.object({
    usResidence: yup
      .string()
      .oneOf(['usResidence', 'nonUsResidence'])
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.usResidence.required'
        )
      ),
    taxIdType: residencyConditionalRequired(
      'usResidence',
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.taxIdType.required'
      )
    )(yup.mixed<TaxIdType>()),
    taxId: residencyConditionalRequired(
      'usResidence',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.taxId.required')
    )(yup.string()),
    idType: residencyConditionalRequired(
      'nonUsResidence',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.idType.required')
    )(yup.mixed<IdType>()),
    idNumber: residencyConditionalRequired(
      'nonUsResidence',
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.idNumber.required'
      )
    )(yup.string()),
  }) as yup.SchemaOf<Residency>;

  const notRequireResidencySchema = yup.object({
    usResidence: yup.string().oneOf(['usResidence', 'nonUsResidence']).nullable(),
    taxIdType: yup.mixed<TaxIdType>().nullable(),
    taxId: yup.string().nullable(),
    idType: yup.mixed<IdType>().nullable(),
    idNumber: yup.string().nullable(),
  }) as yup.SchemaOf<Residency>;

  return {
    phoneSchema,
    emailSchema,
    firstNameSchema,
    lastNameSchema,
    addressSchema,
    countryCodeSchema,
    ssnSchema,
    dateOfBirthSchema,
    residencySchema: showUboResidencyNewFields ? residencySchema : notRequireResidencySchema,
  };
};
