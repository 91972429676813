import { ApiError } from '@melio/api-client';
import { FeesApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@tanstack/react-query';

import { FeesBreakdown, FeesBreakdownRequest } from './types';
import { convertFeesBreakdownCentsToDollars, convertFeesBreakdownRequestCentsToDollars } from './utils';

export type UseFeesBreakdownProps = {
  payload: FeesBreakdownRequest;
  enabled?: boolean;
  params?: {
    paymentSessionId?: string;
  };
};

export const useFeesBreakdown = ({ payload, enabled, params }: UseFeesBreakdownProps) => {
  const query = useQuery<FeesBreakdown, ApiError>({
    enabled,
    queryKey: ['FeesApi', 'FeesBreakdown', payload],
    queryFn: async () => {
      const result = await FeesApiClient.getFeesBreakdown(
        convertFeesBreakdownRequestCentsToDollars(payload),
        undefined,
        undefined,
        params?.paymentSessionId
      );
      return result.data.data;
    },
    select: convertFeesBreakdownCentsToDollars,
  });

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
