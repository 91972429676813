import { getInternationalFormattedIdentifierData, getManagedAccountDeliveryMethodType } from '@melio/ap-domain';
import { Group, Table, Text } from '@melio/penny';
import { formatAddress, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentsTabDeliveryMethodCell = ({ payment }: { payment: Payment }) => {
  const { deliveryMethod } = usePaymentDeliveryMethod(payment);
  const { formatMessage } = useMelioIntl();

  return (
    <Table.Cell
      data-testid={`payments-tab-row-${payment.id}-delivery-method-cell`}
      placeholder={formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.missing')}
    >
      {deliveryMethod && (
        <Group variant="vertical" spacing="xxs" alignItems="flex-start">
          <Text textStyle="body3">{deliveryMethod.type}</Text>
          {deliveryMethod.details && (
            <Text textStyle="body4" color="global.neutral.900" shouldSupportEllipsis>
              {deliveryMethod.details}
            </Text>
          )}
        </Group>
      )}
    </Table.Cell>
  );
};

export const usePaymentDeliveryMethod: (payment: Payment) => {
  deliveryMethod: { type: string; details: string } | null;
} = (payment) => {
  const { formatMessage } = useMelioIntl();
  if (!payment.deliveryMethod) {
    return { deliveryMethod: null };
  }

  switch (payment.deliveryMethod.type) {
    case 'bank-account': {
      const isRtpDeliveryPreferenceType = payment.deliveryPreferenceType === 'rtp';
      const type = isRtpDeliveryPreferenceType
        ? formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryPreference.type.rtp`)
        : formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.bank-account`);
      return {
        deliveryMethod: {
          type,
          details: formatMessage(
            `activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.accountNumber`,
            {
              accountNumber: payment.deliveryMethod.details.accountNumberLast4Digits,
            }
          ),
        },
      };
    }
    case 'card':
      return {
        deliveryMethod: {
          type: formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.card`),
          details: formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.cardNumber`, {
            cardNumber: payment.deliveryMethod.details.lastFourDigits,
          }),
        },
      };
    case 'virtual-account':
      return {
        deliveryMethod: {
          type: formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.virtual-account`),
          details: formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.virtualAccount'
          ),
        },
      };
    case 'paper-check':
      return {
        deliveryMethod: {
          type: formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.paper-check`),
          details: formatMessage('activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.paperCheck', {
            address: formatAddress(payment.deliveryMethod.details.address),
          }),
        },
      };
    case 'international-account':
      return {
        deliveryMethod: {
          type: formatMessage(
            `activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.international-account`
          ),
          details: formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.internationalAccount',
            getInternationalFormattedIdentifierData(payment.deliveryMethod)
          ),
        },
      };
    case 'paypal-balance':
      return {
        deliveryMethod: {
          type: formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.paypal-balance`),
          details: formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.paypal-balance',
            { email: payment.deliveryMethod.details.paypalAccountEmail }
          ),
        },
      };
    case 'managed-account': {
      const managedType = getManagedAccountDeliveryMethodType(payment.deliveryMethod.details);
      switch (managedType) {
        case 'bank-account':
          return {
            deliveryMethod: {
              type: formatMessage(
                `activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.managed-bank-account`
              ),
              details: '',
            },
          };
        case 'paper-check':
          return {
            deliveryMethod: {
              type: formatMessage(
                `activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.managed-paper-check`
              ),
              details: '',
            },
          };
        default:
          return {
            deliveryMethod: {
              type: payment.deliveryMethod.type,
              details: '',
            },
          };
      }
    }
    default:
      return {
        deliveryMethod: {
          type: payment.deliveryMethod.type,
          details: '',
        },
      };
  }
};
