import { InboxItemBillTypeEnum, InboxItemPaymentRequestTypeEnum, useVendors } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import compact from 'lodash/compact';
import { useMemo } from 'react';

import { PayDashboardFilterType } from '../../types';
import { CalcQueryParamsResult, TabFilter } from '../Filters/types';

export const supportedSearchParamKeys = ['inboxItem.vendorId'] as const;
export type SupportedSearchParamKeysType = (typeof supportedSearchParamKeys)[number];

export const useBillsTabFilters = ({ filterContext }: { filterContext?: PayDashboardFilterType }) => {
  const { formatMessage } = useMelioIntl();

  const activeFilters = filterContext?.filters ?? {};
  const vendorsActiveFilterValues = activeFilters['inboxItem.vendorId'] ?? [];

  const { data: vendors = [], isLoading: isFetchingVendors } = useVendors({
    enabled: !!vendorsActiveFilterValues.length,
    params: { search: { 'vendor.id': vendorsActiveFilterValues }, expand: 'none' },
  });
  return useMemo(() => {
    const billsTabFilters: TabFilter<SupportedSearchParamKeysType>[] = compact([
      {
        key: 'inboxItem.vendorId',
        label: formatMessage('activities.payDashboard.billsTab.filters.vendor.title'),
        type: 'multi',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          if (values.length) {
            return {
              filterQueryParams: { 'inboxItem.vendorId': values },
              queryParamsOptions: {
                inboxItemTypes: [InboxItemBillTypeEnum.Bill, InboxItemPaymentRequestTypeEnum.PaymentRequest],
              },
            };
          }
          return { filterQueryParams: {}, queryParamsOptions: {} };
        },
        options: [],
        calcOptions: (values: string[]): TabFilter<SupportedSearchParamKeysType>['options'] => {
          if (!isFetchingVendors && vendors.length) {
            return vendors.map((vendor) => {
              if (values.includes(vendor.id)) {
                return {
                  label: vendor.name,
                  value: vendor.id,
                };
              }
              return { label: '', value: '' };
            });
          }
          return [];
        },
      },
    ]);
    return billsTabFilters;
  }, [formatMessage, isFetchingVendors, vendors]);
};
