import { ConditionalWrapper, FloatingMenu, Group, Icon, IconKey, Text, Tooltip, useBreakpoint } from '@melio/penny';
import { ReactNode } from 'react';

export type AddBillDropdownMenuItemProps = {
  index: number;
  label: string;
  disabled?: { isDisabled: boolean; message?: string };
  isSelected?: boolean;
  variant?: 'default' | 'critical';
  dataTestId?: string;
  onClick?: VoidFunction;
  icon?: IconKey;
  rightElement?: ReactNode;
};

export const AddBillDropdownMenuItem = (props: AddBillDropdownMenuItemProps) => {
  const { label, disabled, onClick, isSelected, dataTestId, icon, rightElement } = props;
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const disabledMessage = disabled?.message;
  const shouldShowTooltip = Boolean(disabled?.isDisabled && disabledMessage);

  const itemContent = (
    <>
      <Group alignItems="center">
        {icon && (
          <Icon type={icon} size={isMobile ? 'large' : 'small'} color="inherit" isDisabled={disabled?.isDisabled} />
        )}
        <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
          {label}
        </Text>
      </Group>
      {rightElement}
    </>
  );

  return (
    <ConditionalWrapper
      condition={shouldShowTooltip}
      wrapper={(children) => (
        <Tooltip content={disabledMessage as string} shouldAddTriggerFocus={disabled?.isDisabled}>
          {children}
        </Tooltip>
      )}
    >
      <FloatingMenu.Item
        data-component="MenuItem"
        data-testid={dataTestId}
        onClick={!disabled?.isDisabled ? onClick : undefined}
        isSelected={isSelected}
        disabled={disabled}
      >
        {itemContent}
      </FloatingMenu.Item>
    </ConditionalWrapper>
  );
};

AddBillDropdownMenuItem.displayName = 'AddBillDropdownMenuItem';
