import { Button, Tooltip } from '@melio/penny';

import { Plan } from '../../../../../api';

export type PlanCardButtonProps = {
  planTier: Plan['tier'];
  isSaving?: boolean;
  isDisabled?: boolean;
  label: string;
  variant: 'primary' | 'tertiary';
  disabledTooltipContent?: string;
  onClick: () => void;
};

export const PlanCardButton = ({
  planTier,
  isSaving,
  isDisabled,
  label,
  onClick,
  variant,
  disabledTooltipContent,
}: PlanCardButtonProps) => (
  <Tooltip
    content={disabledTooltipContent}
    isEnabled={isDisabled && !!disabledTooltipContent}
    data-testid={`subscription-${planTier}-plan-card-button-tooltip`}
  >
    <Button
      data-testid={`subscription-${planTier}-plan-card-button`}
      label={label}
      isDisabled={isDisabled}
      isLoading={isSaving}
      variant={variant}
      onClick={onClick}
    />
  </Tooltip>
);
