import { useDisclosure } from '@chakra-ui/react';
import { useMelioQueryClient } from '@melio/api-client';
import { Container, Group, Loader, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  ApiError,
  TaxpayerIdentificationRequestStatusEnum,
  useTaxPayerInfo,
  useTaxPayerInfoDetailsRequest,
  useVendor,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useSystemMessage } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { useState } from 'react';

import { CancelRequestVendorTaxDetailsModal } from './CancelRequestVendoTaxDetailsModal.widget';
import { TaxPayerInfoCheckbox } from './components/TaxPayerInfoCheckbox.component';
import { TaxPayerRequestStatus } from './components/TaxPayerRequestStatus';
import { TINVerificationStatusBanner } from './components/TINVerificationStatusBanner.component';
import { RequestVendorTaxDetailsModal } from './RequestVendorTaxDetailsModal.widget';
import { TaxPayerDeleteAttachmentModal } from './TaxPayerDeleteAttachmentModal.widget';
import { TaxPayerInfoFormWidget } from './TaxPayerInfoForm.widget';
import { ResendReminderError, W9SubmissionMethod } from './types';
import { useTaxPayerInfoForm } from './useTaxPayerInfoForm';
import { useTaxPayerInfoRequestWithSubscription } from './useTaxPayerInfoRequestWithSubscription';

type TaxPayerInfoCardProps = {
  vendor: Vendor;
  isVendorLoading: boolean;
  onContactorUpdated?: () => void;
};

export const TaxPayerInfoCard = forwardRef<TaxPayerInfoCardProps>(
  ({ vendor, isVendorLoading, onContactorUpdated }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { showMessage } = useSystemMessage();
    const { track, createTrackHandler } = useAnalytics();

    const queryClient = useMelioQueryClient();
    const { isEligible: isEligibleForW9 } = useSubscriptionFeature({
      featureName: 'w9',
    });

    const { isMutating: isVendorMutating, update: updateVendor } = useVendor({ id: vendor.id });
    const taxPayerIdentificationRequestStatus = vendor?.lastTaxpayerIdentificationRequest?.status;
    const isPendingTaxPayerRequestStatus =
      taxPayerIdentificationRequestStatus === TaxpayerIdentificationRequestStatusEnum.Pending;
    const [formVisibility, setFormVisibility] = useState<'hidden' | 'visible'>('hidden');
    const { resend: resendTaxPayerInfoRequest, isMutating: isTaxPayerInfoRequestMutating } =
      useTaxPayerInfoDetailsRequest({
        vendorId: vendor.id,
      });

    const [isW9CollectFromAllVendorsEnabled] = useDevFeature(FeatureFlags.W9CollectFromAllVendors, false);
    const shouldShowTaxpayerInfoForm = isW9CollectFromAllVendorsEnabled || vendor.contractor;

    const { onRequestTaxPayerInfoDetailsWithSubscriptionCheck, isMutating: isMutatingTaxPayerInfoRequest } =
      useTaxPayerInfoRequestWithSubscription(vendor.id);

    const {
      create: uploadW9File,
      isLoading: isLoadingTaxPayerInfo,
      data: taxpayerInfo,
      isMutating: isTaxPayerInfoMutating,
    } = useTaxPayerInfo({
      vendorId: vendor.id,
      taxpayerInfoId: vendor.taxpayerInfo?.id ?? '',
      enabled: !!vendor.taxpayerInfo?.id && !isVendorLoading,
    });

    const taxPayerInfoFormProps = useTaxPayerInfoForm({
      taxPayerInfo: taxpayerInfo,
    });

    const showTaxPayerRequestStatusForm = vendor.contractor && isPendingTaxPayerRequestStatus;

    const getResendReminderErrorMessageByErrorCode = (errorCode?: string) => {
      switch (errorCode) {
        case ResendReminderError.MaxAllowedEmailsSentCountByVendor:
          return 'widgets.taxPayerInfo.vendorUpdate.fail.maxEmailsSentCountByVendor';
        case ResendReminderError.MaxAllowedEmailsSentCountPerRequest:
          return 'widgets.taxPayerInfo.vendorUpdate.fail.maxEmailsSentCountPerRequest';
        default:
          return 'widgets.taxPayerInfo.vendorUpdate.fail.toast';
      }
    };

    const showErrorToast = (error: ApiError) => {
      const title = formatMessage(getResendReminderErrorMessageByErrorCode(error.errorCode));

      showMessage({
        type: 'error',
        title,
        dataTestId: 'vendor-tax-details-request-reminder-fail',
      });

      track('Vendor', 'Status', { Intent: 'send-w9-reminder', TaxId: 'request-sent', Status: 'fail' });
    };

    const onSendReminderRequest = async () => {
      try {
        await resendTaxPayerInfoRequest(vendor.lastTaxpayerIdentificationRequest?.id as string);
        setFormVisibility('hidden');
        showMessage({
          type: 'success',
          title: formatMessage('widgets.taxPayerInfo.statusForm.reminder.toast.success'),
          dataTestId: 'vendor-tax-details-request-reminder-success',
        });
        track('Vendor', 'Status', { Intent: 'send-w9-reminder', TaxId: 'request-sent', Status: 'success' });
      } catch (e) {
        showErrorToast(e as ApiError);
      }
    };

    const showSendReminderForm = () => {
      setFormVisibility('visible');
    };

    const {
      isOpen: isTaxRequestDetailsModalOpen,
      onOpen: openTaxRequestDetailsModal,
      onClose: closeTaxRequestDetailsModal,
    } = useDisclosure();

    const {
      isOpen: isTaxRequestCancelModalOpen,
      onOpen: openTaxRequestCancelModal,
      onClose: closeTaxRequestCancelModal,
    } = useDisclosure();

    const {
      isOpen: isTaxPayerDeleteAttachmentModalOpen,
      onOpen: openTaxPayerDeleteAttachmentModal,
      onClose: closeTaxPayerDeleteAttachmentModal,
    } = useDisclosure();

    const trackOnContractorUpdate = createTrackHandler('Vendor', 'Click');

    const onContractorUpdate = async () => {
      trackOnContractorUpdate({
        IsContractor: vendor.contractor,
        Cta: 'mark-1099-contractor',
        PageName: 'vendor-details',
        Flow: 'dashboard-vendors',
      });

      try {
        await updateVendor({ contractor: !vendor.contractor });
        onContactorUpdated?.();
        void queryClient.invalidateQueries('VendorsApi', 'model', 'vendorsContractorsInfo1099');
      } catch (e) {
        showMessage({
          type: 'error',
          title: formatMessage('widgets.taxPayerInfo.vendorUpdate.fail.toast'),
        });
      }
    };

    const onRequestTaxPayerInfoDetails = () => {
      track('Vendor', 'Click', {
        Intent: 'send-w9-request',
        TaxId: 'email',
        Cta: 'send-w9-request',
      });
      if (vendor?.contact.email) {
        onRequestTaxPayerInfoDetailsWithSubscriptionCheck(vendor?.contact.email, () =>
          taxPayerInfoFormProps.setValue('W9SubmissionMethod', W9SubmissionMethod.Pdf)
        );
      } else {
        openTaxRequestDetailsModal();
      }
    };

    const onFeatureIsBlockedCb = () => {
      taxPayerInfoFormProps.setValue('W9SubmissionMethod', W9SubmissionMethod.Pdf);
    };

    const handleFileUpload = async (file: File) => {
      const result = await uploadW9File({ file });
      return result.file;
    };

    const isMutatingRequestStatusForm =
      isTaxPayerInfoMutating || isVendorMutating || isTaxPayerInfoRequestMutating || isMutatingTaxPayerInfoRequest;
    const isMutatingTaxPayerInfoForm =
      isTaxPayerInfoRequestMutating || isTaxPayerInfoMutating || isLoadingTaxPayerInfo || isMutatingTaxPayerInfoRequest;

    const renderContent = () => {
      if (!shouldShowTaxpayerInfoForm || isVendorMutating) {
        return null;
      }

      if (isVendorLoading) {
        return <Loader />;
      }

      if (showTaxPayerRequestStatusForm) {
        return (
          <TaxPayerRequestStatus
            onShowForm={showSendReminderForm}
            onCancelRequest={openTaxRequestCancelModal}
            onSendReminder={() => void onSendReminderRequest()}
            vendor={vendor}
            isMutating={isMutatingRequestStatusForm}
            formVisibility={formVisibility}
          />
        );
      }

      return (
        <TaxPayerInfoFormWidget
          handleFileDelete={openTaxPayerDeleteAttachmentModal}
          handleFileUpload={handleFileUpload}
          taxPayerInfo={taxpayerInfo}
          isLoading={isMutatingTaxPayerInfoForm}
          vendor={vendor}
          handleSubmitRequest={() => void onRequestTaxPayerInfoDetails()}
          taxPayerInfoFormProps={taxPayerInfoFormProps}
          isBannerCompact
        />
      );
    };

    return (
      <Container ref={ref} overflow="initial" data-testid="taxpayer-info-card">
        <Group variant="vertical" spacing="m">
          <Group justifyContent="space-between">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('widgets.taxPayerInfo.title')}
            </Text>
          </Group>

          <Group spacing="xl" variant="vertical">
            {isVendorMutating ? (
              <Loader />
            ) : (
              <TaxPayerInfoCheckbox
                isUpdating={isVendorMutating}
                onChange={() => void onContractorUpdate()}
                isContractor={vendor.contractor}
              />
            )}
            {isEligibleForW9 && <TINVerificationStatusBanner taxPayerInfo={taxpayerInfo} />}
            {renderContent()}
          </Group>
          <RequestVendorTaxDetailsModal
            isOpen={isTaxRequestDetailsModalOpen}
            onClose={closeTaxRequestDetailsModal}
            vendor={vendor}
            onFeatureIsBlockedCb={onFeatureIsBlockedCb}
          />
          <CancelRequestVendorTaxDetailsModal
            isOpen={isTaxRequestCancelModalOpen}
            onClose={closeTaxRequestCancelModal}
            vendor={vendor}
          />
          <TaxPayerDeleteAttachmentModal
            isOpen={isTaxPayerDeleteAttachmentModalOpen}
            onClose={closeTaxPayerDeleteAttachmentModal}
            taxpayerInfo={taxpayerInfo}
          />
        </Group>
      </Container>
    );
  }
);

TaxPayerInfoCard.displayName = 'TaxPayerInfoCard';
