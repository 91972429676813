import { InvoicesApiPreviewInvoiceIdRequest } from '@melio/ar-api-axios-client';
import { useQuery } from '@tanstack/react-query';

import { getInvoicePreviewPDF } from './utils';

export const useInvoicePreviewPDF = (requestParams: InvoicesApiPreviewInvoiceIdRequest) => {
  const query = useQuery<string, ARPlatformError>(['invoicePreviewPDF', requestParams], () =>
    getInvoicePreviewPDF(requestParams)
  );
  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
