import { usePermissions } from '@melio/platform-permissions';

export const useAccountingPlatformsPermissions = () => {
  const { can } = usePermissions();

  const canConnectToQuickBooksOnline = can({ action: 'create', subject: 'auth:provider:intuit-connect' });
  const canConnectToQuickBooksDesktop = can({ action: 'create', subject: 'accountingPlatform:initQuickBooksDesktop' });
  const canConnectToXero = can({ action: 'create', subject: 'accountingPlatform:authLink' });

  const canConnect = canConnectToQuickBooksOnline || canConnectToQuickBooksDesktop || canConnectToXero;

  const canDisconnectQuickBooksOnline = can({ action: 'create', subject: 'auth:provider:intuit-disconnect' });
  const canDisconnectQuickBooksDesktop = can({ action: 'create', subject: 'accountingPlatform:disconnect' });
  const canDisconnectXero = can({ action: 'create', subject: 'accountingPlatform:disconnect' });

  const canDisconnect = canDisconnectQuickBooksOnline || canDisconnectQuickBooksDesktop || canDisconnectXero;

  return {
    canConnect,
    canDisconnect,
  };
};
