import { DeliveryPreferenceType, PaymentCalendarCalculationDates } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { isToday } from 'date-fns';
import { isEqual } from 'lodash';

import { ArrowLayout } from '../../../../../../components/ArrowLayout/ArrowLayout';

type FlowOfFundsProps = Pick<
  PaymentCalendarCalculationDates,
  'scheduleDate' | 'minDeliveryDate' | 'totalBusinessDays' | 'totalMaxBusinessDays'
> & { deliveryPreference: DeliveryPreferenceType };

export const FlowOfFunds = (props: FlowOfFundsProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const {
    settings: {
      payment: { flowOfFundsIndicator },
    },
  } = useConfig();

  const { scheduleDate, minDeliveryDate, totalBusinessDays, totalMaxBusinessDays, deliveryPreference } = props;

  const formattedScheduleDate = isToday(scheduleDate)
    ? formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.scheduleDate.today')
    : formatDate(scheduleDate, { dateStyle: 'medium' });

  const formattedDeliveryDate = isToday(minDeliveryDate)
    ? formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.deliveryDate.today')
    : formatDate(minDeliveryDate, { dateStyle: 'medium' });

  const getBusinessDaysLabel = () => {
    if (totalBusinessDays === 0) {
      return formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.businessDays.sameDay.label', {
        totalBusinessDays,
      });
    }

    if (flowOfFundsIndicator.showDateRange && !isEqual(totalBusinessDays, totalMaxBusinessDays)) {
      return formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.businessDays.range.label', {
        totalBusinessDays,
        totalMaxBusinessDays,
      });
    }

    return formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.businessDays.single.label', {
      totalBusinessDays,
    });
  };

  const deliveryDateLabel =
    deliveryPreference === 'check'
      ? formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.deliveryDate.check.label')
      : formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.deliveryDate.label');

  return (
    <ArrowLayout
      startLabel={formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.scheduleDate.label')}
      startDescription={formattedScheduleDate}
      centerLabel={getBusinessDaysLabel()}
      endLabel={deliveryDateLabel}
      endDescription={formattedDeliveryDate}
      aria-label={formatMessage('activities.paymentFlow.form.content.deliveryDate.flowOfFunds.ariaLabel')}
      data-testid="flow-of-funds"
    />
  );
};
