// eslint-disable-next-line no-restricted-imports
import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateCache = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[1] === 'model' || query.queryKey[1] === 'collection',
    });
};
