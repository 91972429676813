/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from '@chakra-ui/react';
import { Icon, NakedButton } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType, FeeType, PaperCheck } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { CheckProtectionModalWidget } from '../../../../CheckProtectionModal';
import { Fee } from '../../../../Fee';
import { MethodCard } from '../../../../MethodCard/MethodCard.widget';
import { AddDeliveryMethodActionButton, DeliveryMethodActionsMenu } from '../components/DeliveryMethodActions';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';
import { usePaperCheckDescription } from './usePaperCheckDescription';

type Props = {
  check: DeliveryMethod | null;
  onClick: VoidFunction;
};

export const PaperCheckDeliveryMethodCard = ({ check, onClick }: Props) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { description } = usePaperCheckDescription();

  const checkAddress = (() => {
    if (!check) {
      return undefined;
    }

    const address = (check.details as PaperCheck).address;
    return formatMessage('widgets.delivery-methods.check.address', {
      addressLine1: address.line1,
      addressLine2: address.line2 ? ' ' + address.line2 : '',
      addressCity: address.city,
      addressPostalCode: address.postalCode,
      addressState: address.state,
    });
  })();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isUsHolidaysChecksOn] = usePartnerFeature('eoyCheck', true);
  const tooltip = isUsHolidaysChecksOn
    ? ({
        text: formatMessage('widgets.addOrUpdateDeliveryMethod.check.endOfYear'),
        type: check !== null ? 'warning' : 'neutral',
      } as const)
    : undefined;

  const caption = (() => {
    if (!check) {
      return {
        secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.check.comment', {
          modal: (
            <NakedButton
              variant="secondary"
              onClick={onOpen}
              data-testid="widgets-addOrUpdateDeliveryMethod-check-caption-button"
              label={formatMessage('widgets.addOrUpdateDeliveryMethod.check.captionWithModalText')}
              aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.check.captionWithModalText.ariaLabel')}
            />
          ),
        }),
      };
    }

    return {
      primary: formatMessage('widgets.addOrUpdateDeliveryMethod.check.info.info1', {
        printName: (check.details as PaperCheck).printName,
        checkAddress,
      }),
      secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.check.info.info2', {
        updatedAt: formatDate(check.history.updatedAt),
      }),
    };
  })();

  return (
    <>
      <CheckProtectionModalWidget isOpen={isOpen} onClose={onClose} />

      <MethodCard
        tooltip={tooltip}
        testIds={{
          root: `MethodCard-${DeliveryMethodType.PaperCheck}`,
          header: `method-card-type-${DeliveryMethodType.PaperCheck}`,
        }}
        displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.check.title')}
        helperText={description}
        icon={<Icon type="paper-check" />}
        actionElement={
          check ? (
            <DeliveryMethodActionsMenu
              actions={[
                {
                  label: formatMessage('widgets.addOrUpdateDeliveryMethod.edit'),
                  onClick,
                  dataTestId: 'edit-delivery-method-item',
                },
              ]}
            />
          ) : (
            <AddDeliveryMethodActionButton
              onClick={onClick}
              aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.check.add.ariaLabel', {
                fee: <Fee feeType={FeeType.Check} />,
              })}
              data-testid={`add-delivery-method-card-${DeliveryMethodType.PaperCheck}`}
            />
          )
        }
      >
        <DeliveryMethodCaption primary={caption.primary} secondary={caption.secondary} />
      </MethodCard>
    </>
  );
};
