import { ReceivablePaymentsApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseReceivablePaymentProps = UseModelProps<typeof ReceivablePaymentsApiClient.getReceivablePaymentById>;

export const useReceivablePayment = (props: UseReceivablePaymentProps = {}) =>
  useModel({
    ...props,
    queryKey: 'ReceivablePaymentsApi',
    queryFn: ReceivablePaymentsApiClient.getReceivablePaymentById,
  });
