import { useGuestPayorPaymentRequestDetails, useGuestPayorVendorPaymentDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { usePaymentRequestStatus, useUpdatedInvoiceSystemMessage } from '../../hooks';
import { NoPaymentOptionsScreen } from './screens';

type NoPaymentOptionsActivityProps = {
  onViewInvoice: VoidFunction;
  paymentRequestLink: string;
  onError?: ARErrorFunction;
};

export const NoPaymentOptionsActivity = forwardRef<NoPaymentOptionsActivityProps>(
  ({ onError, onViewInvoice, paymentRequestLink, ...props }, ref) => {
    const { data: paymentRequestDetails, isLoading: isLoadingPaymentRequestDetails } =
      useGuestPayorPaymentRequestDetails({
        onError,
        paymentRequestLink,
      });

    const { data: vendorPaymentDetails, isLoading: isLoadingVendorPaymentDetails } = useGuestPayorVendorPaymentDetails({
      paymentRequestLink,
      onError,
    });

    const { status } = usePaymentRequestStatus(paymentRequestLink);

    useEffect(() => {
      if (status && status != 'no-payment-option') onError?.({ code: 'UNKNOWN_ERROR', message: 'Unknown error' });
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    useUpdatedInvoiceSystemMessage(paymentRequestLink);

    return (
      <NoPaymentOptionsScreen
        onViewInvoice={onViewInvoice}
        paymentRequestDetails={paymentRequestDetails}
        vendorPaymentDetails={vendorPaymentDetails}
        isLoading={isLoadingPaymentRequestDetails || isLoadingVendorPaymentDetails}
        {...props}
        ref={ref}
      />
    );
  }
);
NoPaymentOptionsActivity.displayName = 'NoPaymentOptionsActivity';
