import { getInternationalFormattedIdentifierData, useFeeValueFormatting } from '@melio/ap-domain';
import { MethodCard, SupportedInternationalType } from '@melio/ap-widgets';
import { Group, Icon, Pill, Text } from '@melio/penny';
import { DeliveryMethodType, InternationalAccountDeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { AddDeliveryMethodActionButton } from '../components/DeliveryMethodActions';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';

type Props = {
  deliveryMethod: InternationalAccountDeliveryMethod | undefined;
  supportedInternationalType: SupportedInternationalType;
  countryDisplayName?: string;
  onClick: VoidFunction;
};

export const InternationalDeliveryMethodCard = ({
  deliveryMethod,
  supportedInternationalType,
  onClick,
  countryDisplayName,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { formatFeeValue } = useFeeValueFormatting();

  const content = (() => {
    if (deliveryMethod) {
      const identifierInfo = getInternationalFormattedIdentifierData(deliveryMethod, countryDisplayName);
      if (supportedInternationalType === 'international-usd') {
        return {
          title: formatMessage('widgets.addOrUpdateDeliveryMethod.international.title.USD'),
          helperText: formatMessage('widgets.addOrUpdateDeliveryMethod.international.helper.USD', {
            fee: formatFeeValue('international'),
          }),
          icon: 'international' as const,
          caption: {
            primary: formatMessage('widgets.addOrUpdateDeliveryMethod.international.info.details', identifierInfo),
          },
        };
      }
      return {
        title: formatMessage('widgets.addOrUpdateDeliveryMethod.international.title'),
        helperText: formatMessage('widgets.addOrUpdateDeliveryMethod.international.helper'),
        icon: 'foreign-currency' as const,
        caption: {
          primary: formatMessage('widgets.addOrUpdateDeliveryMethod.international.info.details', identifierInfo),
        },
      };
    }

    switch (supportedInternationalType) {
      case 'international':
        return {
          title: formatMessage('widgets.addOrUpdateDeliveryMethod.international.title.new'),
          hasPill: true,
          helperText: formatMessage('widgets.addOrUpdateDeliveryMethod.international.helper.new', {
            fee: formatFeeValue('international'),
          }),
          icon: 'international' as const,
          caption: {
            secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.international.comment'),
          },
        };

      case 'international-usd':
        return {
          title: formatMessage('widgets.addOrUpdateDeliveryMethod.international.title.new'),
          helperText: formatMessage('widgets.addOrUpdateDeliveryMethod.international.helper.USD', {
            fee: formatFeeValue('international'),
          }),
          icon: 'international' as const,
        };
      case 'international-fx':
    }

    return {
      title: formatMessage('widgets.addOrUpdateDeliveryMethod.international.title'),
      hasPill: true,
      helperText: formatMessage('widgets.addOrUpdateDeliveryMethod.international.helper'),
      icon: 'foreign-currency' as const,
    };
  })();

  return (
    <MethodCard
      testIds={{ root: `MethodCard-${supportedInternationalType}` }}
      icon={<Icon type={content.icon} />}
      displayName={
        content.hasPill ? (
          <Group alignItems="center" justifyContent="space-between">
            <Text textStyle="heading3">{content.title}</Text>
            <Pill
              label={formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.badge.new')}
              status="brand"
              type="primary"
            />
          </Group>
        ) : (
          content.title
        )
      }
      helperText={content.helperText}
      actionElement={
        deliveryMethod ? undefined : (
          <AddDeliveryMethodActionButton
            aria-label={`add-delivery-method-card-${DeliveryMethodType.InternationalAccount}`}
            onClick={onClick}
            data-testid={`add-delivery-method-card-${supportedInternationalType}`}
          />
        )
      }
    >
      {content.caption && <DeliveryMethodCaption {...content.caption} />}
    </MethodCard>
  );
};
