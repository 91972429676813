import { TableColumnDef, useBreakpoint } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import _compact from 'lodash/compact';
import { useMemo } from 'react';

import { FocusSkipToComponentProps } from '../../FocusSkipToComponent';
import { useTableColumnsSort } from '../../hooks/useTableColumnsSort';
import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { BillsTabOriginCell } from '../BillsTab/components/BillsTabOriginCell';
import { PaymentsTabAmountCell } from '../PaymentsTab/components/PaymentsTabAmountCell';
import { PaymentsTabDebitDateCell } from '../PaymentsTab/components/PaymentsTabDebitDateCell';
import { PaymentsTabInvoiceCell } from '../PaymentsTab/components/PaymentsTabInvoiceCell';
import { PaymentsTabMoreActionsCell } from '../PaymentsTab/components/PaymentsTabMoreActionsCell';
import { PaymentsTabScheduledByCell } from '../PaymentsTab/components/PaymentsTabScheduledByCell';
import { PaymentsTabStatusCell } from '../PaymentsTab/components/PaymentsTabStatusCell';
import { PaymentsTabVendorNameCell } from '../PaymentsTab/components/PaymentsTabVendorNameCell';
import { ApprovalsTabActionButtonCell } from './components/ApprovalsTabActionButtonCell';
import { PAYMENT_APPROVAL_SELECTION_FOOTER_APPROVE_BUTTON_ID } from './consts';
import { ApprovalsTabSortableCells, CELLS_IDS, PaymentsTableMeta } from './types';
import { useApprovalsTabSortableColumns } from './useApprovalsTabSortableColumns';

export const useApprovalsTableColumns = () => {
  const { formatMessage } = useMelioIntl();
  const sorting = usePayDashboardSorting();
  const { getSortableHeader } = useTableColumnsSort<ApprovalsTabSortableCells>({
    sorting,
  });
  const [isPreviewInvoiceEnabled] = usePartnerFeature('PreviewInvoice', false);

  const { sortableColumns } = useApprovalsTabSortableColumns();
  const [isMoreActionsEnabled] = useDevFeature(FeatureFlags.FetchPaymentActionsOnDashboard, false);
  const { breakpoint } = useBreakpoint();

  const isLargeScreen = ['l', 'xl'].includes(breakpoint);

  return useMemo<TableColumnDef<Payment, PaymentsTableMeta>[]>(() => {
    const vendorCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.VENDOR_NAME,
      isPinnedToLeft: true,
      size: 'm',
      header: getSortableHeader(sortableColumns[CELLS_IDS.VENDOR_NAME]),
      cell: ({ row, meta }) => {
        if (meta) {
          const focusProps: FocusSkipToComponentProps = {
            disable: meta.rowSelections.length === 0,
            text: 'activities.payDashboard.approvalsTab.selectionFooter.reviewAndPay.skipTo',
            href: `#${PAYMENT_APPROVAL_SELECTION_FOOTER_APPROVE_BUTTON_ID}`,
            dataTestid: 'approvals-tab-approve',
          };
          return (
            <PaymentsTabVendorNameCell
              payment={row}
              focusProps={focusProps}
              rowSelectedCount={meta?.rowSelections.length ?? 0}
            />
          );
        }
        return null;
      },
    };
    const scheduledByCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.SCHEDULED_BY,
      size: 's',
      header: formatMessage('activities.payDashboard.paymentsTab.table.headers.scheduledBy'),
      cell: ({ row }) => <PaymentsTabScheduledByCell payment={row} />,
    };
    const invoiceColumn: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.INVOICE,
      size: 'xs',
      cell: ({ row }) => <PaymentsTabInvoiceCell payment={row} />,
    };
    const billCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.BILL,
      size: 's',
      header: formatMessage('activities.payDashboard.paymentsTab.table.headers.bill'),
      cell: ({ row }) =>
        row.bills?.[0] ? <BillsTabOriginCell billsTabItem={{ type: 'bill', payload: row.bills[0] }} /> : null,
    };
    const debitDateCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.DEBIT_DATE,
      size: 's',
      header: getSortableHeader(sortableColumns[CELLS_IDS.DEBIT_DATE]),
      cell: ({ row }) => <PaymentsTabDebitDateCell payment={row} />,
    };
    const statusCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.STATUS,
      size: 's',
      header: formatMessage('activities.payDashboard.paymentsTab.table.headers.status'),
      cell: ({ row }) => <PaymentsTabStatusCell payment={row} />,
    };
    const amountCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.AMOUNT,
      size: 200,
      isPinnedToRight: isLargeScreen,
      header: getSortableHeader({
        textAlign: 'end',
        ...sortableColumns[CELLS_IDS.AMOUNT],
      }),
      textAlign: 'end',
      cell: ({ row }) => <PaymentsTabAmountCell payment={row} />,
    };
    const actionButtonColumn: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.ACTION_BUTTON,
      size: 140,
      isPinnedToRight: isLargeScreen,
      header: formatMessage('activities.payDashboard.table.headers.action'),
      textAlign: 'end',
      cell: ({ row, meta }) =>
        meta ? (
          <ApprovalsTabActionButtonCell
            payment={row}
            onApprovalDecisionClick={meta.onApprovalDecisionClick}
            onViewPayment={meta.onViewClick}
            isDisabled={!!meta?.rowSelections.length}
          />
        ) : null,
    };

    const moreActionsColumn: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.MORE_ACTIONS,
      size: 'xs',
      isPinnedToRight: isLargeScreen,
      cell: ({ row, meta }) =>
        meta ? (
          <PaymentsTabMoreActionsCell
            payment={row}
            onEditPayment={meta.onEditPayment}
            onCancelPayment={meta.onCancelPayment}
            onMarkFailedPaymentAsPaid={meta.onMarkFailedPaymentAsPaid}
            onSuccessMarkAsUnpaid={meta.onSuccessMarkAsUnpaid}
            onSuccessApprovalDecision={meta.onSuccessApprovalDecision}
          />
        ) : null,
    };

    return _compact([
      vendorCell,
      scheduledByCell,
      isPreviewInvoiceEnabled ? invoiceColumn : null,
      billCell,
      debitDateCell,
      statusCell,
      amountCell,
      actionButtonColumn,
      isMoreActionsEnabled ? moreActionsColumn : null,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, sorting?.sortingState, isLargeScreen]);
};
