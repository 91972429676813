import { FormatDate } from '@melio/i18n-tools';
import { BrandSymbolKey, IconKey, NakedButton } from '@melio/penny';
import {
  BankFundingSourceTypeOptionServer,
  Card,
  CardFundingSourceTypeOptionServer,
  FlexFundingSourceTypeOptionServer,
  FundingSource,
  FundingSourceType,
  PaypalBalanceFundingSourceTypeOptionServer,
  useCollaborator,
  useFundingSource,
  useIsPlaidLinkVerificationTokenFetching,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { differenceInHours } from 'date-fns';
import { isNil } from 'lodash';

export const getFundingSourceImageProps = (fundingSource: FundingSource) => {
  if (fundingSource.type === 'bank-account' && fundingSource.logo && !fundingSource.logo.includes('default')) {
    return { src: fundingSource.logo, alt: 'Account provider logo' };
  }
  return;
};

export const getFundingSourceIconByNetwork = (network: Card['network']): IconKey | BrandSymbolKey => {
  switch (network) {
    case 'visa':
      return 'visa';
    case 'amex':
      return 'amex-logo';
    case 'mastercard':
      return 'mastercard';
    case 'discover':
      return 'discover';
    default:
      return 'credit-card';
  }
};

export type FundingSourceTypeOptionReasonClient = 'goodNotReceived';

export type UnsupportedFundingSourceTypeReasons =
  | CardFundingSourceTypeOptionServer['reason']
  | FundingSourceTypeOptionReasonClient;

export type CardFundingSourceTypeOption = Omit<CardFundingSourceTypeOptionServer, 'reason'> & {
  reason?: UnsupportedFundingSourceTypeReasons;
};

export type BankFundingSourceTypeOption = Omit<BankFundingSourceTypeOptionServer, 'reason'> & {
  reason?: UnsupportedFundingSourceTypeReasons;
};

export type FlexFundingSourceTypeOption = Omit<FlexFundingSourceTypeOptionServer, 'reason'> & {
  reason?: UnsupportedFundingSourceTypeReasons;
};

export type PaypalBalanceFundingSourceTypeOption = Omit<PaypalBalanceFundingSourceTypeOptionServer, 'reason'> & {
  reason?: UnsupportedFundingSourceTypeReasons;
};
export type FundingSourceTypesOption = StrictUnion<
  | BankFundingSourceTypeOption
  | CardFundingSourceTypeOption
  | FlexFundingSourceTypeOption
  | PaypalBalanceFundingSourceTypeOption
>;

export const fsTypesOptionsToFundingSourceTypesOptions = (
  fsTypeOption:
    | BankFundingSourceTypeOptionServer
    | CardFundingSourceTypeOptionServer
    | FlexFundingSourceTypeOptionServer
    | PaypalBalanceFundingSourceTypeOptionServer
): FundingSourceTypesOption => {
  switch (fsTypeOption.type) {
    case FundingSourceType.BankAccount:
      return fsTypeOption as BankFundingSourceTypeOption;
    case FundingSourceType.FlexAccount:
      return fsTypeOption as FlexFundingSourceTypeOption;
    case FundingSourceType.PaypalBalance:
      return fsTypeOption as PaypalBalanceFundingSourceTypeOption;
    case FundingSourceType.Card:
    default:
      return fsTypeOption as CardFundingSourceTypeOption;
  }
};

const getFundingSourceOption = (
  fundingSource: RecursivePartial<FundingSource>,
  fundingSourceTypesOptions?: FundingSourceTypesOption[]
) => {
  const { type, details } = fundingSource;
  if (!type || !fundingSourceTypesOptions) {
    return undefined;
  }

  const network = type === 'card' ? details?.network : undefined;
  return network
    ? fundingSourceTypesOptions.find((it) => it.type === type && it.subtype === network)
    : fundingSourceTypesOptions.find((it) => it.type === type);
};

export const isFundingSourceDisabled = (
  fundingSource: RecursivePartial<FundingSource>,
  fundingSourceTypesOptions?: FundingSourceTypesOption[]
) => !getFundingSourceOption(fundingSource, fundingSourceTypesOptions)?.supported;

export const getDisabledFundingSourceReason = (
  fundingSource: RecursivePartial<FundingSource>,
  fundingSourceTypesOptions?: FundingSourceTypesOption[]
): UnsupportedFundingSourceTypeReasons => getFundingSourceOption(fundingSource, fundingSourceTypesOptions)?.reason;

export const getDisabledFundingTypeReasons = (
  fundingType: RecursivePartial<FundingSourceType>,
  fundingSourceTypesOptions?: FundingSourceTypesOption[]
): UnsupportedFundingSourceTypeReasons[] | undefined => {
  const options = fundingSourceTypesOptions?.filter((it) => it.type === fundingType);
  if (!options || options.some((it) => it.supported)) {
    return undefined;
  }

  return [...new Set(options?.map((it) => it.reason))];
};

export const isFundingTypeSupported = (
  fundingType: FundingSourceType,
  fundingSourceTypesOptions: FundingSourceTypesOption[]
) => !!fundingSourceTypesOptions.find((it) => it.type === fundingType && it.supported);

export const getFundingSourceIconByType = ({
  fundingType,
  bankIcon,
  cardType,
}: {
  fundingType: FundingSource['type'];
  bankIcon: IconKey | BrandSymbolKey;
  cardType?: Card['type'];
}): IconKey | BrandSymbolKey => {
  switch (fundingType) {
    case 'bank-account':
      return bankIcon;
    case 'flex-account':
      return 'flex';
    case 'paypal-balance':
      return 'bank';
    case 'card': {
      if (cardType == 'debit') {
        return 'debit-card';
      } else {
        return 'credit-card';
      }
    }
  }
};

export const getFundingSourceIconType = (fundingSource: FundingSource): IconKey | BrandSymbolKey => {
  if (fundingSource.type === 'bank-account') {
    return 'bank';
  }
  if (fundingSource.type === 'flex-account') {
    return 'flex';
  }

  if (fundingSource.type === 'paypal-balance') {
    return 'bank';
  }

  if (fundingSource.details.network) {
    return getFundingSourceIconByNetwork(fundingSource.details.network);
  }
  return fundingSource.details.type == 'debit' ? 'debit-card' : 'credit-card';
};

export const useGetFundingSourceLabel = (fundingSource?: FundingSource) => {
  const { formatMessage } = useMelioIntl();

  if (!fundingSource) {
    return '';
  }

  switch (fundingSource.type) {
    case FundingSourceType.BankAccount:
      return formatMessage('utils.paymentSource.bankAccount.label');
    case FundingSourceType.FlexAccount:
      return formatMessage('utils.paymentSource.flexAccount.label');
    case FundingSourceType.PaypalBalance:
      return formatMessage(`utils.paymentSource.paypal-balance.label`);
    case FundingSourceType.Card:
      return formatMessage(`utils.paymentSource.${fundingSource.details.type}.label`);
  }
};

export const useGetFundingSourceCardHelperText = ({
  fundingSource,
  fee,
  onClick,
}: {
  fundingSource: FundingSource;
  fee?: string | JSX.Element;
  onClick?: VoidFunction;
}) => {
  const [verifyOnlyByCreator] = usePartnerFeature('verifyFundingSourceOnlyByCreator', false);
  const { data: expandedFundingSource } = useFundingSource({ id: fundingSource.id });
  const { data: actor } = useCollaborator({ id: 'me' });
  const { formatMessage } = useMelioIntl();
  const { type, isVerified, id: fundingSourceId, isBlocked } = fundingSource;
  const isVerifyingBankAccount = useIsPlaidLinkVerificationTokenFetching(fundingSourceId);

  const isFundingSourceCreator = fundingSource.createdById === actor?.userId;
  const fullName = [expandedFundingSource?.createdBy?.firstName, expandedFundingSource?.createdBy?.lastName]
    .join(' ')
    .trim();
  const addedBy = formatMessage('widgets.paymentMethods.paymentMethodLineItem.addedBy', {
    creator: fullName || formatMessage('widgets.paymentMethods.paymentMethodLineItem.addedBy.unknown'),
  });

  if (type === 'bank-account') {
    if (isVerified) {
      return fee
        ? formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.verified.helperText', {
            fee,
            addedBy,
            accountType: fundingSource.details.accountType,
          })
        : undefined;
    } else {
      if (isBlocked) {
        return formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.blocked.helperText', {
          addedBy,
        });
      }
      if (!onClick) {
        throw new Error('verify onClick is required for unverified bank account');
      }

      if (isFundingSourceCreator || !verifyOnlyByCreator) {
        return formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.helperText', {
          link: (
            <NakedButton
              variant="secondary"
              onClick={onClick}
              label={formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.link')}
              isDisabled={isVerifyingBankAccount}
              data-testid="payment-method-bank-account-verification-cta"
            />
          ),
          addedBy,
        });
      } else {
        return;
      }
    }
  } else if (type === 'flex-account') {
    return formatMessage('widgets.fundingSources.selectFundingSourceCard.flex.helperText');
  } else if (type === 'paypal-balance') {
    return formatMessage('widgets.fundingSources.selectFundingSourceCard.paypal-balance.helperText');
  } else if (fundingSource.details?.type == 'credit') {
    return formatMessage('widgets.paymentMethods.paymentMethodLineItem.credit.helperText', { fee, addedBy });
  } else if (fundingSource.details?.type == 'debit') {
    return formatMessage('widgets.paymentMethods.paymentMethodLineItem.debit.helperText', { fee, addedBy });
  }

  return `Missing Description for fundingType: ${fundingSource.type}`;
};

export const availableBalanceUpdatedAtFormat: FormatDate['arguments'] = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/New_York',
  timeZoneName: 'short',
};

export function getFundingSourceBalance(
  fundingSource: Pick<FundingSource, 'availableBalance' | 'availableBalanceUpdatedAt'>
) {
  const ACCOUNT_BALANCE_UPDATE_THRESHOLD = 24;

  if (
    !isNil(fundingSource.availableBalance) &&
    !!fundingSource.availableBalanceUpdatedAt &&
    // eslint-disable-next-line no-restricted-syntax
    differenceInHours(new Date(), fundingSource.availableBalanceUpdatedAt) < ACCOUNT_BALANCE_UPDATE_THRESHOLD
  ) {
    return {
      availableBalance: fundingSource.availableBalance,
      availableBalanceUpdatedAt: fundingSource.availableBalanceUpdatedAt,
    };
  }

  return null;
}
