import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { usePayDashboardPagination } from './components/Pagination';
import { FilterState, PayDashboardFilterType } from './types';

const FilterContext = createContext<PayDashboardFilterType | undefined>(undefined);
export const usePayDashboardFilters = () => useContext(FilterContext);

export const PayDashboardFilterProvider = ({
  children,
  urlSearchParams,
  shouldInitializeProvider = false,
  supportedSearchParamKeys,
}: {
  children: ReactNode;
  urlSearchParams?: URLSearchParams;
  shouldInitializeProvider: boolean;
  supportedSearchParamKeys: readonly string[];
}) => {
  const [filters, setFilters] = useState<FilterState>({});
  const [nonFilterSearchParams, setNonFilterSearchParams] = useState<{
    [key: string]: string;
  }>({});
  const { resetToFirstPage } = usePayDashboardPagination();

  useEffect(() => {
    if (!shouldInitializeProvider) {
      return;
    }
    const initialFilters: FilterState = {};
    const initialNonFilterSearchParams: {
      [key: string]: string;
    } = {};
    if (urlSearchParams?.size) {
      urlSearchParams.forEach((value, key) => {
        if (supportedSearchParamKeys.includes(key)) {
          initialFilters[key] = value.split(',');
        } else {
          initialNonFilterSearchParams[key] = value;
        }
      });
    }

    setFilters(initialFilters);
    setNonFilterSearchParams(initialNonFilterSearchParams);
  }, [shouldInitializeProvider, urlSearchParams, supportedSearchParamKeys]);

  useEffect(() => {
    if (!shouldInitializeProvider) {
      return;
    }
    const params = new URLSearchParams();
    Object.entries(nonFilterSearchParams).forEach(([key, value]) => {
      params.set(key, value);
    });
    Object.entries(filters).forEach(([key, values]) => {
      params.set(key, values.join(','));
    });

    const newQueryParams = `?${params.toString()}`;
    if (newQueryParams !== window.location.search) {
      window.history.pushState({}, '', newQueryParams);
    }
  }, [filters, nonFilterSearchParams, shouldInitializeProvider]);

  const applyAllFilters = (newFilters: Record<string, string[] | undefined>) => {
    const newFiltersExcludedEmpty: FilterState = {};
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value && value.length > 0) {
        newFiltersExcludedEmpty[key] = value;
      }
    });
    setFilters(newFiltersExcludedEmpty);
    resetToFirstPage();
  };

  const applyFilter = (key: string, value: string[]) => {
    if (value.length === 0) {
      setFilters((prev) => {
        const prevFilters = { ...prev };
        delete prevFilters[key];
        return prevFilters;
      });
    } else {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
    resetToFirstPage();
  };

  const hasActiveFilters = useMemo(() => {
    if (!filters) {
      return false;
    }
    return Object.keys(filters).filter((key) => filters[key]).length > 0;
  }, [filters]);

  const resetFilters = () => {
    setFilters({});
    resetToFirstPage();
  };

  return (
    <FilterContext.Provider value={{ filters, applyFilter, applyAllFilters, resetFilters, hasActiveFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
