/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AccountingPlatform,
  BillLineItem,
  BillLineItemType,
  CategoryBasedBillLineItem,
  isXeroAccountingPlatform,
  ItemBasedBillLineItem,
  PostBillsRequest,
} from '@melio/platform-api';

import {
  AddBillV2FormFrequency,
  AddBillV2FormLineItem,
  AddBillV2FormValuesResult,
  BillLineItemType as FormBillLineItemType,
  ItemBasedBillLineItem as FormItemBasedBillLineItem,
} from '../AddBillV2Form/types';

export function mapFormBillToCreateApi(
  formValues: AddBillV2FormValuesResult,
  activeAccountingPlatform?: AccountingPlatform
): PostBillsRequest {
  const mapped: BillLineItem[] = [];
  mapped.push(
    ...mapFormLineItemToApiLineItemBySoftwareAccount(formValues.lineItems, mapped.length, activeAccountingPlatform)
  );

  return {
    note: formValues.noteToSelf,
    amount: parseFloat(formValues.amount!),
    vendorId: formValues.vendorId!,
    invoice: {
      number: formValues.invoiceNumber,
      fileId: formValues.fileId,
      ...(formValues.invoiceDate ? { date: formValues.invoiceDate } : {}),
    },
    ...(formValues.currency ? { currency: formValues.currency } : {}),
    dueDate: formValues.dueDate!.toISOString(),
    lineItems: mapped,
    externalLabelId: formValues.externalLabelId,
  };
}

export function mapFormLineItemToApiLineItemBySoftwareAccount(
  lines: AddBillV2FormLineItem[],
  startIndex: number,
  activeAccountingPlatform?: AccountingPlatform
) {
  const xeroAccountingPlatform = isXeroAccountingPlatform(activeAccountingPlatform);

  if (xeroAccountingPlatform) {
    return mapXeroFormLineItemsToPostAPI(lines, startIndex);
  }

  return mapFormLineItemsToPostAPI(lines, startIndex);
}

export function mapXeroFormLineItemsToPostAPI(lines: AddBillV2FormLineItem[], startIndex: number): BillLineItem[] {
  return (lines || [])
    .filter((l) => l.amount != null)
    .map((line, arrIndex) => mapXeroFormLineItemToApiLineItem(line, startIndex + arrIndex + 1));
}

export function mapFormLineItemsToPostAPI(lines: AddBillV2FormLineItem[], startIndex: number): BillLineItem[] {
  return (lines || [])
    .filter((l) => l.amount != null)
    .map((line, arrIndex) => mapFormLineItemToApiLineItem(line, startIndex + arrIndex + 1));
}

export function mapFormLineItemToApiLineItem(line: AddBillV2FormLineItem, order?: number): BillLineItem {
  if (isItemBasedLineItemForForm(line)) {
    return {
      ...line,
      amount: parseFloat(line.amount!),
      unitPrice: parseFloat(line.unitPrice),
      quantity: parseFloat(line.quantity),
      order,
    } as ItemBasedBillLineItem;
  } else {
    return {
      externalCategoryId: null,
      ...line,
      amount: parseFloat(line.amount!),
      order,
    } as CategoryBasedBillLineItem;
  }
}

export function mapXeroFormLineItemToApiLineItem(line: AddBillV2FormLineItem, order?: number): BillLineItem {
  return {
    ...line,
    type: line.externalItemId ? BillLineItemType.Item : BillLineItemType.Category,
    quantity: parseFloat(line?.quantity as string),
    amount: parseFloat(line.amount!),
    unitPrice: parseFloat(line.unitPrice!),
    order,
  } as unknown as BillLineItem;
}

export function isCategoryBasedLineItem(lineItem: BillLineItem): lineItem is CategoryBasedBillLineItem {
  return lineItem.type === BillLineItemType.Category;
}
export function isItemBasedLineItem(lineItem: BillLineItem): lineItem is ItemBasedBillLineItem {
  return lineItem.type === BillLineItemType.Item;
}
export function isItemBasedLineItemForForm(lineItem: AddBillV2FormLineItem): lineItem is FormItemBasedBillLineItem {
  return lineItem.type === FormBillLineItemType.ITEM;
}

export function isSinglePaymentFields(frequency?: AddBillV2FormFrequency) {
  return frequency === AddBillV2FormFrequency.ONE_TIME;
}
