import { Box } from '@chakra-ui/react';
import { Divider, Group, Icon, StatusIconSolid, Text, Tooltip } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { cloneElement, ComponentProps, PropsWithChildren, ReactElement, ReactNode } from 'react';

type MethodCardProps = PropsWithChildren<{
  displayName: string | ReactNode;

  actionElement?: ReactElement;
  icon?: ReactElement;
  label?: string | ReactNode;
  helperText?: string | ReactNode;
  ariaDisabled?: boolean;
  testIds?: { root?: string; description?: string; header?: string };
  tooltip?: ComponentProps<typeof Header>['tooltip'];
  description?: string;
}>;

export const MethodCard = forwardRef(
  (
    {
      displayName,

      icon,
      label,
      helperText,
      children,
      actionElement,
      description,
      tooltip,
      ariaDisabled,
      testIds,
    }: MethodCardProps,
    ref
  ) => {
    const ariaDisabledValue = ariaDisabled || undefined;
    return (
      <Box
        padding="m"
        border="basic.black"
        borderColor="semantic.border.static"
        borderRadius="global.200"
        data-testid={testIds?.root ?? 'MethodCard'}
        data-component="MethodCard"
        data-disabled={ariaDisabledValue}
        ref={ref}
      >
        <Group justifyContent="space-between" alignItems="center">
          <Group spacing="s">
            {icon ? (
              <Box role="img" alignContent="center" aria-label="logo" data-disabled={ariaDisabledValue}>
                {cloneElement(icon, { size: 'medium' })}
              </Box>
            ) : null}
            <Group spacing="none" data-disabled={ariaDisabledValue}>
              <Group variant="vertical" spacing="xs">
                <Group variant="vertical" spacing="none">
                  <Header main={displayName} label={label} tooltip={tooltip} headerTestId={testIds?.header} />
                  {helperText && (
                    <Text data-testid="method-card-helperText" textStyle="body3" color="semantic.text.secondary">
                      {helperText}
                    </Text>
                  )}
                </Group>
                {description && (
                  <Text
                    data-testid={testIds?.description ?? 'method-card-description'}
                    textStyle="body3"
                    color="semantic.text.secondary"
                  >
                    {description}
                  </Text>
                )}
              </Group>
            </Group>
          </Group>
          {actionElement ? <Box flexShrink={0}>{actionElement}</Box> : null}
        </Group>
        {children && (
          <Group spacing="none" variant="vertical">
            <Box paddingY="14">
              <Divider />
            </Box>
            {children}
          </Group>
        )}
      </Box>
    );
  }
);

MethodCard.displayName = 'MethodCard';

function Header(props: {
  main: string | ReactNode;
  label?: string | ReactNode;
  tooltip: { type: 'warning' | 'neutral'; text: string } | undefined;
  headerTestId?: string;
}) {
  const header = (
    <Group spacing="xxs" alignItems="center">
      <Text data-testid={props.headerTestId} textStyle="body2Semi">
        {props.main}
      </Text>
      {!!props.tooltip && (
        <Tooltip content={props.tooltip.text}>
          {props.tooltip.type === 'warning' ? (
            <StatusIconSolid data-testid="trigger" variant="warning" size="small" />
          ) : (
            <Icon data-testid="trigger" type="info" size="small" />
          )}
        </Tooltip>
      )}
    </Group>
  );
  return (
    <>
      {props.label && (
        <Text textStyle="body4Semi" color="semantic.text.secondary">
          {props.label}
        </Text>
      )}
      {header}
    </>
  );
}
