import { createQueryKey, useNonEntityMutation } from '@melio/api-client';
import {
  AccountingPlatformsApiClient,
  PostAccountingPlatformsVendorsMappingsRequest,
} from '@melio/platform-api-axios-client';

export type VendorsMappingsEntities = PostAccountingPlatformsVendorsMappingsRequest['entities'];

export const useAccountingPlatformVendorsMappings = () =>
  useNonEntityMutation(
    AccountingPlatformsApiClient.postAccountingPlatformVendorsMappings,
    createQueryKey({ queryKey: 'AccountingPlatformsApi', role: 'model', scope: 'default' })
  );
