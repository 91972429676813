/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ApiError,
  DeliveryMethod,
  DeliveryPreference,
  FundingSource,
  PaymentFee,
  useFeesBreakdown,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

type PaymentFeesProps = {
  fundingSource?: FundingSource | null;
  deliveryMethod?: DeliveryMethod;
  paymentAmount?: number | null;
  deliveryPreference?: DeliveryPreference;
  paymentId?: string;
  isFinancing: boolean;
  billIds?: Array<string>;
};

type ValidatedPaymentIntent = {
  fundingSource: FundingSource;
  deliveryMethod: DeliveryMethod;
  paymentAmount: number;
  deliveryPreference: DeliveryPreference;
  paymentId?: string;
  isFinancing: boolean;
  billIds?: Array<string>;
};

export const usePaymentFeesBreakdown = (
  feesBreakdownParams: PaymentFeesProps,
  estimatedFees?: PaymentFee[]
): { paymentFees: PaymentFee[]; isLoading: boolean; error: ApiError<string> | null } => {
  const [shouldUseFeesBreakdownApi] = useDevFeature(FeatureFlags.CheckFeesPromotionEnabled, false);

  const isValidParams = (params: PaymentFeesProps): params is ValidatedPaymentIntent =>
    Boolean(
      params?.fundingSource?.type && params?.deliveryMethod?.type && params?.paymentAmount && params?.deliveryPreference
    );

  const enabled = shouldUseFeesBreakdownApi && isValidParams(feesBreakdownParams);

  const {
    data: feesBreakdown,
    isLoading: isLoadingFeesBreakdown,
    error,
  } = useFeesBreakdown({
    payload: {
      feesBreakdownParams: enabled
        ? [
            {
              deliveryMethodType: feesBreakdownParams.deliveryMethod.type,
              deliveryPreference: feesBreakdownParams.deliveryPreference.type,
              fundingSourceType: feesBreakdownParams.fundingSource.type,
              paymentAmount: feesBreakdownParams.paymentAmount,
              paymentId: feesBreakdownParams.paymentId,
              deliveryMethodId: feesBreakdownParams.deliveryMethod.id,
              fundingSourceId: feesBreakdownParams.fundingSource.id,
              isFinancing: feesBreakdownParams.isFinancing,
              billIds: feesBreakdownParams.billIds || [],
            },
          ]
        : [],
    },
    enabled,
  });

  if (!shouldUseFeesBreakdownApi) {
    return { paymentFees: estimatedFees || [], isLoading: false, error: null };
  }

  const paymentFees: PaymentFee[] = (feesBreakdown?.feesBreakdown || []).map((fee) => ({
    type: fee.type,
    amount: fee.amountToPay,
  }));

  return { paymentFees, isLoading: isLoadingFeesBreakdown, error };
};
