import { Icon } from '@melio/penny';
import { PaypalBalanceAccountDeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { MethodCard } from '../../../../MethodCard/MethodCard.widget';
import { AddDeliveryMethodActionButton, DeliveryMethodActionsMenu } from '../components/DeliveryMethodActions';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';

type Props = {
  paypalBalanceAccount: PaypalBalanceAccountDeliveryMethod | null;
  onClick: VoidFunction;
};

const useCaptionProps = (paypalAccount: PaypalBalanceAccountDeliveryMethod | null) => {
  const { formatMessage } = useMelioIntl();
  if (paypalAccount) {
    return {
      primary: paypalAccount.details.paypalAccountEmail,
    };
  }

  return {
    secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.paypal-balance.comment'),
  };
};

export function PaypalBalanceDeliveryMethodCard({ paypalBalanceAccount, onClick }: Props) {
  const { formatMessage } = useMelioIntl();
  const captionProps = useCaptionProps(paypalBalanceAccount);
  return (
    <MethodCard
      icon={<Icon type="paypal" />}
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.paypal-balance.title')}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.paypal-balance.feeText')}
      actionElement={
        paypalBalanceAccount ? (
          <DeliveryMethodActionsMenu
            actions={[
              {
                label: formatMessage('widgets.addOrUpdateDeliveryMethod.edit'),
                onClick,
                dataTestId: 'edit-delivery-method-item',
              },
            ]}
          />
        ) : (
          <AddDeliveryMethodActionButton
            onClick={onClick}
            aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.paypal-balance.add.ariaLabel')}
            data-testid="add-paypal-balance-delivery-method"
          />
        )
      }
    >
      <DeliveryMethodCaption {...captionProps} />
    </MethodCard>
  );
}
