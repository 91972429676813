import { PartnerGroupEnum } from '@melio/partner-tools';
import { Divider, Grid, Group, useTheme } from '@melio/penny';

import { usePartnerGroup } from '../../../../hooks';
import { PlanCardButton } from './PlanCardButton';
import { PlanCardButtonProps } from './PlanCardButton/PlanCardButton';
import { PlanCardFeaturesList, PlanCardFeaturesListProps } from './PlanCardFeaturesList';
import { PlanCardHeader } from './PlanCardHeader';
import { PlanCardHeaderProps } from './PlanCardHeader/PlanCardHeader';
import { PlanCardPrice, PlanCardPriceProps } from './PlanCardPrice';

export type PlanCardViewProps = {
  id: string;
  headerProps: PlanCardHeaderProps;
  priceProps: PlanCardPriceProps;
  selectPlanButtonProps?: PlanCardButtonProps;
  featureListProps: PlanCardFeaturesListProps;
  shouldRenderMostPopularVariation?: boolean;
  shouldRenderCurrentPlanVariation?: boolean;
  onClick?: VoidFunction;
  withOnboardingPlansExperimentOn?: boolean;
};

type GetStyleProps = {
  shouldRenderCurrentPlanVariation?: boolean;
  shouldRenderMostPopularVariation?: boolean;
  withOnboardingPlansExperimentOn?: boolean;
};

const getStyle = ({
  shouldRenderCurrentPlanVariation,
  shouldRenderMostPopularVariation,
  withOnboardingPlansExperimentOn,
}: GetStyleProps) => {
  if (shouldRenderCurrentPlanVariation) {
    return {
      border: 'none',
      outline: '2px solid',
      backgroundColor: 'initial',
      outlineColor: 'global.brand.700',
    };
  }
  if (shouldRenderMostPopularVariation) {
    return {
      border: '1px transparent',
      outline: withOnboardingPlansExperimentOn ? '2px solid' : '1px solid',
      backgroundColor: 'global.brand.100',
      outlineColor: withOnboardingPlansExperimentOn ? 'global.brand.700' : 'global.brand.200',
    };
  }

  return {
    border: '1px transparent',
    outline: '1px solid',
    backgroundColor: 'initial',
    outlineColor: 'global.neutral.400',
  };
};

export const PlanCardView = ({
  id,
  shouldRenderCurrentPlanVariation,
  shouldRenderMostPopularVariation,
  headerProps,
  priceProps,
  selectPlanButtonProps,
  featureListProps,
  onClick,
  withOnboardingPlansExperimentOn,
}: PlanCardViewProps) => {
  const { borderRadii } = useTheme();

  const { border, outline, backgroundColor, outlineColor } = getStyle({
    shouldRenderCurrentPlanVariation,
    shouldRenderMostPopularVariation,
    withOnboardingPlansExperimentOn,
  });

  const partnerGroup = usePartnerGroup();

  const showUnitPriceDetails = partnerGroup !== PartnerGroupEnum.FISERV && !withOnboardingPlansExperimentOn;
  const gridItemsNumber = showUnitPriceDetails ? 7 : 6;

  return (
    <Grid
      borderRadius={borderRadii.global[200]}
      backgroundColor={backgroundColor}
      border={border}
      outline={outline}
      outlineOffset="-2px"
      boxSizing="border-box"
      outlineColor={outlineColor}
      gridTemplateRows="subgrid"
      gridRow={`auto / span ${gridItemsNumber}`}
      p="m"
      gap="l"
      data-testid={`subscription-plan-card-${id}`}
      cursor={onClick ? 'pointer' : 'initial'}
      onClick={onClick}
    >
      <PlanCardHeader {...headerProps} />
      <PlanCardPrice {...priceProps} showUnitPriceDetails={showUnitPriceDetails} />

      <Group variant="vertical" spacing="s">
        {selectPlanButtonProps && <PlanCardButton {...selectPlanButtonProps} />}
        <Divider />
      </Group>

      <PlanCardFeaturesList {...featureListProps} />
    </Grid>
  );
};
