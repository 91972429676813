import { SortDirection, SortingState } from '@melio/penny';
import { Dispatch, SetStateAction } from 'react';

export enum PayDashboardTabs {
  Vendors = 'vendors',
  Bills = 'bills',
  Payments = 'payments',
  Approvals = 'approvals',
  Financing = 'financing',
}

export type ExpandedSortingState = SortingState & { disableSaveforNextTime?: boolean };

export type PayDashboardSortingType = {
  sortingState?: ExpandedSortingState;
  setSortState: Dispatch<SetStateAction<ExpandedSortingState | undefined>>;
  isLoaded: boolean;
};

export type PayDashboardTabSortableColumn<T extends string> = {
  label: string;
  cellId: T;
  defaultSortOrder: SortDirection;
};

export type FilterState = {
  [key: string]: string[];
};

export type PayDashboardFilterType = {
  filters?: FilterState;
  applyFilter: (key: string, value: string[]) => void;
  applyAllFilters: (newFilters: Record<string, string[] | undefined>) => void;
  resetFilters: () => void;
  hasActiveFilters: boolean;
};
