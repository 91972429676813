import { Avatar, Container, Divider, Group, Icon, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

type MsnBusinessPreviewCardProps = {
  name: string;
  contact: {
    name?: string;
    email?: string;
    phoneNumber?: string;
    address?: string;
  };
};

type LabeledTextProps = {
  label: string;
  text: string;
};

const LabeledText = ({ label, text }: LabeledTextProps) => (
  <Group spacing="xxs" variant="vertical">
    <Text textStyle="body4Semi" color="semantic.text.secondary">
      {label}
    </Text>
    <Text textStyle="body2" color="semantic.text.secondary">
      {text}
    </Text>
  </Group>
);

export const MsnBusinessPreviewCard = ({ name, contact }: MsnBusinessPreviewCardProps) => {
  const { formatMessage } = useMelioIntl();

  const { name: contactName, address, email, phoneNumber } = contact;
  const hasDetails = !!contactName || !!address || !!email || !!phoneNumber;

  return (
    <Container border="regular" paddingX="l" paddingY="l">
      <Group spacing="s" variant="horizontal" alignItems="center">
        <Avatar name={name} badge={<Icon type="verified" size="small" color="brand" />} />
        <Text textStyle="body2Semi">{name}</Text>
      </Group>
      {hasDetails && (
        <>
          <Container paddingY="l">
            <Divider />
          </Container>
          <Group spacing="m" variant="vertical">
            <Text textStyle="body4SemiUpper">{formatMessage('widgets.msnBusinessPreviewCard.title')}</Text>
            {address && (
              <LabeledText label={formatMessage('widgets.msnBusinessPreviewCard.label.address')} text={address} />
            )}
            {email && <LabeledText label={formatMessage('widgets.msnBusinessPreviewCard.label.email')} text={email} />}
            {contactName && (
              <LabeledText label={formatMessage('widgets.msnBusinessPreviewCard.label.name')} text={contactName} />
            )}
            {phoneNumber && (
              <LabeledText
                label={formatMessage('widgets.msnBusinessPreviewCard.label.phoneNumber')}
                text={phoneNumber}
              />
            )}
          </Group>
        </>
      )}
    </Container>
  );
};
