import { Collaborator, RoleUniqueNames } from '@melio/platform-api';

export const TERMS_AND_CONDITIONS_ROUTE = '/terms-of-service';

const ALLOWED_NOT_SIGNED_TERMS_AND_CONDITIONS_ROUTES = [TERMS_AND_CONDITIONS_ROUTE];

export const isRouteAllowedIfTermsAndConditionsNotSigned = (pathname: string) =>
  isRouteAllowed(ALLOWED_NOT_SIGNED_TERMS_AND_CONDITIONS_ROUTES, pathname);

const isRouteAllowed = (whiteList: string[], pathname: string) =>
  whiteList.some((route) => new RegExp(`^${route}(/.*)?$`).test(pathname));

export const isFiservOwner = (actor: Collaborator): boolean => actor.roleUniqueName === RoleUniqueNames.FiservLevel1;
