import { DSLSearchQuery } from '@melio/api-client';
import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemsApiGetInboxItemsRequest,
  InboxItemScannedInvoiceTypeEnum,
  useInboxItems,
} from '@melio/platform-api';
import { Search } from '@melio/platform-api/src/entities/inbox-item/types';

import { ExpandedSortingState } from '../../types';
import { usePayDashboardPagination } from '../Pagination';
import { BillsTabItem } from './types';
import { convertBillsTabSortToApiSort } from './utils';

export const useBillsTabItems = ({
  enabled,
  searchTerm,
  scope,
  sort,
  search,
  params,
}: {
  enabled: boolean;
  searchTerm?: string | null;
  scope?: string;
  sort: ExpandedSortingState | null;
  search?: DSLSearchQuery<Search>;
  params?: InboxItemsApiGetInboxItemsRequest;
}) => {
  const { pageSize } = usePayDashboardPagination();

  return useInboxItems<BillsTabItem>({
    enabled,
    cacheTime: 0,
    staleTime: 0,
    scope,
    params: {
      limit: pageSize,
      sort: sort ? convertBillsTabSortToApiSort(sort) : undefined,
      search: search || undefined,
      inboxItemTypes: params?.inboxItemTypes
        ? params.inboxItemTypes
        : [
            InboxItemBillTypeEnum.Bill,
            InboxItemPaymentRequestTypeEnum.PaymentRequest,
            InboxItemScannedInvoiceTypeEnum.ScannedInvoice,
          ],
      ...(searchTerm ? { searchTerm } : {}),
    },
  });
};
