import { FormattedCurrency, FormattedMessage, MessageKey, useMelioIntl, useMonitoring } from '@melio/ar-domain';
import { FileAttachment, Group, StatusIconSolid, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { BaseLayout, BaseLayoutProps } from '../../../layout';

export type GuestPaymentConfirmationScreenProps = {
  amount: number;
  invoicePreviewUrl?: string;
  onViewInvoice: VoidFunction;
  debitDate: Date;
  deliveryDate: Date;
  totalFeeAmount: number;
} & Pick<PaymentConfirmationDescriptionProps, 'invoiceNumber' | 'vendorName' | 'fundingSourceDisplayData'> &
  Pick<BaseLayoutProps, 'backgroundColor'>;

type PaymentConfirmationDescriptionProps = {
  vendorName: string;
  invoiceNumber: string;
  fundingSourceDisplayData: string;
  formattedDebitDate: string;
  formattedDeliveryDate: string;
  formattedFee?: string;
};

type ConfirmationItem = {
  messageKey: MessageKey;
  values: Record<string, unknown>;
};

const PaymentConfirmationDetails = ({
  vendorName,
  invoiceNumber,
  fundingSourceDisplayData,
  formattedDebitDate,
  formattedDeliveryDate,
  formattedFee,
}: PaymentConfirmationDescriptionProps) => {
  const formattedFeeConfirmationItem: ConfirmationItem = {
    messageKey: 'ar.guestPayment.successfulPayment.paymentConfirmation.fees',
    values: { formattedFee },
  };
  const confirmationItems: ConfirmationItem[] = [
    {
      messageKey: 'ar.guestPayment.successfulPayment.paymentConfirmation.vendorName',
      values: { vendorName },
    },
    {
      messageKey: 'ar.guestPayment.successfulPayment.paymentConfirmation.invoiceNumber',
      values: { invoiceNumber },
    },
    {
      messageKey: 'ar.guestPayment.successfulPayment.paymentConfirmation.paymentMethod',
      values: { paymentMethod: fundingSourceDisplayData },
    },
    {
      messageKey: 'ar.guestPayment.successfulPayment.paymentConfirmation.debitDate',
      values: { debitDate: formattedDebitDate },
    },
    {
      messageKey: 'ar.guestPayment.successfulPayment.paymentConfirmation.deliveryDate',
      values: { deliveryDate: formattedDeliveryDate },
    },
    ...(formattedFee ? [formattedFeeConfirmationItem] : []),
  ];

  return (
    <Group variant="vertical" spacing="xs">
      {confirmationItems.map(({ messageKey, values }, i) => (
        <Text textStyle="body3" key={i}>
          <FormattedMessage id={messageKey} values={values} />
        </Text>
      ))}
    </Group>
  );
};

export const GuestPaymentConfirmationScreen = forwardRef<GuestPaymentConfirmationScreenProps>(
  (
    {
      amount,
      invoicePreviewUrl,
      onViewInvoice,
      debitDate,
      deliveryDate,
      totalFeeAmount,
      invoiceNumber,
      vendorName,
      fundingSourceDisplayData,
      ...props
    },
    ref
  ) => {
    const { formatCurrency, formatDate } = useMelioIntl();
    useAnalyticsView('PaymentRequest', true, true, {
      PageName: 'invoice-paid',
      Intent: 'payment-confirmation',
    });

    const { endAction } = useMonitoring();

    useEffect(() => {
      endAction('pay');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <BaseLayout
        data-component={GuestPaymentConfirmationScreen.displayName}
        data-testid="guest-payment-confirmation-screen"
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="l" hasDivider>
          <Group justifyContent="space-between">
            <Group variant="vertical" justifyContent="space-between">
              <StatusIconSolid variant="success" size="extra-large" />
              <Text textStyle="heading3Semi" color="global.neutral.1000">
                <FormattedMessage id="ar.guestPayment.successfulPayment.paymentCompleted.title" />
              </Text>
              <Text data-testid="payment-confirmation-amount" textStyle="heading1Semi">
                <FormattedCurrency amount={amount} />
              </Text>
            </Group>
            {invoicePreviewUrl && (
              <FileAttachment onViewModeClick={onViewInvoice} value={invoicePreviewUrl} isViewMode width={90} />
            )}
          </Group>
          <PaymentConfirmationDetails
            vendorName={vendorName}
            invoiceNumber={invoiceNumber}
            fundingSourceDisplayData={fundingSourceDisplayData}
            formattedDebitDate={formatDate(debitDate)}
            formattedDeliveryDate={formatDate(deliveryDate)}
            formattedFee={totalFeeAmount ? formatCurrency(totalFeeAmount) : undefined}
          />
        </Group>
      </BaseLayout>
    );
  }
);

GuestPaymentConfirmationScreen.displayName = 'GuestPaymentConfirmationScreen';
