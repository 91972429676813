import { FormDialog, FormDialogWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, Text, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { FeeAbsorptionSettings as FeeAbsorptionSettingsFormFields } from '../types';

export type GlobalFeeAbsorptionSettingsModalScreenProps = FormDialogWidgetProps<
  FeeAbsorptionSettingsFormFields,
  {
    isOpen: boolean;
    onClose: VoidFunction;
  }
>;

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    feesPaidBy: string().required(
      formatMessage(
        'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.required.label'
      )
    ),
  }) as SchemaOf<FeeAbsorptionSettingsFormFields>;
};

export const GlobalFeeAbsorptionSettingsModalScreen = forwardRef<GlobalFeeAbsorptionSettingsModalScreenProps>(
  ({ isOpen, onClose, onSubmit, defaultValues, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, watch, setError, ...useFormResults } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues,
      isSaving,
    });

    return (
      <FormDialog
        data-testid="global-fee-absorption-settings-modal-screen"
        data-component={GlobalFeeAbsorptionSettingsModalScreen.displayName}
        isOpen={isOpen}
        size="large"
        modalSize="medium"
        onClose={onClose}
        header={formatMessage(
          'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.title.text'
        )}
        primaryButton={{
          variant: 'primary',
          label: formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.primaryButton.label'
          ),
        }}
        secondaryButton={{
          variant: 'tertiary',
          label: formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.secondaryButton.label'
          ),
        }}
        useFormResults={useFormResults}
        {...props}
        ref={ref}
      >
        <Group spacing="m" variant="vertical">
          <Text textStyle="body2">
            {formatMessage(
              'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.description.text'
            )}
          </Text>
          <Form.RadioGroup
            variant="vertical"
            options={[
              {
                value: 'payor',
                mainLabelProps: {
                  label: formatMessage(
                    'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.payor.main.label'
                  ),
                },
                descriptionProps: {
                  label: formatMessage(
                    'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.payor.description.label'
                  ),
                },
              },
              {
                value: 'vendor',
                mainLabelProps: {
                  label: formatMessage(
                    'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.vendor.main.label'
                  ),
                },
                descriptionProps: {
                  label: formatMessage(
                    'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.vendor.description.label'
                  ),
                },
              },
            ]}
            {...registerField('feesPaidBy')}
          />
        </Group>
      </FormDialog>
    );
  }
);

GlobalFeeAbsorptionSettingsModalScreen.displayName = 'GlobalFeeAbsorptionSettingsModalScreen';
