import { Container, Group, SectionBanner, Text, useBreakpointValue } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { formatAddress, MsnBusinessItem } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import {
  PromoteAccountingSoftwareSyncBanner,
  useShowPromoteAccountingSoftwareSyncBanner,
} from '../../../promote-accounting-software-sync';
import { FooterContent } from '../../components/FooterContent';
import { HeaderContent } from '../../components/HeaderContent';
import { BusinessSearchForm, BusinessSearchFormProps } from './components/BusinessSearchForm';
import { MsnBusinessPreviewCard } from './components/MsnBusinessPreviewCard.widget';
import { formatPhone } from './utils';

type SearchBusinessScreenProps = BusinessSearchFormProps & {
  onClose: VoidFunction;
  stepCompleted: boolean;
  selectedMsnBusinessItem?: MsnBusinessItem;
};

export const SearchBusinessScreen = withAnalyticsContext<SearchBusinessScreenProps>(
  ({
    setAnalyticsProperties,
    onSelectOption,
    onCreateOption,
    onClearField,
    stepCompleted,
    onClose,
    selectedMsnBusinessItem,
    ...props
  }) => {
    const { formatMessage } = useMelioIntl();
    const isMobile = useBreakpointValue({ xs: true, s: false });
    const [isNewBusinessesSearchEnabled] = usePartnerFeature('NewBusinessesSearch', false);

    setAnalyticsProperties({
      PageName: 'search-vendor',
      Intent: 'add-a-vendor',
    });

    const { track } = useAnalytics();
    const { viewAnalyticsProps } = useShowPromoteAccountingSoftwareSyncBanner({ variant: 'vendor' });

    useEffect(() => {
      track('Vendor', 'View', viewAnalyticsProps);
    }, [track, viewAnalyticsProps]);

    return (
      <NewSinglePaymentStepLayout
        data-component="AddVendorActivity.SearchBusinessScreen"
        data-testid="add-vendor-activity-search-business-screen"
        isStickyFooter
        headerContent={<HeaderContent onClose={onClose} />}
        footerContent={<FooterContent onClose={onClose} isButtonsDisabled />}
        {...props}
      >
        {isMobile ? null : (
          <NewSinglePaymentStepLayout.Title textAlign="start" autoFocus={!stepCompleted}>
            {formatMessage('activities.addVendor.screens.addVendorDetails.title')}
          </NewSinglePaymentStepLayout.Title>
        )}
        <PromoteAccountingSoftwareSyncBanner
          variant="vendor"
          analyticsProps={{
            EntryPoint: 'dashboard-vendors',
            Flow: 'dashboard-user-message',
            PageName: 'add-vendor',
          }}
        />
        <NewSinglePaymentStepLayout.Description
          as="p"
          paddingTop={isMobile ? 'xs' : undefined}
          textStyle={isMobile ? 'body3' : undefined}
          textAlign="start"
        >
          {isNewBusinessesSearchEnabled
            ? formatMessage('activities.addVendor.searchAndAddVendorDetails.description.msn')
            : formatMessage('activities.addVendor.searchAndAddVendorDetails.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" spacing="xs" width="full">
            <Text color="global.neutral.900" textStyle={isMobile ? 'caption1' : 'body4'}>
              {formatMessage('activities.addVendor.searchAndAddVendorDetails.requiredFields')}
            </Text>
            <BusinessSearchForm
              onSelectOption={onSelectOption}
              onCreateOption={onCreateOption}
              onClearField={onClearField}
              stepCompleted={stepCompleted}
            />
            {selectedMsnBusinessItem && (
              <Container paddingTop="m">
                <Group variant="vertical" spacing="m">
                  <SectionBanner
                    description={formatMessage('activities.addVendor.searchAndAddVendorDetails.sectionBanner.msn')}
                  />
                  <MsnBusinessPreviewCard
                    name={selectedMsnBusinessItem.business.name ?? ''}
                    contact={{
                      name: selectedMsnBusinessItem.business.contact?.name,
                      email: selectedMsnBusinessItem.business.contact?.email,
                      phoneNumber: formatPhone(selectedMsnBusinessItem.business.contact?.phoneNumber),
                      address: selectedMsnBusinessItem.business.contact?.address
                        ? formatAddress(selectedMsnBusinessItem.business.contact.address)
                        : '',
                    }}
                  />
                </Group>
              </Container>
            )}
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

SearchBusinessScreen.displayName = 'AddVendorActivity.SearchBusinessScreen';
