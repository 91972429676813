// eslint-disable-next-line no-restricted-imports
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

import {
  ApiError,
  ApiQueryKey,
  EntityWithId,
  MutationCallbackOptions,
  MutationContext,
  OnMutationSuccess,
  UseModelApiResult,
} from './types';
import { useMelioQueryClient } from './useMelioQueryClient';
import { useOnUpdateError, useOnUpdateMutate } from './util';

type UseUpdateMutationOptions<TData, TVariables> = MutationCallbackOptions<TData, TVariables> & {
  optimisticUpdate?: boolean;
};

export function useUpdateMutation<TData extends EntityWithId, TVariables = Partial<TData>>(
  mutationFn: MutationFunction<TData, TVariables>,
  { queryKey }: Pick<UseModelApiResult<TData, any>, 'queryKey'>, // eslint-disable-line @typescript-eslint/no-explicit-any
  options: UseUpdateMutationOptions<TData, TVariables> = {}
) {
  const onSuccess = useOnUpdateSuccess<TData, TVariables>(queryKey);
  const onMutate = useOnUpdateMutate<TData, TVariables>(queryKey);
  const onError = useOnUpdateError<TData, TVariables>(queryKey);

  const mutationOptions: UseMutationOptions<TData, ApiError, TVariables, MutationContext<TData>> = {
    onSuccess: (...args) => {
      void onSuccess(...args);
      void options.onSuccess?.(...args);
    },
    onError: (...args) => {
      if (options.optimisticUpdate) {
        void onError(...args);
      }
      void options.onError?.(...args);
    },
  };

  if (options.optimisticUpdate) {
    mutationOptions.onMutate = onMutate;
  }

  return useMutation<TData, ApiError, TVariables, MutationContext<TData>>(queryKey, mutationFn, mutationOptions)
    .mutateAsync;
}

export const useOnUpdateSuccess = <TData extends EntityWithId, TVariables = Partial<TData>>(
  queryKey: ApiQueryKey
): OnMutationSuccess<TData, TVariables> => {
  const queryClient = useMelioQueryClient();
  const [entity, , modelId] = queryKey;

  const getUpdatedCollectionData = (data: TData) => (collectionData?: TData[]) =>
    collectionData?.map((current) => (current.id === data.id ? data : current)) || [];

  return (modelData) => {
    queryClient.setQueriesData([entity, 'model', modelId], modelData);
    queryClient.setQueriesData([entity, 'collection'], getUpdatedCollectionData(modelData));
  };
};
