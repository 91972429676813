import { FundingSource } from '@melio/platform-api';

export const getFundingSourceDetails = (fundingSource: FundingSource) => {
  switch (fundingSource.type) {
    case 'bank-account':
      return { lastFourDigits: fundingSource.details.accountNumberLast4Digits };
    case 'flex-account':
      return { lastFourDigits: fundingSource.details.externalProviderId.slice(-4) };
    case 'card':
      return {
        lastFourDigits: fundingSource.details.lastFourDigits,
      };
    default:
      return {
        lastFourDigits: undefined,
      };
  }
};
