import { SelectNewOption } from '@melio/penny';
import { countries, usCountry } from '@melio/platform-api';

export const countryOptions: SelectNewOption<string>[] = countries.map(({ name, code }) => ({
  label: name,
  value: code,
  testId: `${code}`,
}));

export const usCountryOption: SelectNewOption<string> = {
  label: usCountry.name,
  value: usCountry.code,
  testId: `${usCountry.code}`,
};
