import { usePaymentFlowContext } from '@melio/ap-domain';
import { BillSubscriptionEndPolicyEnum, useBillSubscription } from '@melio/platform-api';

import { PaymentFlowFormFields } from '../types';

export const useEditBillSubscriptionSubmit = ({ billSubscriptionId }: { billSubscriptionId: string }) => {
  const { paymentSessionId } = usePaymentFlowContext();

  const {
    data: billSubscription,
    _mutations: {
      update: { error: billSubscriptionError },
    },
    update: updateBillSubscription,
    isMutating: isUpdatingBillSubscription,
  } = useBillSubscription({
    id: billSubscriptionId,
    params: {
      expand: ['nextOccurrence', 'vendor', 'fundingSource', 'deliveryMethod', 'occurrences'],
      paymentSessionId,
    },
  });

  const onSubmit = (formFields: PaymentFlowFormFields) => {
    const billSubscriptionFormValues = getEditBillSubscriptionFormValues(formFields);
    return updateBillSubscription(billSubscriptionFormValues);
  };

  return {
    result: billSubscription,
    error: billSubscriptionError,
    onSubmit,
    isSubmitting: isUpdatingBillSubscription,
  };
};

export const getEditBillSubscriptionFormValues = (data: PaymentFlowFormFields) => ({
  amount: Number(data.amountToPay),
  fundingSourceId: data.fundingSourceId,
  deliveryMethodId: data.deliveryMethodId,
  memoToVendor: data.noteToVendor,
  intervalType: data.intervalType,
  startDate: data.startDate || undefined,
  debitDate: data.scheduleDate,
  ...(data.endPolicy === BillSubscriptionEndPolicyEnum.NoEndDate
    ? {
        endPolicy: BillSubscriptionEndPolicyEnum.NoEndDate,
        endDate: null,
        numOfOccurrences: null,
        lastAmount: null,
      }
    : data.endPolicy === BillSubscriptionEndPolicyEnum.EndDate
    ? {
        endPolicy: BillSubscriptionEndPolicyEnum.EndDate,
        endDate: data.endDate,
        numOfOccurrences: null,
        lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
      }
    : {
        endPolicy: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
        endDate: null,
        numOfOccurrences: data.numOfOccurrences ? Number(data.numOfOccurrences) : undefined,
        lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
      }),
});
