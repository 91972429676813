import { useMelioIntl } from '@melio/ar-domain';
import { Button, Group, Icon, IconButton, Tooltip } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';

type ButtonsProps = {
  onCreateInvoice: VoidFunction;
  onVisitSupportSettingsPage: VoidFunction;
  onVisitSettingsPage: VoidFunction;
};

export const HeaderToolBar: React.FC<ButtonsProps> = ({
  onCreateInvoice,
  onVisitSupportSettingsPage,
  onVisitSettingsPage,
}) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: { isEmbeddedExperience, hideHeaderNavigation },
  } = useConfig();

  return (
    <Group spacing="s" variant="horizontal">
      <Tooltip
        dontDescribeChild
        content={formatMessage('ar.dashboard.activities.tabsLayout.supportButton.tooltip.label')}
      >
        <IconButton
          onClick={onVisitSupportSettingsPage}
          icon="help-circle"
          data-testid="dashboard-support-icon-button"
          variant="primary"
          aria-label={formatMessage('ar.dashboard.activities.tabsLayout.supportButton.tooltip.label')}
        />
      </Tooltip>
      {isEmbeddedExperience && hideHeaderNavigation && (
        <Tooltip
          dontDescribeChild
          content={formatMessage('ar.dashboard.activities.tabsLayout.settingsButton.tooltip.label')}
        >
          <IconButton
            aria-label={formatMessage('ar.dashboard.activities.tabsLayout.settingsButton.tooltip.label')}
            variant="primary"
            size="large"
            icon="settings"
            data-testid="settings-button"
            onClick={onVisitSettingsPage}
          />
        </Tooltip>
      )}
      <Button
        variant="primary"
        label={formatMessage('ar.dashboard.activities.tabsLayout.createInvoiceButton.label')}
        leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
        onClick={onCreateInvoice}
        data-testid="dashboard-create-invoice-button"
      />
    </Group>
  );
};

HeaderToolBar.displayName = 'HeaderToolBar';
