import {
  Card,
  CardType,
  DeliveryMethodType,
  FeeType,
  FundingSource,
  FundingSourceType,
  PaymentFee,
} from '@melio/platform-api';
import { getDollarsFromCents } from '@melio/platform-utils';

export const convertPaymentFeeCentsToDollars = (paymentFees: PaymentFee[]) =>
  paymentFees.map((paymentFee) => ({
    type: paymentFee.type,
    amount: getDollarsFromCents(paymentFee.amount),
  }));

export const getFeeTypeByFundingSource = (fundingSource: FundingSource) =>
  fundingSource.type == FundingSourceType.BankAccount
    ? FeeType.Ach
    : (fundingSource?.details as Card).type === CardType.Credit
    ? FeeType.Credit
    : FeeType.Debit;

export const FeeTypeByDeliveryMethod: Record<string, FeeType> = {
  [DeliveryMethodType.BankAccount]: FeeType.Ach,
  [DeliveryMethodType.PaperCheck]: FeeType.Check,
  [DeliveryMethodType.DomesticWireAccount]: FeeType.DomesticWire,
  [DeliveryMethodType.VirtualAccount]: FeeType.Virtual,
};

export const getFeeTypeByDeliveryMethod = (deliveryMethod: DeliveryMethodType) =>
  FeeTypeByDeliveryMethod[deliveryMethod];
