import { PillProps } from '@melio/penny';
import { FeeCatalog, FeeCatalogValueTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useFeePillProps = (fee?: FeeCatalog): Array<PillProps & { 'data-testid': string }> | undefined => {
  const { formatCurrency, formatMessage } = useMelioIntl();

  if (!fee) {
    return undefined;
  }

  const feeText =
    fee?.valueType === FeeCatalogValueTypeEnum.Percent
      ? formatMessage('utilities.fees.feesPillProps.label.percent', {
          feeValue: fee.value,
        })
      : formatMessage('utilities.fees.feesPillProps.label.fixed', {
          feeValue: formatCurrency(fee.value),
        });

  return [
    {
      label: feeText,
      status: 'brand' as const,
      type: 'secondary' as const,
      'data-testid': 'fee-pill',
    },
  ];
};
