import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { object } from 'yup';

import { ManageClientFormSchema, useManageClientFormSchemaValidators } from '../../../components/ManageClientForm';

export const useNewFirmClientFormSchema = () => {
  const {
    businessName,
    businessDBA,
    shouldInviteClient,
    clientEmailAddress,
    clientFirstName,
    clientLastName,
    whoPays,
    fundingSourceId,
    subscriptionPlanId,
  } = useManageClientFormSchemaValidators();
  const isSubscriptionEnabled = useIsSubscriptionsEnabled();

  return object().shape({
    businessName,
    businessDBA,
    shouldInviteClient,
    clientEmailAddress,
    clientFirstName,
    clientLastName,
    ...(isSubscriptionEnabled && {
      whoPays,
      fundingSourceId,
      subscriptionPlanId,
    }),
  }) as unknown as ManageClientFormSchema;
};
