import { useMemo } from 'react';

import { ManageClientFormStep, ManageClientFormStepPayload } from '../../../components/ManageClientForm';
import { useStepManager } from '../../../hooks';

export const useAssignFirmClientPlanFormStepManager = ({
  defaultActiveId,
}: {
  defaultActiveId?: ManageClientFormStep;
}) => {
  const stepsData = useMemo(
    () => [
      {
        stepId: ManageClientFormStep.BillingDetails,
      },
      {
        stepId: ManageClientFormStep.PlanSelection,
      },
      {
        stepId: ManageClientFormStep.ReviewAndConfirm,
      },
    ],
    []
  );

  const { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep } = useStepManager<
    ManageClientFormStep,
    ManageClientFormStepPayload
  >({
    data: stepsData,
    defaultActiveId,
  });

  return { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep };
};
