import { usePartnerFeature } from '@melio/platform-provider';
import { useCallback, useEffect, useState } from 'react';
import { useHref, useNavigate } from 'react-router-dom';

type State = {
  searchParams: { [k: string]: string };
  redirectPath: string;
};

export const parseQueryString = (params: string) => JSON.parse(params) as Record<string, unknown>;

export const encodeStringifiedParams = (params: Record<string, unknown>) => btoa(JSON.stringify(params));

export const useRedirectUrls = (originalRedirectUrl: string, originalErrorRedirectUrl?: string) => {
  const [popupEnabled] = usePartnerFeature('UseOAuthPopup', false);

  const popupPath = '/third-party-connect-popup-callback';

  const redirectPath = popupEnabled ? `${popupPath}/${btoa(originalRedirectUrl)}` : originalRedirectUrl;
  const errorRedirectPath = popupEnabled
    ? `${popupPath}/${btoa(originalErrorRedirectUrl || '')}`
    : originalErrorRedirectUrl;

  const redirectPathWithPartner = useHref(redirectPath, { relative: 'route' });
  const errorRedirectPathWithPartner = useHref(errorRedirectPath || popupPath, { relative: 'route' });

  return {
    redirectUrl: `${window.location.origin}${redirectPathWithPartner}`,
    errorRedirectUrl: `${window.location.origin}${errorRedirectPathWithPartner}`,
  };
};

type OAuthPopupOptions = {
  redirectMethod?: 'replace' | 'push';
  onClose?: () => void;
};

export const useOAuthPopup = ({ redirectMethod, onClose }: OAuthPopupOptions) => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState<Window | null>(null);

  const onComplete = useCallback(
    ({ searchParams, redirectPath }: State) => {
      const replace = redirectMethod === 'replace';
      const queryString = new URLSearchParams(searchParams).toString();

      return navigate(`${redirectPath}?${queryString}`, { replace });
    },
    [navigate, redirectMethod]
  );

  const handleMessage = useCallback(
    ({ data }: MessageEvent<State>) => {
      if (!data.searchParams || !data.redirectPath) {
        return;
      }
      onComplete(data);
      window.removeEventListener('message', handleMessage);
    },
    [onComplete]
  );

  useEffect(() => {
    if (!popup) {
      return;
    }

    const pollerId = window.setInterval(() => {
      if (popup.closed) {
        window.removeEventListener('message', handleMessage);
        window.clearInterval(pollerId);
        setPopup(null);
        onClose?.();
      }
    }, 500);

    return () => {
      if (pollerId) {
        window.clearInterval(pollerId);
      }
    };
  }, [handleMessage, navigate, onClose, popup]);

  const startOAuthFlow = useCallback(
    (url: string) => {
      try {
        setPopup(null);

        const width = 600;
        const height = 500;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2;

        const tempPopup = window.open(
          url,
          'OAuth',
          `width=${width},height=${height},left=${left},top=${top},` +
            'toolbar=0,scrollbars=1,status=0,resizable=1,location=0,menuBar=0,noopener=0'
        );

        if (!tempPopup) {
          setPopup(null);
          throw new Error('Popup blocked');
        }

        setPopup(tempPopup);

        window.addEventListener('message', handleMessage);
      } catch (err) {
        const parsedError = err instanceof Error ? err : new Error('Authentication failed');

        throw parsedError;
      }
    },
    [handleMessage]
  );

  return { startOAuthFlow };
};
