import { AccountingPlatform, BillLineItem, isXeroAccountingPlatform, ScannedInvoice } from '@melio/platform-api';
import { convertAmountToCurrency } from '@melio/platform-utils';

import {
  AddBillV2DFormInitialValues,
  AddBillV2FormLineItem,
  AddBillV2FormValuesResult,
  BillLineItemType,
  CategoryBasedBillLineItem,
  ItemBasedBillLineItem,
  XeroSyncedBillLineItem,
} from '../add-bill';
import { diffAmounts } from '../add-bill/bill-utils';

export function convertScannedInvoicesToInitialValues(
  scannedInvoice: ScannedInvoice,
  isLineItemsAvailable: boolean
): {
  values: AddBillV2DFormInitialValues;
  mismatchedAmount: number;
} {
  const { invoiceNumber, dueDate, amount, scannedInvoiceLineItems, invoiceDate } = scannedInvoice;
  const values = {
    dueDate: dueDate ?? undefined,
    invoiceDate: invoiceDate ?? undefined,
    amount: String(Number(amount ?? '')),
    invoiceNumber: invoiceNumber ?? '',
    lineItems: isLineItemsAvailable
      ? scannedInvoiceLineItems?.map((lineItem) => {
          const { externalItemId, amount, description, quantity, unitPrice, type } = lineItem;
          const amountTranslated = String(convertAmountToCurrency(Number(amount ?? '')));
          const unitPriceTranslated = String(convertAmountToCurrency(Number(unitPrice ?? '')));

          return {
            type: type === BillLineItemType.ITEM ? BillLineItemType.ITEM : BillLineItemType.CATEGORY,
            amount: !amount && unitPrice && quantity === 1 ? unitPriceTranslated : amountTranslated,
            description: description ?? '',
            quantity: unitPrice && amount && unitPrice === amount && !quantity ? 1 : String(quantity ?? ''),
            unitPrice: !unitPrice && amount && quantity === 1 ? amountTranslated : unitPriceTranslated,
            externalItemId,
          } as AddBillV2FormLineItem;
        })
      : [getInitialValueLineItemWithAmount(Number(amount ?? '') ?? 0)],
    vendorId: scannedInvoice.vendorId ?? undefined,
  };

  return { values, mismatchedAmount: diffAmounts(values) };
}

export function getInitialValueLineItemWithAmount(amount: number, description = '') {
  const lineAmount = String(Number(amount ?? ''));
  return {
    type: BillLineItemType.CATEGORY,
    description,
    amount: lineAmount,
    quantity: '1',
    unitPrice: lineAmount,
    externalItemId: null,
  };
}

export function convertToBillLineItems(
  lineItems: AddBillV2FormValuesResult['lineItems'],
  activeAccountingPlatform?: AccountingPlatform
): BillLineItem[] {
  return lineItems.map((lineItem) => {
    const isXero = isXeroAccountingPlatform(activeAccountingPlatform);

    if (isXero) {
      const { type, description, externalItemId, quantity, unitPrice, amount, order, externalCategoryId } =
        lineItem as XeroSyncedBillLineItem;
      return {
        type,
        description: description ?? '',
        amount: Number(amount),
        order: Number(order),
        unitPrice: Number(unitPrice),
        quantity: Number(quantity),
        externalCategoryId: externalCategoryId ?? null,
        externalItemId: externalItemId ?? null,
      } as BillLineItem;
    }
    if (lineItem.type === BillLineItemType.CATEGORY) {
      const { type, description, amount, order, externalCategoryId } = lineItem as CategoryBasedBillLineItem;
      return {
        type,
        description: description ?? '',
        amount: Number(amount),
        order: Number(order),
        externalCategoryId: externalCategoryId ?? null,
      };
    }

    const { type, description, amount, order, externalItemId, unitPrice, quantity } = lineItem as ItemBasedBillLineItem;
    return {
      type,
      description: description ?? '',
      amount: Number(amount),
      order: Number(order),
      unitPrice: Number(unitPrice),
      quantity: Number(quantity),
      externalItemId,
    };
  });
}
