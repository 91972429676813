import { Pill, PillProps, Text } from '@melio/penny';

export type ArrowLayoutProps = {
  startLabel: string;
  startDescription: string;
  centerLabel: string;
  centerPillProps?: PillProps;
  endLabel: string;
  endDescription: string;
  'aria-label': string;
  'data-testid': string;
};

// This feature design is not aligned with the design system, so I'm implementing this in plain CSS to meet the deadline.
export const ArrowLayout = (props: ArrowLayoutProps) => {
  const {
    startLabel,
    startDescription,
    centerLabel,
    centerPillProps,
    endLabel,
    endDescription,
    'aria-label': ariaLabel,
    'data-testid': dataTestId,
  } = props;

  return (
    <ol
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '8px',
        listStyleType: 'none',
      }}
      aria-label={ariaLabel}
      data-testid={dataTestId}
    >
      <li
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          gap: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto',
          }}
        >
          <Text textStyle="body3" color="semantic.text.secondary" data-testid={`${dataTestId}-startLabel`}>
            {startLabel}
          </Text>
          <Text textStyle="body3" data-testid={`${dataTestId}-startDescription`}>
            {startDescription}
          </Text>
        </div>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'row', alignItems: 'center' }} aria-hidden>
          <div
            style={{
              width: '13px',
              height: '13px',
              backgroundColor: 'transparent',
              border: '2px solid #E4E7EC',
              borderRadius: '50%',
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto',
            }}
          />
          <div
            style={{
              flexGrow: 1,
              height: '2px',
              backgroundColor: '#E4E7EC',
            }}
          />
        </div>
      </li>
      <li
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: 'auto',
          alignItems: 'center',
          gap: '4px',
          textWrap: 'nowrap',
        }}
      >
        <Text textStyle="body4" color="semantic.text.secondary" data-testid={`${dataTestId}-centerLabel`}>
          {centerLabel}
        </Text>
        {centerPillProps && <Pill {...centerPillProps} data-testid={`${dataTestId}-centerPill`} />}
      </li>
      <li
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'row', alignItems: 'center' }} aria-hidden>
          <div
            style={{
              flexGrow: 1,
              height: '2px',
              backgroundColor: '#E4E7EC',
            }}
          />
          <div
            style={{
              position: 'relative',
              width: '13px',
              height: '2px',
              backgroundColor: '#E4E7EC',
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto',
            }}
          >
            <div
              style={{
                position: 'absolute',
                backgroundColor: '#E4E7EC',
                width: '13px',
                height: '2px',
                borderRadius: '10px',
                transform: 'rotate(45deg)',
                right: '-2px',
                top: '-4px',
              }}
            />
            <div
              style={{
                position: 'absolute',
                backgroundColor: '#E4E7EC',
                width: '13px',
                height: '2px',
                borderRadius: '10px',
                transform: 'rotate(-45deg)',
                right: '-2px',
                top: '4px',
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 'auto',
          }}
        >
          <Text textStyle="body3" color="semantic.text.secondary" data-testid={`${dataTestId}-endLabel`}>
            {endLabel}
          </Text>
          <Text textStyle="body3" data-testid={`${dataTestId}-endDescription`}>
            {endDescription}
          </Text>
        </div>
      </li>
    </ol>
  );
};
