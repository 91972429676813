import { useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  AccountingPlatformsApiClient,
  AccountingPlatformSyncStatusResponseData,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from '@tanstack/react-query';

type GetQueryFn = typeof AccountingPlatformsApiClient.getAccountingPlatformSyncStatus;

export type UseAccountingPlatformSyncProps = UseModelApiProps<GetQueryFn, AccountingPlatformSyncStatusResponseData>;

export const useAccountingPlatformSync = (props: UseAccountingPlatformSyncProps = {}) => {
  const queryClient = useQueryClient();
  const query = useModelApi<GetQueryFn>({
    ...props,
    id: props.id,
    queryKey: ['AccountingPlatformsApi', 'syncStatus', props.id],
    queryFn: () => AccountingPlatformsApiClient.getAccountingPlatformSyncStatus(props.id as string),
  });

  const sync = useMutation(
    query.queryKey,
    () => AccountingPlatformsApiClient.syncAccountingPlatform(props.id as string),
    {
      onSuccess: () => queryClient.invalidateQueries(query.queryKey),
    }
  );

  return {
    ...query,
    sync: () => sync.mutateAsync(),
  };
};
