import { useMelioIntl } from '@melio/platform-i18n';
import { Tier } from '@melio/subscriptions';

import {
  ManageClientFormFields,
  ManageClientFormStep,
  useManageClientForm,
} from '../../../../components/ManageClientForm';
import { ManageClientForm } from '../../../../components/ManageClientForm/ManageClientForm';
import { useSubscriptionFundingSource } from '../../../../hooks';
import { useGetAccountingFirmClientPlanByTier } from '../../../../hooks/useGetAccountingFirmClientPlanByTier';
import { useGetSearchParamsValues } from '../../../../hooks/useGetSearchParamsValues';
import { SubscriptionBillingPayor } from '../../../../types';
import { useAssignFirmClientPlanFormSchema, useAssignFirmClientPlanFormStepManager } from '../../hooks';

type AssignFirmClientPlanFormProps = {
  organizationId: string;
  onSubmit: (fields: ManageClientFormFields) => Promise<void>;
  isSaving: boolean;
};

export const AssignFirmClientPlanForm = ({ organizationId, onSubmit, isSaving }: AssignFirmClientPlanFormProps) => {
  const { getParamsValues } = useGetSearchParamsValues(['fundingSourceId']);
  const { fundingSourceId: fundingSourceIdFromUrl } = getParamsValues();
  const defaultAccountingFirmClientPlan = useGetAccountingFirmClientPlanByTier(Tier.MelioAccountingClientCore);
  const { formatMessage } = useMelioIntl();

  const { fundingSourceId: subscriptionFundingSourceId } = useSubscriptionFundingSource();

  const form = useManageClientForm({
    isSaving,
    onSubmit,
    schema: useAssignFirmClientPlanFormSchema(),
    defaultValues: {
      whoPays: SubscriptionBillingPayor.Client,
      subscriptionPlanId: defaultAccountingFirmClientPlan?.id || '',
      fundingSourceId: fundingSourceIdFromUrl || subscriptionFundingSourceId,
      organizationId,
    },
  });

  const stepManager = useAssignFirmClientPlanFormStepManager({
    defaultActiveId: ManageClientFormStep.BillingDetails,
  });

  return (
    <ManageClientForm
      form={form}
      isSaving={isSaving}
      stepManager={stepManager}
      labels={{ submit: formatMessage(`activities.accountants.assignClientPlan.form.submitButton.label`) }}
    />
  );
};
