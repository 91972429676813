import { useMelioIntl } from '@melio/platform-i18n';
import { Tier } from '@melio/subscriptions';

import {
  ManageClientFormFields,
  ManageClientFormStep,
  useManageClientForm,
} from '../../../../components/ManageClientForm';
import { ManageClientForm } from '../../../../components/ManageClientForm/ManageClientForm';
import { useSubscriptionFundingSource } from '../../../../hooks';
import { useGetFormSessionStorage, useSetFormSessionStorage } from '../../../../hooks/useFormSessionStorage';
import { useGetAccountingFirmClientPlanByTier } from '../../../../hooks/useGetAccountingFirmClientPlanByTier';
import { useGetSearchParamsValues } from '../../../../hooks/useGetSearchParamsValues';
import { SubscriptionBillingPayor } from '../../../../types';
import { useNewFirmClientFormSchema, useNewFirmClientStepManager } from '../../hooks';

type NewFirmClientFormProps = {
  onSubmit: (data: ManageClientFormFields) => Promise<void>;
  isSaving: boolean;
};

type AdditionalWatchPropsFromStorage = { activeStep: ManageClientFormStep };
export const SESSION_STORAGE_FORM_DATA_KEY = 'new_firm_client';

export const NewFirmClientForm = ({ onSubmit, isSaving }: NewFirmClientFormProps) => {
  const { fundingSourceId: subscriptionFundingSourceId } = useSubscriptionFundingSource();
  const defaultAccountingFirmClientPlan = useGetAccountingFirmClientPlanByTier(Tier.MelioAccountingClientCore);
  const { formatMessage } = useMelioIntl();

  const {
    resumedFormValues,
    additionalPropValues: { activeStep },
  } = useGetFormSessionStorage<ManageClientFormFields, AdditionalWatchPropsFromStorage>(SESSION_STORAGE_FORM_DATA_KEY);

  const { getParamsValues } = useGetSearchParamsValues(['fundingSourceId']);
  const { fundingSourceId: fundingSourceIdFromUrl } = getParamsValues();

  const form = useManageClientForm({
    isSaving,
    onSubmit,
    schema: useNewFirmClientFormSchema(),
    defaultValues: {
      businessDBA: '',
      businessName: '',
      clientEmailAddress: '',
      clientFirstName: '',
      clientLastName: '',
      shouldInviteClient: false,
      subscriptionPlanId: defaultAccountingFirmClientPlan?.id || '',
      ...resumedFormValues,
      whoPays: fundingSourceIdFromUrl
        ? SubscriptionBillingPayor.AccountingFirm
        : resumedFormValues.whoPays || SubscriptionBillingPayor.Client,
      fundingSourceId: fundingSourceIdFromUrl || resumedFormValues.fundingSourceId || subscriptionFundingSourceId,
    },
  });

  const stepManager = useNewFirmClientStepManager({
    defaultActiveId: activeStep as ManageClientFormStep,
  });

  useSetFormSessionStorage<ManageClientFormFields, AdditionalWatchPropsFromStorage>(
    SESSION_STORAGE_FORM_DATA_KEY,
    form.watch,
    {
      activeStep: stepManager.expandedStep || activeStep || ManageClientFormStep.BasicDetails,
    }
  );

  return (
    <ManageClientForm
      form={form}
      isSaving={isSaving}
      stepManager={stepManager}
      labels={{ submit: formatMessage(`activities.accountants.addClient.form.submitButton.label`) }}
    />
  );
};
