import { Box } from '@chakra-ui/react';
import { Container, Group, Icon, SectionBanner, Text } from '@melio/penny';

type PlansPromotionBannerProps = {
  promotionBanner: string;
  variant: 'panel' | 'banner';
};

export const PlansPromotionBanner = ({ promotionBanner, variant }: PlansPromotionBannerProps) => {
  if (variant === 'panel') {
    return (
      <Box backgroundColor="semantic.fill.brand.secondary" width="full" paddingY="xs">
        <Container width="fit-content" data-testid="subscription-promotion-banners">
          <Group alignItems="center" spacing="xs">
            <Icon type="discount" color="brand" aria-hidden />
            <Text textStyle="body3">{promotionBanner}</Text>
          </Group>
        </Container>
      </Box>
    );
  }

  return (
    <Container width="full">
      <SectionBanner
        data-testid="subscription-promotion-banners"
        description={promotionBanner}
        variant="brand"
        data-role="alert"
        leftElement={<Icon type="discount" color="brand" aria-hidden />}
      />
    </Container>
  );
};
