import { useUserPreference } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

import { FISERV_DASHBOARD_MIGRATED_USER_KEY } from '../PayDashboardTour/consts';
import { FirstTimeExperienceModal } from './FirstTimeExperienceModal';
import { MigratedUserExperienceModal } from './migratedUserExperience.tsx/MigratedUserExperienceModal';

type FirstTimeExperienceModalWrapperProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const FirstTimeExperienceModalWrapper = ({ isOpen, onClose }: FirstTimeExperienceModalWrapperProps) => {
  const [isMigratedUserTourTripEnabled] = usePartnerFeature('MigratedUserTourTripEnabled', false);
  //indicate if the user is migrated
  const { data: migratedUserPreference } = useUserPreference({
    id: FISERV_DASHBOARD_MIGRATED_USER_KEY,
    enabled: true,
  });

  if (isMigratedUserTourTripEnabled && migratedUserPreference?.value === 'true') {
    return <MigratedUserExperienceModal isOpen={isOpen} onClose={onClose} />;
  }

  return <FirstTimeExperienceModal isOpen={isOpen} onClose={onClose} />;
};
