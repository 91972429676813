import { Form, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { PaymentFlowFormFields } from '../../../types';

type MemoToVendorInputProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
};

export const NoteToVendorInput = ({ form }: MemoToVendorInputProps) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = form;
  const {
    settings: {
      payment: {
        memoToVendor: { maxLength: memoToVendorMaxLength },
      },
    },
  } = useConfig();

  return (
    <Form.TextArea
      {...registerField('noteToVendor')}
      labelProps={{
        label: formatMessage('activities.paymentFlow.form.content.noteToVendor.label'),
      }}
      helperTextProps={{
        label: formatMessage('activities.paymentFlow.form.content.noteToVendor.helperText'),
      }}
      placeholder={formatMessage('activities.paymentFlow.form.content.noteToVendor.placeholder')}
      data-private
      maxChars={memoToVendorMaxLength}
      numberOfRows={1}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Should be exported from Penny (https://meliorisk.atlassian.net/browse/ME-84316)
      // eslint-disable-next-line
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    />
  );
};
