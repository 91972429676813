import { UseMelioFormResults } from '@melio/penny';
import {
  OrganizationVerifiedPersona,
  OrganizationVerifiedPersonaType,
  UpsertOrganizationVerifiedPersonaRequest,
} from '@melio/platform-api';
import { formatDomesticPhoneNumberToIntl } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import {
  ACCOUNTHOLDER_DECISION_MAKER_KEY,
  AccountHolderForm,
  DecisionMakerForm,
  InternationalIdTypeMap,
  OTHER_DECISION_MAKER_KEY,
  OwnershipDetailsForm,
  Residency,
  TaxIdTypeMap,
  UBO_FROM_ACCOUNT_HOLDER_ID,
} from './types';

export const fXVerifiedPersonasFormToDataModel = ({
  accountHolderForm,
  ownershipDetailsForm,
  decisionMakerForm,
  existingPersonas,
  isNewUboFieldEnabled,
}: {
  accountHolderForm: UseMelioFormResults<AccountHolderForm>;
  ownershipDetailsForm: UseMelioFormResults<OwnershipDetailsForm>;
  decisionMakerForm: UseMelioFormResults<DecisionMakerForm>;
  existingPersonas?: OrganizationVerifiedPersona[];
  isNewUboFieldEnabled: boolean;
}): UpsertOrganizationVerifiedPersonaRequest[] => {
  const requests: UpsertOrganizationVerifiedPersonaRequest[] = [];

  const decisionMakerFormValues = decisionMakerForm.getValues();
  const accountHolderFormValues = accountHolderForm.getValues();

  if (!accountHolderFormValues.isUbo) {
    const accountHolderModel = accountHolderToVerifiedPersona(
      accountHolderFormValues,
      decisionMakerFormValues,
      isNewUboFieldEnabled
    );
    requests.push(accountHolderModel);
  }

  if (isDecisionMakesOther(decisionMakerFormValues)) {
    const decisionMakerModel = decisionMakerToVerifiedPersona(decisionMakerFormValues, isNewUboFieldEnabled);
    requests.push(decisionMakerModel);
  }

  const otherModels = ownershipDetailsToVerifiedPersona(
    ownershipDetailsForm,
    decisionMakerFormValues,
    accountHolderFormValues,
    isNewUboFieldEnabled,
    existingPersonas
  );
  requests.push(...otherModels);

  return requests;
};

const getResidencyDetails = (residency: Residency, isNewUboFieldEnabled: boolean) => {
  if (!isNewUboFieldEnabled) {
    return;
  }
  if (residency.usResidence === 'usResidence') {
    return {
      usResidence: true,
      taxIdType: TaxIdTypeMap[residency.taxIdType],
      taxId: residency.taxId,
    };
  }
  return {
    usResidence: false,
    internationalId: residency.idNumber,
    internationalIdType: InternationalIdTypeMap[residency.idType],
  };
};

const getTaxId = (taxId?: string) => {
  if (isEmpty(taxId)) {
    return;
  }
  return taxId;
};

const isDecisionMakesOther = (decisionMakerFormValues: DecisionMakerForm) =>
  decisionMakerFormValues.uboId === OTHER_DECISION_MAKER_KEY;

const accountHolderToVerifiedPersona = (
  accountHolderFormValues: AccountHolderForm,
  decisionMakerFormValues: DecisionMakerForm,
  isNewUboFieldEnabled: boolean
): UpsertOrganizationVerifiedPersonaRequest => {
  const types: OrganizationVerifiedPersonaType[] = [OrganizationVerifiedPersonaType.FxAccountHolder];

  const isDecisionMakerAlsoAccountHolder = decisionMakerFormValues.uboId === ACCOUNTHOLDER_DECISION_MAKER_KEY;
  if (isDecisionMakerAlsoAccountHolder) {
    types.push(OrganizationVerifiedPersonaType.Director);
  }

  const jobTitle = isDecisionMakerAlsoAccountHolder ? decisionMakerFormValues.companyRole : null;
  return {
    firstName: accountHolderFormValues.firstName,
    lastName: accountHolderFormValues.lastName,
    dateOfBirth: accountHolderFormValues.dayOfBirth?.toISOString(),
    taxId: getTaxId(accountHolderFormValues.taxId),
    address: accountHolderFormValues.address,
    ...getResidencyDetails(accountHolderFormValues.residencyDetails, isNewUboFieldEnabled),
    ...(jobTitle ? { jobTitle } : {}),
    phoneNumber: accountHolderFormValues.phoneNumber
      ? formatDomesticPhoneNumberToIntl(accountHolderFormValues.phoneNumber)
      : null,
    types,
  };
};

const decisionMakerToVerifiedPersona = (
  decisionMakerFormValues: DecisionMakerForm,
  isNewUboFieldEnabled: boolean
): UpsertOrganizationVerifiedPersonaRequest => ({
  firstName: decisionMakerFormValues.decisionMakerFirstName,
  lastName: decisionMakerFormValues.decisionMakerLastName,
  dateOfBirth: decisionMakerFormValues.decisionMakerDayOfBirth.toISOString(),
  taxId: getTaxId(decisionMakerFormValues.decisionMakerSsn),
  address: decisionMakerFormValues.decisionMakerAddress,
  jobTitle: decisionMakerFormValues.companyRole,
  types: [OrganizationVerifiedPersonaType.Director],
  ...getResidencyDetails(decisionMakerFormValues.decisionMakerResidencyDetails, isNewUboFieldEnabled),
});

const ownershipDetailsToVerifiedPersona = (
  ownershipDetailsForm: UseMelioFormResults<OwnershipDetailsForm>,
  decisionMakerFormValues: DecisionMakerForm,
  accountHolderFormValues: AccountHolderForm,
  isNewUboFieldEnabled: boolean,
  existingPersonas?: OrganizationVerifiedPersona[]
): UpsertOrganizationVerifiedPersonaRequest[] => {
  const ubos = ownershipDetailsForm.getValues('ubos');
  return ubos.map((ubo) => {
    const existingUbo = existingPersonas?.find((persona) => persona.id === ubo.uboId);
    const uboIsDecisionMaker = decisionMakerFormValues.uboId === ubo.uboId;
    let address;
    let phoneNumber;

    const types: OrganizationVerifiedPersonaType[] = [OrganizationVerifiedPersonaType.Ubo];
    if (uboIsDecisionMaker) {
      types.push(OrganizationVerifiedPersonaType.Director);
    }
    if (ubo.uboId === UBO_FROM_ACCOUNT_HOLDER_ID) {
      address = accountHolderFormValues.address;
      phoneNumber = accountHolderFormValues.phoneNumber;
      types.push(OrganizationVerifiedPersonaType.FxAccountHolder);
    }

    const jobTitle = uboIsDecisionMaker ? decisionMakerFormValues.companyRole : null;
    const request: UpsertOrganizationVerifiedPersonaRequest = {
      firstName: ubo.ownerFirstName,
      lastName: ubo.ownerLastName,
      // @ts-expect-error Incompatibility with DS's Date picker.
      dateOfBirth: ubo.dateOfBirth?.toISOString(),
      phoneNumber: phoneNumber ? formatDomesticPhoneNumberToIntl(phoneNumber) : null,
      taxId: getTaxId(ubo.ssn),
      address: address || ubo.address,
      ownershipPercentage: parseInt(ubo.ownershipPercentage, 10),
      ...(jobTitle ? { jobTitle } : {}),
      types,
      ...getResidencyDetails(ubo.residencyDetails, isNewUboFieldEnabled),
    };
    if (existingUbo) {
      request.id = ubo.uboId;
    }

    return request;
  });
};
