import { ARInvoice, useCustomers } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { InvoiceActionsHandlers, invoices_screen_id, invoices_tab_panel_id } from '../../utils';
import { useInvoicesFiltering } from './hooks';
import { InvoicesTabScreen } from './screens';
import { Filters } from './types';

export type InvoiceTableTabActivityProps = {
  onDeleteInvoice: (id: string) => unknown;
  onMarkInvoiceAsPaid: (id: string) => unknown;
  onUpdateMarkAsPaid: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  onPreviewInvoicePDF: (fileId: string) => unknown;
  onCancelInvoice: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onDownloadInvoice: (fileId: string) => unknown;
  onPreviewInvoice: (id: string) => unknown;
  onCopyLink: (link: string) => unknown;
  onRowClick?: (params: { rowId: string; rowData: ARInvoice }) => void;
  selectedInvoiceId?: string;
  onError?: ARErrorFunction;
};

export const InvoiceTableTabActivity = forwardRef<InvoiceTableTabActivityProps>(
  (
    {
      onError,
      selectedInvoiceId,
      onRowClick,
      onDeleteInvoice,
      onMarkInvoiceAsPaid,
      onUpdateMarkAsPaid,
      onSendReminder,
      onPreviewInvoicePDF,
      onPreviewInvoice,
      onCancelInvoice,
      onEditInvoice,
      onDownloadInvoice,
      onCopyLink,
    },
    ref
  ) => {
    const {
      invoiceStatus,
      summary,
      isReportsFetching,
      updatedAt,
      dueDate,
      updatedAtRange,
      dueDateRange,
      invoices,
      pagination,
      isFetching,
      sortParams,
      setParams,
      clearFilterParams,
      search,
      error,
    } = useInvoicesFiltering();

    const filters: Filters = {
      ...(invoiceStatus && { invoiceStatus }),
      ...(updatedAt && { updatedAt }),
      ...(dueDate && { dueDate }),
      ...(updatedAtRange && { updatedAtRange }),
      ...(dueDateRange && { dueDateRange }),
    };

    useEffect(() => {
      if (error) {
        onError?.(error);
      }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    // preload customers to display in table
    const { isLoading: isCustomersLoading } = useCustomers();

    const invoiceActions: InvoiceActionsHandlers = {
      onSendReminder,
      onDeleteInvoice,
      onMarkInvoiceAsPaid,
      onUpdateMarkAsPaid,
      onPreviewInvoicePDF,
      onDownloadInvoice,
      onCopyLink,
      onCancelInvoice,
      onEditInvoice,
      onPreviewInvoice,
    };

    return (
      <InvoicesTabScreen
        invoices={invoices}
        invoiceSummary={summary}
        isReportsFetching={isReportsFetching}
        pagination={pagination}
        isFetching={isFetching || isCustomersLoading}
        onRowClick={onRowClick}
        onParamsChange={setParams}
        filters={filters}
        onClearFilters={clearFilterParams}
        search={search}
        sortParams={sortParams}
        invoiceActions={invoiceActions}
        selectedInvoiceId={selectedInvoiceId}
        id={invoices_screen_id}
        aria-labelledby={invoices_tab_panel_id}
        ref={ref}
      />
    );
  }
);
InvoiceTableTabActivity.displayName = 'InvoiceTableScreen';
