import { getFeeTypeByFundingSource } from '@melio/ap-domain';
import { availableBalanceUpdatedAtFormat, getFundingSourceBalance } from '@melio/ap-widgets';
import { PillProps } from '@melio/penny';
import { FeeCatalog, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useFormatExpirationDate } from '@melio/platform-utils';

import { useFeePillProps } from '../../../hooks/useFeePillProps';

export const useFundingSourceCardProps = ({
  fundingSource,
  feeCatalog,
}: {
  fundingSource: FundingSource;
  feeCatalog?: FeeCatalog[];
}): {
  title: string;
  line1?: string;
  line2?: string;
  pillProps?: Array<PillProps & { 'data-testid': string }>;
} => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { formatExpirationDate } = useFormatExpirationDate();
  const feeForFundingSource = feeCatalog?.find((fee) => fee.feeType === getFeeTypeByFundingSource(fundingSource));
  const pillProps = useFeePillProps(feeForFundingSource);

  if (fundingSource.type === 'bank-account') {
    const balance = getFundingSourceBalance(fundingSource);

    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      pillProps,
      line2: balance
        ? formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.line2', {
            availableBalance: formatCurrency(balance.availableBalance),
            availableBalanceUpdatedAt: formatDate(balance.availableBalanceUpdatedAt, availableBalanceUpdatedAtFormat),
          })
        : undefined,
    };
  } else if (fundingSource.type === 'card') {
    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      pillProps,
      line2: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.line2', {
        expirationDate: formatExpirationDate({
          month: fundingSource.details.expirationMonth,
          year: fundingSource.details.expirationYear,
        }),
      }),
    };
  } else if (fundingSource.type === 'paypal-balance') {
    return {
      title: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.paypal-balance.title'),
      line2: fundingSource.details.paypalAccountEmail,
    };
  } else {
    return {
      title: 'not implemented',
    };
  }
};
