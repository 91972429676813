import { useNonEntityMutation } from '@melio/api-client';
import { PayorApiClient, PostPayorPaymentIntentRequest } from '@melio/ar-api-axios-client';

import { PromiseFunctionReturnType } from '../../api-client';

export type UseGuestPayorPaymentIntentProps = PostPayorPaymentIntentRequest;

type MutationFn = typeof PayorApiClient.postPayorPaymentIntent;

export const useGuestPayorPaymentIntent = () => {
  const guestPaymentIntent = useNonEntityMutation<
    MutationFn,
    PostPayorPaymentIntentRequest,
    PromiseFunctionReturnType<MutationFn>
  >(PayorApiClient.postPayorPaymentIntent, ['PayorApi', 'model', 'postPayorPaymentIntent', 'default']);

  return {
    createGuestPaymentIntent: guestPaymentIntent.mutateAsync,
  };
};
