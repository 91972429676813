import { ApiGetRequest, PayorVendorPaymentDetails, PublicPayorApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

type UseGuestPayorVendorPaymentDetails = Omit<UseModelProps<ApiGetRequest<PayorVendorPaymentDetails>>, 'id'> &
  (
    | {
        paymentRequestLink: string;
        vendorHandle?: never;
      }
    | {
        paymentRequestLink?: never;
        vendorHandle: string;
      }
  );

export const useGuestPayorVendorPaymentDetails = (props: UseGuestPayorVendorPaymentDetails) =>
  useModel({
    ...props,
    id: props.vendorHandle ?? props.paymentRequestLink,
    enabled: (!!props.vendorHandle || !!props.paymentRequestLink) && props.enabled,
    queryKey: ['PublicPayorApi', 'VendorPaymentDetails', props.paymentRequestLink, props.vendorHandle],
    queryFn: () => PublicPayorApiClient.getVendorPaymentDetails(props.paymentRequestLink, props.vendorHandle),
  });
