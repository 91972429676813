import { UseMelioFormResults } from '@melio/penny';
import { SchemaOf } from 'yup';

import { SubscriptionBillingPayor } from '../../types';

export type ManageClientFormFields = {
  businessName: string;
  businessDBA: string;
  shouldInviteClient: boolean;
  clientEmailAddress: string;
  clientFirstName: string;
  clientLastName: string;
  whoPays: SubscriptionBillingPayor;
  fundingSourceId: string;
  subscriptionPlanId?: string;
  subscriptionPromoCode?: string;
  organizationId?: string;
};

export type ManageClientFormResults = UseMelioFormResults<ManageClientFormFields>;

export type ManageClientFormSchema = SchemaOf<ManageClientFormFields>;

export enum ManageClientFormStep {
  BasicDetails = 'basic-details',
  BillingDetails = 'billing-details',
  PlanSelection = 'plan-selection',
  ReviewAndConfirm = 'review-and-confirm',
}

export type ManageClientFormStepPayload = {
  title?: string;
  description?: string;
  stepId: ManageClientFormStep;
  component?: React.FC<StepFormProps>;
};

export type StepFormProps = {
  submitLabel: string;
  focusErrorStep: (error: boolean) => void;
  onContinue: () => void;
  isSaving: boolean;
};
