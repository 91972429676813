/* eslint-disable max-lines */
import {
  Button,
  Container,
  FloatingMenu,
  Group,
  Icon,
  Layout,
  Link,
  SectionBanner,
  SelectableDropdownMenu,
  Text,
  useBreakpoint,
  useToast,
} from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { JoinOrganizationRequest, Organization, useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { CardContainer, CardsGroup } from './components/cardLayouts';
import { JoinOrganizationRequestCard } from './components/cards/JoinOrganizationRequestCard';
import { NoAccessOrganizationCard } from './components/cards/NoAccessOrganizationCard';
import { OrganizationCard } from './components/cards/OrganizationCard';
import { FirmDashboardEmptyStateGuard } from './components/FirmDashboardEmptyState.guard';
import { CancelJoinOrganizationRequestModal } from './components/modals/CancelJoinOrganizationRequestModal';
import { ResendJoinOrganizationRequestModal } from './components/modals/ResendJoinOrganizationRequestModal';
import { TopPlanSelectionBanner } from './components/TopPlanSelectionBanner/TopPlanSelectionBanner';
import { useHiddenClientsDropdown } from './hooks/useHiddenClientsDropdown';
import { DisplayMode, useFirmDashboard } from './useFirmDashboard';
import { hasEmptyPlanStatement } from './utils/assign-plan.utils';

type Props = {
  goToSettingsSubscriptionPlansAndRefresh: VoidFunction;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};
export const FirmDashboardActivity = withAnalyticsContext<Props>(
  ({ setAnalyticsProperties, goToSettingsSubscriptionPlansAndRefresh, onSwitchOrganization }) => {
    const { track } = useAnalytics();
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const { isExtraSmallScreen } = useBreakpoint();
    const { goToAssignClientPlan, goToClientCreationOptions, goToSupportPage } = useAccountantsRoutes();
    const {
      isLoading,
      isLoadingExpanded,
      isPaymentsOverviewLoading,
      clientsToShow,
      joinOrganizationRequestsToShow,
      clientsWithoutPermissions,
      expiredRequests,
      pendingRequests,
      setIsSwitching,
      hasHiddenClients,
      hiddenClientsAmount,
      updateClient,
      setDisplayMode,
      displayMode,
      error,
      isExpandedClientsFetchedSuccessfully,
      eligibleClientsForAssignPlan,
    } = useFirmDashboard();
    const hiddenClientsDropdown = useHiddenClientsDropdown(displayMode);
    const { data: firmAccount } = useAccount({ id: 'me' });
    const [resendRequestEntity, setResendRequestEntity] = useState<JoinOrganizationRequest>();
    const [cancelRequestEntity, setCancelRequestEntity] = useState<JoinOrganizationRequest>();

    setAnalyticsProperties({
      PageName: 'my-clients',
      Flow: 'manage-my-clients',
      Intent: 'manage-my-clients',
      NumberOfClients: clientsToShow.length,
      RequestsStatus: {
        pending: pendingRequests.length,
        expired: expiredRequests.length,
      },
    });

    useEffect(() => {
      if (isExpandedClientsFetchedSuccessfully) {
        track('User', 'View', {
          ViewMode: displayMode === 'hidden' ? 'hidden-clients' : 'active-clients',
          NumberOfHiddenClients: hiddenClientsAmount,
        });
      }
    }, [hiddenClientsAmount, isExpandedClientsFetchedSuccessfully, displayMode, track]);

    const onAddButtonClick = () => {
      track('User', 'Click', {
        Intent: 'add-client',
        Cta: 'add-client',
      });
      goToClientCreationOptions();
    };
    const onSwitchToClient = async (organizationId: string) => {
      setIsSwitching(true);
      try {
        await onSwitchOrganization({ organizationId });
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('activities.accountants.firmDashboard.switchOrganization.toast.error'),
        });
      }
    };
    const onChangeClientPlan = async (organizationId: string) => {
      setIsSwitching(true);
      try {
        await onSwitchOrganization({ organizationId, switchAccessTokenOnly: true, keepLoadingState: true });
        goToSettingsSubscriptionPlansAndRefresh();
        setIsSwitching(false);
      } catch (error) {
        setIsSwitching(false);
        toast({
          type: 'error',
          title: formatMessage('activities.accountants.firmDashboard.changeClientPlan.toast.error'),
        });
      }
    };
    const onToggleHiddenState = async (organization: Organization) => {
      try {
        track('User', 'Click', {
          Intent: 'client-options',
          Cta: organization.isHidden ? 'unhide-client' : 'hide-client',
        });
        await updateClient({ id: organization.id, data: { isHidden: !organization.isHidden } });
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('screens.serverError.title'),
        });
      }
    };
    const onGoToAssignPlan = (organizationId: string) => {
      track('User', 'Click', {
        Intent: 'client-options',
        Cta: 'assing-plan',
      });
      goToAssignClientPlan({ accountingClientId: organizationId });
    };
    const onManageClientPlan = (orgId: string) => {
      track('User', 'Click', {
        Intent: 'client-options',
        Cta: 'proceed-to-plans',
      });
      void onChangeClientPlan(orgId);
    };
    const onProceedCheckout = (orgId: string) => {
      track('User', 'Click', {
        Intent: 'client-options',
        Cta: 'proceed-to-checkout',
      });
      void onSwitchToClient(orgId);
    };
    const onOrganizationCardClicked = (orgId: string) => {
      const subscriptionInfo = clientsToShow.find(({ organization }) => organization.id === orgId)?.subscriptionInfo;

      const action =
        !subscriptionInfo?.isAwaitingActivation && hasEmptyPlanStatement(subscriptionInfo)
          ? 'assign-plan'
          : 'choose-client';

      track('User', 'Click', {
        Intent: 'choose-client',
        Cta: action,
      });

      if (action === 'assign-plan') {
        return onGoToAssignPlan(orgId);
      }

      return onSwitchToClient(orgId);
    };

    return (
      <Layout paddingContent={['none', null, 'l']} data-testid="accountants-dashboard">
        <Group variant="vertical" spacing="m">
          <Container paddingTop={['m', null, '0'] as never} paddingX={['m', null, '0'] as never} overflow="initial">
            <Group justifyContent="space-between" spacing="m">
              <Group variant="vertical" spacing="xs">
                <PageTitle textStyle={['heading2Semi', null, 'heading1Semi'] as never}>
                  {formatMessage('activities.accountants.firmDashboard.title')}
                </PageTitle>
                <Text textStyle="body3" color="semantic.text.secondary">
                  {firmAccount?.company.name}
                </Text>
              </Group>
              <Group variant="vertical" alignItems="flex-end">
                <Group variant="horizontal" spacing={['xs', null, 's'] as never} alignItems="center">
                  <Button
                    leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
                    data-testid="add-company-button"
                    size={isExtraSmallScreen ? 'small' : 'medium'}
                    variant="primary"
                    label={formatMessage('activities.accountants.firmDashboard.addClientButton.label')}
                    onClick={onAddButtonClick}
                  />
                </Group>
                {hasHiddenClients && (
                  <SelectableDropdownMenu
                    items={hiddenClientsDropdown.items}
                    data-testid="hidden-clients-dropdown-container"
                    trigger={
                      <FloatingMenu.ActionTrigger
                        data-testid="hidden-clients-dropdown-trigger"
                        label={hiddenClientsDropdown.selectedItem.label}
                      />
                    }
                    isOpen={hiddenClientsDropdown.isOpen}
                    onOpenChange={hiddenClientsDropdown.setIsOpen}
                    selectedItemValue={hiddenClientsDropdown.selectedItem.value}
                    onSelect={(item) => {
                      setDisplayMode(item.value as DisplayMode);
                      hiddenClientsDropdown.setIsOpen(false);
                    }}
                  />
                )}
              </Group>
            </Group>
          </Container>
          {error && (
            <SectionBanner
              variant="critical"
              title={formatMessage('activities.accountants.firmDashboard.general.error.title')}
              description={formatMessage('activities.accountants.firmDashboard.general.error.description', {
                code: error.code,
                message: error.message,
                contactSupportLink: (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      goToSupportPage();
                    }}
                    label={formatMessage(
                      'activities.accountants.firmDashboard.general.error.description.support.link.label'
                    )}
                    href="" // default is prevented
                  />
                ),
              })}
            />
          )}
          {eligibleClientsForAssignPlan.length ? (
            <Container>
              <TopPlanSelectionBanner clients={eligibleClientsForAssignPlan} />
            </Container>
          ) : null}
          <Container overflow="initial">
            <FirmDashboardEmptyStateGuard
              isLoading={isLoading}
              isEmpty={!clientsToShow.length && !joinOrganizationRequestsToShow.length}
            >
              {!isLoading && (
                <CardsGroup>
                  {clientsToShow.map((client) => (
                    <CardContainer key={`organization_card_${client.organization.id}`}>
                      <OrganizationCard
                        client={client}
                        isPaymentsOverviewLoading={isPaymentsOverviewLoading}
                        isLoading={isLoadingExpanded}
                        onManageClientPlan={() => onManageClientPlan(client.organization.id)}
                        onToggleHiddenState={() => void onToggleHiddenState(client.organization)}
                        onProceedToCheckout={() => void onProceedCheckout(client.organization.id)}
                        onGoToAssignPlan={() => onGoToAssignPlan(client.organization.id)}
                        onClick={
                          isExpandedClientsFetchedSuccessfully
                            ? () => onOrganizationCardClicked(client.organization.id)
                            : undefined
                        }
                        isExpandedClientsFetchedSuccessfully={isExpandedClientsFetchedSuccessfully}
                      />
                    </CardContainer>
                  ))}
                  {clientsWithoutPermissions.map((client) => (
                    <CardContainer key={`join_organization_accounting_software_card_${client.organization.id}`}>
                      <NoAccessOrganizationCard organization={client.organization} />
                    </CardContainer>
                  ))}
                  {joinOrganizationRequestsToShow.map((joinOrganizationRequest) => (
                    <CardContainer key={`join_organization_request_card_${joinOrganizationRequest.id}`}>
                      <JoinOrganizationRequestCard
                        joinOrganizationRequest={joinOrganizationRequest}
                        onRequestCancel={() => setCancelRequestEntity(joinOrganizationRequest)}
                        onRequestResend={() => setResendRequestEntity(joinOrganizationRequest)}
                      />
                    </CardContainer>
                  ))}
                  {resendRequestEntity ? (
                    <ResendJoinOrganizationRequestModal
                      joinOrganizationRequest={resendRequestEntity}
                      onClose={() => setResendRequestEntity(undefined)}
                    />
                  ) : null}
                  {cancelRequestEntity ? (
                    <CancelJoinOrganizationRequestModal
                      joinOrganizationRequest={cancelRequestEntity}
                      onClose={() => setCancelRequestEntity(undefined)}
                    />
                  ) : null}
                </CardsGroup>
              )}
            </FirmDashboardEmptyStateGuard>
          </Container>
        </Group>
      </Layout>
    );
  }
);
