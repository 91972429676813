import { ApiPagination, ReceivablePayment } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { PaymentActionsHandlers } from '../../../utils';
import { PaymentsTable, PaymentsTableToolbar } from '../components';

export type PaymentsTableScreenProps = {
  payments: ReceivablePayment[];
  pagination: ApiPagination;
  tableActions: PaymentActionsHandlers;
  onSearch: (search: string) => void;
  searchTerm?: string;
  isLoading?: boolean;
  onError?: ARErrorFunction;
};

export const PaymenstTabScreen = forwardRef<PaymentsTableScreenProps>(
  ({ onError, isLoading, tableActions, onSearch, searchTerm, ...props }, ref) => (
    <>
      <PaymentsTableToolbar onSearch={onSearch} searchTerm={searchTerm} />
      <PaymentsTable
        tableActions={tableActions}
        onRowClick={({ rowId }) => tableActions.onViewPayment(rowId)}
        isLoading={isLoading}
        ref={ref}
        {...props}
      />
    </>
  )
);

PaymenstTabScreen.displayName = 'PaymentsTabScreen';
