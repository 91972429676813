import { isFiservOwner } from '@melio/ap-activities/src/components/terms-and-conditions/TermsAndConditions.utils';
import { LoadingContainer } from '@melio/penny';
import { useCollaborator } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

import { useRouter } from '@/hooks/router.hooks';
import { TermsAndConditionsNonOwnerScreen } from '@/screens/legal-documents/TermsAndConditionsNonOwner.screen';
import { TermsAndConditionsOwnerScreen } from '@/screens/legal-documents/TermsAndConditionsOwner.screen';

export const TermsAndConditionsRoute = () => {
  const { generateBackToPartnerUrl } = useRouter();
  const backToPartnerUrl = generateBackToPartnerUrl();
  const { goToPayDashboard } = useRouter();
  const { data: actor, isLoading: isCollaboratorLoading } = useCollaborator({ id: 'me' });
  const isOwner = actor ? isFiservOwner(actor) : undefined;
  const [redirectOnTermsAndConditionsScreenReject] = usePartnerFeature(
    'RedirectOnTermsAndConditionsScreenReject',
    false,
  );

  const onClose = () => {
    // if we are in an iframe, we should send post message to partner here.
    // this is a solution for click-out screen
    if (backToPartnerUrl && redirectOnTermsAndConditionsScreenReject) {
      window.location.href = backToPartnerUrl;
    }
  };

  if (isCollaboratorLoading) {
    return <LoadingContainer isLoading />;
  }

  return isOwner ? (
    <TermsAndConditionsOwnerScreen onClose={onClose} onBack={goToPayDashboard} />
  ) : (
    <TermsAndConditionsNonOwnerScreen onClose={onClose} />
  );
};
