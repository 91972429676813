/* eslint-disable max-lines */
import { Box, useDisclosure } from '@chakra-ui/react';
import { useNavigationWithQueryParams } from '@melio/ap-domain';
import { Container, Group, Link, useBreakpoint, useTable } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { FxQuote, Payment, PaymentDate, PostApprovalDecisionEnum, usePaginatedPayments } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useLocation, useSystemMessage } from '@melio/platform-utils';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useResolvedPath } from 'react-router-dom';

import { DeletePaymentModal, SelectedEntity } from '../../../delete-payment-modal';
import { MarkAsPaidModal } from '../../../mark-as-paid-modal/MarkAsPaidModal';
import { PaymentApprovalWorkflowDeclineModalActivity } from '../../../payment-approval-workflow';
import { PaymentDrawerActivity } from '../../../payment-drawer';
import { useBatchPaymentApprovalDecision } from '../../../payment-drawer/hooks/useBatchPaymentApprovalDecision';
import { PaymentsLateApprovalModalActivity } from '../../../payments-late-approval-modal';
import { ROW_HEIGHT } from '../../consts';
import { emitFocusEvent, FocusEvents } from '../../FocusSkipToComponent';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PayDashboardTabs } from '../../types';
import { APTable } from '../APTable';
import { MobileSortMenu } from '../MobileSortMenu/MobileSortMenu';
import { PayDashboardPagination, PayDashboardPaginationProvider, usePayDashboardPagination } from '../Pagination';
import { PaymentsTabListItem } from '../PaymentsTab/components/PaymentsTabListItem';
import { usePaymentAriaLabel } from '../PaymentsTab/PaymentsTab';
import { EmptySearchResult, SearchBar } from '../Search';
import { ApprovalsTabEmptyStateWrapper } from './components/ApprovalsTabEmptyStateWrapper';
import { PaymentsApprovalSelectionFooter } from './components/PaymentsApprovalSelectionFooter';
import { useManageApprovalsButtonLabel } from './components/useManageApprovalsButtonLabel';
import { COLLABORATORS_SETTINGS_RELPATH, WORKFLOW_SETTINGS_RELPATH } from './consts';
import { CELLS_IDS } from './types';
import { useApprovalsTabAnalytics } from './useApprovalsTabAnalytics';
import { useApprovalsTableColumns } from './useApprovalsTableColumns';
import { useApprovalsTabSelection } from './useApprovalsTabSelection';
import { useApprovalsTabSortableColumns } from './useApprovalsTabSortableColumns';
import { convertApprovalsTabSortToApiSort } from './utils';

export type ApprovalsTabProps = {
  onEditPayment: ({ id, returnUrl }: { id: Payment['id']; returnUrl: string }) => void;
  onRetryFailedToDeliverPayment: (paymentId: Payment['id']) => void;
  onRetryFailedToCollectPayment: (paymentId: Payment['id']) => void;
  onRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndResendPayment: (paymentId: Payment['id']) => void;
};

const paymentLateModalAnalytics = { name: 'Dashboard' };

const ApprovalsTabComponent = withAnalyticsContext(
  ({
    onEditPayment,
    onRetryFailedToDeliverPayment,
    onRetryFailedToCollectPayment,
    onRefundPayment,
    onVoidAndRefundPayment,
    onVoidAndResendPayment,
  }: ApprovalsTabProps) => {
    const { isExtraSmallScreen } = useBreakpoint();
    const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
    const [isMobileSortEnabled] = useDevFeature<boolean>(FeatureFlags.NpeSortMobile, false);
    const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>(null);
    const { track } = useAnalytics();
    const { pathname } = useLocation();
    const resolvedPathUrl = useResolvedPath('');
    const [selectedRow, setSelectedRow] = useState<Payment>();
    const { searchTerm } = useSearchTerm();
    const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
    const navigate = useNavigate();
    const { showMessage } = useSystemMessage();
    const { formatMessage, formatCurrency } = useMelioIntl();
    const {
      batchApprovalDecision,
      showSuccessMessage: showApprovalSuccessMessage,
      isMutating: isMutatingApprovals,
    } = useBatchPaymentApprovalDecision();

    const { sortableColumns: approvalsTabSortableItems } = useApprovalsTabSortableColumns();
    const sorting = usePayDashboardSorting();
    const {
      settings: { isPaymentApprovalLimitEnabled, payDashboardSearchLabelDisabled },
    } = useConfig();

    const { buttonLabel } = useManageApprovalsButtonLabel();

    const [approvePaymentModalData, setApprovePaymentModalData] = useState<
      { approvedPayments: Payment[]; latePayments: Payment[]; fxPayments: Payment[] } | undefined
    >(undefined);

    const {
      isOpen: isCancelPaymentModalOpen,
      onOpen: onCancelPaymentModalOpen,
      onClose: onCancelPaymentModalClose,
    } = useDisclosure();

    const {
      isOpen: isMarkAsPaidModalOpen,
      onOpen: onMarkAsPaidModalOpen,
      onClose: onMarkAsPaidClose,
    } = useDisclosure();

    const handleApprovalDecision = async (
      payments: Payment[],
      approvalDecisionStatus: PostApprovalDecisionEnum,
      overrides?: Record<Payment['id'], { quote?: FxQuote; date?: PaymentDate }>
    ) => {
      try {
        const { approvedPayments, latePayments, fxPayments } = await batchApprovalDecision({
          payments,
          decision: approvalDecisionStatus,
          overrides,
        });
        if (latePayments.length > 0 || fxPayments.length > 0) {
          setApprovePaymentModalData({ approvedPayments, latePayments, fxPayments });
          if (approvedPayments.length > 0 && rowSelections.length > 0 && onRowSelectionChange) {
            approvedPayments.map((approvedPayment) =>
              onRowSelectionChange({ rowId: approvedPayment.id, isSelected: false, rowData: approvedPayment })
            );
          }
          return;
        }
        const ids = payments.map((p) => p.id);
        handleOnSuccessApprovalDecision(ids, approvalDecisionStatus);
      } catch (e) {
        track('Payment', 'Status', {
          Intent: 'payment-approval',
          Status: 'error',
        });
      }
    };

    const handleMarkedFailedPaymentAsPaid = (id: string) => {
      setSelectedRow(payments.find(({ id: paymentId }) => id === paymentId));
      onMarkAsPaidModalOpen();
    };

    const handelOnSuccessMarkAsPaid = () => {
      refetchPayments();
    };

    const handleOnSuccessApprovalDecision = (ids: string[], decision: PostApprovalDecisionEnum) => {
      refetchPayments();
      setClearSelectedPayments(true);
      showApprovalSuccessMessage({
        ids,
        decision,
        onToastActionClick: () =>
          ids.length === 1 && ids[0] ? navigate(`../payments/${ids[0]}`) : navigate('../payments'),
      });
    };

    const handleOnSuccessMarkAsUnpaid = async (payment: Payment) => {
      await refetchPayments();
      track('Dashboard', 'Status', {
        Intent: 'mark-as-unpaid',
        Status: 'success',
        PaymentId: payment.id,
      });
      showMessage({
        type: 'success',
        title: formatMessage('activities.payDashboard.billsTab.markAsUnpaid.toast.success', {
          amount: formatCurrency(payment.amount),
          vendorName: payment.vendor?.name || '',
        }),
        action: {
          type: 'button',
          text: formatMessage('activities.payDashboard.billsTab.markAsUnpaid.toast.success.single.action'),
          onAction: (closeToast) => {
            track('Dashboard', 'Click', {
              Intent: 'mark-as-unpaid',
              Cta: 'view-bill',
              Status: 'success',
              PaymentId: payment.id,
            });
            closeToast();
            return navigate(`../${PayDashboardTabs.Bills}`);
          },
        },
      });
    };

    const { pageSize, resetToFirstPage } = usePayDashboardPagination();

    const paginationResponse = usePaginatedPayments({
      cacheTime: 0,
      params: {
        ...(searchTerm ? { searchTerm } : {}),
        sort: sorting?.sortingState ? convertApprovalsTabSortToApiSort(sorting.sortingState) : undefined,
        expand: [
          'vendor',
          'createdBy',
          'bills',
          'bills.file',
          'subscriptionOccurrence',
          'subscriptionOccurrence.billSubscription',
          'fundingSource',
          'deliveryMethod',
          'bills.files',
          'paymentActions',
        ],
        pendingCurrentUserApproval: true,
        limit: pageSize,
      },
      enabled: !!sorting?.isLoaded,
    });
    const {
      data: paymentsResult,
      refetch: refetchPayments,
      isLoading: isLoadingPayments,
      isPreviousData,
      isFetching: isFetchingPayments,
    } = paginationResponse;

    const payments = paymentsResult?.data ?? [];
    const [clearSelectedPayments, setClearSelectedPayments] = useState(false);

    const {
      selectedRows,
      rowSelections,
      onRowSelectionChange,
      onAllRowsSelectionChange,
      disableRowSelection,
      rowSelectionTooltips,
      areAllSelected,
    } = useApprovalsTabSelection(payments, clearSelectedPayments, setClearSelectedPayments);

    const onRowClick = useCallback(
      ({ rowData }: { rowData: Payment }) => {
        if (rowSelections.length) {
          return;
        }
        track('Dashboard', 'Click', { Intent: 'view-approval-payment', Cta: 'row-approval-details' });
        handleNavigationWithQueryParams({ newPath: rowData.id });
      },
      [handleNavigationWithQueryParams, rowSelections.length, track]
    );

    const { routeReady } = useMonitoring();

    useApprovalsTabAnalytics({ payments: payments ?? [], areAllSelected });
    const {
      isEmptyState,
      isEmptySearchResult,
      isInitialLoading,
      isTableLoading: isPaginationLoading,
      shouldShowTabHeader,
      shouldShowPaginationControls,
    } = useLoadingState({
      isLoading: isLoadingPayments || !sorting?.isLoaded,
      searchTerm,
      items: payments ?? [],
      paginationConfig: {
        isFetching: isFetchingPayments,
        isPreviousData,
        totalCount: paymentsResult?.pagination.totalCount,
      },
    });

    const isTableLoading = isPaginationLoading || isMutatingApprovals || isFetchingPayments;

    const APPROVALS_SETTINGS_PATH = isPaymentApprovalLimitEnabled
      ? COLLABORATORS_SETTINGS_RELPATH
      : WORKFLOW_SETTINGS_RELPATH;

    useEffect(() => {
      if (!isLoadingPayments) {
        track('Dashboard', 'View', { Intent: 'view-approvals' });
      }
    }, [isLoadingPayments, track]);

    const handleCloseDrawer = () => {
      handleNavigationWithQueryParams({ newPath: resolvedPathUrl.pathname, options: { state: { keepToast: true } } });
    };

    const handleCancelPayment = (id: string, currentEntity: SelectedEntity) => {
      track('Dashboard', 'Click', { Intent: 'cancel-payment', Cta: 'cancel-payment' });
      setSelectedRow(payments.find(({ id: paymentId }) => id === paymentId));
      setSelectedEntity(currentEntity);
      onCancelPaymentModalOpen();
    };

    const handelOnSuccessCancelPayment = () => {
      refetchPayments();
    };

    const handleEditPayment = (id: Payment['id']) => {
      track('Dashboard', 'Click', { Intent: 'edit-payment', Cta: 'edit-payment' });
      onEditPayment({ id, returnUrl: pathname });
    };

    const handleViewPayment = (id: Payment['id']) => {
      handleNavigationWithQueryParams({ newPath: id });
    };

    const handleManageWorkflowsClick = () => {
      track('Dashboard', 'Click', { Intent: 'approval-workflow', Cta: 'manage-approval-workflows' });
      navigate(APPROVALS_SETTINGS_PATH);
    };

    const {
      isOpen: isDeclinePaymentModalOpen,
      onOpen: onDeclinePaymentModalOpen,
      onClose: onDeclinePaymentModalClose,
    } = useDisclosure();

    const columns = useApprovalsTableColumns();

    const mobileSortOptions = Object.values(approvalsTabSortableItems);
    const mobileRowRenderer = useCallback((row: Payment) => <PaymentsTabListItem payment={row} />, []);

    const getItemAriaLabelContext = usePaymentAriaLabel();

    const tableProps = useTable({
      isLoading: isTableLoading,
      data: payments,
      columns,
      getRowId: (row) => row.id,
      rowSelectionTooltips,
      selectedRows,
      onRowSelectionChange,
      disableRowSelection,
      onAllRowsSelectionChange,
      onRowClick,
      ...sorting,
      headerVariant: 'dark',
      hideHeaderWhileLoading: isInitialLoading,
      getRowSelectionAriaLabel: getItemAriaLabelContext,
      mobileRowRenderer,
      allRowsSelectionAriaLabel: '',
      captionId: formatMessage(`activities.payDashboard.tabs.approvals.caption`),
      meta: {
        rowSelections,
        getItemAriaLabelContext,
        onViewClick: handleViewPayment,
        onEditPayment: handleEditPayment,
        onCancelPayment: handleCancelPayment,
        onMarkFailedPaymentAsPaid: handleMarkedFailedPaymentAsPaid,
        onSuccessMarkAsUnpaid: handleOnSuccessMarkAsUnpaid,
        onSuccessApprovalDecision: handleOnSuccessApprovalDecision,
        onApprovalDecisionClick: handleApprovalDecision,
      },
    });

    const renderContent = () => {
      const captionLabel = shouldShowTabHeader
        ? formatMessage('activities.payDashboard.tabs.approvals.captionLabel')
        : undefined;
      if (isEmptySearchResult && !isFetchingPayments) {
        return (
          <>
            <APTable {...tableProps} captionLabel={captionLabel} />
            <EmptySearchResult onClear={() => emitFocusEvent(FocusEvents.TAB_TOP_SEARCH)} />
          </>
        );
      }
      if (isEmptyState) {
        return <ApprovalsTabEmptyStateWrapper onLinkClick={handleManageWorkflowsClick} />;
      }

      return <APTable {...tableProps} captionLabel={captionLabel} />;
    };

    const hideSearchLabel = isExtraSmallScreen || payDashboardSearchLabelDisabled;

    return (
      <Container data-testid="pay-dashboard-approvals-tab" overflow="initial" position="relative">
        {!isLoadingPayments && <span ref={routeReady} />}
        <Group variant="vertical" width="full" spacing={isExtraSmallScreen ? 's' : 'm'}>
          <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
            {shouldShowTabHeader && (
              <Group
                variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                justifyContent="space-between"
                alignItems="center"
                width="full"
                spacing="xs"
                data-testid="pay-dashboard-payments-sub-and-search-tab"
              >
                <Group variant="horizontal" justifyContent="space-between" alignItems="center" width="full">
                  <SearchBar
                    onSearchSubmitted={resetToFirstPage}
                    label={
                      hideSearchLabel ? undefined : formatMessage('activities.payDashboard.approvalsTab.searchLabel')
                    }
                    pagination={!paginationResponse.isFetching ? paginationResponse.data?.pagination : undefined}
                    focusOnEvent={FocusEvents.TAB_TOP_SEARCH}
                    placeholder={
                      hideSearchLabel ? 'activities.payDashboard.approvalsTab.search.placeholder' : undefined
                    }
                  />
                  {isMobileSortEnabled && isExtraSmallScreen && (
                    <Container width="fit-content" overflow="initial">
                      <MobileSortMenu
                        items={mobileSortOptions}
                        title="activities.payDashboard.approvalsTab.sort.title"
                      />
                    </Container>
                  )}
                  {!isExtraSmallScreen && !isEmptyState && (
                    <Link
                      variant="standalone"
                      size="medium"
                      data-testid="approvals-tab-manage-link"
                      color="secondary"
                      label={buttonLabel}
                      href={APPROVALS_SETTINGS_PATH}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleManageWorkflowsClick();
                      }}
                    />
                  )}
                </Group>
              </Group>
            )}
          </Container>
          <Group alignItems="flex-end" variant="vertical" spacing="m">
            <Container overflow="initial">{renderContent()}</Container>

            <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
          </Group>
          {rowSelections?.length ? <Box height={`${ROW_HEIGHT}px`} /> : null}

          {selectedRow && (
            <DeletePaymentModal
              isOpen={isCancelPaymentModalOpen}
              onClose={onCancelPaymentModalClose}
              payment={selectedRow}
              selectedEntity={selectedEntity}
              onSuccess={handelOnSuccessCancelPayment}
            />
          )}
          {isMarkAsPaidEnabled && selectedRow && (
            <MarkAsPaidModal
              id={selectedRow.id}
              isOpen={isMarkAsPaidModalOpen}
              onClose={onMarkAsPaidClose}
              onSuccess={handelOnSuccessMarkAsPaid}
              type="payment"
            />
          )}
          {approvePaymentModalData && (
            <PaymentsLateApprovalModalActivity
              lateApprovalPayments={approvePaymentModalData.latePayments}
              approvedPayments={approvePaymentModalData.approvedPayments}
              fxPayments={approvePaymentModalData.fxPayments}
              analytics={paymentLateModalAnalytics}
              isOpen
              isMutating={isMutatingApprovals}
              onClose={() => setApprovePaymentModalData(undefined)}
              onSuccess={async ({ payments, overrides }) => {
                setApprovePaymentModalData(undefined);
                await handleApprovalDecision(payments, PostApprovalDecisionEnum.Approved, overrides);
              }}
            />
          )}
        </Group>
        <Routes>
          <Route
            path=":paymentId"
            element={
              <PaymentDrawerActivity
                onClose={handleCloseDrawer}
                onEditPayment={handleEditPayment}
                onRetryFailedToDeliverPayment={onRetryFailedToDeliverPayment}
                onRetryFailedToCollectPayment={onRetryFailedToCollectPayment}
                onRefundPayment={onRefundPayment}
                onVoidAndRefundPayment={onVoidAndRefundPayment}
                onVoidAndResendPayment={onVoidAndResendPayment}
                onMarkAsUnpaid={handleOnSuccessMarkAsUnpaid}
                onApprovalDecision={handleOnSuccessApprovalDecision}
              />
            }
          />
        </Routes>
        {isDeclinePaymentModalOpen && rowSelections && (
          <PaymentApprovalWorkflowDeclineModalActivity
            isOpen
            paymentsIds={rowSelections}
            onClose={onDeclinePaymentModalClose}
            onSuccess={handleOnSuccessApprovalDecision}
          />
        )}
        <PaymentsApprovalSelectionFooter
          paymentIds={rowSelections}
          onApprove={handleApprovalDecision}
          onDecline={onDeclinePaymentModalOpen}
          onCancel={() => onAllRowsSelectionChange?.(false)}
          isMutating={isMutatingApprovals}
        />
      </Container>
    );
  }
);

export const ApprovalsTab = (props: ApprovalsTabProps) => (
  <PayDashboardPaginationProvider>
    <PayDashboardSortingProvider
      defaultSort={{ id: CELLS_IDS.DEBIT_DATE, sortDirection: 'asc' }}
      tableId={PayDashboardTabs.Approvals}
    >
      <ApprovalsTabComponent {...props} />
    </PayDashboardSortingProvider>
  </PayDashboardPaginationProvider>
);
