import { MethodCard, VirtualCardInfoModal } from '@melio/ap-widgets';
import { BrandSymbol, Icon, NakedButton } from '@melio/penny';
import { DeliveryMethodType, VirtualCardAccountDeliveryMethod } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';

import { AddDeliveryMethodActionButton, DeliveryMethodActionsMenu } from '../components/DeliveryMethodActions';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';

type Props = {
  unmanagedVirtualCard: VirtualCardAccountDeliveryMethod | null;
  onClick: VoidFunction;
};

export const UnmanagedVirtualCardDeliveryMethodCard = ({ unmanagedVirtualCard, onClick }: Props) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { settings } = useConfig();
  const [isModalOpen, modalControls] = useBoolean(false);

  const modalEntryButtonText = formatMessage(
    'widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.info.noCardAdditionalInfo.action'
  );
  const shouldShowModal = !unmanagedVirtualCard && modalEntryButtonText;
  const modalEntryPoint = shouldShowModal ? (
    <NakedButton variant="secondary" onClick={modalControls.on} label={modalEntryButtonText} />
  ) : null;

  const caption = (() => {
    if (!unmanagedVirtualCard) {
      return {
        secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.info.noCardAdditionalInfo', {
          link: modalEntryPoint,
        }),
      };
    }

    return {
      primary: unmanagedVirtualCard.details.accountEmail,
      secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.info.secondaryAdditionalInfo', {
        updatedAt: formatDate(unmanagedVirtualCard?.history.updatedAt, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
      }),
    };
  })();

  const icon = unmanagedVirtualCard ? (
    <BrandSymbol type={settings.icons.deliveryMethod['virtualCard']} />
  ) : (
    <Icon type="single-use-card" />
  );

  return (
    <>
      <MethodCard
        testIds={{ root: 'MethodCard-virtual-card' }}
        displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.title')}
        helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.feeText')}
        icon={icon}
        actionElement={
          unmanagedVirtualCard ? (
            <DeliveryMethodActionsMenu
              actions={[
                {
                  label: formatMessage('widgets.addOrUpdateDeliveryMethod.edit'),
                  onClick,
                  dataTestId: 'edit-delivery-method-item',
                },
              ]}
            />
          ) : (
            <AddDeliveryMethodActionButton
              data-testid={`add-delivery-method-card-${DeliveryMethodType.VirtualCard}`}
              onClick={onClick}
            />
          )
        }
      >
        <DeliveryMethodCaption {...caption} />
      </MethodCard>
      {!unmanagedVirtualCard && isModalOpen && (
        <VirtualCardInfoModal onClose={modalControls.off} addVirtualCardHandler={onClick} />
      )}
    </>
  );
};
