import {
  useGuestPayorPaymentRequestDetails,
  useGuestPayorVendorPaymentDetails,
  useMelioIntl,
  useSystemMessage,
} from '@melio/ar-domain';
import { useEffect } from 'react';

export const useUpdatedInvoiceSystemMessage = (paymentRequestLink: string) => {
  const { data: paymentRequestDetails } = useGuestPayorPaymentRequestDetails({
    paymentRequestLink,
  });
  const { data: vendorPaymentDetails } = useGuestPayorVendorPaymentDetails({
    paymentRequestLink,
  });
  const { triggerMessage } = useSystemMessage();
  const { formatMessage, formatDate } = useMelioIntl();

  useEffect(() => {
    if (
      paymentRequestDetails &&
      vendorPaymentDetails &&
      !paymentRequestDetails.receivablePaymentDetails &&
      paymentRequestDetails.invoice.editedAt
    ) {
      triggerMessage({
        title: formatMessage('ar.guestPayment.notifications.updatedAt.description.text', {
          updatedAtDate: formatDate(paymentRequestDetails.invoice.editedAt),
          companyName: vendorPaymentDetails.payeeDetails.companyName,
        }),
        type: 'informative',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequestDetails, vendorPaymentDetails]);
};
