import { Box } from '@chakra-ui/react';
import { FormattedMessage, PayorVendorPaymentDetails, useMelioIntl } from '@melio/ar-domain';
import { Group, SelectionCard, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useFormattedFee } from '../hooks';
import { GuestPayorPaymentOptions } from '../types';

export type FundingSourceSelectionProps = {
  fees?: PayorVendorPaymentDetails['fees'];
  selectedFundingSource?: GuestPayorPaymentOptions;
  onCardSelected: VoidFunction;
  onBankSelected: VoidFunction;
  isDisabled?: boolean;
};

export const FundingSourceSelection = forwardRef<FundingSourceSelectionProps>(
  ({ onBankSelected, onCardSelected, selectedFundingSource, fees, isDisabled, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { getFeeLabel } = useFormattedFee();

    return (
      <Group variant="vertical" spacing="m" data-testid="funding-source-tabs" {...props} ref={ref}>
        <Text textStyle="body2Semi" color="global.neutral.1000">
          <FormattedMessage id="ar.guestPayment.paymentMethods.tabs.title.text" />
        </Text>
        <Box pointerEvents={isDisabled ? 'none' : undefined}>
          <Group>
            <SelectionCard
              variant="flat"
              icon="credit-card"
              data-testid="card-funding-source-tab"
              mainLabelProps={{
                label: formatMessage('ar.guestPayment.paymentMethods.tabs.card.label'),
              }}
              onClick={onCardSelected}
              isSelected={selectedFundingSource === 'card'}
              descriptionProps={{ label: getFeeLabel(fees?.card) }}
            />
            <SelectionCard
              variant="flat"
              icon="bank"
              data-testid="bank-funding-source-tab"
              mainLabelProps={{
                label: formatMessage('ar.guestPayment.paymentMethods.tabs.bank.label'),
              }}
              onClick={onBankSelected}
              isSelected={selectedFundingSource === 'bank'}
              descriptionProps={{ label: getFeeLabel(fees?.ach) }}
            />
          </Group>
        </Box>
      </Group>
    );
  }
);

FundingSourceSelection.displayName = 'SelectFundingSource';
