/* eslint-disable max-lines */
import {
  getAccountingPlatformBrandSymbolType,
  getAccountingPlatformNameForAnalytics,
  getSyncMonitoringActionName,
  useAccountingPlatformPollingSync,
} from '@melio/ap-domain';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  PartnerName,
  SyncActivationTypeEnum,
  useAccount,
  useAccountingPlatform,
  useAccountingPlatformDuplicateVendors,
  useAccountingPlatformSyncActivation,
  useAccountingPlatformVendorsMappings,
  VendorsMappingsEntities,
} from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { usePartnerFeature } from '@melio/platform-provider';
import { useWizard } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { MonitoredAction } from '../../monitoring';
import { locationsMap, navigationMap } from './consts';
import { useInitialStep } from './hooks/useInitialStep';
import { useInitQuickBooksDesktop } from './hooks/useInitQuickBooksDesktop';
import { useParseAuthSearchParams } from './hooks/useParseAuthSearchParams';
import { useRegisterOrganization } from './hooks/useRegisterOrganization';
import { useVerifyQuickBooksDesktop } from './hooks/useVerifyQuickBooksDesktop';
import { ConfirmConnectStep } from './steps/ConfirmConnectStep';
import { ConnectToQuickBooksDesktopStep } from './steps/ConnectToQuickBooksDesktopStep';
import { InitialStep } from './steps/InitialStep';
import { SelectOrganizationStep } from './steps/SelectOrganizationStep';
import { SyncErrorStep } from './steps/SyncErrorStep';
import { SyncStep } from './steps/SyncStep';
import { VendorLinkingStep } from './steps/VendorLinkingStep';
import { AccountingPlatformSyncConnectionErrorEnum, ActivityStepsEnum, Steps, SyncFlowEnum } from './types';

type Props = {
  partnerName: PartnerName;
  onDone: ({
    flow,
    accountingPlatformName,
    accountingPlatformNameForAnalytics,
  }: {
    flow: SyncFlowEnum;
    accountingPlatformName: string;
    accountingPlatformNameForAnalytics: string;
  }) => void;
  onQuickBooksOnlineDone: ({
    flow,
    orgId,
    authToken,
    accountingPlatformId,
    accountingPlatformName,
    revalidateAccountingPlatforms,
  }: {
    flow: SyncFlowEnum;
    orgId?: string | null;
    authToken?: string | null;
    accountingPlatformId?: string;
    accountingPlatformName?: string;
    revalidateAccountingPlatforms?: boolean;
  }) => void;
  onError: (
    error: AccountingPlatformSyncConnectionErrorEnum,
    accountingPlatformNameForAnalytics: string,
    accountingPlatformId?: string
  ) => void;
  onClose: (flow: SyncFlowEnum) => void;
};

export const AccountingPlatformConnectActivity = withAnalyticsContext<Props>(
  ({ partnerName, onDone, onQuickBooksOnlineDone, onError, onClose, setAnalyticsProperties }) => {
    const [isDuplicateVendorsFeatureEnabled] = usePartnerFeature('DuplicateVendors', false);

    const { track } = useAnalytics();
    const { goNextMap, completeFlow } = useWizard<Steps, typeof navigationMap>({
      firstStep: ActivityStepsEnum.Initial,
      flowName: 'AccountingPlatformConnect',
      locationsMap,
      navigationMap,
      cancelUrlFallback: '',
      enableMidFlowEntry: true,
    });
    const { isLoading: isLoadingAccount, data: currentAccount } = useAccount({ id: 'me' });
    const {
      orgId,
      authToken,
      authError,
      accountingPlatformId,
      isQuickBooksOnline,
      isQuickBooksDesktop,
      flow,
      authParams,
    } = useParseAuthSearchParams() || {};
    const [popupEnabled] = usePartnerFeature('UseOAuthPopup', false);
    const { data: accountingPlatform, isLoading: isLoadingAccountingPlatform } = useAccountingPlatform({
      id: accountingPlatformId,
    });
    const { connectionStatus } = accountingPlatform || {};
    const accountingPlatformName = useAccountingPlatformName(accountingPlatform?.accountingSlug);
    const accountingPlatformBrandSymbolKey = getAccountingPlatformBrandSymbolType(accountingPlatform?.accountingSlug);

    const {
      data: duplicateVendors,
      hasDuplicateVendors,
      isFetching: isFetchingDuplicateVendors,
      refetch: refetchDuplicateVendors,
    } = useAccountingPlatformDuplicateVendors({
      props: {
        enabled: isDuplicateVendorsFeatureEnabled,
      },
    });
    const shouldQboGoToVendorLinking = !!hasDuplicateVendors && isDuplicateVendorsFeatureEnabled;

    const { mutateAsync: mapVendorsAsync, isLoading: isMapVendorsLoading } = useAccountingPlatformVendorsMappings();
    const { mutateAsync: syncActivation, isLoading: isSyncActivationLoading } = useAccountingPlatformSyncActivation();

    const accountingPlatformNameForAnalytics = useMemo(
      () => getAccountingPlatformNameForAnalytics(accountingPlatform?.accountingSlug),
      [accountingPlatform?.accountingSlug]
    );
    const actionName = getSyncMonitoringActionName(accountingPlatform?.accountingSlug, true) as MonitoredAction;
    const { registerOrganization, isRegisteringOrganization, isOrganizationRegistered } = useRegisterOrganization({
      accountingPlatformId,
      authParams,
    });
    const {
      initQuickBooksDesktop,
      isMutating: initQuickBooksDesktopLoading,
      initQuickBooksDesktopData,
    } = useInitQuickBooksDesktop({
      accountingPlatformId,
      organizationId: currentAccount?.organizationId || '',
    });
    const {
      verifyQuickBooksDesktop,
      isMutating: verifyQuickBooksDesktopLoading,
      isUnavailable: isQuickBooksDesktopUnavailable,
      verifyQuickBooksDesktopData,
      registrableQuickBooksDesktopOrganization,
    } = useVerifyQuickBooksDesktop({
      flow,
      accountingPlatformId,
      organizationId: currentAccount?.organizationId || '',
      onSuccess: () => {
        if (initQuickBooksDesktopData?.externalOrganizationId) {
          goNextMap[ActivityStepsEnum.Initial]({
            navArgs: [
              {
                isQuickBooksDesktop,
                isRegistrableQuickBooksDesktopOrganizationExists:
                  !!registrableQuickBooksDesktopOrganization?.externalId,
                isMultiOrgsAvailableToConnect: false,
              },
            ],
          });
        }
      },
    });
    const companyName = currentAccount?.company?.name;
    const { startAction, endAction } = useMonitoring<MonitoredAction>();

    const { triggerSync, isRunning } = useAccountingPlatformPollingSync({
      enabled: isOrganizationRegistered,
      accountingPlatformId: accountingPlatformId || '',
      onSyncDone: ({ analyticsProps }: { analyticsProps: Record<string, string | boolean> }) => {
        track('Dev', 'SyncAccountingSoftware', {
          accountingSoftwareType: accountingPlatformNameForAnalytics,
          component: 'AccountingPlatformConnectActivity',
          ...analyticsProps,
        });

        endAction(actionName);

        completeFlow(() => {
          onDone({ flow: flow || SyncFlowEnum.Setting, accountingPlatformName, accountingPlatformNameForAnalytics });
        });
      },
      onSyncError: () => {
        goNextMap[ActivityStepsEnum.Sync]();
      },
    });

    const turnOnSync = async () => {
      if (accountingPlatformId) {
        if (isDuplicateVendorsFeatureEnabled) {
          await syncActivation({
            accountingPlatformId,
            syncActivationType: SyncActivationTypeEnum.Activate,
          });
        }

        triggerSync();
      }
    };

    setAnalyticsProperties({
      Intent: 'sync',
      EntryPoint: 'settings-page',
      Flow: accountingPlatform?.accountingSlug ? `${accountingPlatform?.accountingSlug}-sync` : undefined,
    });

    useEffect(() => {
      if (isRunning) {
        startAction(actionName);
      }
    }, [isRunning, actionName, startAction]);

    const handleCancelClick = () => {
      onClose(flow || SyncFlowEnum.Setting);
    };

    const { registrableExternalOrganizations } = useInitialStep({
      authError,
      authParams,
      isQuickBooksOnline,
      isQuickBooksDesktop,
      accountingPlatformId,
      hasDuplicateVendors,
      onQuickBooksOnlineDone: async () => {
        if (shouldQboGoToVendorLinking) {
          goNextMap[ActivityStepsEnum.Initial]({
            navArgs: [{ shouldGoToVendorLinking: shouldQboGoToVendorLinking }],
          });
        } else {
          await turnOnSync();

          onQuickBooksOnlineDone({
            revalidateAccountingPlatforms: popupEnabled,
            flow: flow || SyncFlowEnum.Setting,
            orgId,
            authToken,
            accountingPlatformId,
            accountingPlatformName,
          });
        }
      },
      onError: (error: AccountingPlatformSyncConnectionErrorEnum) => {
        onError(error, accountingPlatformNameForAnalytics, accountingPlatformId);
      },
      onOrganizationsToConnectFound(isQuickBooksDesktop, organizationsCount) {
        goNextMap[ActivityStepsEnum.Initial]({
          navArgs: [{ isQuickBooksDesktop, isMultiOrgsAvailableToConnect: organizationsCount > 1 }],
        });
      },
    });

    const handleConnectSelectedOrganization = async (externalOrganizationId: string) => {
      await registerOrganization(externalOrganizationId);

      const refetchingDuplicateVendors = isDuplicateVendorsFeatureEnabled ? await refetchDuplicateVendors() : null;
      const shouldGoToVendorLinking = !!refetchingDuplicateVendors?.data?.length;

      if (!shouldGoToVendorLinking) {
        await turnOnSync();
      }

      goNextMap[ActivityStepsEnum.SelectOrganization]({
        navArgs: [{ shouldGoToVendorLinking }],
      });
    };

    const handleConfirmConnectOrganization = async (externalOrganizationId: string) => {
      await registerOrganization(externalOrganizationId);

      const refetchingDuplicateVendors = isDuplicateVendorsFeatureEnabled ? await refetchDuplicateVendors() : null;
      const shouldGoToVendorLinking = !!refetchingDuplicateVendors?.data?.length;

      if (!shouldGoToVendorLinking) {
        await turnOnSync();
      }

      goNextMap[ActivityStepsEnum.ConfirmConnect]({
        navArgs: [{ shouldGoToVendorLinking }],
      });
    };

    const handleTrySyncAgain = () => {
      triggerSync();
      goNextMap[ActivityStepsEnum.SyncError]();
    };

    const onDownloadWebConnector = () => {
      const link = document.createElement('a');
      const file = new Blob([initQuickBooksDesktopData?.qwcFileXMLContent || ''], { type: 'text/xml' });
      link.href = URL.createObjectURL(file);
      link.download = `${companyName || 'Melio'}-${partnerName}.qwc`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const onVendorsLinkingConfirm = async (entities: VendorsMappingsEntities) => {
      try {
        if (accountingPlatformId) {
          await mapVendorsAsync({
            entities,
          });

          await turnOnSync();

          if (isQuickBooksOnline) {
            onQuickBooksOnlineDone({
              flow: flow || SyncFlowEnum.Setting,
              orgId,
              authToken,
              accountingPlatformId,
            });
          } else {
            goNextMap[ActivityStepsEnum.VendorLinking]();
          }
        }
      } catch (error) {
        onError(
          AccountingPlatformSyncConnectionErrorEnum.GeneralError,
          accountingPlatformNameForAnalytics,
          accountingPlatformId
        );
      }
    };

    const isConfirmConnectScreenLoading =
      isLoadingAccountingPlatform ||
      isLoadingAccount ||
      !accountingPlatformName ||
      isFetchingDuplicateVendors ||
      isSyncActivationLoading;

    const isSelectOrganizationStepSubmitting =
      isRegisteringOrganization || isFetchingDuplicateVendors || isSyncActivationLoading;

    const isVendorLinkingStepLoading =
      isLoadingAccountingPlatform ||
      isLoadingAccount ||
      isFetchingDuplicateVendors ||
      isMapVendorsLoading ||
      isSyncActivationLoading;

    return (
      <Routes>
        <Route path={locationsMap[ActivityStepsEnum.Initial]} element={<InitialStep />} />
        <Route
          path={locationsMap[ActivityStepsEnum.SelectOrganization]}
          element={
            <SelectOrganizationStep
              isSubmitting={isSelectOrganizationStepSubmitting}
              externalOrganizations={registrableExternalOrganizations}
              accountingPlatformName={accountingPlatformName}
              onCancel={handleCancelClick}
              onSubmitOrganization={handleConnectSelectedOrganization}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.ConnectToQuickBooksDesktop]}
          element={
            <ConnectToQuickBooksDesktopStep
              flow={flow}
              isError={isQuickBooksDesktopUnavailable}
              isLoading={initQuickBooksDesktopLoading}
              isVerifyLoading={verifyQuickBooksDesktopLoading}
              connectionStatus={connectionStatus}
              onCancel={handleCancelClick}
              verifyQuickBooksDesktopData={verifyQuickBooksDesktopData}
              initQuickBooksDesktopData={initQuickBooksDesktopData}
              onVerifyConnection={verifyQuickBooksDesktop}
              initQuickBooksDesktop={initQuickBooksDesktop}
              onDownloadWebConnector={onDownloadWebConnector}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.ConfirmConnect]}
          element={
            <ConfirmConnectStep
              flow={flow}
              isLoading={isConfirmConnectScreenLoading}
              isRegistering={isRegisteringOrganization}
              companyName={companyName}
              isQuickBooksDesktop={isQuickBooksDesktop}
              externalOrganizations={
                isQuickBooksDesktop ? [registrableQuickBooksDesktopOrganization] : registrableExternalOrganizations
              }
              accountingPlatformName={accountingPlatformName}
              accountingPlatformNameForAnalytics={accountingPlatformNameForAnalytics}
              onCancel={handleCancelClick}
              onSubmitOrganization={handleConfirmConnectOrganization}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.VendorLinking]}
          element={
            <VendorLinkingStep
              flow={flow}
              isError={false}
              isLoading={isVendorLinkingStepLoading}
              duplicateVendors={duplicateVendors}
              accountingPlatformBrandSymbolKey={accountingPlatformBrandSymbolKey}
              isConfirmLoading={false}
              onCancel={handleCancelClick}
              onConfirm={onVendorsLinkingConfirm}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.Sync]}
          element={
            <SyncStep
              flow={flow}
              accountingPlatformName={accountingPlatformName}
              accountingPlatformNameForAnalytics={accountingPlatformNameForAnalytics}
              isLoadingAccountingPlatform={isLoadingAccountingPlatform}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.SyncError]}
          element={<SyncErrorStep onCancel={handleCancelClick} onTryAgain={handleTrySyncAgain} />}
        />
      </Routes>
    );
  }
);
