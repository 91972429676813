import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api-axios-client';

export type { AccountingPlatform };

export type {
  AccountingPlatformBankAccount,
  AccountingPlatformBillLabel,
  AccountingPlatformBillLabelOption,
  AccountingPlatformBillLineItemLabel,
  AccountingPlatformBillLineItemLabelOption,
  AccountingPlatformCategory,
  AccountingPlatformInitQuickBooksDesktopResponseData,
  AccountingPlatformItem,
  AccountingPlatformParseAuthParamsResponseData,
  AccountingPlatformParseAuthParamsResponseDataCompanies,
  AccountingPlatformPaymentAccount,
  AccountingPlatformSyncStatusResponseData,
  AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData,
  AccountingSoftwareDuplicatedVendorMatch,
  BillLineItemLabel,
  PostAccountingPlatformPaymentAccountRequest,
  PostAccountingPlatformPaymentAccountResponseData,
} from '@melio/platform-api-axios-client';
export {
  AccountingPlatformBillLineItemLabelOptionStatusEnum,
  VerifyQuickBooksDesktopConnectionStatus,
} from '@melio/platform-api-axios-client';

export type XeroAccountingPlatform = AccountingPlatform & { accountingSlug: 'xero' };
export type QuickBooksOnlineAccountingPlatform = AccountingPlatform & { accountingSlug: 'quickBooksOnline' };
export type QuickBooksDesktopAccountingPlatform = AccountingPlatform & { accountingSlug: 'quickBooksDesktop' };

export function isXeroAccountingPlatform(
  accountingPlatform?: AccountingPlatform
): accountingPlatform is XeroAccountingPlatform {
  return accountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero;
}

export function isQuickBooksOnlineAccountingPlatform(
  accountingPlatform?: AccountingPlatform
): accountingPlatform is QuickBooksOnlineAccountingPlatform {
  return accountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
}

export function isQuickBooksDesktopAccountingPlatform(
  accountingPlatform?: AccountingPlatform
): accountingPlatform is QuickBooksDesktopAccountingPlatform {
  return accountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop;
}
