import { Form, Group, useMelioForm } from '@melio/penny';
import { Address, CardHolderDetails, US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { FormWidgetProps } from '../../types';
import { AddressSearchWidget, AddressSearchWidgetProps } from '../form-controls';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    city: string().required(formatMessage('widgets.cardHolderDetailsForm.city.required')),
    firstName: string()
      .required(formatMessage('widgets.cardHolderDetailsForm.firstName.required'))
      .min(2, formatMessage('widgets.cardHolderDetailsForm.firstName.length')),
    lastName: string()
      .required(formatMessage('widgets.cardHolderDetailsForm.lastName.required'))
      .min(2, formatMessage('widgets.cardHolderDetailsForm.lastName.length')),
    line1: string().required(formatMessage('widgets.cardHolderDetailsForm.address.required')).nullable(),
    state: string().required(formatMessage('widgets.cardHolderDetailsForm.state.required')),
    postalCode: string().required(formatMessage('widgets.cardHolderDetailsForm.zipcode.required')),
  }) as SchemaOf<CardHolderDetails>;
};

export type CardHolderDetailsFormProps = FormWidgetProps<CardHolderDetails> & {
  headerContent?: React.ReactNode;
};

export const CardHolderDetailsForm = forwardRef<CardHolderDetailsFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, defaultValues, isSaving, headerContent, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField, setValue } = useMelioForm<CardHolderDetails>({
      onSubmit,
      schema: useSchema(),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - types will change in penny
    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address;
      if (!address) {
        setValue('line1', '', {
          shouldValidate: true,
        });
      } else {
        const setFormField = (field: keyof Omit<Address, 'line2' | 'countryCode'>) => {
          if (address[field]) {
            setValue(field, address[field], {
              shouldValidate: true,
            });
          }
        };

        setFormField('line1');
        setFormField('state');
        setFormField('city');
        setFormField('postalCode');
      }
    };

    return (
      <Group variant="vertical" spacing="s" data-testid="card-holder-details-form-widget">
        {headerContent}
        <Form data-component="CardHolderDetailsForm" {...props} {...formProps} columns={2} ref={ref}>
          <Form.TextField
            labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.firstName.label') }}
            placeholder={formatMessage('widgets.cardHolderDetailsForm.firstName.placeholder')}
            {...registerField('firstName')}
          />
          <Form.TextField
            labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.lastName.label') }}
            placeholder={formatMessage('widgets.cardHolderDetailsForm.lastName.placeholder')}
            {...registerField('lastName')}
          />
          <AddressSearchWidget
            {...registerField('line1')}
            labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.address.label') }}
            placeholder={formatMessage('widgets.cardHolderDetailsForm.address.placeholder')}
            onChange={handleAddressChange}
            // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
            formatSelectedValue={(option) =>
              (option.value as unknown as Address).line1 || (option.value as unknown as string)
            }
            colSpan={2}
          />
          <Form.TextField
            labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.city.label') }}
            placeholder={formatMessage('widgets.cardHolderDetailsForm.city.placeholder')}
            {...registerField('city')}
          />
          <Form.SelectNew
            labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.state.label') }}
            placeholder={formatMessage('widgets.cardHolderDetailsForm.state.placeholder')}
            {...registerField('state')}
            options={US_STATES.map((state) => ({
              value: state,
              label: formatMessage(`local.USA.states.${state}`),
              testId: state,
            }))}
          />
          <Form.TextField
            labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.zipcode.label') }}
            placeholder={formatMessage('widgets.cardHolderDetailsForm.zipcode.placeholder')}
            {...registerField('postalCode')}
            colSpan={2}
          />
        </Form>
      </Group>
    );
  }
);
CardHolderDetailsForm.displayName = 'CardHolderDetailsForm';
