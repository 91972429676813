import { useMelioIntl } from '@melio/ar-domain';
import { Container, Form, Group, Icon, ListItem } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useInvoiceFormContext } from '../../../utils';
import { usePayOptionDescriptionLabel } from './hooks';

type PayOptionCardProps = {
  payOptionType: 'bankTransfer' | 'card';
  isDisabled?: boolean;
  isFeeAbsorbedByPayor?: boolean;
};
const FieldName = {
  bankTransfer: `isAchAllowed`,
  card: `isCardAllowed`,
} as const;

export const PayOptionCard = forwardRef<PayOptionCardProps>(
  ({ payOptionType, isDisabled, isFeeAbsorbedByPayor }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { registerField, watch } = useInvoiceFormContext();
    const payOptionDescriptionLabel = usePayOptionDescriptionLabel({
      isLinkDisabled: Boolean(!watch(FieldName[payOptionType]) || isDisabled),
      isFeeAbsorbedByPayor,
      payOptionType,
    });

    return (
      <Container
        backgroundColor="default"
        border="regular"
        paddingX="m"
        paddingY="m"
        width="full"
        height="full"
        ref={ref}
      >
        <Group>
          <Group.Item shrink={0}>
            <Container width="fit-content" alignItems="center" height="full">
              <Icon aria-hidden size="large" type={payOptionType === 'card' ? 'credit-card' : 'bank'} />
            </Container>
          </Group.Item>
          <Group.Item grow={1}>
            <Container>
              <ListItem
                descriptionProps={{ label: payOptionDescriptionLabel }}
                mainLabelProps={{
                  variant: 'bold',
                  label: formatMessage(
                    `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.${payOptionType}.title.text`
                  ),
                }}
              />
            </Container>
          </Group.Item>
          <Group.Item shrink={0}>
            <Container width="fit-content" alignItems="center" height="full">
              <Form.Checkbox
                isChecked={isDisabled ? false : undefined}
                isDisabled={isDisabled}
                aria-label={formatMessage(
                  `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.${payOptionType}.title.text`
                )}
                {...registerField(FieldName[payOptionType])}
              />
            </Container>
          </Group.Item>
        </Group>
      </Container>
    );
  }
);
