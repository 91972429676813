export const formatPhone = (phone?: string) => {
  if (!phone) {
    return undefined;
  }

  const [, partOne, partTwo, partThree] = phone.match(/(\d{1,3})(\d{0,3})(\d*)/) || [];

  if (!partOne || !partTwo) {
    return undefined;
  }

  return `(${partOne}) ${partTwo}${partThree ? '-' + partThree : ''}`;
};
