import { convertCentsToDollars, convertDollarsToCents } from '@melio/platform-utils';

import { FeesBreakdown, FeesBreakdownRequest } from './types';

export const convertFeesBreakdownCentsToDollars = (data: FeesBreakdown) =>
  convertCentsToDollars(data, [
    'fees',
    'discounts',
    'feesToPay',
    'feesBreakdown[].amount',
    'feesBreakdown[].discount',
    'feesBreakdown[].amountToPay',
  ]);

export const convertFeesBreakdownRequestCentsToDollars = (params: FeesBreakdownRequest) => ({
  feesBreakdownParams: params.feesBreakdownParams.map((data) => convertDollarsToCents(data, ['paymentAmount'])),
});
