import { InternationalAddress } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { DecisionMakerForm, OTHER_DECISION_MAKER_KEY, Residency } from '../types';
import { useFxCommonValidations } from '../utils/formUtils';

export const useDecisionMakerFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const [showUboResidencyNewFields] = useDevFeature<boolean>(FeatureFlags.UboResidencyNewFields, false);
  const { firstNameSchema, ssnSchema, addressSchema, lastNameSchema, dateOfBirthSchema, residencySchema } =
    useFxCommonValidations();

  const schema = yup.object().shape({
    uboId: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.decisionMakerField.required'
        )
      ),
    companyRole: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.companyRoleField.required'
        )
      ),
    decisionMakerFirstName: whenDecisionMakerIsOther({
      then: firstNameSchema,
    }),
    decisionMakerLastName: whenDecisionMakerIsOther({
      then: lastNameSchema,
    }),
    decisionMakerDayOfBirth: whenDecisionMakerIsOther({
      then: dateOfBirthSchema,
    }),
    decisionMakerSsn: whenDecisionMakerIsOther({
      then: showUboResidencyNewFields ? yup.string().nullable() : ssnSchema,
    }),
    decisionMakerAddress: yup.object().optional().nullable().when('uboId', {
      is: isOtherUbo,
      then: addressSchema,
    }) as yup.SchemaOf<InternationalAddress>,
    decisionMakerResidencyDetails: yup.object().optional().nullable().when('uboId', {
      is: isOtherUbo,
      then: residencySchema,
    }) as yup.SchemaOf<Residency>,
  }) as yup.SchemaOf<DecisionMakerForm>;

  return schema;
};

const isOtherUbo = (id: string) => id === OTHER_DECISION_MAKER_KEY;

const whenDecisionMakerIsOther = ({ then }: { then: yup.AnySchema }) =>
  yup.mixed().when('uboId', {
    is: isOtherUbo,
    then,
    otherwise: yup.string().optional().nullable(),
  });
