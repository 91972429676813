import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';

export const createPdfFile = (data: string, file: string, isDownload = true): void => {
  const blob = new Blob([Buffer.from(data, 'base64')], { type: 'application/pdf' });
  const fileName = `${file}.pdf`;
  if (isDownload) {
    saveAs(blob, fileName);
  } else {
    // view file in a new tab
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    window.open(link.href, '_blank');

    setTimeout(() => URL.revokeObjectURL(link.href), 5000);
  }
};
