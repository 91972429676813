import { BusinessesApiClient, extractData } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type UseSearchBusinessesProps = Pick<UseQueryOptions<typeof BusinessesApiClient.searchBusinesses>, 'enabled'> & {
  params: {
    searchTerm?: string;
  };
};

export const useSearchBusinesses = ({ params, enabled = true }: UseSearchBusinessesProps) => {
  const { searchTerm } = params;

  const isEnabled = enabled === true && !!searchTerm;
  const queryKey = ['BusinessesApi', 'SearchBusinesses', searchTerm];

  const query = useQuery({
    queryKey,
    enabled: isEnabled,
    queryFn: async () => {
      const { data } = await BusinessesApiClient.searchBusinesses(searchTerm ?? '');
      return data.data;
    },
  });

  const isFetching = useIsFetching(queryKey) > 0;

  return {
    ...query,
    isLoading: query.isInitialLoading,
    isFetching,
    fetch: (searchTerm?: string) => BusinessesApiClient.searchBusinesses(searchTerm ?? '').then(extractData),
  };
};
