import { useVendor } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useEffect, useState } from 'react';

import { isValidAccountNumber } from './Payment.utils';

export const useVendorUnMaskedAccountNumber = ({ vendorId }: { vendorId?: string }) => {
  const [unmaskedAccountNumber, setUnmaskedAccountNumber] = useState<string>();
  const {
    settings: { unmaskVendorAccountNumber },
  } = useConfig();
  const {
    data: vendor,
    isLoading,
    getVendorUnmaskedAccountNumber,
  } = useVendor({
    id: vendorId,
    enabled: unmaskVendorAccountNumber,
  });

  const isUnmanagedVendor = unmaskVendorAccountNumber && vendor && vendor.managedBy === null;
  useEffect(() => {
    const fetchAndSetUnmaskedAccountNumber = async () => {
      if (isUnmanagedVendor) {
        const accountNumber = vendor?.accountNumber || '';
        if (isValidAccountNumber(accountNumber)) {
          setUnmaskedAccountNumber(accountNumber);
        } else {
          const { accountNumber: unmaskedAccountNumber } = await getVendorUnmaskedAccountNumber();
          setUnmaskedAccountNumber(unmaskedAccountNumber);
        }
      }
    };

    fetchAndSetUnmaskedAccountNumber();
  }, [isUnmanagedVendor, getVendorUnmaskedAccountNumber, vendor?.accountNumber]);

  return { isLoading, unmaskedAccountNumber };
};
