import { ReceivablePayment } from '@melio/ar-api-axios-client';
import { PillProps } from '@melio/penny';

import { useMelioIntl } from './i18n';

export const usePaymentStatusLabel = () => {
  const { formatMessage } = useMelioIntl();

  const getPaymentStatusVariant = (status: ReceivablePayment['status']): PillProps['status'] => {
    const statusMap: Record<ReceivablePayment['status'], PillProps['status']> = {
      completed: 'success',
      'in-progress': 'informative',
      failed: 'critical',
      scheduled: 'informative',
    };
    return statusMap[status];
  };

  const getPaymentStatusLabel = (status: ReceivablePayment['status']): string => {
    const labelMap: Record<ReceivablePayment['status'], string> = {
      completed: formatMessage('ar.dashboard.activities.paymentStatus.completed.label'),
      'in-progress': formatMessage('ar.dashboard.activities.paymentStatus.inProgress.label'),
      failed: formatMessage('ar.dashboard.activities.paymentStatus.failed.label'),
      scheduled: formatMessage('ar.dashboard.activities.paymentStatus.scheduled.label'),
    };
    return labelMap[status];
  };

  return {
    getPaymentStatusVariant,
    getPaymentStatusLabel,
  };
};
