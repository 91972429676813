import { useIsOwner } from '@melio/platform-api';

import { getQuantityBasedIsFeatureInPlan } from '../utils';
import { usePlansTiers } from './usePlansTiers';

/**
 * Hook to check if the new plan will remove the current user from the organization.
 * The logic is based on the current user not being the owner of the organization and the new plan not supporting multiple users.
 * @param newPlanId - The new plan id.
 * @returns true if the new plan will remove the current user from the organization, false otherwise.
 */
export const useIsPlanWillRemoveCurrentUser = (newPlanId: string) => {
  const isOwner = useIsOwner();
  const { getPlan } = usePlansTiers();

  if (isOwner) {
    return false;
  }

  const newPlan = getPlan(newPlanId);

  const { isEligible: isSubscriptionSupportMultiUsers } = getQuantityBasedIsFeatureInPlan(newPlan, 'collaborators', 2);

  return !isSubscriptionSupportMultiUsers;
};
