import { isEbill } from '@melio/ap-domain';
import { EbillCellBadge } from '@melio/ap-widgets';
import { Button, Group, Icon, Popover, StatusIconSolid, Table, TableCellProps, Text, Tooltip } from '@melio/penny';
import { Bill, PaymentIntent, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { identity } from 'lodash';

import { CellError } from '../../../../useSubmitBatchPaymentsErrors/types';

export type BillDetailsCellProps = TableCellProps & {
  vendor: Vendor;
  paymentIntent: PaymentIntent;
  bills: Bill[];
  onViewBillDetailsClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
  error?: CellError;
};

export const BillDetailsCell = forwardRef<BillDetailsCellProps, 'div'>(
  ({ paymentIntent, bills, vendor, onViewBillDetailsClick, error, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const {
      settings: {
        batchPayments: { showVendorDetails },
      },
    } = useConfig();

    const isDraftBill = paymentIntent.status === 'draft';
    const shouldShowInvoiceNumberTooltip = !!bills[0] && !!bills[0].invoice.number && bills[0].paymentRequest;

    const showBillDetails = () => {
      if (bills[0]) {
        onViewBillDetailsClick(paymentIntent.id, bills[0]);
      }
    };

    const onClick = bills.length === 1 && !isDraftBill ? showBillDetails : undefined;
    const showEbill = bills.length === 1 && bills[0] ? isEbill(bills[0]) : false;

    const invoiceNumber =
      bills.length === 1 && bills[0] && bills[0].invoice.number
        ? formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.number', {
            invoiceNumber: bills[0].invoice.number,
          })
        : formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.placeholder');
    const vendorDetails = () => [vendor.nickname, vendor.accountNumber, invoiceNumber].filter(identity).join(' | ');

    return (
      <Table.Cell
        data-testid="bill-details-cell"
        {...props}
        data-component="BillDetailsCell"
        onClick={onClick}
        ref={ref}
      >
        <Group width="full" variant="vertical" spacing="xxs">
          <Group alignItems="center" spacing="xxs">
            <Text shouldSupportEllipsis textStyle="body3" color="inherit">
              {vendor.name}
            </Text>
            {showEbill && <EbillCellBadge vendor={vendor} isReadOnly />}
            {error && (
              <Tooltip content={error.label} shouldAddTriggerFocus>
                <StatusIconSolid variant={error.variant} size="small" data-testid="vendor-cell-tooltip-icon" />
              </Tooltip>
            )}
          </Group>

          {bills.length === 1 && bills[0] ? (
            <Group spacing="xs" alignItems="center">
              <Text
                shouldSupportEllipsis
                textStyle="body4"
                color={bills[0].invoice.number ? 'global.neutral.900' : 'global.neutral.800'}
              >
                {showVendorDetails ? vendorDetails() : invoiceNumber}
              </Text>
              {shouldShowInvoiceNumberTooltip && (
                <Popover
                  description={formatMessage(
                    'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.description'
                  )}
                  actionRenderer={({ onClose, ...props }) => (
                    <Button
                      {...props}
                      size="small"
                      variant="secondary-inverse"
                      label={formatMessage(
                        'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.actionLabel'
                      )}
                      onClick={() => {
                        onClose();
                        showBillDetails();
                      }}
                    />
                  )}
                  triggerEvent="hover"
                >
                  <Icon type="info" color="informative" size="small" data-testid="invoice-number-tooltip" />
                </Popover>
              )}
            </Group>
          ) : (
            <Text textStyle="body4" color="global.neutral.900">
              {formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.numberOfBills', {
                numberOfBills: bills.length,
              })}
            </Text>
          )}
        </Group>
      </Table.Cell>
    );
  }
);

BillDetailsCell.displayName = 'BillDetailsCell';
