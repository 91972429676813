import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformDuplicateVendorsProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatformDuplicatedVendors
>;

export const useAccountingPlatformDuplicateVendors = (params?: {
  props?: UseAccountingPlatformDuplicateVendorsProps;
  onError?: () => void;
}) => {
  const { props, onError } = params || {};

  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatformDuplicatedVendors>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'getAccountingPlatformDuplicatedVendors'],
    queryFn: () => AccountingPlatformsApiClient.getAccountingPlatformDuplicatedVendors(),
    onError,
  });

  return {
    ...query,
    hasDuplicateVendors: query.data ? !!query.data?.length : null,
  };
};
