import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Group, NakedButton, Text } from '@melio/penny';
import React from 'react';

import { PaymentOptionSwitcher } from './PaymentOptionSwitcher';

export type InvoiceCustomInstructionsProps = {
  customPaymentDetails: string;
  onEditCustomPaymentDetails: VoidFunction;
  onRemoveCustomPaymentDetails: VoidFunction;
  isACHPaymentAllowed: boolean;
  isCardPaymentAllowed: boolean;
  onToggleACHPaymentsAllowed: (enabled: boolean) => void;
  onToggleCardPaymentsAllowed: (enabled: boolean) => void;
  isUpdating: boolean;
};

export const InvoicePaymentOptions = ({
  isACHPaymentAllowed,
  isCardPaymentAllowed,
  customPaymentDetails,
  onEditCustomPaymentDetails,
  onRemoveCustomPaymentDetails,
  onToggleACHPaymentsAllowed,
  onToggleCardPaymentsAllowed,
  isUpdating,
}: InvoiceCustomInstructionsProps) => {
  const { formatMessage } = useMelioIntl();
  const hasCustomPaymentDetails = !!customPaymentDetails;

  return (
    <Group variant="vertical" spacing="m">
      <Group variant="vertical" spacing="xs">
        <Text textStyle="body1Semi" as="h3">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.paymentOptions.title.text" />
        </Text>
        <Text textStyle="body3">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.paymentOptions.subtitle.text" />
        </Text>
      </Group>
      <Group variant="vertical">
        <PaymentOptionSwitcher
          paymentOptionType="ACH"
          iconType="bank"
          title={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.container.ACH.title.text'
          )}
          value={isACHPaymentAllowed}
          onChange={onToggleACHPaymentsAllowed}
          isUpdating={isUpdating}
        />
        <PaymentOptionSwitcher
          paymentOptionType="card"
          iconType="credit-card"
          title={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.container.Card.title.text'
          )}
          value={isCardPaymentAllowed}
          onChange={onToggleCardPaymentsAllowed}
          isUpdating={isUpdating}
        />
        {hasCustomPaymentDetails ? (
          <EditCustomPaymentDetails
            onRemoveCustomPaymentDetails={onRemoveCustomPaymentDetails}
            onEditCustomPaymentDetails={onEditCustomPaymentDetails}
            customPaymentDetails={customPaymentDetails}
          />
        ) : (
          <AddCustomPaymentDetailsButton onEditCustomPaymentDetails={onEditCustomPaymentDetails} />
        )}
      </Group>
    </Group>
  );
};

export const AddCustomPaymentDetailsButton = ({
  onEditCustomPaymentDetails,
}: Pick<InvoiceCustomInstructionsProps, 'onEditCustomPaymentDetails'>) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NakedButton
      data-testid="add-invoice-custom-payment-details-button"
      variant="secondary"
      onClick={onEditCustomPaymentDetails}
      label={formatMessage(
        'ar.settings.flows.invoiceSettings.sections.paymentOptions.customPaymentDetails.add.nakedButton.label'
      )}
    />
  );
};

export const EditCustomPaymentDetails = ({
  customPaymentDetails,
  onEditCustomPaymentDetails,
  onRemoveCustomPaymentDetails,
}: Pick<
  InvoiceCustomInstructionsProps,
  'customPaymentDetails' | 'onEditCustomPaymentDetails' | 'onRemoveCustomPaymentDetails'
>) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="l">
      <Group variant="vertical" spacing="none">
        <Text textStyle="body4Semi" color="global.neutral.800">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.paymentOptions.customPaymentDetails.editMode.title.text" />
        </Text>
        <Text data-testid="invoice-custom-payment-details-text-value" textStyle="body2">
          {customPaymentDetails}
        </Text>
      </Group>
      <Group>
        <Button
          data-testid="remove-invoice-custom-payment-details-button"
          onClick={onRemoveCustomPaymentDetails}
          variant="critical-secondary"
          label={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.customPaymentDetails.editMode.buttons.remove.text'
          )}
        />
        <Button
          data-testid="edit-invoice-custom-payment-details-button"
          onClick={onEditCustomPaymentDetails}
          variant="secondary"
          label={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.customPaymentDetails.editMode.buttons.edit.text'
          )}
        />
      </Group>
    </Group>
  );
};
