import { ApiError, useMelioQueryClient } from '@melio/api-client';
import {
  PaymentsApiClient,
  PayorApiClient,
  PostPayorPaymentRequest,
  PostPayorPayPaymentRequest,
  PostPayorPayPaymentResponseData,
} from '@melio/ar-api-axios-client';
import { useMutation } from '@tanstack/react-query';

import { useModel, UseModelProps } from '../../api-client';

export type UseGuestPayorPaymentProps = UseModelProps<typeof PaymentsApiClient.getPaymentsPaymentId>;

export const useGuestPayorPayment = (props: UseGuestPayorPaymentProps = {}) => {
  const query = useModel({
    ...props,
    queryKey: ['PaymentApi', props.id],
    queryFn: PaymentsApiClient.getPaymentsPaymentId,
  });

  const queryClient = useMelioQueryClient();

  const { mutateAsync: createPayment, isLoading: isCreatingPayment } = useMutation<
    PostPayorPayPaymentResponseData,
    ApiError,
    PostPayorPayPaymentRequest | PostPayorPaymentRequest
  >(
    async (params) => {
      if ('paymentRequestLink' in params) {
        const { paymentRequestLink, scheduledDate, fundingSourceId } = params;
        const response = await PayorApiClient.postPayorPayPayment({
          fundingSourceId,
          paymentRequestLink,
          scheduledDate,
        });
        return response.data.data;
      }

      const { fundingSourceId, guestPaymentIntentId, scheduledDate } = params;

      const response = await PayorApiClient.postPayorPayment({ fundingSourceId, guestPaymentIntentId, scheduledDate });
      return response.data.data;
    },
    {
      mutationKey: ['PayorApiClient:postPayorPayPayment'],
      onSuccess: () => {
        void queryClient.invalidateQueries('PublicPayorApi');
      },
    }
  );

  return {
    ...query,
    createPayment,
    isCreatingPayment,
  };
};
