/* eslint-disable max-lines */
import { Container, Group, Icon, Image, Link, Loader, Modal, SectionBanner, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  GetContractorsInfo1099Response,
  useVendors1099ReportableContractors,
  useVendorsContractorsInfo1099,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { useEffect } from 'react';

import { PremiumBadge } from './components/PremiumBadge.widget';

type Sync1099ContractorsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isRedirecting: boolean;
  isSyncedWithZenwork: boolean;
  onFeatureIsBlockedCb?: () => void;
  onImportContractors: () => void;
  isDashboardView: boolean;
};

const instructionsList = [
  'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.capabilities.start',
  'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.capabilities.payments',
] as MessageKey[];

export const Sync1099ContractorsModal = ({
  isOpen,
  onImportContractors,
  isRedirecting,
  onClose,
  isDashboardView,
  isSyncedWithZenwork,
}: Sync1099ContractorsModalProps) => {
  const { data: contractorsInfo1099 = {}, isLoading: isLoadingContractorsInfo } = useVendorsContractorsInfo1099({});
  const { hasContractorsToReport, isLoading: isLoadingReportableContractors } = useVendors1099ReportableContractors({
    enabled: isOpen,
  });

  const { pendingW9RequestsCount = 0, contractorsWithInvalidTINCount = 0 }: GetContractorsInfo1099Response['data'] =
    contractorsInfo1099 || {};
  const {
    settings: {
      File1099SettingsTaxAndReports: { assets },
      zenworkPricingPolicies,
      contractorLearnMoreLink,
      contractorsPendingW9Link,
      contractorsInvalidTINLink,
    },
  } = useConfig();

  const getUserMessage = () => {
    if (!hasContractorsToReport) {
      return 'no-eligible-vendors-or-payments';
    }
    if (contractorsWithInvalidTINCount > 0) {
      return 'failed-tin-match';
    }
    if (pendingW9RequestsCount > 0) {
      return 'pending-w9-request';
    }
    if (isSyncedWithZenwork) {
      return 'update-contractors-info';
    }
    return undefined;
  };

  const analyticsViewProp = 'Vendor';
  const analyticsEntryPointProp = isDashboardView ? 'Dashboard' : 'Settings';
  const analyticsProps = {
    PageName: 'sync-with-tax-1099',
    Intent: 'sync-1099',
    EntryPoint: analyticsEntryPointProp,
    Flow: '1099' as const,
    ...(getUserMessage() && { UserMessage: getUserMessage() }),
  };

  const { createTrackHandler, track } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'sync-now' | 'update-now' | 'message-learn-more' | 'cancel' | 'exit' | 'learn-more' | 'got-it';
    PageName: string;
    Intent: string;
    Flow: '1099';
    UserMessage?: string;
  }>(analyticsViewProp, 'Click', analyticsProps);

  const { tryUseFeature: tryUseTax1099Feature } = useSubscriptionFeature({
    featureName: 'tax1099',
  });
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();

  const handleSectionBannerLearnMoreClick = () => {
    trackAndHandleClick({ Cta: 'message-learn-more' });
  };

  useEffect(() => {
    if (!isLoadingReportableContractors && isOpen) {
      track(analyticsViewProp, 'View', analyticsProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, track, isLoadingReportableContractors]);

  const getSectionBannerDescriptionText = () => {
    if (!hasContractorsToReport) {
      return formatMessage(
        'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.noReportableVendors.informationDescription'
      );
    }
    if (contractorsWithInvalidTINCount > 0) {
      return formatMessage(
        'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.invalidTINs.informationDescription',
        {
          learnMoreLink: (
            <Link
              newTab
              onClick={handleSectionBannerLearnMoreClick}
              label={formatMessage('widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.learnMore')}
              href={contractorsInvalidTINLink}
            />
          ),
        }
      );
    }
    if (pendingW9RequestsCount > 0) {
      return formatMessage(
        'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.pendingW9.informationDescription',
        {
          learnMoreLink: (
            <Link
              newTab
              onClick={handleSectionBannerLearnMoreClick}
              label={formatMessage('widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.learnMore')}
              href={contractorsPendingW9Link}
            />
          ),
        }
      );
    }
    if (isSyncedWithZenwork) {
      return formatMessage(
        'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.synced.informationDescription'
      );
    }

    return formatMessage(
      'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.informationDescription'
    );
  };

  const onImportContractorsWithTracking = () => {
    if (!hasContractorsToReport) {
      trackAndHandleClick({ Cta: 'got-it' }, onClose);
    } else {
      trackAndHandleClick({ Cta: isSyncedWithZenwork ? 'update-now' : 'sync-now' }, onImportContractors);
    }
  };

  const onImportContractorsRequest = () => {
    tryUseTax1099Feature({
      onFeatureIsEligible: onImportContractorsWithTracking,
    });
  };

  const getSectionBannerType = () => {
    if (!hasContractorsToReport) {
      return 'critical';
    }
    if (contractorsWithInvalidTINCount > 0) {
      return 'warning';
    }
    return 'informative';
  };

  const isFetchingContractorsInfo = isLoadingContractorsInfo || isLoadingReportableContractors;

  const getCTAMessage = () => {
    if (!hasContractorsToReport && !isFetchingContractorsInfo) {
      return formatMessage('widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.unavailable.cta');
    }

    return formatMessage(
      isSyncedWithZenwork
        ? 'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.synced.cta'
        : 'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.cta'
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
      data-testid="sync-1099-contractors-modal"
      header={
        <Group
          data-testid="sync-1099-contractors-modal-title"
          variant="horizontal"
          spacing="s"
          justifyContent="flex-start"
        >
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage(
              isSyncedWithZenwork
                ? 'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.synced.title'
                : 'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.title'
            )}
          </Text>
          <PremiumBadge />
        </Group>
      }
      primaryButton={{
        label: getCTAMessage(),
        variant: 'primary',
        isLoading: isRedirecting,
        isDisabled: isFetchingContractorsInfo,
        onClick: onImportContractorsRequest,
      }}
      secondaryButton={{
        label: formatMessage('widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.cancel'),
        variant: 'tertiary',
        onClick: () => trackAndHandleClick({ Cta: 'cancel' }, onClose),
      }}
    >
      <Group variant="vertical" spacing="m">
        <Image
          src={assets?.zenworkSyncWithTax1099Banner ?? ''}
          width="512"
          height={isExtraSmallScreen ? 'auto' : '200'}
          alt={formatMessage('widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.title')}
        />
        <Group as="ul" variant="vertical" spacing="none">
          <Container paddingBottom="xs">
            <Text textStyle="body2Semi">
              {formatMessage('widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.howItWorks')}
            </Text>
          </Container>
          {instructionsList.map((item) => (
            <Container key={item} paddingBottom="xs" as="li">
              <Group alignItems="center" spacing="xs" key={item}>
                <Icon type="checked" size="small" color="brand" aria-hidden="true" />
                <Text textStyle="body3">{formatMessage(item)}</Text>
              </Group>
            </Container>
          ))}
          <Container as="li">
            <Group alignItems="center" spacing="xs">
              <Icon type="checked" size="small" color="brand" aria-hidden="true" />
              <Text textStyle="body3">
                {formatMessage(
                  'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.capabilities.file',
                  {
                    learnMoreLink: (
                      <Link
                        href={contractorLearnMoreLink}
                        label={formatMessage(
                          'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.learnMore'
                        )}
                        color="secondary"
                        newTab
                      />
                    ),
                  }
                )}
              </Text>
            </Group>
          </Container>
        </Group>

        <Container>
          <Text textStyle="body3" color="global.neutral.800">
            {formatMessage('widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.fees', {
              pricingPoliciesLink: (
                <Link
                  href={zenworkPricingPolicies}
                  label={formatMessage(
                    'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.pricingPolicies'
                  )}
                  newTab
                />
              ),
            })}
          </Text>
        </Container>

        {isFetchingContractorsInfo ? (
          <Loader />
        ) : (
          <SectionBanner
            title={
              !hasContractorsToReport
                ? formatMessage(
                    'widgets.taxAndReportsSettings.taxFormSection.sync1099ContractorsModal.noReportableVendors.title'
                  )
                : undefined
            }
            variant={getSectionBannerType()}
            data-testid="sync-1099-contractors-modal-section-banner"
            description={getSectionBannerDescriptionText()}
          />
        )}
      </Group>
    </Modal>
  );
};
