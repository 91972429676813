import {
  createProvider,
  FormattedCurrency as _FormattedCurrency,
  FormattedDate,
  useDynamicSettings,
} from '@melio/i18n-tools';
import Big, { BigSource } from 'big.js';
import { ComponentProps, FC, useCallback, VFC } from 'react';

import { ConversionUtils } from '../conversion-utils';
import { usePartnerConfig } from '../partner-config';
import { globalMessages } from './global-messages';
import localMessages from './messages.json';

type Messages = typeof localMessages;
export type MessageKey = keyof Messages;

const messages = { ...globalMessages, ...localMessages } as const;
const { AsyncLocalizationProvider, useMelioIntl: _useMelioIntl, FormattedMessage } = createProvider<Messages>(messages);

const useMelioIntl = () => {
  const {
    formatCurrency: _formatCurrency,
    formatPercents: _formatPercents,
    formatDate: _formatDate,
    ...intl
  } = _useMelioIntl();

  const formatDate = useCallback<typeof _formatDate>(
    (value: string, options = { dateStyle: 'medium' }, ...args) => _formatDate(value, options, ...args),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const formatCurrency = useCallback(
    (value: number, currency?: string, options?: Parameters<typeof _formatCurrency>[2]) => {
      const targetValue = ConversionUtils.fromCentsToDollars(value);
      return _formatCurrency(targetValue, currency, options);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  type FormatPercentsOptions = NonNullable<Parameters<typeof _formatPercents>[1]>;
  type FormatPercents = (value: number, options?: Omit<FormatPercentsOptions, 'divide'>) => string;

  const formatPercents = useCallback<FormatPercents>(
    (value, options = {}) => _formatPercents(value, { maximumFractionDigits: 3, ...options }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { ...intl, formatCurrency, formatPercents, formatDate };
};

type LocalizationProviderProps = Omit<ComponentProps<typeof AsyncLocalizationProvider>, 'translationsSrc'>;
const LocalizationProvider: FC<LocalizationProviderProps> = ({ shouldShowContentKeys, ...props }) => {
  const dynamicSettings = useDynamicSettings(window.localStorage, window.location.href);
  return (
    <AsyncLocalizationProvider
      {...props}
      translationsSrc={usePartnerConfig().translationsSrc}
      shouldShowContentKeys={shouldShowContentKeys ?? dynamicSettings.shouldShowContentKeys}
    />
  );
};

type FormattedCurrencyProps = Override<ComponentProps<typeof _FormattedCurrency>, { amount: BigSource }>;

const FormattedCurrency: VFC<FormattedCurrencyProps> = ({ amount, ...props }) => (
  <_FormattedCurrency amount={ConversionUtils.fromCentsToDollars(amount)} {...props} />
);

type FormattedPercentsProps = {
  value: BigSource;
};

const FormattedPercents = ({ value }: FormattedPercentsProps) => (
  <>{useMelioIntl().formatPercents(new Big(value).toNumber())}</>
);

export { FormattedCurrency, FormattedDate, FormattedMessage, FormattedPercents, LocalizationProvider, useMelioIntl };
