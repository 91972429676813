import { FileInfo, InvoiceExpandedSummary, ReceivablePayment } from '@melio/ar-domain';
import { Drawer, LoadingContainer } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React, { useEffect } from 'react';

import { DrawerBody, DrawerHeader } from '../components';

export type PaymentDrawerScreenProps = {
  isOpen: boolean;
  isLoadingPayment: boolean;
  isLoadingInvoice?: boolean;
  isLoadingPayeeFile?: boolean;
  isLoadingPayorFile?: boolean;
  onCloseButtonClick: () => void;
  onClose: VoidFunction;
  receivablePayment?: ReceivablePayment;
  expandedInvoice?: InvoiceExpandedSummary;
  payeeFile?: FileInfo;
  payorFile?: FileInfo;
};

export const PaymentDrawerScreen = forwardRef<PaymentDrawerScreenProps>(
  (
    {
      payeeFile,
      payorFile,
      isLoadingPayment,
      isLoadingInvoice,
      isLoadingPayorFile,
      isLoadingPayeeFile,
      onCloseButtonClick,
      expandedInvoice,
      isOpen,
      receivablePayment,
      onClose: onCloseComplete,
    },
    ref
  ) => {
    const { track } = useAnalytics();

    useEffect(() => {
      if (receivablePayment) {
        track('Payment', 'View', {
          ProductName: 'ar',
          PageName: 'payment-details',
          Flow: 'manage-payments',
          Intent: 'payment-drawer',
          PaymentId: receivablePayment.id,
          PaymentStatus: receivablePayment.status,
          PaymentAmount: receivablePayment.amount,
          PaymentMethod: receivablePayment.method,
          InvoiceId: receivablePayment.invoices?.[0]?.id,
        });
      }
    }, [receivablePayment]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Drawer
        isOpen={isOpen}
        onClose={onCloseButtonClick}
        onCloseComplete={onCloseComplete}
        body={
          <LoadingContainer isLoading={isLoadingPayment}>
            {!isLoadingPayment && (
              <DrawerBody
                isLoadingPayorFile={isLoadingPayorFile}
                receivablePayment={receivablePayment}
                expandedInvoice={expandedInvoice}
                payeeFile={payeeFile}
                payorFile={payorFile}
                isLoadingPayeeFile={isLoadingPayeeFile}
                isLoadingInvoice={isLoadingInvoice}
              />
            )}
          </LoadingContainer>
        }
        header={<DrawerHeader />}
        data-testid="payment-drawer-screen"
        ref={ref}
      />
    );
  }
);
PaymentDrawerScreen.displayName = 'PaymentDrawerScreen';
