import { CatalogItem, FormattedCurrency, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, Group, Icon, Pill, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useCatalogItemActionsItems } from '../hooks';

export type CatalogItemsTableListItemProps = {
  catalogItem: CatalogItem;
  onEditItem: (itemId: string) => unknown;
  onDeleteItem: (itemId: string) => unknown;
};

export const CatalogItemsTableListItem = forwardRef<CatalogItemsTableListItemProps>(
  ({ catalogItem, onEditItem, onDeleteItem, ...props }, ref) => {
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const { formatMessage } = useMelioIntl();
    const actions = useCatalogItemActionsItems({ catalogItem, onEditItem, onDeleteItem });

    return (
      <ActionsDropdownMenu
        isOpen={isActionsMenuOpen}
        onOpenChange={setIsActionsMenuOpen}
        trigger={<CatalogItemsTableListItemContent catalogItem={catalogItem} ref={ref} {...props} />}
        label={formatMessage('ar.settings.activities.catalogItemsTable.listItem.actionsMenu.label')}
        items={actions}
        ref={ref}
        {...props}
      />
    );
  }
);
CatalogItemsTableListItem.displayName = 'CatalogItemsTableListItem';

type CatalogItemsTableListItemContentProps = {
  catalogItem: CatalogItem;
};

const CatalogItemsTableListItemContent = forwardRef<CatalogItemsTableListItemContentProps>(
  ({ catalogItem, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group
        spacing="xs"
        variant="vertical"
        {...props}
        ref={ref}
        data-testid={`catalog-item-list-item-${catalogItem.id}`}
        data-component={CatalogItemsTableListItemContent.displayName}
      >
        <Group spacing="xxs" alignItems="center">
          <Group justifyContent="space-between" width="full">
            <Text textStyle="body2Semi" data-testid="catalog-item-name">
              {catalogItem.name}
            </Text>
            <Text textStyle="body2Semi" data-testid="catalog-item-price">
              <FormattedCurrency amount={catalogItem.price} />
            </Text>
          </Group>

          <Icon type="chevron-right" size="small" />
        </Group>
        {catalogItem.taxable && (
          <Pill
            data-testid="catalog-item-taxable-badge"
            type="secondary"
            status="neutral"
            label={formatMessage('ar.settings.activities.catalogItemsTable.listItem.taxable.badge.label')}
          />
        )}
      </Group>
    );
  }
);
CatalogItemsTableListItemContent.displayName = 'CatalogItemsTableListItemContent';
