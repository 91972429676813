import { ComponentType, createContext, useContext } from 'react';

import { usePaymentSessionId } from './usePaymentSessionId';

const PaymentFlowContext = createContext<{ paymentSessionId?: string }>({});

export const usePaymentFlowContext = () => useContext(PaymentFlowContext);

export function withPaymentFlowContext<T extends object>(UI: ComponentType<T>) {
  return function PaymentSessionWrapper(props: T) {
    const paymentSessionId = usePaymentSessionId();

    return (
      <PaymentFlowContext.Provider value={{ paymentSessionId }}>
        <UI {...props} />
      </PaymentFlowContext.Provider>
    );
  };
}
