import { useAddDeliveryMethodUtils } from '@melio/ap-widgets/src/components/delivery-methods/cards/AddDeliveryMethodCard/utils';
import { Form, Text } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import {
  AddVendorPaypalAccountForm,
  usePaypalBalanceAccountForm,
} from '../../../../../hooks/usePaypalBalanceAccountForm.hook';
import { DeliveryMethodCardProps } from '../../types';
import { DeliveryMethodSectionCard } from '../DeliveryMethodSectionCard';

export const PaypalBalanceAccountCard = ({
  onSubmit,
  isExpanded,
  onAdd,
  onCancel,
  isDisabled,
  values,
}: DeliveryMethodCardProps<AddVendorPaypalAccountForm>) => {
  const { formProps, registerField, handleSubmit } = usePaypalBalanceAccountForm({
    onSubmit,
    defaultValues: values,
  });
  const { formatMessage } = useMelioIntl();
  // TODO: MarikSh Change supported to come from supported types
  const { getIconKey, getLabel, getFee } = useAddDeliveryMethodUtils({
    type: DeliveryMethodType.PaypalBalance,
    supported: true,
    suspense: true,
  });

  const _onSubmit = handleSubmit();

  const fee = getFee(DeliveryMethodType.PaypalBalance);
  return (
    <DeliveryMethodSectionCard
      isExpanded={isExpanded}
      onAdd={onAdd}
      onCancel={onCancel}
      onSubmit={_onSubmit}
      isDisabled={isDisabled}
      label={getLabel()}
      icon={getIconKey()}
      description={values?.email}
      pillProps={
        fee
          ? {
              label: fee,
            }
          : undefined
      }
      testId="paypal-balance-account-card"
    >
      <Form {...formProps}>
        <Form.ContentBox>
          <Text textStyle="body3" color="semantic.text.secondary">
            {formatMessage('activities.deliveryMethodsWidget.forms.paypalBalanceAccount.description')}
          </Text>
        </Form.ContentBox>
        <Form.TextField
          {...registerField('email')}
          labelProps={{
            label: formatMessage('activities.deliveryMethodsWidget.forms.paypalBalanceAccount.field.email.label'),
          }}
          helperTextProps={{
            label: formatMessage('activities.deliveryMethodsWidget.forms.paypalBalanceAccount.field.email.helper'),
          }}
        />
      </Form>
    </DeliveryMethodSectionCard>
  );
};
