import { IconKey } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodByPayor, DeliveryMethodTypeOption, formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const getHasAllPossibleTypes = (deliveryMethods: DeliveryMethodByPayor[]) =>
  !!(
    deliveryMethods.find((deliveryMethod) => deliveryMethod.type === 'bank-account') &&
    deliveryMethods.find((deliveryMethod) => deliveryMethod.type === 'paper-check')
  );

export const getDropdownOptions = (
  deliveryMethods: DeliveryMethodByPayor[],
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[],
  selectedDeliveryMethodId?: DeliveryMethodByPayor['id']
): DeliveryMethodByPayor[] => {
  const legalDeliveryMethods = deliveryMethods.filter(
    (dm) => deliveryMethodTypeOptions.find((opt) => opt.type === dm.type)?.supported
  );

  return legalDeliveryMethods.length === 1 && legalDeliveryMethods[0]?.id === selectedDeliveryMethodId
    ? []
    : legalDeliveryMethods;
};

export const useDeliveryMethodOptions = () => {
  const { formatMessage } = useMelioIntl();

  return {
    getOptionLabel: (deliveryMethod: DeliveryMethodByPayor) => {
      switch (deliveryMethod.type) {
        case 'bank-account':
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.bank-account.option`,
            { last4digits: deliveryMethod.details.accountNumberLast4Digits }
          );
        case 'paper-check':
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.paper-check.option`,
            { address: formatAddress(deliveryMethod.details.address) }
          );
        case 'domestic-wire-account':
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.domestic-wire-account.option`,
            { last4digits: deliveryMethod.details.accountNumber.slice(-4) }
          );
        default:
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.${deliveryMethod.type}.option`
          );
      }
    },

    getOptionToDisplayOnSelectLabel: ({ type }: DeliveryMethod) =>
      formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.${type}.selected`),

    getOptionIconKey: ({ type }: DeliveryMethodByPayor): IconKey => {
      switch (type) {
        case 'bank-account':
          return 'bank';
        case 'paper-check':
          return 'paper-check';
        case 'international-account':
          return 'international';
        case 'virtual-card':
          return 'single-use-card';
        default:
          return 'email';
      }
    },
  };
};
