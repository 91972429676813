import { usePartnerFeature } from '@melio/platform-provider';

import { usePayDashboardFilters } from '../../PayDashboardFilterProvider';
import { useNewPaymentFilters } from './useNewPaymentFilters';

export const usePaymentsTabFiltersQueryParams = () => {
  const [isPaymentsTabFiltersEnabled] = usePartnerFeature('PaymentsTabFilters', false);
  const filtersContext = usePayDashboardFilters();
  const paymentsTabFilters = useNewPaymentFilters();

  let filtersQueryParams = {};

  if (!filtersContext?.filters || !Object.keys(filtersContext.filters).length || !isPaymentsTabFiltersEnabled) {
    return { filtersQueryParams, activeFilters: {}, hasActiveFilters: false };
  }

  const filters = filtersContext.filters;
  Object.entries(filters).forEach(([key, selectedValues]) => {
    const paymentsTabFilter = paymentsTabFilters.find((f) => f.key === key);
    if (paymentsTabFilter) {
      filtersQueryParams = {
        ...filtersQueryParams,
        ...paymentsTabFilter.calcQueryParams(selectedValues)?.filterQueryParams,
      };
    }
  });
  return { filtersQueryParams, activeFilters: filters, hasActiveFilters: filtersContext.hasActiveFilters };
};
