import { withPaymentFlowContext } from '@melio/ap-domain';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { SeriesPaymentUpdatedSuccessfullyScreen } from '../../edit-bill-subscription/screens/newPaymentUpdated/SeriesPaymentUpdatedSuccessfully.screen';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { useEditBillSubscriptionDefaults } from './useEditBillSubscriptionDefaults';
import { useEditBillSubscriptionSubmit } from './useEditBillSubscriptionSubmit';

type EditRecurringActivityProps = {
  billSubscriptionId: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const EditRecurringActivity = withPaymentFlowContext<EditRecurringActivityProps>(
  ({ billSubscriptionId, onDone, onClose }: EditRecurringActivityProps) => {
    const { formatMessage } = useMelioIntl();
    const title = formatMessage('activities.paymentFlow.form.header.title.editRecurringPayment');
    const navigate = useNavigate();

    useSetDocumentTitle(title);

    const {
      result: billSubscription,
      error: submitError,
      onSubmit,
      isSubmitting,
    } = useEditBillSubscriptionSubmit({ billSubscriptionId });

    const { defaultValues, isLoading } = useEditBillSubscriptionDefaults({ billSubscriptionId });

    if (isLoading) {
      return <PaymentFlowLoader />;
    }

    return (
      <Routes>
        <Route
          path="/*"
          element={
            <PaymentFlowActivity
              defaultValues={defaultValues}
              billSubscription={billSubscription}
              title={title}
              isSubmitting={isSubmitting}
              submitError={submitError}
              onSubmit={(data) => onSubmit(data).then(() => navigate('success'))}
              onClose={onClose}
            />
          }
        />
        <Route
          path="/success"
          element={
            billSubscription ? (
              <SeriesPaymentUpdatedSuccessfullyScreen billSubscription={billSubscription} onBackToDashboard={onDone} />
            ) : null
          }
        />
      </Routes>
    );
  }
);
