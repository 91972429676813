import {
  FileInfo,
  FormattedCurrency,
  FormattedMessage,
  MessageKey,
  ReceivablePayment,
  ReceivablePaymentStatus,
  useMelioIntl,
  usePaymentStatusLabel,
} from '@melio/ar-domain';
import { Group, Icon, Link, Loader, Pill, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { downloadFile } from '../utils';

export type PaymentInformationProps = {
  receivablePayment: ReceivablePayment;
  payeeFile?: FileInfo;
  isLoadingPayeeFile?: boolean;
  payorFile?: FileInfo;
  isLoadingPayorFile?: boolean;
  customerName?: string;
};

const PaymentHeaderTitle = () => (
  <Text as="h3" textStyle="heading3Semi">
    <FormattedMessage id="ar.dashboard.activities.paymentDrawer.body.header.label" />
  </Text>
);

const PaymentStatusBadge = ({ status }: { status: ReceivablePaymentStatus }) => {
  const { getPaymentStatusLabel, getPaymentStatusVariant } = usePaymentStatusLabel();

  return (
    <Pill
      type="secondary"
      status={getPaymentStatusVariant(status)}
      label={getPaymentStatusLabel(status)}
      data-testid="payment-drawer-status-badge"
    />
  );
};

const PaymentAmountSummary = ({ amount }: { amount: number }) => (
  <Group alignItems="center" justifyContent="space-between">
    <Text as="h2" textStyle="body2Semi">
      <FormattedMessage id="ar.dashboard.activities.paymentDrawer.body.header.label" />
    </Text>
    <Text textStyle="body2Semi" data-testid="payment-drawer-total-amount">
      <FormattedCurrency amount={amount} />
    </Text>
  </Group>
);

const PaymentInfoRow = ({
  messageKey,
  primaryText,
  secondaryText,
  sectionType,
}: {
  messageKey: MessageKey;
  primaryText?: string | null;
  secondaryText?: string;
  sectionType: string;
}) => {
  if (!primaryText) return null;

  return (
    <Group variant="vertical" spacing="xxs" data-testid={`payment-drawer-${sectionType}-section`}>
      <Text as="h4" textStyle="body4Semi" color="global.neutral.700">
        <FormattedMessage id={messageKey} />
      </Text>
      <Group variant="vertical" spacing="none">
        <Text
          as="h2"
          textStyle="body2"
          color="global.neutral.1000"
          data-testid={`payment-drawer-${sectionType}-primary-value`}
        >
          {primaryText}
        </Text>
        <Text
          as="h3"
          textStyle="body3"
          color="global.neutral.700"
          data-testid={`payment-drawer-${sectionType}-secondary-value`}
        >
          {secondaryText}
        </Text>
      </Group>
    </Group>
  );
};

const PaymentDetailsSection = ({
  isAchPayment,
  paymentDate,
  scheduleDate,
}: {
  isAchPayment: boolean;
  paymentDate: string;
  scheduleDate: string;
}) => (
  <Group variant="vertical" spacing="m" data-testid="payment-drawer-payment-details-section">
    <Text as="h3" textStyle="body3Semi">
      <FormattedMessage id="ar.dashboard.activities.paymentDrawer.body.titles.paymentDetails.label" />
    </Text>
    <Group alignItems="center">
      <Icon type="bank" />
      <Group variant="vertical" spacing="xxs">
        <Text as="h4" textStyle="body4Semi" color="global.neutral.800">
          <FormattedMessage
            id={
              isAchPayment
                ? 'ar.dashboard.activities.paymentDrawer.body.titles.paymentDetails.paymentType.title.bank.label'
                : 'ar.dashboard.activities.paymentDrawer.body.titles.paymentDetails.paymentType.title.card.label'
            }
          />
        </Text>
        <Text as="h2" textStyle="body2" color="global.neutral.1000" data-testid="payment-type">
          <FormattedMessage
            id={
              isAchPayment
                ? 'ar.dashboard.activities.paymentDrawer.body.titles.paymentDetails.paymentType.value.bank.label'
                : 'ar.dashboard.activities.paymentDrawer.body.titles.paymentDetails.paymentType.value.card.label'
            }
          />
        </Text>
      </Group>
    </Group>
    <Group alignItems="center">
      <Icon type={isAchPayment ? 'scheduled' : 'calendar-move'} />
      <Group variant="vertical" spacing="xxs">
        <Text as="h4" textStyle="body4Semi" color="global.neutral.800">
          <FormattedMessage id="ar.dashboard.activities.paymentDrawer.body.titles.paymentDetails.paymentDate.title.label" />
        </Text>
        <Text as="h2" textStyle="body2" color="global.neutral.1000" data-testid="payment-creation-date">
          {paymentDate}
        </Text>
        <Text as="h4" textStyle="body4" color="global.neutral.700" data-testid="payment-scheduled-date">
          <FormattedMessage
            id="ar.dashboard.activities.paymentDrawer.body.titles.paymentDetails.paymentDate.value.scheduleDate.label"
            values={{ date: scheduleDate }}
          />
        </Text>
      </Group>
    </Group>
  </Group>
);

const PaymentLinkRow = ({
  messageKey,
  file,
  isLoading,
  fileNameFallback,
  testid,
}: {
  messageKey: MessageKey;
  file?: FileInfo;
  isLoading?: boolean;
  fileNameFallback: string;
  testid: string;
}) => {
  const renderContent = () => {
    if (isLoading) return <Loader />;

    if (!file)
      return (
        <Text as="h2" textStyle="body2" color="global.neutral.1000" data-testid={`payment-drawer-${testid}-fallback`}>
          {fileNameFallback}
        </Text>
      );

    return (
      <Link
        href="#"
        newTab
        color="secondary"
        data-testid={`payment-drawer-${testid}`}
        label={file.fileName as string}
        onClick={(e) => {
          e.preventDefault();
          downloadFile(file.url, file.fileName as string);
        }}
      />
    );
  };

  return (
    <Group variant="vertical" spacing="xxs" alignItems="baseline">
      <Text as="h4" textStyle="body4Semi" color="global.neutral.700">
        <FormattedMessage id={messageKey} />
      </Text>
      {renderContent()}
    </Group>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
{
  /*  test download link implementation when get real data*/
}
export const PaymentInformation = forwardRef<PaymentInformationProps>(
  ({ customerName, isLoadingPayorFile, payorFile, isLoadingPayeeFile, payeeFile, receivablePayment }, ref) => {
    const { formatDate } = useMelioIntl();
    const [invoiceDetails] = receivablePayment.invoices ?? [];

    return (
      <Group variant="vertical" spacing="m" ref={ref}>
        <PaymentHeaderTitle />
        <PaymentStatusBadge status={receivablePayment.status} />
        <PaymentAmountSummary amount={receivablePayment.amount} />
        <PaymentInfoRow
          messageKey="ar.dashboard.activities.paymentDrawer.body.titles.payer.label"
          primaryText={receivablePayment.payorDetails?.companyName}
          secondaryText={receivablePayment.payorDetails?.email}
          sectionType="payor"
        />
        <PaymentInfoRow
          messageKey="ar.dashboard.activities.paymentDrawer.body.titles.invoiceReference.label"
          primaryText={receivablePayment.referenceNumber}
          sectionType="invoice-reference-number"
        />
        <PaymentInfoRow
          messageKey="ar.dashboard.activities.paymentDrawer.body.titles.customer.label"
          primaryText={customerName}
          sectionType="customer"
        />
        {invoiceDetails ? (
          <PaymentLinkRow
            messageKey="ar.dashboard.activities.paymentDrawer.body.titles.invoiceNumber.label"
            file={payeeFile}
            isLoading={isLoadingPayeeFile}
            fileNameFallback={invoiceDetails.invoiceNumber || '-'}
            testid="payee-file"
          />
        ) : null}
        {receivablePayment.paymentAttachmentIds?.[0] ? (
          <PaymentLinkRow
            messageKey="ar.dashboard.activities.paymentDrawer.body.titles.payorFileAttachment.label"
            file={payorFile}
            isLoading={isLoadingPayorFile}
            fileNameFallback={payorFile?.fileName || '-'}
            testid="payor-file"
          />
        ) : null}
        <PaymentInfoRow
          messageKey="ar.dashboard.activities.paymentDrawer.body.titles.note.label"
          primaryText={receivablePayment.note}
          sectionType="note"
        />
        <PaymentDetailsSection
          isAchPayment={receivablePayment.method === 'ach'}
          paymentDate={formatDate(receivablePayment.creationDate)}
          scheduleDate={formatDate(receivablePayment.scheduleDate)}
        />
      </Group>
    );
  }
);
