import {
  AccountingPlatform,
  AccountingPlatformConnectionStatus,
  isQuickBooksDesktopAccountingPlatform,
  isQuickBooksOnlineAccountingPlatform,
  isXeroAccountingPlatform,
} from '@melio/platform-api';

export const getQboAccountingId = (accountingPlatformData?: AccountingPlatform[]) => {
  const quickBooksOnlineAccountingPlatform = accountingPlatformData?.find(isQuickBooksOnlineAccountingPlatform);
  const qboAccountingId = quickBooksOnlineAccountingPlatform?.id;
  const qboAccountDisconnect =
    quickBooksOnlineAccountingPlatform?.connectionStatus === AccountingPlatformConnectionStatus.Disconnected ||
    quickBooksOnlineAccountingPlatform?.connectionStatus === AccountingPlatformConnectionStatus.Unlinked;

  return qboAccountDisconnect ? qboAccountingId : undefined;
};

export const getXeroAccountingPlatform = (accountingPlatforms?: AccountingPlatform[]) =>
  accountingPlatforms?.find(isXeroAccountingPlatform);

export const getQuickBooksDesktopAccountingPlatform = (accountingPlatforms?: AccountingPlatform[]) =>
  accountingPlatforms?.find(isQuickBooksDesktopAccountingPlatform);
