import { Box } from '@chakra-ui/react';
import { Group, Link, Text } from '@melio/penny';
import { FormattedMessage, useConfig, useMelioIntl } from '@melio/platform-provider';

type IconProps = {
  height?: string;
  width?: string;
};

type Props = {
  textStyle?: 'caption1' | 'body4';
  privacyIconProps?: IconProps;
  pciIconProps?: IconProps;
};

export const PCIComplienceInfo: React.VFC<Props> = ({ textStyle = 'body4', privacyIconProps, pciIconProps }) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      pciCompliance: { link },
    },
  } = useConfig();
  return (
    <Group alignItems="center" data-testid="pci-legal-info">
      <Box as="span" color="legalInfo.icon.color">
        <PrivacyIcon {...privacyIconProps} />
      </Box>
      <Text as="p" textStyle={textStyle}>
        <FormattedMessage
          id="widgets.legal.info"
          values={{
            link: <Link href={link} label={formatMessage('widgets.legal.link')} newTab />,
          }}
        />
      </Text>
      <PciIcon {...pciIconProps} />
    </Group>
  );
};

const PrivacyIcon = ({ width = '30', height = '37' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 30 37" fill="currentColor" aria-label="privacy-icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6 0.813232L29.2 4.51601V21.3902C29.2 29.5486 22.6768 36.1868 14.6 36.1868C6.52316 36.1868 0 29.5486 0 21.3902V4.51601L14.6 0.813232ZM2 6.0721V21.3902C2 28.4711 7.6547 34.1868 14.6 34.1868C21.5453 34.1868 27.2 28.4711 27.2 21.3902V6.0721L14.6 2.87655L2 6.0721Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.275 22.3632C14.9792 22.3632 15.55 21.7924 15.55 21.0882C15.55 20.3841 14.9792 19.8132 14.275 19.8132C13.5708 19.8132 13 20.3841 13 21.0882C13 21.7924 13.5708 22.3632 14.275 22.3632Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.8132H21.4065V26.71H8V16.8132ZM10 18.8132V24.71H19.4065V18.8132H10Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0847 10.8132C14.0186 10.8132 13 11.7915 13 13.1976V18.71H11V13.1976C11 10.8654 12.7435 8.81323 15.0847 8.81323C17.4258 8.81323 19.1694 10.8654 19.1694 13.1976V18.71H17.1694V13.1976C17.1694 11.7915 16.1508 10.8132 15.0847 10.8132Z"
    />
  </svg>
);
// XXX move icons to design system
const PciIcon = ({ width = '90', height = '35' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 90 35" fill="currentColor" aria-label="pci-icon">
    <path
      d="M52.0472 22.0579L49.107 23.0031C49.6891 21.9512 50.3906 20.7927 51.092 19.8933L52.0472 22.0579Z"
      fill="#717173"
    />
    <path
      d="M13.3749 17.477V13.8337H14.6286C14.6286 13.8337 16.4345 13.9099 16.5838 15.1752C16.6136 15.2666 16.6136 15.8916 16.5838 15.9221C16.345 17.4008 14.7778 17.477 14.7778 17.477H13.3749Z"
      fill="#717173"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.346 0L48.107 13.1098C47.4517 14.0233 45.4879 16.794 43.6405 19.5077V10.8003H38.4168V22.4485C37.6984 21.9521 36.9428 21.6027 36.2147 21.4768V20.8918C36.2147 20.8461 36.1848 20.8308 36.155 20.8613C36.155 20.8613 35.5878 21.3186 33.1103 21.5778C32.4088 21.654 32.0655 21.6235 31.8118 21.5778C28.2895 20.968 28.1253 18.3461 28.1253 18.3461C28.1253 18.3209 28.1208 18.2865 28.1168 18.2555C28.1134 18.2303 28.1104 18.2073 28.1104 18.1936V17.1875C28.1104 17.1418 28.1104 17.0656 28.1253 17.0351C28.1253 17.0351 28.3641 14.215 31.8118 13.9101H33.1103C34.6177 14.1082 35.8415 14.8704 35.8415 14.8704C35.8863 14.8857 35.9162 14.8704 35.9162 14.8247V11.2881C35.9162 11.2424 35.8863 11.1966 35.8415 11.1662C35.8415 11.1662 35.155 10.7088 33.0655 10.5869C32.961 10.5259 31.1253 10.5107 30.6327 10.5869C23.021 11.2119 22.7374 16.8369 22.7374 17.0808V18.4528C22.7374 18.6205 22.7374 24.3674 30.6327 24.8705C31.2661 24.9203 32.3864 24.8888 32.8583 24.8755C32.9644 24.8726 33.0381 24.8705 33.0655 24.8705C33.0728 24.8705 33.0801 24.8705 33.0874 24.8705C33.2537 25.8137 33.7103 26.8255 34.3462 27.7591L11.8542 35L0.167969 2.43902L42.346 0ZM44.2226 6.39479C44.2226 8.10211 42.7898 9.47407 41.0287 9.47407C39.2675 9.47407 37.8347 8.08687 37.8347 6.39479C37.8347 4.68746 39.2675 3.31551 41.0287 3.31551C42.7898 3.31551 44.2226 4.68746 44.2226 6.39479ZM8.22576 24.7026C8.22576 24.7484 8.25561 24.7788 8.30039 24.7788H13.3898C13.4346 24.7788 13.4644 24.7484 13.4644 24.7026V20.6477C13.4644 20.602 13.4943 20.5715 13.5391 20.5715C13.5391 20.5715 21.6732 21.166 21.6732 15.602C21.6732 11.1965 16.5688 10.7239 14.8972 10.8001H8.30039C8.25561 10.8001 8.22576 10.8306 8.22576 10.8764V24.7026Z"
      fill="#717173"
    />
    <path
      d="M41.7947 31.1968C42.2126 31.1968 42.541 31.1968 43.0783 30.9529C44.929 29.9621 51.1825 14.3675 57.7794 9.56568C57.8242 9.5352 57.8689 9.48947 57.8988 9.44373C57.9436 9.38276 57.9436 9.32178 57.9436 9.32178C57.9436 9.32178 57.9436 9.00166 56.9734 9.00166C51.1079 8.83398 45.0036 21.4102 41.7947 26.3797C41.7499 26.4407 41.541 26.3797 41.541 26.3797C41.541 26.3797 39.3918 23.7883 37.5262 22.7974C37.4814 22.7822 37.2724 22.706 37.0486 22.7212C36.8993 22.7212 36.0337 22.9041 35.6307 23.331C35.1531 23.8492 35.168 24.1389 35.168 24.7639C35.168 24.8096 35.1979 25.023 35.2576 25.1297C35.7202 25.9529 37.7948 28.8797 39.5112 30.4956C39.7649 30.6938 40.1679 31.1968 41.7947 31.1968Z"
      fill="#C2C2C3"
    />
    <path
      d="M80.3439 12.4695L83.4781 12.2713C83.5528 12.7896 83.6871 13.186 83.896 13.4603C84.2393 13.9024 84.7169 14.1158 85.3438 14.1158C85.8064 14.1158 86.1795 14.0091 86.4333 13.7805C86.687 13.5518 86.8213 13.2927 86.8213 13.003C86.8213 12.7286 86.7019 12.4695 86.4631 12.2408C86.2243 12.0122 85.6572 11.814 84.7766 11.6158C83.3289 11.2805 82.314 10.8384 81.6871 10.2896C81.0752 9.74084 80.7618 9.03962 80.7618 8.18597C80.7618 7.62194 80.926 7.10365 81.2394 6.60061C81.5528 6.09756 82.0304 5.71646 82.6722 5.42683C83.3139 5.13719 84.1796 5 85.2841 5C86.6422 5 87.687 5.25915 88.3884 5.77744C89.1048 6.29573 89.5227 7.1189 89.6571 8.24694L86.5527 8.42987C86.4631 7.94207 86.299 7.59146 86.0303 7.3628C85.7617 7.13414 85.4034 7.02743 84.9408 7.02743C84.5527 7.02743 84.2692 7.10365 84.0751 7.27134C83.8811 7.43902 83.7916 7.63719 83.7916 7.88109C83.7916 8.04877 83.8662 8.21646 84.0304 8.35365C84.1796 8.49084 84.5527 8.62804 85.1348 8.74999C86.5676 9.07011 87.5974 9.39023 88.2243 9.71035C88.8511 10.0305 89.2989 10.4421 89.5824 10.9146C89.866 11.3872 90.0004 11.936 90.0004 12.5305C90.0004 13.2317 89.8063 13.8719 89.4332 14.4512C89.0601 15.0457 88.5228 15.4878 87.8512 15.7927C87.1795 16.0975 86.3139 16.25 85.2841 16.25C83.4781 16.25 82.2244 15.8994 81.5229 15.1829C80.8215 14.4664 80.4334 13.567 80.3439 12.4695Z"
      fill="#717173"
    />
    <path
      d="M72.9918 12.2714L69.8575 12.4696C69.962 13.5671 70.35 14.4665 71.0366 15.183C71.7381 15.8994 72.9918 16.25 74.7977 16.25C75.8276 16.25 76.6932 16.0976 77.3649 15.7927C78.0365 15.4879 78.5738 15.0458 78.9469 14.4513C79.3201 13.872 79.5141 13.2318 79.5141 12.5305C79.5141 11.936 79.3798 11.3873 79.0962 10.9147C78.8126 10.4421 78.3649 10.0306 77.738 9.71043C77.1111 9.39031 76.0813 9.07018 74.6485 8.75006C74.0664 8.62811 73.6933 8.49092 73.544 8.35373C73.3799 8.21653 73.3052 8.04885 73.3052 7.88117C73.3052 7.63726 73.3948 7.43909 73.5888 7.27141C73.7828 7.10373 74.0664 7.02751 74.4545 7.02751C74.9171 7.02751 75.2753 7.13422 75.544 7.36288C75.8126 7.59153 75.9768 7.94214 76.0664 8.42995L79.1708 8.24702C79.0365 7.11897 78.6186 6.2958 77.9022 5.77751C77.2007 5.25922 76.1559 5.00008 74.7977 5.00008C73.6933 5.00008 72.8276 5.13727 72.1858 5.4269C71.5441 5.71654 71.0664 6.09764 70.753 6.60068C70.4396 7.10373 70.2754 7.62202 70.2754 8.18604C70.2754 9.0397 70.5739 9.74091 71.2008 10.2897C71.8276 10.8385 72.8425 11.2805 74.2903 11.6159C75.1709 11.8141 75.738 12.0123 75.9768 12.2409C76.2156 12.4696 76.335 12.7287 76.335 13.0031C76.335 13.2927 76.2007 13.5519 75.947 13.7805C75.6933 14.0092 75.3201 14.1159 74.8574 14.1159C74.2306 14.1159 73.753 13.9025 73.4097 13.4604C73.2008 13.186 73.0664 12.7897 72.9918 12.2714Z"
      fill="#717173"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.0484 5.19806H59.168V16.0517H64.0484C64.6305 16.0517 65.2872 15.9603 66.0036 15.7621C66.526 15.6097 67.0185 15.32 67.4812 14.8932C67.9439 14.4511 68.3021 13.9176 68.5558 13.2773C68.8095 12.6371 68.9438 11.7377 68.9438 10.5792C68.9438 9.83221 68.8543 9.13099 68.6752 8.4755C68.511 7.82001 68.2274 7.24075 67.8394 6.7377C67.4663 6.24989 66.9737 5.86879 66.3767 5.5944C65.7947 5.33526 65.0186 5.19806 64.0484 5.19806ZM62.4515 13.5822V7.65233H63.2723C64.138 7.65233 64.735 7.86574 65.1081 8.29257C65.4812 8.7194 65.6603 9.49685 65.6603 10.6554C65.6603 11.5243 65.5708 12.1645 65.4066 12.5456C65.2424 12.9267 65.0036 13.2011 64.7201 13.3536C64.4365 13.506 63.944 13.5822 63.2574 13.5822H62.4515Z"
      fill="#717173"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.2849 18.3539C62.9864 18.6588 62.8371 19.0856 62.8371 19.6344C62.8371 20.0307 62.8968 20.3204 63.061 20.5948C63.2252 20.8691 63.4192 21.0521 63.658 21.174C63.9118 21.296 64.2103 21.357 64.5835 21.357C64.9417 21.357 65.2551 21.2807 65.4939 21.1435C65.7477 21.0063 65.9268 20.8082 66.0611 20.5643C66.1954 20.3204 66.2551 20.0002 66.2551 19.6039C66.2551 19.0551 66.1059 18.6435 65.8074 18.3387C65.5089 18.049 65.0909 17.8966 64.5387 17.8966C64.0013 17.8966 63.5834 18.049 63.2849 18.3539ZM64.0312 20.3661C63.9118 20.2137 63.8521 19.9698 63.8521 19.6344C63.8521 19.299 63.9118 19.0551 64.0312 18.9332C64.1506 18.7807 64.3148 18.7045 64.5237 18.7045C64.7327 18.7045 64.9118 18.7655 65.0312 18.9179C65.1656 19.0551 65.2253 19.2838 65.2253 19.6039C65.2253 19.9698 65.1656 20.2289 65.0462 20.3813C64.9267 20.5185 64.7626 20.5948 64.5387 20.5948C64.3148 20.5948 64.1506 20.5185 64.0312 20.3661Z"
      fill="#717173"
    />
    <path
      d="M62.4819 20.2138L61.5864 19.9394C61.5417 20.1528 61.467 20.3053 61.3477 20.4425C61.2581 20.5492 61.1089 20.6102 60.9148 20.6102C60.7208 20.6102 60.5567 20.5339 60.4522 20.3968C60.3477 20.2596 60.288 20.0004 60.288 19.6346C60.288 19.3449 60.3328 19.1163 60.4223 18.9791C60.5417 18.7809 60.7208 18.6894 60.9447 18.6894C61.0342 18.6894 61.1238 18.7047 61.2133 18.7504C61.3029 18.7961 61.3626 18.8571 61.4223 18.9333C61.467 18.9791 61.4969 19.0553 61.5268 19.162L62.4222 18.9638C62.3177 18.598 62.1387 18.3388 61.8999 18.1711C61.6611 18.0035 61.3477 17.912 60.9298 17.912C60.4074 17.912 59.9895 18.0492 59.706 18.3541C59.4224 18.6589 59.2731 19.0858 59.2731 19.6346C59.2731 20.0461 59.3627 20.3967 59.5269 20.6559C59.691 20.9303 59.885 21.1132 60.1089 21.2199C60.3477 21.3266 60.6313 21.3724 60.9895 21.3724C61.288 21.3724 61.5267 21.3266 61.7208 21.2352C61.9148 21.1589 62.064 21.0218 62.1984 20.8541C62.3327 20.6864 62.4222 20.473 62.4819 20.2138Z"
      fill="#717173"
    />
    <path
      d="M68.1107 17.9575L68.6181 20.0001L69.1256 17.9575H70.4539V21.3111H69.6181V18.7501L68.9763 21.3111H68.2301L67.5883 18.7501V21.3111H66.7525V17.9575H68.1107Z"
      fill="#717173"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.7918 17.9575H71.1053V21.3264H72.1202V20.0764H72.6724C73.0754 20.0764 73.3888 19.9849 73.5828 19.7867C73.7768 19.6038 73.8813 19.3294 73.8813 18.994C73.8813 18.6587 73.7918 18.4148 73.6127 18.2318C73.4336 18.0489 73.1649 17.9575 72.7918 17.9575ZM72.3739 19.3904H72.1351V18.6434H72.4187C72.6127 18.6434 72.7321 18.6739 72.8067 18.7501C72.8813 18.8264 72.9112 18.9178 72.9112 19.0245C72.9112 19.1312 72.8664 19.2075 72.7918 19.2837C72.7022 19.3599 72.5679 19.3904 72.3739 19.3904Z"
      fill="#717173"
    />
    <path d="M74.423 17.9575H75.4379V20.4879H77.02V21.3111H74.423V17.9575Z" fill="#717173" />
    <path d="M78.5406 17.9575H77.5257V21.3111H78.5406V17.9575Z" fill="#717173" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.2888 20.7472H80.1395L79.9754 21.2959H78.9455L80.1843 17.9423H81.2888L82.5275 21.2959H81.4679L81.2888 20.7472ZM81.0798 20.0307L80.7216 18.8264L80.3634 20.0307H81.0798Z"
      fill="#717173"
    />
    <path
      d="M83.782 17.9575H82.8418V21.3111H83.797V19.4666L85.0208 21.3111H85.976V17.9575H85.0208V19.8172L83.782 17.9575Z"
      fill="#717173"
    />
    <path d="M86.4326 17.9575H89.5072V18.7806H88.4774V21.3111H87.4625V18.7806H86.4326V17.9575Z" fill="#717173" />
  </svg>
);
