import {
  AccountingPlatform,
  AccountingPlatformBillLabel,
  AccountingPlatformBillLineItemLabel,
  AccountingPlatformCategory,
  AccountingPlatformItem,
  isQuickBooksDesktopAccountingPlatform,
  isQuickBooksOnlineAccountingPlatform,
  useAccountingPlatformBillLabels,
  useAccountingPlatformBillLineItemLabels,
  useAccountingPlatformCategories,
  useAccountingPlatformItems,
  useAccountingPlatforms,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

type AccountingPlatformEntitiesResult = {
  activeAccountingPlatform?: AccountingPlatform;
  billLabels?: AccountingPlatformBillLabel[];
  billLineItemLabels?: AccountingPlatformBillLineItemLabel[];
  categories?: AccountingPlatformCategory[];
  hasAccountingPlatform: boolean;
  hasAccountingPlatformItems: boolean;
  isAccountingPlatformEntitiesLoading: boolean;
  items?: AccountingPlatformItem[];
};

export const useAccountingPlatformEntities = (enableQueries: boolean): AccountingPlatformEntitiesResult => {
  const [isPlatformBillClassesQboEnabled] = useDevFeature(FeatureFlags.PlatformBillClassesQbo, false);
  const [isPlatformBillLocationsEnabled] = useDevFeature(FeatureFlags.PlatformBillLocations, false);
  const {
    hasAccountingPlatform,
    activeAccountingPlatform,
    isLoading: isAccountingPlatformsLoading,
  } = useAccountingPlatforms();

  const isQuickBooksOnline = isQuickBooksOnlineAccountingPlatform(activeAccountingPlatform);
  const isQuickBooksDesktop = isQuickBooksDesktopAccountingPlatform(activeAccountingPlatform);

  const {
    isLoading: isItemsLoading,
    data: items,
    hasAccountingPlatformItems,
  } = useAccountingPlatformItems({
    accountingPlatformId: activeAccountingPlatform?.id,
    enabled: enableQueries && hasAccountingPlatform,
    noInvalidations: true,
  });

  const { isLoading: isCategoriesLoading, data: categories } = useAccountingPlatformCategories({
    accountingPlatformId: activeAccountingPlatform?.id,
    enabled: enableQueries && hasAccountingPlatform,
    noInvalidations: true,
  });

  const { data: billLineItemLabels, isLoading: isLoadingBillLineItemLabels } = useAccountingPlatformBillLineItemLabels({
    accountingPlatformId: activeAccountingPlatform?.id,
    enabled: enableQueries && isPlatformBillClassesQboEnabled && (isQuickBooksOnline || isQuickBooksDesktop),
    noInvalidations: true,
  });

  const { data: billLabels, isLoading: isLoadingBillLabels } = useAccountingPlatformBillLabels({
    accountingPlatformId: activeAccountingPlatform?.id,
    enabled: enableQueries && isPlatformBillLocationsEnabled && isQuickBooksOnline,
    noInvalidations: true,
  });

  const isAccountingPlatformEntitiesLoading =
    isAccountingPlatformsLoading ||
    isItemsLoading ||
    isCategoriesLoading ||
    isLoadingBillLabels ||
    isLoadingBillLineItemLabels;

  return {
    activeAccountingPlatform,
    billLabels,
    billLineItemLabels,
    categories,
    hasAccountingPlatform,
    hasAccountingPlatformItems,
    isAccountingPlatformEntitiesLoading,
    items,
  };
};
