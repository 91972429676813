import { isDeliveryPreferenceTypeFast, usePaymentProcessingInfo } from '@melio/ap-domain';
import { Calendar, CalendarLegendProps, Container, Group } from '@melio/penny';
import { ApprovalRequirementStatus, DeliveryPreference, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { isCreditCardFundingSource } from '../../../utils';
import {
  DeliveryDateEstimation,
  DeliveryDateEstimationWarning,
} from '../DeliveryDateEstimation/DeliveryDateEstimation';
import { DeliveryPreferenceForm } from '../DeliveryPreferenceForm';

export type ScheduleDateWidgetProps = {
  fundingSource: FundingSource;
  dueDate: Date;
  selectedDate: Date;
  onSelectDate: (type: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  isDisabled?: boolean;
  deliveryPreferences: DeliveryPreference[];
  selectedDeliveryPreferenceType: DeliveryPreference['type'];
  onSelectDeliveryPreference: (type: DeliveryPreference['type']) => unknown;
  onError?: ErrorFunction;
  approvalRequirementStatus?: ApprovalRequirementStatus;
};

export const ScheduleDateWidget = forwardRef<ScheduleDateWidgetProps>(
  (
    {
      fundingSource,
      dueDate,
      onSelectDate,
      onSelectDeliveryPreference,
      minDate,
      maxDate,
      selectedDate,
      isDisabled,
      deliveryPreferences,
      selectedDeliveryPreferenceType,
      onError,
      approvalRequirementStatus,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatDate, formatDateTimeRange } = useMelioIntl();
    const { isPaymentProcessedByCapitalOne, calculateExpiryDate } = usePaymentProcessingInfo();
    const selectedDeliveryPreference = deliveryPreferences.find(({ type }) => type === selectedDeliveryPreferenceType);
    const defaultDeliveryPreference = deliveryPreferences.find(
      ({ type, supported }) => supported && !isDeliveryPreferenceTypeFast(type)
    );
    const isUnverifiedBankAccount = fundingSource.type === 'bank-account' && !fundingSource.isVerified;
    const isPendingApprovalWorkflow = approvalRequirementStatus === ApprovalRequirementStatus.Pending;
    const hasFastOption = deliveryPreferences.length > 1;

    useEffect(() => {
      if (!selectedDeliveryPreference?.supported) {
        if (defaultDeliveryPreference) {
          onSelectDeliveryPreference(defaultDeliveryPreference.type);
        }
      }
    }, [defaultDeliveryPreference, onSelectDeliveryPreference, selectedDeliveryPreference?.supported]);

    if (!selectedDeliveryPreference) {
      onError?.({ code: '500', message: 'could not find deliveryPreferences' });
      return null;
    }

    const buildLegends = (): CalendarLegendProps[] => {
      const legendDate = isPaymentProcessedByCapitalOne(selectedDeliveryPreferenceType)
        ? formatDateTimeRange(selectedDate, calculateExpiryDate(selectedDate), {
            dateStyle: 'medium',
          })
        : formatDate(selectedDate, { dateStyle: 'medium' });

      return [
        {
          testId: 'delivery-date-widget-legend-deduction-date',
          label: formatMessage(
            `widgets.deliveryDate.deductionDate.label.${
              isCreditCardFundingSource(fundingSource) ? 'creditCardFundingType' : 'default'
            }`
          ),
          date: legendDate,
        },
        {
          testId: 'delivery-date-widget-legend-due-date',
          label: formatMessage('widgets.deliveryDate.dueDate.label'),
          date: formatDate(dueDate, { dateStyle: 'medium' }),
          variant: 'secondary',
        },
      ];
    };

    const footer = (
      <Group variant="vertical" hasDivider spacing="none">
        {hasFastOption ? (
          <Container paddingX="m" paddingY="m">
            <DeliveryPreferenceForm
              data-testid="delivery-date-widget-delivery-preference-form"
              fundingSource={fundingSource}
              deliveryPreferences={deliveryPreferences}
              deliveryPreferenceType={selectedDeliveryPreference.type}
              onChange={onSelectDeliveryPreference}
              isDisabled={isDisabled}
            />
          </Container>
        ) : (
          <Container paddingX="m" paddingY="m">
            <DeliveryDateEstimation
              deliveryPreference={selectedDeliveryPreference}
              fundingSource={fundingSource}
              approvalRequirementStatus={approvalRequirementStatus}
            />
          </Container>
        )}
        {hasFastOption && (isUnverifiedBankAccount || isPendingApprovalWorkflow) ? (
          <Container paddingX="m" paddingY="m">
            <DeliveryDateEstimationWarning
              deliveryPreference={selectedDeliveryPreference}
              fundingSource={fundingSource}
              approvalRequirementStatus={approvalRequirementStatus}
            />
          </Container>
        ) : null}
      </Group>
    );
    return (
      <Calendar
        ref={ref}
        data-component="ScheduleDateWidget"
        data-testid="delivery-date-widget"
        {...props}
        minDate={minDate}
        maxDate={maxDate}
        excludeHolidays
        weekDays={[1, 2, 3, 4, 5]}
        onSelect={onSelectDate}
        selectedDate={selectedDate}
        secondarySelectedDate={dueDate}
        isDisabled={isDisabled}
        legendItems={buildLegends()}
        footer={footer}
      />
    );
  }
);
ScheduleDateWidget.displayName = 'ScheduleDateWidget';
