import { useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { PatchSubscriptionMeRequest, Subscription, SubscriptionsApiClient } from '@melio/platform-api-axios-client';

type QueryFN = (_?: string) => ReturnType<typeof SubscriptionsApiClient.getSubscriptionsMe>;
type UpdateFn = (
  _id: string,
  data: PatchSubscriptionMeRequest
) => ReturnType<typeof SubscriptionsApiClient.patchSubscriptionsMe>;
type CreateFn = typeof SubscriptionsApiClient.postSubscriptions;

export type UseSubscriptionMeProps = UseModelApiProps<QueryFN, Subscription>;

export const useSubscriptionMe = (props: UseSubscriptionMeProps = {}) => {
  const queryClient = useMelioQueryClient();

  return useModelApi<QueryFN, UpdateFn, never, CreateFn>({
    ...props,
    id: 'me',
    queryKey: ['SubscriptionsApi'],
    queryFn: () => SubscriptionsApiClient.getSubscriptionsMe(),
    createFn: SubscriptionsApiClient.postSubscriptions,
    cacheTime: Infinity,
    staleTime: Infinity,
    updateFn: (_id: string, data: PatchSubscriptionMeRequest) => SubscriptionsApiClient.patchSubscriptionsMe(data),
    onUpdate: () => queryClient.invalidateQueries('SubscriptionOrganizationsApi'),
  });
};
