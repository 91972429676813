import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { Group, Text } from '@melio/penny';
import { ApprovalRequirementStatus, DeliveryPreference, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { VFC } from 'react';

export type DeliveryDateEstimationProps = {
  deliveryPreference: DeliveryPreference;
  fundingSource: FundingSource;
  approvalRequirementStatus?: ApprovalRequirementStatus;
};
export const DeliveryDateEstimation: VFC<DeliveryDateEstimationProps> = ({
  deliveryPreference,
  fundingSource,
  approvalRequirementStatus,
}) => {
  const { formatMessage, formatDateTimeRange } = useMelioIntl();
  const { minDeliveryDate, maxDeliveryDate } = deliveryPreference;

  return (
    <Group spacing="xxs" variant="vertical" data-component="EstimateDeliveryDate" data-testid="delivery-date-estimate">
      <Text textStyle="body4Semi" color="global.neutral.800">
        {formatMessage('widgets.deliveryDate.estimate.label')}
      </Text>
      <Text textStyle="body2Semi">
        {formatDateTimeRange(minDeliveryDate, maxDeliveryDate, {
          dateStyle: 'medium',
        })}
      </Text>
      <DeliveryDateEstimationWarning
        deliveryPreference={deliveryPreference}
        fundingSource={fundingSource}
        approvalRequirementStatus={approvalRequirementStatus}
      />
    </Group>
  );
};

export const DeliveryDateEstimationWarning = ({
  deliveryPreference,
  fundingSource,
  approvalRequirementStatus,
}: {
  deliveryPreference: DeliveryPreference;
  fundingSource: FundingSource;
  approvalRequirementStatus?: ApprovalRequirementStatus;
}) => {
  const description = useEstimateText(deliveryPreference, fundingSource, approvalRequirementStatus);

  return (
    <Text textStyle="body4" color="global.neutral.900" data-testid="delivery-date-estimation-warning">
      {description}
    </Text>
  );
};

export const useEstimateText = (
  { type, effectiveScheduleDate }: DeliveryPreference,
  fundingSource: FundingSource,
  approvalRequirementStatus?: ApprovalRequirementStatus
) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { isPaymentProcessedByCapitalOne, calculateExpiryDate } = usePaymentProcessingInfo();
  const scheduleDate = formatDate(effectiveScheduleDate, { month: 'short', day: 'numeric' });
  const hasPendingApprovalWorkflow = approvalRequirementStatus === ApprovalRequirementStatus.Pending;

  if (fundingSource.type === 'bank-account' && !fundingSource.isVerified) {
    const last4digits = fundingSource.details.accountNumberLast4Digits;
    return formatMessage(
      hasPendingApprovalWorkflow
        ? 'widgets.deliveryDate.estimate.description.unverified.approvalWorkflow'
        : 'widgets.deliveryDate.estimate.description.unverified',
      {
        date: scheduleDate,
        last4digits,
      }
    );
  }

  if (type === 'virtual') {
    return formatMessage(
      hasPendingApprovalWorkflow
        ? 'widgets.deliveryDate.estimate.description.unilateral.approvalWorkflow'
        : 'widgets.deliveryDate.estimate.description.unilateral',
      {
        date: scheduleDate,
      }
    );
  }

  if (hasPendingApprovalWorkflow) {
    return formatMessage('widgets.deliveryDate.estimate.description.approvalWorkflow', {
      date: scheduleDate,
    });
  }

  const getFormattedExpiryDate = () => {
    const expiryDate = calculateExpiryDate(effectiveScheduleDate);
    return formatDate(expiryDate, { day: 'numeric', month: 'short' });
  };

  switch (type) {
    case 'virtual-card':
      return isPaymentProcessedByCapitalOne(type)
        ? formatMessage('widgets.deliveryDate.estimate.description.virtualCard', {
            expiryDate: getFormattedExpiryDate(),
          })
        : formatMessage('widgets.deliveryDate.estimate.description.general');
    case 'rpps':
    case 'ach':
      return formatMessage('widgets.deliveryDate.estimate.description.general');
    case 'check':
      return formatMessage('widgets.deliveryDate.estimate.description.paperCheck');
    case 'international':
      return formatMessage('widgets.deliveryDate.estimate.description.international');
  }
  return '';
};
