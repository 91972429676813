import { Container, Drawer, Group, Text, useBreakpoint, useFormSubmissionController } from '@melio/penny';
import { EventProperties, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { ModelError, UpdateVendorParams, useVendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { useUpsertVendorDeliveryMethods } from '../../../../hooks/useUpsertVendorDeliveryMethods';
import { getErrorsByType, getInlineApiErrorsFields } from '../../apiErrorsUtils';
import { ErrorTypeToErrorCodesMap, UpdateVendorOnDone, VendorFormBannerApiErrorCode } from '../../types';
import { EditVendorFormWidget } from '../EditVendorForm';
import { getVendorDefaultFormValues } from '../EditVendorForm/utils';
import { EditVendorFormFields, EditVendorFormWidgetProps } from '../types';
import { EditVendorDrawerFooter } from './EditVendorDrawerFooter.widget';

export type EditVendorDrawerWidgetProps = {
  isOpen: boolean;
  vendorId: string;
  pageEntryPoint: 'pay';
  analyticsProperties?: EventProperties;
  onDone: UpdateVendorOnDone;
  onClose: VoidFunction;
  onSelectCompanyName?: EditVendorFormWidgetProps['onSelectCompanyName'];
};

export const EditVendorDrawerWidget = withSystemMessageProvider(
  withAnalyticsContext<EditVendorDrawerWidgetProps>(
    ({
      isOpen,
      vendorId,
      pageEntryPoint,
      analyticsProperties,
      onDone,
      onClose,
      onSelectCompanyName,
      setAnalyticsProperties,
    }) => {
      const { onSubmissionStateChange, submitButtonProps, cancelButtonProps, handleSubmit } =
        useFormSubmissionController<EditVendorFormFields>();
      const { formatMessage } = useMelioIntl();
      const { showMessage } = useSystemMessage();
      const { track, trackMarketing } = useAnalytics();
      const { isExtraSmallScreen } = useBreakpoint();
      const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();
      const {
        data: vendor,
        update: updateVendor,
        isLoading: isVendorLoading,
        isMutating: isVendorUpdating,
      } = useVendor({ id: vendorId });
      const defaultValues = useMemo(() => getVendorDefaultFormValues(vendor), [vendor]);
      const { upsertVendorDeliveryMethods, setUpdatedDeliveryMethods, isDeliveryMethodsMutating } =
        useUpsertVendorDeliveryMethods(vendorId);

      setAnalyticsProperties({
        PageName: 'edit-a-vendor',
        PageEntryPoint: pageEntryPoint,
        Intent: 'edit-vendor-on-the-go',
      });

      useEffect(() => {
        track('Vendor', 'View', { VendorType: 'local' });
      }, [track]);

      type TrackEditVendorStatusParams = {
        vendorType: 'directory' | 'local';
        status: 'success' | 'failure';
        vendorId?: string;
        inlineErrorsFields?: (keyof EditVendorFormFields)[];
        bannerErrorsTypes?: VendorFormBannerApiErrorCode[];
      };

      const trackUpdateVendorStatus = ({
        vendorType,
        status,
        vendorId,
        inlineErrorsFields,
        bannerErrorsTypes,
      }: TrackEditVendorStatusParams) => {
        const properties = {
          PageName: 'edit-a-vendor',
          Intent: 'edit-a-vendor',
          VendorType: vendorType,
          Status: status,
          InlineErrorsFields: inlineErrorsFields,
          BannerErrorsTypes: bannerErrorsTypes,
          ...(analyticsProperties ?? {}),
          ...(vendorId ? { VendorId: vendorId } : {}),
        };

        track('Vendor', 'Status', properties);
        status === 'success' && trackMarketing('bill-create_edit-vendor', properties);
      };

      const handleUpdateVendor = (data: UpdateVendorParams) => {
        setApiErrorsCodes(undefined);

        upsertVendorDeliveryMethods()
          .then(() => updateVendor({ ...data }))
          .then((vendor) => {
            trackUpdateVendorStatus({
              vendorType: 'local',
              status: 'success',
              vendorId: vendor.id,
            });

            onDone({ vendor });
          })
          .catch((error: ModelError) => handleUpdateVendorFail(data, error));
      };

      const handleUpdateVendorFail = (submittedData: UpdateVendorParams, error: ModelError) => {
        const { inline, banner } = getErrorsByType(error, false);

        if (inline?.length || banner?.length) {
          trackUpdateVendorStatus({
            vendorType: 'local',
            status: 'failure',
            ...(inline?.length ? { inlineErrorsFields: getInlineApiErrorsFields(inline) } : {}),
            ...(banner?.length ? { bannerErrorsTypes: banner } : {}),
          });
          setApiErrorsCodes({ inline, banner });
        } else {
          showMessage({
            type: 'error',
            title: formatMessage('widgets.editVendorDrawer.toast.error', {
              companyName: submittedData.name,
            }),
            action: {
              text: formatMessage('widgets.editVendorDrawer.toast.error.action'),
              onAction: (close) => {
                close();
                handleUpdateVendor(submittedData);
              },
              type: 'button',
            },
          });
        }
      };

      const onSubmit = () => {
        track(`Vendor`, 'Click', {
          VendorType: 'local',
          Cta: 'save',
        });
        handleSubmit?.()();
      };

      const handleCloseClick = () => {
        track('Vendor', 'Click', {
          Cta: 'exit',
        });

        onClose();
      };

      const handleCancelClick = () => {
        track('Vendor', 'Click', {
          Cta: 'cancel',
        });

        onClose();
      };

      return (
        <Drawer
          isOpen={isOpen}
          isLoading={isVendorLoading}
          onClose={handleCloseClick}
          data-testid="edit-vendor-drawer"
          closeButtonAriaLabel={formatMessage('widgets.vendorDrawer.closeButtonAriaLabel')}
          header={
            <Text as="h2" textStyle="heading2Semi">
              {formatMessage('widgets.editVendorDrawer.title')}
            </Text>
          }
          body={
            <Group variant="vertical" spacing="m">
              <SystemMessageDisplay />
              <Container overflow="visible">
                <Container paddingBottom="xs" paddingTop={isExtraSmallScreen ? 'xs' : undefined}>
                  <Text color="global.neutral.900" textStyle="body4">
                    {formatMessage('widgets.editVendorDrawer.requiredFields')}
                  </Text>
                </Container>
                <EditVendorFormWidget
                  vendor={vendor}
                  onSubmit={handleUpdateVendor}
                  onDeliveryMethodChanged={(deliveryMethods) => setUpdatedDeliveryMethods(deliveryMethods)}
                  onSubmissionStateChange={onSubmissionStateChange}
                  isSaving={isVendorUpdating || isDeliveryMethodsMutating}
                  defaultValues={defaultValues}
                  inlineApiErrorCodes={apiErrorsCodes?.inline}
                  bannerApiErrorCodes={apiErrorsCodes?.banner}
                  onSelectCompanyName={onSelectCompanyName}
                  titlesAs="h3"
                />
              </Container>
            </Group>
          }
          footer={
            <EditVendorDrawerFooter
              onClose={handleCancelClick}
              cancelButtonProps={cancelButtonProps}
              submitButtonProps={
                submitButtonProps
                  ? {
                      ...submitButtonProps,
                      onClick: onSubmit,
                    }
                  : undefined
              }
            />
          }
        />
      );
    }
  )
);
