import { FormattedMessage, useIsMobile, useMelioIntl, useMonitoring } from '@melio/ar-domain';
import { TBTFormWidget, TBTFormWidgetFields } from '@melio/form-controls';
import { Button, Group, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { SignInBanner } from '../../../../components';
import { PaymentRequestDetailsLayout, PaymentRequestDetailsLayoutProps } from '../../../../layout';
import {
  CardHolderAddressDetailsAndScheduledDate,
  CardHolderDetailsFormFields,
  CardHolderEmailDetails,
  CardHolderNameDetails,
} from '../../../types';
import {
  CardHolderAddressDetailsAndScheduledDateForm,
  CardHolderEmailDetailsForm,
  CardHolderNameDetailsForm,
} from '../components';

export type AddCardAccountDetailsScreenProps = Pick<
  PaymentRequestDetailsLayoutProps,
  | 'onViewInvoice'
  | 'onSelectFundingSource'
  | 'paymentRequestDetails'
  | 'vendorPaymentDetails'
  | 'isLoading'
  | 'isDisabled'
> & {
  onSubmit: (data: CardHolderDetailsFormFields) => void;
  isSaving?: boolean;
  isCheckingEmail?: boolean;
  isHidden?: boolean;
  onEmailChange: (email: string) => void;
  signInUrl?: string;
  isPayable: boolean;
};

export const AddCardAccountDetailsScreen = forwardRef<AddCardAccountDetailsScreenProps>(
  (
    {
      onSubmit,
      isSaving,
      onViewInvoice,
      isDisabled,
      isLoading,
      onSelectFundingSource,
      paymentRequestDetails,
      vendorPaymentDetails,
      onEmailChange: _onEmailChange,
      isCheckingEmail,
      signInUrl,
      isPayable,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile();
    const { track, trackAsync } = useAnalytics();
    const { routeReady } = useMonitoring();
    const { formatCurrency, formatMessage } = useMelioIntl();

    const formSize = isMobile ? 'small' : 'large';

    const cardHolderEmailDetailsForm = useFormSubmissionController<CardHolderEmailDetails>();
    const cardHolderNameDetailsForm = useFormSubmissionController<CardHolderNameDetails>();
    const cardDetailsForm = useFormSubmissionController<TBTFormWidgetFields>();
    const cardHolderAddressDetailsForm = useFormSubmissionController<CardHolderAddressDetailsAndScheduledDate>();

    const forms = [
      cardHolderEmailDetailsForm,
      cardHolderNameDetailsForm,
      cardDetailsForm,
      cardHolderAddressDetailsForm,
    ];

    // We decided not to use here useAnalyticsView hook, becuase the PageName prop is the same in both card and bank screens
    useEffect(() => {
      if (!isLoading) {
        track('PaymentRequest', 'View');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const [isReady, ready] = useBoolean(false);
    const [cardDetails, setCardDetails] = useState<TBTFormWidgetFields>();
    const [submittedCardHolderEmailDetails, setSubmittedCardHolderEmailDetails] = useState<CardHolderEmailDetails>();
    const [cardHolderNameDetails, setCardHolderNameDetails] = useState<CardHolderNameDetails>();
    const [cardHolderAddressDetailsAndScheduledDate, setCardHolderAddressDetailsAndScheduledDate] =
      useState<CardHolderAddressDetailsAndScheduledDate>();

    const isFormValid =
      forms.every((form) => form.formState?.isValid) &&
      cardDetails &&
      submittedCardHolderEmailDetails &&
      cardHolderNameDetails &&
      cardHolderAddressDetailsAndScheduledDate;

    const onClick = () => {
      forms.reverse().forEach((form) => form.submitButtonProps?.onClick());

      if (!isFormValid) {
        track('PaymentRequest', 'Status', { ErrorType: 'invalid-card-details' });
      }
    };

    const resetSubmittedEmailDetails = () => setSubmittedCardHolderEmailDetails(undefined);

    const onEmailChange = (email: string) => {
      resetSubmittedEmailDetails();
      _onEmailChange(email);
    };

    useEffect(() => {
      if (isFormValid) {
        onSubmit({
          ...cardDetails,
          ...cardHolderAddressDetailsAndScheduledDate,
          ...submittedCardHolderEmailDetails,
          ...cardHolderNameDetails,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isFormValid,
      cardDetails,
      cardHolderAddressDetailsAndScheduledDate,
      submittedCardHolderEmailDetails,
      cardHolderNameDetails,
    ]);

    const isSubmitButtonDisabled = !isPayable || forms.some((form) => form.submitButtonProps?.isDisabled);
    const isSubmitButtonLoading = forms.some((form) => form.submitButtonProps?.isLoading) || isSaving;

    const onSignInClick = () => {
      trackAsync('PaymentRequest', 'Click', { PageName: 'payment-request-sign-in', Cta: 'sign-in' }).finally(() => {
        signInUrl && window.location.assign(signInUrl);
      });
    };

    const isSignInBannerVisible = !!signInUrl;

    useEffect(() => {
      if (isSignInBannerVisible) {
        track('PaymentRequest', 'View', {
          PageName: 'payment-request-sign-in',
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignInBannerVisible]);

    return (
      <PaymentRequestDetailsLayout
        onViewInvoice={onViewInvoice}
        onSelectFundingSource={onSelectFundingSource}
        paymentRequestDetails={paymentRequestDetails}
        vendorPaymentDetails={vendorPaymentDetails}
        selectedFundingSource="card"
        isDisabled={isSaving || isDisabled}
        data-loading={!!isLoading}
        data-testid="add-card-account-details-screen"
        data-component={AddCardAccountDetailsScreen.displayName}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" spacing={isMobile ? 'm' : 'l'}>
            <Text textStyle="body2Semi">
              <FormattedMessage id="ar.guestPayment.activities.cardHolder.form.title" />
            </Text>
            {isReady && (
              <>
                <Group variant="vertical" spacing="xs">
                  {isSignInBannerVisible && <SignInBanner onSignInClick={onSignInClick} />}
                  <CardHolderEmailDetailsForm
                    onSubmit={setSubmittedCardHolderEmailDetails}
                    onSubmissionStateChange={cardHolderEmailDetailsForm.onSubmissionStateChange}
                    isSaving={isSaving}
                    size={formSize}
                    onEmailChange={onEmailChange}
                    isLoading={isCheckingEmail}
                  />
                </Group>
                <CardHolderNameDetailsForm
                  onSubmit={setCardHolderNameDetails}
                  onSubmissionStateChange={cardHolderNameDetailsForm.onSubmissionStateChange}
                  isSaving={isSaving}
                  size={formSize}
                />
              </>
            )}
            <TBTFormWidget
              onSubmissionStateChange={cardDetailsForm.onSubmissionStateChange}
              onSubmit={setCardDetails}
              isSaving={isSaving}
              onReady={ready.on}
              size={formSize}
              hideCardLogos
            />
            {isReady && (
              <CardHolderAddressDetailsAndScheduledDateForm
                onSubmit={setCardHolderAddressDetailsAndScheduledDate}
                onSubmissionStateChange={cardHolderAddressDetailsForm.onSubmissionStateChange}
                isSaving={isSaving}
                size={formSize}
              />
            )}
          </Group>
          {isReady && (
            <Button
              ref={routeReady}
              data-testid="add-card-submit-button"
              isDisabled={isSubmitButtonDisabled}
              isLoading={isSubmitButtonLoading}
              onClick={onClick}
              size="large"
              label={formatMessage('ar.guestPayment.activities.cardHolder.form.buttons.submit.text', {
                amount: formatCurrency(paymentRequestDetails?.invoice.totalAmount ?? 0),
              })}
            />
          )}
        </Group>
      </PaymentRequestDetailsLayout>
    );
  }
);
AddCardAccountDetailsScreen.displayName = 'AddCardAccountDetailsScreen';
