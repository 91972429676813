import { isCurrencyCloudInternationalDm, useInternationalFxFeature } from '@melio/ap-domain';
import { getSupportedInternationalType, SupportedInternationalType } from '@melio/ap-widgets';
import {
  DeliveryMethod,
  DeliveryMethodTypeOption,
  InternationalAccountDeliveryMethod,
  OrganizationBusinessType,
  UnsupportedDeliveryMethodTypeReason,
  Vendor,
} from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

const SHOW_UNSUPPORTED_DMS_FOR_REASONS: UnsupportedDeliveryMethodTypeReason[] = [
  UnsupportedDeliveryMethodTypeReason.DeliveryMethodNotSupportedForState,
  UnsupportedDeliveryMethodTypeReason.AccountAddressNotValid,
];

export type useInternationalDeliveryMethodCardsToShowProps = {
  vendorCurrency?: Vendor['currency'];
  businessType?: OrganizationBusinessType | null;
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[];
  deliveryMethods: DeliveryMethod[];
};

export const useInternationalDeliveryMethodCardsToShow = ({
  vendorCurrency,
  businessType,
  deliveryMethodTypeOptions,
  deliveryMethods,
}: useInternationalDeliveryMethodCardsToShowProps): {
  showCurrencyCloud: boolean;
  showWiseCard: boolean;
  deliveryMethod?: InternationalAccountDeliveryMethod;
  wiseSupportedInternationalType: SupportedInternationalType;
} => {
  const isInternationalFxEnabled = useInternationalFxFeature();
  const [isInternationalUsdWiseEnabled] = usePartnerFeature('EnableInternationalUSDWise', false);

  const supportedInternationalType = getSupportedInternationalType({
    isInternationalFxEnabled,
    vendorCurrency,
    businessType,
  });

  const internationalDmTypeOption = deliveryMethodTypeOptions?.find(
    (option) => option.type === 'international-account',
  );
  const existingInternationalDeliveryMethod = deliveryMethods.find((dm) => dm.type === 'international-account');
  const hasCurrencyCloudAccount =
    !!existingInternationalDeliveryMethod && isCurrencyCloudInternationalDm(existingInternationalDeliveryMethod);
  const hasWiseAccount =
    !!existingInternationalDeliveryMethod && !isCurrencyCloudInternationalDm(existingInternationalDeliveryMethod);

  // Internationl not supported
  if (!internationalDmTypeOption?.supported) {
    const reason = internationalDmTypeOption?.reason;
    if (reason && SHOW_UNSUPPORTED_DMS_FOR_REASONS.includes(reason)) {
      return {
        showCurrencyCloud: hasCurrencyCloudAccount,
        showWiseCard: hasWiseAccount,
        wiseSupportedInternationalType: supportedInternationalType,
        deliveryMethod: existingInternationalDeliveryMethod,
      };
    }

    return {
      showCurrencyCloud: false,
      showWiseCard: false,
      wiseSupportedInternationalType: supportedInternationalType,
    };
  }

  if (isInternationalUsdWiseEnabled) {
    // When the flag is enbaled, even if the vendor has a CurrencyCloud account, we show the Wise card.
    return {
      showCurrencyCloud: false,
      showWiseCard: true,
      wiseSupportedInternationalType: supportedInternationalType,
      deliveryMethod: existingInternationalDeliveryMethod,
    };
  }

  // Wise flag is disabled.
  if (hasCurrencyCloudAccount) {
    return {
      showCurrencyCloud: true,
      showWiseCard: false,
      wiseSupportedInternationalType: 'international-usd', // Doesn't really matter.
      deliveryMethod: existingInternationalDeliveryMethod,
    };
  }

  if (hasWiseAccount) {
    return {
      showCurrencyCloud: false,
      showWiseCard: true,
      wiseSupportedInternationalType: 'international-fx', // When the flag is disabeld, wise card is only for FX.
      deliveryMethod: existingInternationalDeliveryMethod,
    };
  }

  // No international delivery method, wise flag is disabled.
  return {
    showCurrencyCloud:
      supportedInternationalType === 'international' || supportedInternationalType === 'international-usd',
    showWiseCard: supportedInternationalType === 'international' || supportedInternationalType === 'international-fx',
    wiseSupportedInternationalType: 'international-fx', // When the flag is disabeld, wise card is only for FX.
  };
};
