import { DeliveryMethod, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useDeliveryDateTooltipText = ({
  vendor,
  deliveryMethod,
}: {
  vendor: Vendor | undefined;
  deliveryMethod: DeliveryMethod | undefined;
}): string | null => {
  const { formatMessage } = useMelioIntl();

  if (!vendor) {
    return formatMessage('activities.paymentFlow.form.content.deliveryDate.tooltip.disabledVendorNotSelected');
  }

  if (!deliveryMethod) {
    return formatMessage('activities.paymentFlow.form.content.deliveryDate.tooltip.disabledDeliveryMethodNotSelected');
  }

  return null;
};
