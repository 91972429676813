import { PCIComplienceInfo, TBTFormWidget, TBTFormWidgetFields, TBTFormWidgetProps } from '@melio/ap-widgets';
import { Text, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Card } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { CreateCardDetailsScreenTitle } from '../shared/CreateCardDetailsScreenTitle';

export type TBTCreateCardDetailsScreenProps = {
  onClose: VoidFunction;
  onDone: TBTFormWidgetProps['onSubmit'];
  onError?: ErrorFunction;
  cardType?: Card['type'];
  isSaving?: boolean;
};

export const TBTCreateCardDetailsScreen: React.VFC<TBTCreateCardDetailsScreenProps> = forwardRef<
  TBTCreateCardDetailsScreenProps,
  'div'
>(({ onClose, onDone, onError, cardType, isSaving, ...props }, ref) => {
  const [isLoading, loading] = useBoolean(true);
  useAnalyticsView('AddCCDetails', !isLoading);
  const { formatMessage } = useMelioIntl();

  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<TBTFormWidgetFields>();

  return (
    <NewSinglePaymentStepLayout
      data-component="AddCardFundingSourceActivity.TBTCreateCardDetailsScreen"
      data-testid="tbt-add-card-funding-source-activity-create-card-details-screen"
      ref={ref}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Footer>
          <NewSinglePaymentStepLayout.NextButton
            data-testid="create-card-details-cancel-button"
            variant="tertiary"
            label={formatMessage('activities.addCardFundingSource.screens.createCardDetails.cancel')}
            onClick={onClose}
          />
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            label={formatMessage('activities.addCardFundingSource.screens.createCardDetails.save.and.continue')}
          />
        </NewSinglePaymentStepLayout.Footer>
      }
      isStickyFooter
      bottomContent={
        <NewSinglePaymentStepLayout.Content>
          <PCIComplienceInfo />
        </NewSinglePaymentStepLayout.Content>
      }
    >
      <NewSinglePaymentStepLayout.Title textAlign="justify">
        <CreateCardDetailsScreenTitle cardType={cardType} />
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <TBTFormWidget
          headerContent={
            <Text color="semantic.text.secondary" textStyle="body4">
              {formatMessage('widgets.tbtForm.cardVerificationCode.requiredFields')}
            </Text>
          }
          onSubmit={onDone}
          onSubmissionStateChange={onSubmissionStateChange}
          onReady={loading.off}
          isSaving={isSaving}
          size="large"
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});
TBTCreateCardDetailsScreen.displayName = 'AddCardFundingSourceActivity.TBTCreateCardDetailsScreen';
