import {
  CardNetwork,
  useAllowedMcc,
  useGuestPayorVendorPaymentDetails,
  useMelioIntl,
  useVendor,
} from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { ComponentProps, useEffect, useMemo, useState } from 'react';

import { CardBrand } from '../../types';
import { IndustrySelectionAmexModalScreen, IndustrySelectionVisaModalScreen } from './screens';

type IndustrySelectionModalActivityProps = {
  isOpen: boolean;
  cardBrand?: CardBrand;
  onDone: VoidFunction;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  vendorId: string;
  paymentRequestLink: string;
};

export const IndustrySelectionModalActivity = withAnalyticsContext<IndustrySelectionModalActivityProps>(
  forwardRef(
    (
      { paymentRequestLink, isOpen: _isOpen, cardBrand = 'unknown', onDone, onClose, vendorId, setAnalyticsProperties },
      ref
    ) => {
      const { track } = useAnalytics();
      const { formatMessage } = useMelioIntl();

      const [errorBannerProps, _setErrorBannerProps] = useState<ComponentProps<typeof ModalComponent>['banner']>();
      const closeErrorBanner = () => _setErrorBannerProps(undefined);
      const setErrorBanner = (description: string) =>
        _setErrorBannerProps({ description, variant: 'critical', showCloseIcon: true, onClose: closeErrorBanner });

      const cardNetwork: CardNetwork =
        cardBrand == 'american-express' ? 'amex' : cardBrand == 'visa' ? 'visa' : 'other';
      const isMccRequired = ['visa', 'amex'].includes(cardNetwork);
      const isOpen = isMccRequired && _isOpen;

      const vendorPaymentDetailsModel = useGuestPayorVendorPaymentDetails({ paymentRequestLink });
      const vendorModel = useVendor({ id: vendorId, enabled: false });
      const mccModel = useAllowedMcc({ cardNetwork, enabled: isOpen });

      if (isOpen) {
        setAnalyticsProperties({
          PageName: 'add-vendor-details',
          Intent: 'add-vendor-business-details',
          CardNetwork: cardNetwork,
        });
      }

      useEffect(() => {
        if (isOpen) track('PaymentRequest', 'View');
      }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

      const handleSave = async (mcc: string) => {
        try {
          track('PaymentRequest', 'Click', { Cta: 'save' });
          await vendorModel.update({ traits: { mcc } });
          closeErrorBanner();
          onDone();
        } catch (error) {
          setErrorBanner(formatMessage('ar.guestPayment.industrySelectionModal.error.text'));
        }
      };

      const handleClose = () => {
        track('PaymentRequest', 'Click', { Cta: 'close' });
        onClose();
      };

      const ModalComponent = useMemo(
        () => (cardBrand == 'american-express' ? IndustrySelectionAmexModalScreen : IndustrySelectionVisaModalScreen),
        [cardBrand]
      );

      useEffect(() => {
        if (!isMccRequired) onDone();
      }, [isMccRequired]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <ModalComponent
          isOpen={isOpen}
          isSavingIndustry={vendorModel.isMutating}
          isLoadingMccList={mccModel.isLoading || vendorPaymentDetailsModel.isLoading}
          onSave={(...args) => void handleSave(...args)}
          onClose={handleClose}
          mccIndustryList={mccModel.data ?? []}
          vendorCompanyName={vendorPaymentDetailsModel.data?.payeeDetails.companyName ?? ''}
          banner={errorBannerProps}
          ref={ref}
        />
      );
    }
  )
);
IndustrySelectionModalActivity.displayName = 'IndustrySelectionModalActivity';
