import { CardBrand } from '@melio/platform-provider';

import { AmexLogo, DinersLogo, DiscoverLogo, MastercardLogo, VisaLogo } from './assets';

export type CardBrandLogoProps = {
  brand: CardBrand;
  isSelected: boolean;
};

const opacityValue = (isSelected: boolean) => (isSelected ? 1 : 0.35);

export const CardBrandLogo = ({ brand, isSelected }: CardBrandLogoProps) => {
  switch (brand) {
    case 'visa':
      return <VisaLogo opacity={opacityValue(isSelected)} data-testid="visa" aria-label="visa" />;
    case 'mastercard':
      return <MastercardLogo opacity={opacityValue(isSelected)} data-testid="mastercard" aria-label="mastercard" />;
    case 'american-express':
      return (
        <AmexLogo opacity={opacityValue(isSelected)} data-testid="american-express" aria-label="american-express" />
      );
    case 'diners-club':
      return <DinersLogo opacity={opacityValue(isSelected)} data-testid="diners-club" aria-label="diners-club" />;
    case 'discover':
      return <DiscoverLogo opacity={opacityValue(isSelected)} data-testid="discover" aria-label="discover" />;
    default:
      return <></>;
  }
};
