import { Container, Form, Group, Modal, NakedButton, Text, useMelioForm } from '@melio/penny';
import { debounce, noop } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { Color, parseColor } from 'react-aria-components';
import { object, string } from 'yup';

import { useMelioIntl } from '../../i18n';
import { useIsMobile } from '../../useIsMobile';
import { ColorPickerArea } from './ColorPickerArea';
import { isHexColorValid } from './utils';

export type ColorPickerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  hexColor?: string;
  hexColorEmptyState: string;
  text: { header: string; description: string; inputLabel: string; inputPlaceHolder?: string };
  onSubmit: (color?: string) => void;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    colorInput: string()
      .max(7)
      .test('Hex color validation', formatMessage('colorPicker.modal.error'), (inputColor?: string | null) => {
        if (inputColor) {
          return isHexColorValid(inputColor);
        }
        return true;
      }),
  });
};

export const ColorPickerModal = ({
  isOpen,
  onClose,
  hexColor,
  hexColorEmptyState,
  text,
  onSubmit,
}: ColorPickerModalProps) => {
  const [color, setColor] = useState(parseColor(`#${hexColor || hexColorEmptyState}`).toFormat('hsl'));
  const { registerField, setValue, trigger, getValues, clearErrors, reset } = useMelioForm<{
    colorInput?: string;
  }>({
    defaultValues: { colorInput: hexColor },
    onSubmit: noop,
    schema: useSchema(),
  });
  const isMobile = useIsMobile();
  const { formatMessage } = useMelioIntl();

  const handelOnClose = () => {
    setColor(parseColor(`#${hexColor || hexColorEmptyState}`).toFormat('hsl'));
    reset();
    onClose();
  };

  const debouncedSetColor = debounce((color: string) => {
    setColor(parseColor(color).toFormat('hsl'));
  }, 300);
  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setValue('colorInput', color);

    if (color && isHexColorValid(color)) {
      debouncedSetColor(color);
    }
  };

  const handleOnChangeEndColorArea = (color: Color) => {
    let hexColorNumber = color.toHexInt().toString(16).padStart(6, '0').toUpperCase();
    // In case for black color
    hexColorNumber = hexColorNumber === '0' ? '000000' : hexColorNumber;
    setValue('colorInput', `#${hexColorNumber}`);
    clearErrors('colorInput');
  };

  const handleResetToDefaultButton = () => {
    setValue('colorInput', '');
  };

  const handleOnClickPrimaryButton = () => {
    const color = getValues('colorInput');
    const hexColor = color?.replace('#', '').toUpperCase();

    void trigger('colorInput').then((isColorValid) => {
      if ((!!hexColor && isColorValid) || !hexColor) {
        onSubmit(hexColor);
      }
    });
  };

  return (
    <Modal
      data-testid="color-picker-modal"
      header={text.header}
      isOpen={isOpen}
      onClose={handelOnClose}
      primaryButton={{
        label: formatMessage('colorPicker.modal.primaryButton.label'),
        variant: 'primary',
        onClick: handleOnClickPrimaryButton,
      }}
      secondaryButton={{
        label: formatMessage('colorPicker.modal.secondaryButton.label'),
        variant: 'secondary',
        onClick: handelOnClose,
      }}
    >
      <Group spacing="s" variant={isMobile ? 'vertical' : 'horizontal'} height="full">
        {isMobile && <Text>{text.description}</Text>}
        <Group.Item basis="40%">
          <Container paddingTop="s" paddingLeft={isMobile ? 's' : undefined}>
            <ColorPickerArea
              isMobile={isMobile}
              onChange={setColor}
              color={color}
              onChangeEnd={handleOnChangeEndColorArea}
            />
          </Container>
        </Group.Item>
        <Group.Item basis="60%">
          <Container paddingTop="s" paddingX="xxs">
            <Group variant="vertical">
              {!isMobile && <Text>{text.description}</Text>}
              <Form>
                <Form.TextField
                  {...registerField('colorInput')}
                  labelProps={{ label: text.inputLabel }}
                  placeholder={`# ${text.inputPlaceHolder || ''}`}
                  helperTextProps={{ label: formatMessage('colorPicker.modal.input.helperText.label') }}
                  maskProps={{
                    mask: [
                      '#',
                      /[a-fA-F0-9]/,
                      /[a-fA-F0-9]/,
                      /[a-fA-F0-9]/,
                      /[a-fA-F0-9]/,
                      /[a-fA-F0-9]/,
                      /[a-fA-F0-9]/,
                    ],
                  }}
                  onChange={handleOnChangeInput}
                />
              </Form>
              <NakedButton
                data-testid="color-picker-modal-reset-button"
                label={formatMessage('colorPicker.modal.resetToDefault')}
                size="medium"
                variant="primary"
                onClick={handleResetToDefaultButton}
              />
            </Group>
          </Container>
        </Group.Item>
      </Group>
    </Modal>
  );
};
