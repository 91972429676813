import { SelectOption } from '@melio/penny';
import { BusinessResultItem, MsnBusinessItem } from '@melio/platform-api';

export const getSelectOptionsFromBusinesses = (businesses: BusinessResultItem[]): SelectOption[] =>
  businesses.map(({ business }) => ({
    label: business.name,
    value: business.self,
    avatarProps: {
      name: business.name,
      src: business.logoUri,
    },
  }));

export const getSelectOptionsFromBusinessItems = (businessItems: MsnBusinessItem[]): SelectOption[] =>
  businessItems.map(({ business }) => ({
    label: business.name ?? '',
    value: business.id,
    avatarProps: {
      name: business.name,
    },
  }));
