import { Container, Form, Group, LoadingContainer, Text, useBreakpointValue, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { CardBrand } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { CardBrandLogosWidget } from '../../cardBrandLogo';
import { CaratFormInputField } from './CaratFormInputFiled';
import { CaratFormWidgetFields, CaratFormWidgetProps, CaratInputValidType } from './types';
import { useCaratForm } from './useCaratForm';

export const CaratFormWidget = forwardRef<CaratFormWidgetProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, isSaving, onReady, ...props }, ref) => {
    const [selectedBrand, setSelectedBrand] = useState<CardBrand>('unknown');
    const isMobile = useBreakpointValue({ xs: true, s: false });
    const { formatMessage } = useMelioIntl();
    const errorFocusRef = useRef<{
      cardNumber: boolean;
      expiration: boolean;
      securityCode: boolean;
    }>({
      cardNumber: false,
      expiration: false,
      securityCode: false,
    });
    const submit = () => {
      const isValidForm = Object.values(cardFieldsInputDataRef.current).every((field) => field.isValid);
      if (!isValidForm) {
        setCardErrorsCallback(cardFieldsInputDataRef.current.cardNumber, true);
        setCardErrorsCallback(cardFieldsInputDataRef.current.expiration, true);
        setCardErrorsCallback(cardFieldsInputDataRef.current.securityCode, true);
        return;
      }

      if (cardFromObjectRef.current) {
        onSubmit(cardFromObjectRef.current);
      }
    };

    const { formProps, registerField, setError, clearErrors } = useMelioForm<CaratFormWidgetFields>({
      onSubmit: submit,
      isSaving,
      onSubmissionStateChange,
    });

    const setCardErrorsCallback = useCallback(
      (cardInput: CaratInputValidType, withFocus = false) => {
        switch (cardInput.field) {
          case 'cardNumber': {
            if (cardInput.isValid) {
              errorFocusRef.current.cardNumber = false;
              clearErrors('cardNumber');
            } else {
              if (withFocus) {
                errorFocusRef.current.cardNumber = true;
                cardFromObjectRef.current?.focus('cardNumber');
              }
              if (!cardInput.hasData) {
                setError('cardNumber', {
                  message: formatMessage('widgets.tbtForm.cardNumber.validation.required'),
                });
              } else {
                setError('cardNumber', {
                  message: formatMessage('widgets.tbtForm.cardNumber.validation.format'),
                });
              }
            }
            break;
          }
          case 'expiration': {
            if (cardInput.isValid) {
              errorFocusRef.current.expiration = false;
              clearErrors('cardExpiration');
            } else {
              if (!errorFocusRef.current.cardNumber && withFocus) {
                errorFocusRef.current.expiration = true;
                cardFromObjectRef.current?.focus('expiration');
              }
              if (!cardInput.hasData) {
                setError('cardExpiration', {
                  message: formatMessage('widgets.tbtForm.cardExpiration.validation.required'),
                });
              } else if (!cardInput.isValid) {
                setError('cardExpiration', {
                  message: formatMessage('widgets.tbtForm.cardExpiration.validation.format'),
                });
              }
            }
            break;
          }
          case 'securityCode': {
            if (cardInput.isValid) {
              errorFocusRef.current.securityCode = false;
              clearErrors('cardVerificationCode');
            } else {
              if (!errorFocusRef.current.cardNumber && !errorFocusRef.current.expiration && withFocus) {
                errorFocusRef.current.securityCode = true;
                cardFromObjectRef.current?.focus('securityCode');
              }
              if (!cardInput.hasData) {
                setError('cardVerificationCode', {
                  message: formatMessage('widgets.tbtForm.cardVerificationCode.validation.required'),
                });
              } else if (!cardInput.isValid) {
                setError('cardVerificationCode', {
                  message: formatMessage('widgets.tbtForm.cardVerificationCode.validation.format'),
                });
              }
            }
          }
        }
      },
      // cardFromObjectRef should not be part of the dep array
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setError, clearErrors, formatMessage]
    );

    const { cardFieldsInputDataRef, cardFromObjectRef, inputFormFocusPerField, isFormLoading } = useCaratForm({
      setSelectedBrand,
      setCardErrorsCallback,
    });

    useEffect(() => {
      if (!isFormLoading) {
        onReady?.();
      }
    }, [isFormLoading, onReady]);

    const { error: cardNumberFormError, ...cardNumberRegisterField } = registerField('cardNumber');
    const { error: cardExpirationFormError, ...cardExpirationRegisterField } = registerField('cardExpiration');
    const { error: cardVerificationCodeFormError, ...cardVerificationCodeRegisterField } =
      registerField('cardVerificationCode');

    const getErrorMessageForAria = () => {
      if (isMobile) {
        return null;
      }
      let text = '';
      let key = '';
      if (cardNumberFormError?.message && inputFormFocusPerField['cardNumber']) {
        text = cardNumberFormError.message;
        key = 'cardNumber';
      }
      if (cardExpirationFormError?.message && inputFormFocusPerField['expiration']) {
        text = cardExpirationFormError.message;
        key = 'expiration';
      }
      if (cardVerificationCodeFormError?.message && inputFormFocusPerField['securityCode']) {
        text = cardVerificationCodeFormError.message;
        key = 'securityCode';
      }

      return (
        <div data-testid="carat-form-widget-aria-error" style={{ width: 0, height: 0, opacity: 0 }} aria-live="polite">
          <div key={key}>{text}</div>
        </div>
      );
    };
    return (
      <Group data-component="CaratFormWidget" {...props} variant="vertical" spacing="s">
        <CardBrandLogosWidget selectedBrand={selectedBrand} contentAlignment="left" />
        <LoadingContainer isLoading={isFormLoading}>
          <Container paddingY="s">
            <Text textStyle="body4" color="semantic.text.secondary">
              {formatMessage('widgets.carat.form.requirementText')}
            </Text>
          </Container>
          <Form {...formProps} columns={2} ref={ref}>
            <CaratFormInputField
              {...cardNumberRegisterField}
              data-focus={!!inputFormFocusPerField['cardNumber']}
              name="cardNumber"
              colSpan={2}
              labelProps={{ label: formatMessage('widgets.tbtForm.cardNumber.label') }}
              id="carat-card-number"
              data-testid="carat-card-number"
              data-invalid={!!cardNumberFormError}
              error={cardNumberFormError}
              isRequired
            />
            <CaratFormInputField
              {...cardExpirationRegisterField}
              data-focus={!!inputFormFocusPerField['expiration']}
              name="cardExpiration"
              colSpan={1}
              labelProps={{ label: formatMessage('widgets.tbtForm.cardExpiration.label') }}
              id="carat-expiration-date"
              data-testid="carat-expiration-date"
              isRequired
              data-invalid={!!cardExpirationFormError}
              error={cardExpirationFormError}
            />
            <CaratFormInputField
              {...cardVerificationCodeRegisterField}
              data-focus={!!inputFormFocusPerField['securityCode']}
              colSpan={1}
              name="cardVerificationCode"
              labelProps={{ label: formatMessage('widgets.tbtForm.cardVerificationCode.label') }}
              id="carat-security-code"
              data-testid="carat-security-code"
              isRequired
              data-invalid={!!cardVerificationCodeFormError}
              error={cardVerificationCodeFormError}
            />
          </Form>
        </LoadingContainer>
        {getErrorMessageForAria()}
      </Group>
    );
  }
);
CaratFormWidget.displayName = 'CaratFormWidget';
