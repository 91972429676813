import { useIsOwner } from '@melio/platform-api';

import {
  SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED_ROUTE,
  SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE_ROUTE,
} from '../router/routes.consts';

export const useConfirmSubscriptionDowngradeRedirectRoute = () => {
  const isOwner = useIsOwner();

  const confirmDowngradeRedirectRoute = isOwner
    ? SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE_ROUTE
    : SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED_ROUTE;

  return confirmDowngradeRedirectRoute;
};
