import { forwardRef } from '@melio/platform-utils';

import { FiltersToolBar } from './FiltersToolbar';

export type PaymentsTableToolbarProps = {
  isLoading?: boolean;
  searchTerm?: string;
  onSearch: (value: string) => void;
};

export const PaymentsTableToolbar = forwardRef<PaymentsTableToolbarProps>(({ searchTerm, onSearch, ...props }, ref) => (
  <FiltersToolBar
    data-component={PaymentsTableToolbar.displayName}
    search={searchTerm}
    onSearch={onSearch}
    {...props}
    ref={ref}
  />
));

PaymentsTableToolbar.displayName = 'PaymentsTableToolbar';
