import {
  MessageKey,
  PatchInvoicePreferencesRequest,
  useInvoicePreference,
  useMelioIntl,
  useSystemMessage,
} from '@melio/ar-domain';

export const useHandleUpdateInvoicePreferences = (onError?: ARErrorFunction) => {
  const { update } = useInvoicePreference({ onError });
  const { triggerMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();

  const handleUpdate = async (
    updateData: Partial<PatchInvoicePreferencesRequest>,
    successMessageKey: MessageKey,
    failureMessageKey: MessageKey
  ) => {
    try {
      await update(updateData);
      triggerMessage({
        type: 'success',
        title: formatMessage(successMessageKey),
      });
    } catch (err) {
      triggerMessage({
        type: 'critical',
        title: formatMessage(failureMessageKey),
      });
      onError?.(err as ARPlatformError);
    }
  };

  return handleUpdate;
};
