import { BankAccountDeliveryMethod, useGetDeliveryMethodDecryptedAccountNumberQuery } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

export const useDeliveryMethodDecryptedAccountNumber = ({
  bankAccount,
  enabled = true,
}: {
  bankAccount: BankAccountDeliveryMethod | undefined;
  enabled?: boolean;
}) => {
  const {
    settings: { decryptDeliveryMethodAccountNumber },
  } = useConfig();

  const shouldDecrypt = enabled && !!bankAccount?.id && !!decryptDeliveryMethodAccountNumber;

  const { data: decryptedBankAccountNumber, isLoading } = useGetDeliveryMethodDecryptedAccountNumberQuery({
    deliveryMethodId: bankAccount?.id,
    enabled: shouldDecrypt,
  });

  return {
    bankAccountNumber: shouldDecrypt ? decryptedBankAccountNumber : bankAccount?.details.accountNumber,
    isLoading: shouldDecrypt && isLoading,
  };
};
