import { Bill, BillSubscription, DeliveryMethod, Payment, PaymentRestrictions, Vendor } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

export const useFrequencySelectorSettings = ({
  bill,
  payment,
  billSubscription,
  selectedVendor,
  selectedDeliveryMethod,
  paymentRestrictions,
}: {
  bill: Bill | undefined;
  payment: Payment | undefined;
  billSubscription?: BillSubscription;
  selectedVendor: Vendor | undefined;
  selectedDeliveryMethod: DeliveryMethod | undefined;
  paymentRestrictions: PaymentRestrictions | undefined;
}): {
  isVisible: boolean;
  isDisabled: boolean;
  recurringDisabledText: string | undefined;
} => {
  const { can } = usePermissions();
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      payment: {
        scheduling: { initialFormCollapsedState },
      },
    },
  } = useConfig();

  const isVisible = !bill && !payment && !billSubscription && can({ action: 'create', subject: 'billSubscription' });
  const isDisabled = !selectedVendor || (initialFormCollapsedState === 'full' && !selectedDeliveryMethod);

  const getRecurringDisabledText = () => {
    const reasonsKeys: Record<string, MessageKey> = {
      ineligible_delivery_method:
        'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.disabled.ineligible_dm',
      ineligible_funding_source:
        'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.disabled.ineligible_fs',
      ineligible_funding_source_and_delivery_method:
        'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.disabled.ineligible_fs_dm',
    };

    const key: MessageKey | undefined = paymentRestrictions?.recurringPaymentDisabledReason
      ? reasonsKeys[paymentRestrictions.recurringPaymentDisabledReason]
      : undefined;

    return key ? formatMessage(key) : undefined;
  };

  return {
    isVisible,
    isDisabled,
    recurringDisabledText: getRecurringDisabledText(),
  };
};
