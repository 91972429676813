import { useEffect } from 'react';
import { Button, Container, Divider, Grid, Layout, LoadingContainer, Text } from '@melio/penny';
import { useLegalDocuments, useLegalDocumentsContent, useLegalDocumentsContentApprove } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';

const HtmlRender = ({ content }: { content: string }) => <div dangerouslySetInnerHTML={{ __html: content }} />;

export const TermsAndConditionsOwnerScreen = ({ onClose, onBack }: { onClose: () => void; onBack: () => void }) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const [signTermsAndConditionsEnabled] = usePartnerFeature('TermsAndConditions', false);

  const { data: legalDocuments, isLoading: legalDocumentsLoading } = useLegalDocuments({
    signTermsAndConditionsEnabled,
  });
  const { data: legalDocumentContent, isLoading: legalDocumentContentLoading } = useLegalDocumentsContent();
  const {
    mutateAsync: acknowledgeTermsAndConditions,
    isFetching: acknowledgeFetching,
    isError,
  } = useLegalDocumentsContentApprove();

  const tabTitle = formatMessage('activities.termsAndConditions.title');
  useSetDocumentTitle(tabTitle);

  const acknowledge = async () => {
    await acknowledgeTermsAndConditions();
    onBack();
  };

  useEffect(() => {
    if (isError) {
      showMessage({
        title: formatMessage('screens.serverError.title'),
        type: 'error',
      });
    }
    //adding showMessage to the dependency array will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, formatMessage]);

  if (legalDocumentsLoading || legalDocumentContentLoading || acknowledgeFetching || !legalDocuments) {
    return <LoadingContainer aria-live="polite" isLoading />;
  }

  return (
    <Layout maxWidth="600px" backgroundColor="white" paddingContent="none">
      <SystemMessageDisplay data-testid="terms-and-conditions-owner-top-error-banner" />
      <Container height="full" textAlign="start" data-testid="terms-and-conditions-owner" paddingY="xxl">
        <Grid justifyItems="start" alignContent="center" height="full" textAlign="start" gap="l">
          {legalDocuments.isFirstTime ? (
            <Text data-testid="terms-and-conditions-owner-title" textStyle="heading1Semi" as="h1">
              {formatMessage('activities.termsAndConditions.owner.title')}
            </Text>
          ) : (
            <Text data-testid="terms-and-conditions-owner-updated-title" textStyle="heading1Semi" as="h1">
              {formatMessage('activities.termsAndConditions.owner.updated.title')}
            </Text>
          )}
          {legalDocuments.isFirstTime ? (
            <Text data-testid="terms-and-conditions-owner-description">
              {formatMessage('activities.termsAndConditions.owner.description')}
            </Text>
          ) : (
            <Text data-testid="terms-and-conditions-owner-updated-description">
              {formatMessage('activities.termsAndConditions.owner.updated.description')}
            </Text>
          )}

          <Grid gap="m" textAlign="start" width="full">
            <Divider />
            <HtmlRender content={legalDocumentContent!} />
            <Divider />
          </Grid>

          <Grid gap="m" textAlign="start" width="full">
            {legalDocuments.isFirstTime ? (
              <Text textStyle="body3" data-testid="terms-and-conditions-owner-actionRequired">
                {formatMessage('activities.termsAndConditions.owner.actionRequired')}
              </Text>
            ) : (
              <Text textStyle="body3" data-testid="terms-and-conditions-owner-updated-actionRequired">
                {formatMessage('activities.termsAndConditions.owner.updated.actionRequired')}
              </Text>
            )}

            <Grid gap="s" width="full">
              <Button
                isFullWidth
                data-testid="terms-and-conditions-owner-accept"
                label={formatMessage('activities.termsAndConditions.owner.accept')}
                size="medium"
                onClick={acknowledge}
              />
              <Button
                isFullWidth
                data-testid="terms-and-conditions-owner-decline"
                label={formatMessage('activities.termsAndConditions.owner.decline')}
                variant="tertiary"
                size="medium"
                onClick={onClose}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
