import { useMelioQueryClient } from '@melio/api-client';
import { AROrganizationPreference, AROrganizationPreferencesApiClient } from '@melio/ar-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseAROrganizationPreferencesProps = UseCollectionProps<
  typeof AROrganizationPreferencesApiClient.arGetOrganizationPreferences
>;

export const useAROrganizationPreferences = (props: UseAROrganizationPreferencesProps = {}) => {
  const queryClient = useMelioQueryClient();

  return useCollection({
    ...props,
    queryKey: 'AROrganizationPreferencesApi',
    queryFn: AROrganizationPreferencesApiClient.arGetOrganizationPreferences,
    createFn: (organizationPreference: AROrganizationPreference) =>
      AROrganizationPreferencesApiClient.arSetOrganizationPreferences({ organizationPreference }),
    onCreate: (data: AROrganizationPreference) =>
      queryClient.invalidateQueries('AROrganizationPreferencesApi', 'model', data.key),
  });
};
