import { useMelioIntl } from '@melio/ar-domain';
import { Group, NakedButton, SearchBar as DSSearchBar, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import { Filters } from '../types';
import { DateRangeFilter } from './DateRangeFilter';
import { InvoiceStatusFilter } from './InvoiceStatusFilter';

export type FilterBarProps = {
  selectedFilters: Filters;
  onSelectFilters: (filters: Filters) => void;
  onClearFilters: () => void;
  search?: string;
  onSearch: (value: string) => void;
};

export const FiltersToolBar = forwardRef<FilterBarProps>(
  ({ onSelectFilters, selectedFilters, onClearFilters, onSearch, search, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group
        variant="horizontal"
        justifyContent="flex-start"
        alignItems="center"
        width="full"
        spacing="s"
        data-component="FiltersToolBar"
        {...props}
        ref={ref}
      >
        <DSSearchBar
          data-testid="invoice-table-search-input"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          label={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.label')}
          placeholder={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.placeholder')}
          onClear={() => onSearch('')}
          aria-label={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.aria-label')}
          instructionsText={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.instructions')}
        />
        <>
          <Text {...{ minWidth: 'fit-content' }} textStyle="body3Semi" color="global.neutral.900">
            {formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.title')}
          </Text>
          <Group
            variant="horizontal"
            justifyContent="flex-start"
            alignItems="center"
            width="full"
            allowOverflowX
            spacing="s"
            ref={ref}
          >
            <InvoiceStatusFilter
              onApply={(filter) => onSelectFilters({ invoiceStatus: filter })}
              selectedStatusFilters={selectedFilters.invoiceStatus ? selectedFilters.invoiceStatus : undefined}
            />
            <DateRangeFilter
              type="updatedAtRange"
              selectedFilter={selectedFilters.updatedAt}
              selectedFilterRange={selectedFilters.updatedAtRange}
              onApply={(dateRange, datePreset) => onSelectFilters({ updatedAtRange: dateRange, updatedAt: datePreset })}
            />
            <DateRangeFilter
              selectedFilter={selectedFilters.dueDate}
              selectedFilterRange={selectedFilters.dueDateRange}
              type="dueDateRange"
              onApply={(dateRange, datePreset) => onSelectFilters({ dueDateRange: dateRange, dueDate: datePreset })}
            />
            {!isEmpty(selectedFilters) && (
              <NakedButton
                data-testid="invoice-table-clear-filters"
                variant="secondary"
                label={formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.clear.label')}
                onClick={onClearFilters}
              />
            )}
          </Group>
        </>
      </Group>
    );
  }
);
FiltersToolBar.displayName = 'FiltersToolBar';
