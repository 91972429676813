import { Group, Pill, PillProps, StatusIconSolid, Table, Text, useTable } from '@melio/penny';
import { Loan } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

export const InstallmentsTable = ({ loan }: { loan: Loan }) => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();

  const { status, nextInstallmentNumber, installments } = loan;
  const cellsColor = status === 'handled-by-credit-key' ? 'global.neutral.600' : 'global.neutral.900';

  const getInstallmentStatusLabelProps = useCallback(
    (installment: Loan['installments'][0]): PillProps & { textTransform?: string } => {
      const installmentStatus = installment.status;
      switch (installmentStatus) {
        case 'completed':
          return { status: 'success', label: installmentStatus, textTransform: 'capitalize' };
        case 'failed':
          return { status: 'critical', label: installmentStatus, textTransform: 'capitalize' };
        case 'canceled':
          return { status: 'critical', label: installmentStatus, textTransform: 'capitalize' };
        case 'handled-by-credit-key':
          return {
            status: 'warning',
            label: formatMessage(
              'widgets.paymentDetails.payFrom.loan.installments.table.content.installment.status.handledByCreditKey'
            ),
          };
        default:
          return { status: 'informative', label: installmentStatus, textTransform: 'capitalize' };
      }
    },
    [formatMessage]
  );

  const shouldCellBeBold = useCallback(
    (installment: Loan['installments'][0]) =>
      loan.status === 'active' && installment.installmentNumber === nextInstallmentNumber,
    [loan.status, nextInstallmentNumber]
  );

  const tableProps = useTable({
    headerVariant: 'dark',
    data: installments,
    columns: [
      {
        id: 'number',
        header: formatMessage('widgets.paymentDetails.payFrom.loan.installments.table.header.installment.number'),
        cell: ({ row: installment }) => {
          const content = installment.installmentNumber;
          return (
            <Table.Cell color={cellsColor}>
              {shouldCellBeBold(installment) ? <Text textStyle="body3Semi"> {content}</Text> : content}
            </Table.Cell>
          );
        },
        size: 'xs',
      },
      {
        id: 'debitDate',
        header: formatMessage('widgets.paymentDetails.payFrom.loan.installments.table.header.installment.debitDate'),
        cell: ({ row: installment }) => {
          const content = formatDate(installment.scheduledDate, { dateStyle: 'medium' });
          return (
            <Table.Cell color={cellsColor}>
              {shouldCellBeBold(installment) ? <Text textStyle="body3Semi"> {content}</Text> : content}
            </Table.Cell>
          );
        },
        size: 's',
      },
      {
        id: 'status',
        header: formatMessage('widgets.paymentDetails.payFrom.loan.installments.table.header.installment.status'),
        cell: ({ row: installment }) => (
          <Table.Cell>
            <Group spacing="xs">
              <Pill {...getInstallmentStatusLabelProps(installment)} type="secondary" />
              {installment.status === 'handled-by-credit-key' && <StatusIconSolid variant="warning" size="small" />}
            </Group>
          </Table.Cell>
        ),
        size: 's',
      },
      {
        id: 'amount',
        header: formatMessage('widgets.paymentDetails.payFrom.loan.installments.table.header.installment.amount'),
        cell: ({ row: installment }) => {
          const content = formatCurrency(installment.amount);
          return (
            <Table.Cell color={cellsColor} textAlign="end">
              {shouldCellBeBold(installment) ? <Text textStyle="body3Semi"> {content}</Text> : content}
            </Table.Cell>
          );
        },
        size: 's',
        textAlign: 'end',
      },
    ],
  });

  return <Table {...tableProps} />;
};
