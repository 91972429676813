import { StatusModal, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useIsSubscriptionsEnabled, usePlanInfo, useSubscription } from '../../../../../hooks';

export type SubscriptionCancelLoseAccessModalScreenProps = {
  isOpen: boolean;
  onClose: () => void;
  newPlanName: string;
  onProceedToCancel: () => void;
};

export const SubscriptionCancelLoseAccessModalScreen = ({
  isOpen,
  onClose,
  onProceedToCancel,
  newPlanName,
}: SubscriptionCancelLoseAccessModalScreenProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { formatMessage } = useMelioIntl();
  const { planName: currentPlanName } = usePlanInfo(subscription?.planId);

  if (!isSubscriptionsEnabled) {
    return null;
  }

  return (
    <StatusModal
      isOpen={isOpen}
      onClose={onClose}
      footer={undefined}
      header={formatMessage('activities.subscription.cancelLoseAccessModal.title')}
      data-testid="subscription-cancel-lose-access-modal-screen"
      primaryButton={{
        label: formatMessage('activities.subscription.cancelLoseAccessModal.primaryButton'),
        onClick: onProceedToCancel,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.cancelLoseAccessModal.secondaryButton'),
        onClick: onClose,
        variant: 'tertiary',
      }}
      variant="warning"
    >
      <Typography.Paragraph
        content={formatMessage('activities.subscription.cancelLoseAccessModal.results.description', {
          newPlanName,
          currentPlanName,
        })}
      />
      <Typography.ParagraphList
        list={([1, 2, 3] as const).map((value) => ({
          content: formatMessage(`activities.subscription.cancelLoseAccessModal.results.item.${value}`),
        }))}
        type="unordered"
      />
    </StatusModal>
  );
};
