/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';

import { supportApi } from '@/api/apiClients';
import { usePartnerConfig } from '@/hooks/partners';

type zendeskUserData = {
  email: string;
  firstName: string;
  lastName: string;
  orgId?: string;
  id: string;
  registrationFlow?: string;
};

const isZendeskWidget = (_instance: unknown): _instance is ZendeskWidget => {
  return !!window?.zE;
};

const setZendeskData = (user: zendeskUserData, onError?: () => void) => {
  try {
    // sending user data to support
    const zendesk = window.zE!;
    const { email, firstName, lastName, orgId, id, registrationFlow } = user;

    zendesk('webWidget', 'prefill', {
      name: { value: `${firstName} ${lastName}` },
      email: { value: email },
    });

    const tags = [orgId ? `authenticated_organization_id_${orgId}` : '', `accountId-${id}`];
    if (registrationFlow) {
      tags.push(registrationFlow);
    }
    // custom tags
    zendesk('messenger:set', 'conversationTags', tags);
  } catch (error) {
    // disable chat if cannot send user data
    onError?.();
  }
};

const showZendeskChat = () => {
  if (!window?.zE) {
    return;
  }

  window.zE('messenger', 'open');
};

const hideZendesk = () => {
  const zendesk = window?.zE;
  if (zendesk) {
    zendesk('messenger', 'hide');
    return;
  }
};

//we need to save it here because we can use this hook multiple time but it's will load only one time
let zendeskEnabled = false;

export const useZendesk = () => {
  const zendeskRef = React.useRef({
    attemptsToInit: 8,
  });
  const { data: account } = useAccount({ id: 'me' });
  const { track } = useAnalytics();
  const { partnerConfig } = usePartnerConfig();

  const setData = React.useCallback((user: zendeskUserData) => {
    const onError = () => {
      zendeskEnabled = false;
    };
    setZendeskData(user, onError);
  }, []);

  const logUserIntoZendesk = React.useCallback(() => {
    const zendesk = window.zE;
    if (!isZendeskWidget(zendesk)) {
      return;
    }

    zendesk('messenger', 'loginUser', async (callback: (token: string) => void) => {
      try {
        const {
          data: { token },
        } = await supportApi.issueZendeskToken();
        callback(token);
      } catch (e: unknown) {
        track('Support', 'zendeskGetTokenError', { error: (e as Error).message.toString() });
      }
    });
  }, []);

  const initZendesk = React.useCallback(
    ({ user }: { user: zendeskUserData }) => {
      const zendesk = window.zE;

      if (!zendesk && zendeskRef.current.attemptsToInit > 0) {
        // waiting for zendesk object
        zendeskRef.current.attemptsToInit -= 1;

        setTimeout(() => initZendesk({ user }), 500);

        return;
      }
      if (!zendesk) {
        return;
      }
      zendesk('messenger:on', 'open', () => logUserIntoZendesk());
      zendeskEnabled = true;
      // save user data for chat session
      setData(user);
    },
    [partnerConfig, setData],
  );

  React.useEffect(() => {
    if (!partnerConfig.config.services.zendeskKey || !account) {
      return;
    }
    const { user: accountUser, id } = account;
    const user: zendeskUserData = {
      email: accountUser.email!,
      firstName: accountUser.firstName,
      id,
      orgId: account.organizationId,
      lastName: accountUser.lastName,
    };
    const zendeskScriptId = 'ze-snippet';
    const scriptElement = document.getElementById(zendeskScriptId);
    if (scriptElement) {
      return;
    }
    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${partnerConfig.config.services.zendeskKey}`;
    script.id = zendeskScriptId;

    // handle zendesk init
    script.onload = () => initZendesk({ user });

    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);

    const { headerColor, resultListsColor, themeColor, title, logo } = partnerConfig.config.zendesk;
    // remove full screen button from chat
    window.zESettings = {
      webWidget: {
        navigation: {
          popoutButton: {
            enabled: false,
          },
        },
        chat: {
          concierge: {
            avatarPath: logo,
          },
          title: {
            '*': title,
          },
        },
        color: {
          theme: themeColor,
          resultLists: resultListsColor,
          header: headerColor,
        },
      },
    };

    document.body.appendChild(script);
    //hide the zendesk launcher button
    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);
  }, [partnerConfig.config, account]);

  const showZendesk = React.useCallback(() => {
    if (!zendeskEnabled) {
      return;
    }
    track('Support', 'openChatSupport', { userId: account?.id, email: account?.user?.email! });
    showZendeskChat();
  }, [track, account]);

  //call it when user logout
  const logoutChat = React.useCallback(() => {
    //reset data
    setData({
      email: '',
      firstName: '',
      id: '',
      lastName: '',
      orgId: '',
      registrationFlow: '',
    });

    hideZendesk();
  }, [hideZendesk, setData]);

  return {
    showZendesk,
    hideZendesk,
    logoutChat,
  };
};
