import { isVendorDirectoryDetailsCompleted } from '@melio/ap-domain';
import { TableRowSelectionState, useBreakpoint, useTable } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import uniq from 'lodash/uniq';
import { useCallback, useMemo, useState } from 'react';

type UseVendorsTabSelectionResult = {
  rowSelections: string[];
  areAllSelected: boolean;
  uncheckSelection: (selection: Selection) => void;
} & Pick<
  Parameters<typeof useTable<Vendor>>[0],
  'selectedRows' | 'onRowSelectionChange' | 'onAllRowsSelectionChange' | 'rowSelectionTooltips'
>;

type UseVendorsTabSelectionProps = {
  vendorsData: Vendor[];
  onChange: (rowSelections: string[], lastSelection: Selection) => void;
};

export type Selection =
  | {
      type: 'all';
    }
  | { type: 'row'; rowData: Vendor }
  | null;

export const useVendorsTabSelection = ({
  vendorsData,
  onChange,
}: UseVendorsTabSelectionProps): UseVendorsTabSelectionResult => {
  const [rowSelections, setRowSelections] = useState<string[]>([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const {
    settings: {
      vendor: { requiredDirectoryFields },
    },
  } = useConfig();
  const [isVendorsBatchPaymentsEnabled] = useDevFeature<boolean>(
    FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled,
    false,
    {
      shouldTrack: true,
    }
  );
  const [isPlatformBatchPaymentsEnabled] = useDevFeature<boolean>(FeatureFlags.PlatformBatchPayments, false);
  const [isCollectMissingVendorInfoEnabled] = useDevFeature<boolean>(
    FeatureFlags.CollectMissingVendorInfoEnabled,
    false
  );

  const onRowSelectionChange = useCallback(
    (selectionState: TableRowSelectionState<Vendor>) => {
      const { rowId, isSelected } = selectionState;
      setRowSelections((selectedRows) =>
        isSelected ? uniq([...selectedRows, rowId]) : selectedRows.filter((selectedRowId) => selectedRowId !== rowId)
      );

      onChange(
        isSelected && !rowSelections.includes(rowId)
          ? [...rowSelections, rowId]
          : rowSelections.filter((selectedRow) => selectedRow !== rowId),
        {
          type: 'row',
          rowData: selectionState.rowData,
        }
      );
    },
    [rowSelections, onChange]
  );

  const onAllRowsSelectionChange = useCallback(
    (areAllSelected: boolean) => {
      setAreAllSelected(areAllSelected);
      const rowSelections = areAllSelected ? vendorsData?.map((vendor) => vendor.id) ?? [] : [];
      setRowSelections(rowSelections);

      onChange(rowSelections, areAllSelected ? { type: 'all' } : null);
    },
    [vendorsData, onChange]
  );

  const rowSelectionTooltips = useMemo(
    () => ({
      header: {
        content: !areAllSelected
          ? formatMessage('activities.payDashboard.vendorsTab.table.tooltips.selectAll')
          : formatMessage('activities.payDashboard.vendorsTab.table.tooltips.deselectAll'),
      },
      row: ({ isSelectionDisabled, rowData }: { isSelectionDisabled: boolean; rowData: Vendor }) => {
        const isVendorDirectoryInfoCompleted = isVendorDirectoryDetailsCompleted({
          vendor: rowData,
          isCollectMissingVendorInfoEnabled,
          requiredDirectoryFields,
        });
        return isSelectionDisabled
          ? {
              content: !isVendorDirectoryInfoCompleted
                ? formatMessage('activities.payDashboard.vendorsTab.table.cells.disabled.tooltips.missingVendorDetails')
                : formatMessage('activities.payDashboard.vendorsTab.table.cells.disabled.tooltips.fx'),
            }
          : undefined;
      },
    }),
    [areAllSelected, formatMessage, isCollectMissingVendorInfoEnabled, requiredDirectoryFields]
  );

  const uncheckSelection = useCallback(
    (selection: Selection) => {
      if (!selection) {
        return;
      }

      if (selection.type === 'all') {
        onAllRowsSelectionChange(false);
      } else if (selection.type === 'row') {
        onRowSelectionChange({
          rowData: selection.rowData,
          rowId: selection.rowData.id,
          isSelected: false,
        });
      }
    },
    [onAllRowsSelectionChange, onRowSelectionChange]
  );

  if (isExtraSmallScreen || !isVendorsBatchPaymentsEnabled || !isPlatformBatchPaymentsEnabled) {
    return { rowSelections: [], areAllSelected: false, uncheckSelection };
  }

  return {
    rowSelections,
    selectedRows: rowSelections.reduce<Record<string, boolean>>((obj, rowId) => ({ ...obj, [rowId]: true }), {}),
    onRowSelectionChange,
    onAllRowsSelectionChange,
    rowSelectionTooltips,
    areAllSelected,
    uncheckSelection,
  };
};
