import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { isValidInternationalPhoneNumber } from '@melio/platform-utils';
import * as yup from 'yup';

import { AccountHolderForm } from '../types';
import { useFxCommonValidations } from '../utils/formUtils';

export const useAccountHolderFormSchema = () => {
  const { addressSchema, dateOfBirthSchema, firstNameSchema, lastNameSchema, emailSchema, residencySchema, ssnSchema } =
    useFxCommonValidations();
  const [showUboResidencyNewFields] = useDevFeature<boolean>(FeatureFlags.UboResidencyNewFields, false);
  const { formatMessage } = useMelioIntl();

  let schema = yup.object().shape({
    firstName: firstNameSchema.trim(),
    lastName: lastNameSchema.trim(),
    dayOfBirth: dateOfBirthSchema,
    phoneNumber: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.required'
        )
      )
      .test(
        'valid-intl-phone',
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.phoneNumber.invalid'
        ),
        isValidInternationalPhoneNumber
      ),
    address: addressSchema,
    taxId: yup.string().nullable(),
    isUbo: yup.boolean().nullable(),
    email: emailSchema.trim(),
    residencyDetails: residencySchema,
  });

  if (!showUboResidencyNewFields) {
    schema = schema.concat(
      yup.object().shape({
        taxId: ssnSchema.trim(),
      })
    );
  }

  return schema as yup.SchemaOf<AccountHolderForm>;
};
