import { Box } from '@chakra-ui/react';
import { SystemMessage, TestModeMessage, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { BrazeContentCard, BrazeContentCardType } from '@melio/in-app-marketing';
import { Group, TabItem as DSTabItem, Tabs } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { CompleteMandatoryDetailsWarningBanner, ComplianceVerificationFailedBanner } from '@melio/platform-kyc';
import { ContentToSkip, PageTitle } from '@melio/platform-utils';
import { Outlet } from 'react-router-dom';

import { HeaderToolBar, HeaderToolBarMobile } from './components';

export type TabsLayoutProps<T extends string = string> = {
  tabs: TabItem<T>[];
  activeTab: T;
  onChange: (name: T) => void;
  onCreateInvoice: VoidFunction;
  onCreateCustomer: VoidFunction;
  onVisitSupportSettingsPage: VoidFunction;
  onVisitSettingsPage: VoidFunction;
  kycBannerVariation?: 'complete-details' | 'verification-failed';
  onCompleteKycDetails?: VoidFunction;
};

export function TabsLayout<T extends string = string>({
  activeTab,
  onChange,
  tabs,
  onCreateInvoice,
  onCreateCustomer,
  onVisitSupportSettingsPage,
  onVisitSettingsPage,
  kycBannerVariation,
  onCompleteKycDetails,
  ...props
}: TabsLayoutProps<T>) {
  const { formatMessage } = useMelioIntl();
  const isMobile = useIsMobile();

  const { track } = useAnalytics();

  const onClickSupportButton = () => {
    track('Dashboard', 'Click', {
      Cta: '?',
      Intent: 'get-support',
    });

    onVisitSupportSettingsPage();
  };

  const onClickCreateInvoice = () => {
    track('Dashboard', 'Click', {
      Cta: 'create-invoice',
      Intent: 'create-invoice',
    });

    onCreateInvoice();
  };

  return (
    <Box backgroundColor="white" data-component={TabsLayout.displayName} {...props}>
      <Group variant="vertical">
        <Group variant="vertical" spacing="s">
          <TestModeMessage screen="dashboard" />
          <SystemMessage />
        </Group>
        <Group variant="vertical" spacing="m">
          {kycBannerVariation === 'complete-details' && onCompleteKycDetails ? (
            <CompleteMandatoryDetailsWarningBanner onOpenCompleteRequiredDetailsDialog={onCompleteKycDetails} />
          ) : kycBannerVariation === 'verification-failed' ? (
            <ComplianceVerificationFailedBanner />
          ) : null}
          <Group variant="vertical">
            <Group spacing="m" variant="horizontal" justifyContent="space-between">
              <PageTitle textStyle="heading1Semi">
                {formatMessage('ar.dashboard.activities.tabsLayout.title.label')}
              </PageTitle>
              {isMobile ? (
                <HeaderToolBarMobile
                  onCreateInvoice={onClickCreateInvoice}
                  onCreateCustomer={onCreateCustomer}
                  onVisitSupportSettingsPage={onVisitSupportSettingsPage}
                  onVisitSettingsPage={onVisitSettingsPage}
                />
              ) : (
                <HeaderToolBar
                  onVisitSettingsPage={onVisitSettingsPage}
                  onVisitSupportSettingsPage={onClickSupportButton}
                  onCreateInvoice={onClickCreateInvoice}
                />
              )}
            </Group>
            <BrazeContentCard cardType={BrazeContentCardType.DASHBOARD_CONTENT_CARD} location="ar" />
            <Group spacing="none" variant="vertical">
              <ContentToSkip />
              <Tabs
                aria-label={formatMessage('ar.dashboard.activities.tabsLayout.tabs.aria-label')}
                tabs={tabs}
                activeTab={activeTab}
                onChange={onChange as never}
              />
            </Group>
            <Outlet />
          </Group>
        </Group>
      </Group>
    </Box>
  );
}

TabsLayout.displayName = 'TabsLayout';

export type TabItem<T = string> = Override<DSTabItem, { name: T }>;
