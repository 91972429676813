import { PayorPaymentRequestDetails, PayorVendorPaymentDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { GuestPayorFundingSourceTypes } from '../types';
import { PaymentLayout } from './Payment.layout';
import { SharedPaymentLayoutProps } from './types';

export type PaymentRequestDetailsLayoutProps = SharedPaymentLayoutProps & {
  isLoading?: boolean;
  isHidden?: boolean;
  paymentRequestDetails?: PayorPaymentRequestDetails;
  vendorPaymentDetails?: PayorVendorPaymentDetails;
};

export const PaymentRequestDetailsLayout = forwardRef<PaymentRequestDetailsLayoutProps>(
  (
    {
      paymentRequestDetails,
      vendorPaymentDetails,
      isLoading,
      onViewInvoice,
      onSelectFundingSource,
      isDisabled,
      selectedFundingSource,
      ...props
    },
    ref
  ) => (
    <PaymentLayout
      isLoading={!paymentRequestDetails || !vendorPaymentDetails}
      isLoadingContent={isLoading}
      data-layout={PaymentRequestDetailsLayout.displayName}
      companyName={vendorPaymentDetails?.payeeDetails.companyName}
      companyLogoUrl={vendorPaymentDetails?.payeeDetails.logoUrl ?? undefined}
      fees={vendorPaymentDetails?.fees}
      invoiceDueDate={paymentRequestDetails?.invoice.dueDate}
      invoiceTotalAmount={paymentRequestDetails?.invoice.totalAmount}
      invoiceFileUrl={paymentRequestDetails?.invoice.fileInfo?.previewUrls[0]}
      supportedPaymentMethods={getGuestPayorFundingSourceTypes(vendorPaymentDetails)}
      onViewInvoice={onViewInvoice}
      selectedFundingSource={selectedFundingSource}
      onSelectFundingSource={onSelectFundingSource}
      isDisabled={isDisabled}
      isPayable={paymentRequestDetails?.isPayable}
      backgroundColor={vendorPaymentDetails?.payeeDetails.brandColor ?? undefined}
      {...props}
      ref={ref}
    />
  )
);
PaymentRequestDetailsLayout.displayName = 'PaymentRequestDetailsLayout';

const getGuestPayorFundingSourceTypes = (
  vendorPaymentDetails?: PayorVendorPaymentDetails
): GuestPayorFundingSourceTypes[] => {
  const target: GuestPayorFundingSourceTypes[] = [];
  const { isAchAllowed, isCardAllowed } = vendorPaymentDetails?.paymentOptions ?? {};
  if (isAchAllowed) target.push('bank');
  if (isCardAllowed) target.push('card');
  return target;
};
