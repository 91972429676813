import { Box, useDimensions } from '@chakra-ui/react';
import {
  Container,
  Grid,
  GridItem,
  Group,
  Layout,
  LoadingContainer,
  Pill,
  SectionBanner,
  Switch,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils, useLocation } from '@melio/platform-utils';
import { addDays } from 'date-fns';
import { useRef, useState } from 'react';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../api';
import {
  useCyclesPricing,
  useDefaultSubscriptionPromotion,
  useFreeTrialEligibility,
  usePendingSubscription,
  usePlanInfo,
  usePlansTiers,
  useSubscription,
} from '../../../../hooks';
import { plansSorter } from '../../../../utils';
import { PlanPricingInfoLink } from '../../../components/CurrentPlan/fiserv/PlanPricingInfoLink';
import { PlanCard } from '../components/PlanCard';
import { PlansPromotionBanner } from '../components/PlansPromotionBanner';
import { PlansSelectionHeader } from '../components/PlansSelectionHeader';
import { SubscribeToPlanType } from '../types';

export const enum SubscriptionPlansVariant {
  PAGE = 'PAGE',
  MODAL = 'MODAL',
}

export type SubscriptionPlansScreenProps = {
  variant?: SubscriptionPlansVariant;
  isUpdatingSubscription?: boolean;
  selectedPlanId?: string;
  onShowReactivatePlanModal: () => void;
  onSelectPlan: ({ selectedPlanId, selectedBillingCycle }: SubscribeToPlanType) => void;
  onBack?: () => void;
  isLoading?: boolean;
  withOnboardingPlansExperimentOn?: boolean;
  isFiservPartner: boolean;
};

export const SubscriptionPlansScreen = withAnalyticsContext<SubscriptionPlansScreenProps>(
  ({
    variant = SubscriptionPlansVariant.PAGE,
    isUpdatingSubscription,
    selectedPlanId,
    onSelectPlan,
    setAnalyticsProperties,
    onBack,
    onShowReactivatePlanModal: onShowReactivateModal,
    isLoading,
    withOnboardingPlansExperimentOn,
    isFiservPartner,
    ...rest
  }) => {
    const subscription = useSubscription();
    const { getAvailablePlans } = usePlansTiers();
    const availablePlans = getAvailablePlans();

    const { data: currentAccount, isLoading: isLoadingAccount } = useAccount({ id: 'me' });

    const containerRef = useRef<HTMLInputElement>(null);
    const dimensions = useDimensions(containerRef, true);
    const { formatPercents, formatMessage, formatDate } = useMelioIntl();
    const { getAnnualPlanSavingPercents } = useCyclesPricing();
    const { isReactivationAllowed, pendingPlanInfo } = usePendingSubscription();
    const { isExtraSmallScreen } = useBreakpoint();
    const currentPlanInfo = usePlanInfo(subscription?.planId);
    const [selectedBillingCycle, setSelectedBillingCycle] = useState(
      subscription?.planCyclePeriod ?? SubscriptionBillingCycleEnum.Monthly
    );
    const [isReactivateBannerClosed, setIsReactivateBannerClosed] = useState(false);

    const { promotionBanner, isFetching: isFetchingPromotionBanner } = useDefaultSubscriptionPromotion();

    const { state: locationState } = useLocation<{
      analyticsEntryPoint?: string;
      analyticsFlow?: string;
    }>();

    setAnalyticsProperties({
      PageName: 'plan-selection',
      Flow: locationState?.analyticsFlow ?? 'subscription',
    });
    useAnalyticsView('Organization', true, true, {
      Intent: 'choose-plan',
      PlanId: subscription?.planId || 'post-onboarding',
      CurrentPlan: currentPlanInfo.planName.toLowerCase() || 'post-onboarding',
      EntryPoint: locationState?.analyticsEntryPoint ?? (subscription ? 'post-onboarding-modal' : 'settings'),
    });
    const { track } = useAnalytics();
    const { createDate } = useDateUtils();

    const handleSelectPlan = (selectedPlanId: Plan['id'], planName: string) => {
      track('Organization', 'Click', {
        Intent: 'subscribe',
        Cta: 'subscribe',
        PlanChosenId: selectedPlanId,
        PlanChosen: planName,
        BillingCycle: selectedBillingCycle,
      });
      onSelectPlan({ selectedPlanId, selectedBillingCycle });
    };

    const toggleBillingCycle = () => {
      const newCycle =
        selectedBillingCycle === SubscriptionBillingCycleEnum.Monthly
          ? SubscriptionBillingCycleEnum.Annual
          : SubscriptionBillingCycleEnum.Monthly;

      track('Organization', 'Click', {
        Intent: 'update-payment-frequency',
        Cta: newCycle,
      });

      if (isReactivationAllowed) {
        onShowReactivateModal();
        return;
      }
      setSelectedBillingCycle(
        selectedBillingCycle === SubscriptionBillingCycleEnum.Monthly
          ? SubscriptionBillingCycleEnum.Annual
          : SubscriptionBillingCycleEnum.Monthly
      );
    };

    const { getFirstPaidPlan } = usePlansTiers();
    const firstPaidPlan = getFirstPaidPlan();
    const hasBothBillingCycles = firstPaidPlan?.cycles.annual && firstPaidPlan?.cycles.monthly;
    const isCycleEditable =
      hasBothBillingCycles && (!subscription || subscription.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly);

    const annualPlanSavingPercents = getAnnualPlanSavingPercents();

    const { isEligibleForFreeTrial, isFetching: isFetchingFreeTrialEligibilityData } = useFreeTrialEligibility();

    const gridGap = 24;
    const containerBaseWidth = 1200;
    const plansCount = availablePlans.length;
    const baseWidthHeader = containerBaseWidth - gridGap;
    const baseWidth = containerBaseWidth - gridGap * (plansCount - 1);
    const minSizeColumn = 225;
    const maxColumnSize = baseWidth / plansCount;
    const minContainerSize = plansCount * minSizeColumn;
    const containerWidth = dimensions?.borderBox?.width;
    const shouldWrap = containerWidth && containerWidth < minContainerSize;
    const gridTemplateCol = shouldWrap ? '1fr' : `repeat(${plansCount}, minmax(0, ${maxColumnSize}px))`;
    const gridItemColSpan = shouldWrap ? 1 : plansCount;
    const isOverallLoading = isLoading || isFetchingFreeTrialEligibilityData || isLoadingAccount;
    const containerSpacingVertical = (dimensions?.borderBox?.top || 0) * 2;
    const loadingContainerHeight = isOverallLoading ? `calc(100vh - ${containerSpacingVertical}px)` : 'full';

    return (
      <LoadingContainer isLoading={isOverallLoading} data-testid="subscription-plans-screen">
        <Layout paddingContent="none" maxWidth="full">
          {!isFetchingPromotionBanner && promotionBanner && variant === SubscriptionPlansVariant.PAGE ? (
            <PlansPromotionBanner variant="panel" promotionBanner={promotionBanner} />
          ) : null}
          <Box
            height={loadingContainerHeight}
            {...(variant === SubscriptionPlansVariant.MODAL
              ? { padding: '0' }
              : { padding: { xs: 's', m: 'm', l: 'l', xl: 'xxl' } })}
          >
            <Group spacing="none" justifyContent="center" variant="vertical" {...rest} ref={containerRef}>
              {variant === SubscriptionPlansVariant.PAGE && (
                <PlansSelectionHeader
                  onBack={onBack}
                  isEligibleForFreeTrial={isEligibleForFreeTrial}
                  gridTemplateCol={shouldWrap ? '1fr' : `repeat(2, minmax(0, ${baseWidthHeader / 2}px))`}
                />
              )}

              <Grid
                justifyContent="center"
                gridColumnGap="m"
                gridRowGap="s"
                pt={variant === SubscriptionPlansVariant.MODAL ? 'none' : 'xl'}
                gridTemplateColumns={gridTemplateCol}
              >
                {!isFetchingPromotionBanner && promotionBanner && variant === SubscriptionPlansVariant.MODAL ? (
                  <GridItem colSpan={gridItemColSpan}>
                    <PlansPromotionBanner variant="banner" promotionBanner={promotionBanner} />
                  </GridItem>
                ) : null}

                {!isReactivateBannerClosed && isReactivationAllowed && subscription && (
                  <GridItem colSpan={gridItemColSpan}>
                    <Container width="full">
                      <SectionBanner
                        showCloseIcon
                        data-testid="pending-plan-change-section-banner"
                        onClose={() => setIsReactivateBannerClosed(true)}
                        title={formatMessage('activities.subscription.plans.selection.reactivateBanner.title')}
                        description={formatMessage(
                          'activities.subscription.plans.selection.reactivateBanner.description',
                          {
                            date: formatDate(addDays(createDate(subscription.endDate), 1), {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                            }),
                            nextPlanName: pendingPlanInfo.planName,
                            currentPlanName: currentPlanInfo.planName,
                          }
                        )}
                        closeButtonAriaLabel={formatMessage(
                          'activities.subscription.plans.selection.reactivateBanner.closeButtonAriaLabel'
                        )}
                        data-role="alert"
                      />
                    </Container>
                  </GridItem>
                )}

                {isCycleEditable && (
                  <GridItem colSpan={gridItemColSpan}>
                    <Group
                      spacing="xs"
                      width="full"
                      alignItems="center"
                      variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                    >
                      <Group spacing="s">
                        <Text>{formatMessage('activities.subscription.plans.selection.monthly')}</Text>
                        <Switch
                          data-testid="subscription-plans-cycle-switch"
                          size="large"
                          value={selectedBillingCycle === SubscriptionBillingCycleEnum.Annual}
                          onChange={toggleBillingCycle}
                        />
                        <Text>{formatMessage('activities.subscription.plans.selection.yearly')}</Text>
                      </Group>
                      {annualPlanSavingPercents && (
                        <Pill
                          type="secondary"
                          label={formatMessage('activities.subscription.checkout.billingCycle.annualSavingBadge', {
                            savingPercent: formatPercents(annualPlanSavingPercents, { divide: true }),
                          })}
                          status="success"
                        />
                      )}
                    </Group>
                  </GridItem>
                )}

                {availablePlans.sort(plansSorter).map((plan) => (
                  <PlanCard
                    key={plan.id}
                    plan={plan}
                    isSelected={selectedPlanId === plan.id}
                    onSelectPlan={handleSelectPlan}
                    selectedBillingCycle={selectedBillingCycle}
                    isEligibleForFreeTrial={isEligibleForFreeTrial}
                    isAccountingClient={!!currentAccount?.isAccountingClient}
                    withOnboardingPlansExperimentOn={withOnboardingPlansExperimentOn}
                  />
                ))}
                {isFiservPartner && <PlanPricingInfoLink />}
              </Grid>
            </Group>
          </Box>
        </Layout>
      </LoadingContainer>
    );
  }
);
