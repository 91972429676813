import { useModelApi, UseModelApiProps, useNonEntityMutation, useUpdateMutation } from '@melio/api-client';
import {
  AccountingPlatform,
  AccountingPlatformParseAuthParamsRequest,
  AccountingPlatformsApiClient,
  RegisterAccountingPlatformOrganizationRequest,
} from '@melio/platform-api-axios-client';

type QueryFn = typeof AccountingPlatformsApiClient.getAccountingPlatformsById;
type UseAccountingPlatformProps = UseModelApiProps<QueryFn, AccountingPlatform>;

export const useAccountingPlatform = (props: UseAccountingPlatformProps) => {
  const query = useModelApi<QueryFn>({
    ...props,
    queryKey: ['AccountingPlatformsApi', props.id],
    queryFn: AccountingPlatformsApiClient.getAccountingPlatformsById,
  });

  const generateAuthLink = useUpdateMutation(
    AccountingPlatformsApiClient.createAccountingPlatformAuthLink,
    query.queryKey
  );

  const disconnect = useUpdateMutation(AccountingPlatformsApiClient.disconnectAccountingPlatformById, query.queryKey);
  const registerOrganization = useNonEntityMutation(
    (params: RegisterAccountingPlatformOrganizationRequest) =>
      AccountingPlatformsApiClient.registerAccountingPlatformOrganization(props.id as string, params),
    query.queryKey
  );
  const parseAuthParams = async ({ authParams }: AccountingPlatformParseAuthParamsRequest) => {
    const {
      data: {
        data: { companies },
      },
    } = await AccountingPlatformsApiClient.accountingPlatformParseAuthParams(props.id as string, { authParams });
    return { companies };
  };

  return {
    ...query,
    generateAuthLink: generateAuthLink.createCallback(props.id as string),
    disconnect: disconnect.createCallback(props.id as string),
    registerOrganization: registerOrganization.mutateAsync,
    parseAuthParams,
    isRegisteringOrganization: registerOrganization.isLoading,
    isOrganizationRegistered: registerOrganization.isSuccess,
  };
};
