import { useAROrganizationPreferences, useInvoicePreference, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { Loader } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React from 'react';

import { useHandleUpdateInvoicePreferences } from './hooks';
import {
  AcceptedPaymentMethodsScreen,
  GlobalFeeAbsorptionSettingsModalScreen,
  RemoveCustomPaymentDetailsModalScreen,
} from './screens';
import { FeeAbsorptionSettings, FeesPaidBy } from './types';

export type AcceptedPaymentMethodsActivityProps = {
  onError?: ARErrorFunction;
  onEditCustomPaymentDetails: VoidFunction;
};

export const AcceptedPaymentMethodsActivity = forwardRef<AcceptedPaymentMethodsActivityProps>(
  ({ onError, onEditCustomPaymentDetails }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();
    const { data: invoicePreferences, isFetching, isMutating } = useInvoicePreference({ onError });
    const {
      data: orgPreferences,
      isLoading: isOrganizationPreferencesLoading,
      create: createOrganizationPreferences,
      refetch: refetchOrganizationPreferences,
    } = useAROrganizationPreferences();
    const [isSettingFeeAbsorption, setIsSettingFeeAbsorption] = useBoolean(false);

    const isVendorAbsorbedFee = !!orgPreferences?.find(
      ({ key, value }) => key === 'isVendorAbsorbedFee' && value === 'true'
    );
    const feesPaidBy: FeesPaidBy = isVendorAbsorbedFee ? 'vendor' : 'payor';

    const [isRemoveCustomPaymentDetailsModalOpen, removeCustomPaymentDetailsModalOpen] = useBoolean(false);
    const [isGlobalFeeAbsorptionSettingsModalOpen, globalFeeAbsorptionSettingsModalOpen] = useBoolean(false);
    const handleUpdateInvoicePreferences = useHandleUpdateInvoicePreferences(onError);

    const handleRemoveCustomPaymentDetails = () => {
      handleUpdateInvoicePreferences(
        { customPayInstructions: '' },
        'ar.settings.activities.acceptedPaymentMethods.removeCustomPaymentDetails.toast.success.label',
        'ar.settings.activities.acceptedPaymentMethods.removeCustomPaymentDetails.toast.failure.label'
      ).finally(() => removeCustomPaymentDetailsModalOpen.off());
    };

    const handleTogglePaymentOption = (key: 'allowACHPayment' | 'allowCardPayment', isEnabled: boolean) => {
      handleUpdateInvoicePreferences(
        { [key]: isEnabled },
        isEnabled
          ? 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.toast.activated.success.label'
          : 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.toast.deactivated.success.label',
        'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.toast.failure.label'
      ).finally(() => removeCustomPaymentDetailsModalOpen.off());
    };

    const handleFeeAbsorptionSettings = async ({ feesPaidBy }: FeeAbsorptionSettings) => {
      try {
        setIsSettingFeeAbsorption.on();
        await createOrganizationPreferences({
          key: 'isVendorAbsorbedFee',
          value: String(feesPaidBy === 'vendor'),
        });
        await refetchOrganizationPreferences();
        triggerMessage({
          type: 'success',
          title: formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.toast.success.label'
          ),
        });
      } catch (err) {
        triggerMessage({
          type: 'critical',
          title: formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.toast.failure.label'
          ),
        });
        onError?.(err as ARPlatformError);
      } finally {
        setIsSettingFeeAbsorption.off();
        globalFeeAbsorptionSettingsModalOpen.off();
      }
    };

    if (isFetching || isOrganizationPreferencesLoading || !invoicePreferences) {
      return <Loader />;
    }

    return (
      <>
        <AcceptedPaymentMethodsScreen
          invoicePreferences={invoicePreferences}
          onEditCustomPaymentDetails={onEditCustomPaymentDetails}
          onRemoveCustomPaymentDetails={() => removeCustomPaymentDetailsModalOpen.on()}
          onToggleACHPaymentsAllowed={(isEnabled) => handleTogglePaymentOption('allowACHPayment', isEnabled)}
          onToggleCardPaymentsAllowed={(isEnabled) => handleTogglePaymentOption('allowCardPayment', isEnabled)}
          isUpdating={isMutating}
          feesPaidBy={feesPaidBy}
          onEditFeeAbsorption={() => globalFeeAbsorptionSettingsModalOpen.on()}
          ref={ref}
        />
        <RemoveCustomPaymentDetailsModalScreen
          isSubmitting={isMutating}
          isOpen={isRemoveCustomPaymentDetailsModalOpen}
          onClose={() => removeCustomPaymentDetailsModalOpen.off()}
          onCloseModal={() => removeCustomPaymentDetailsModalOpen.off()}
          onSubmit={handleRemoveCustomPaymentDetails}
        />
        <GlobalFeeAbsorptionSettingsModalScreen
          isOpen={isGlobalFeeAbsorptionSettingsModalOpen}
          onClose={() => globalFeeAbsorptionSettingsModalOpen.off()}
          isSaving={isSettingFeeAbsorption}
          defaultValues={{ feesPaidBy }}
          onSubmit={handleFeeAbsorptionSettings}
        />
      </>
    );
  }
);

AcceptedPaymentMethodsActivity.displayName = 'AcceptedPaymentMethodsActivity';
