import { UseWizardArgs } from '@melio/platform-utils';

import { ActivityStepsEnum, InitialStepProps, Steps } from './types';

export const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  [ActivityStepsEnum.Initial]: ActivityStepsEnum.Initial,
  [ActivityStepsEnum.SelectOrganization]: ActivityStepsEnum.SelectOrganization,
  [ActivityStepsEnum.ConnectToQuickBooksDesktop]: ActivityStepsEnum.ConnectToQuickBooksDesktop,
  [ActivityStepsEnum.ConfirmConnect]: ActivityStepsEnum.ConfirmConnect,
  [ActivityStepsEnum.VendorLinking]: ActivityStepsEnum.VendorLinking,
  [ActivityStepsEnum.Sync]: ActivityStepsEnum.Sync,
  [ActivityStepsEnum.SyncError]: ActivityStepsEnum.SyncError,
};

export const navigationMap = {
  [ActivityStepsEnum.Initial]: ({
    shouldGoToVendorLinking,
    isQuickBooksDesktop,
    isMultiOrgsAvailableToConnect,
    isRegistrableQuickBooksDesktopOrganizationExists,
  }: InitialStepProps) => {
    if (shouldGoToVendorLinking) {
      return ActivityStepsEnum.VendorLinking;
    }

    if (isQuickBooksDesktop) {
      if (isRegistrableQuickBooksDesktopOrganizationExists) {
        return ActivityStepsEnum.ConfirmConnect;
      }
      return ActivityStepsEnum.ConnectToQuickBooksDesktop;
    }

    if (isMultiOrgsAvailableToConnect) {
      return ActivityStepsEnum.SelectOrganization;
    }

    return ActivityStepsEnum.ConfirmConnect;
  },
  [ActivityStepsEnum.SelectOrganization]: ({ shouldGoToVendorLinking }: { shouldGoToVendorLinking: boolean }) => {
    if (shouldGoToVendorLinking) {
      return ActivityStepsEnum.VendorLinking;
    }

    return ActivityStepsEnum.Sync;
  },
  [ActivityStepsEnum.ConnectToQuickBooksDesktop]: () => ActivityStepsEnum.Sync,
  [ActivityStepsEnum.ConfirmConnect]: ({ shouldGoToVendorLinking }: { shouldGoToVendorLinking: boolean }) => {
    if (shouldGoToVendorLinking) {
      return ActivityStepsEnum.VendorLinking;
    }

    return ActivityStepsEnum.Sync;
  },
  [ActivityStepsEnum.VendorLinking]: () => ActivityStepsEnum.Sync,
  [ActivityStepsEnum.Sync]: () => ActivityStepsEnum.SyncError,
  [ActivityStepsEnum.SyncError]: () => ActivityStepsEnum.Sync,
};
