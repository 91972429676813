import { useConfig } from '@melio/platform-provider';
import { useEffect, useRef } from 'react';

export function useCollapsedState(options: {
  deps: { vendorId: string | undefined; amountToPay: number | undefined };
  isLoading: boolean;
}): 'collapsed' | 'loading' | 'open' {
  const { deps, isLoading } = options;
  const {
    settings: {
      payment: {
        scheduling: { initialFormCollapsedState },
      },
    },
  } = useConfig();
  const lockRef = useRef({ loadedOnce: false });
  const dependenciesFilled = Object.values(deps).every(Boolean);

  useEffect(() => {
    if (!isLoading && dependenciesFilled && !lockRef.current.loadedOnce) {
      lockRef.current.loadedOnce = true;
    }
  }, [isLoading, deps, dependenciesFilled]);
  useEffect(() => {
    lockRef.current.loadedOnce = false;
  }, [deps.vendorId, deps.amountToPay]);

  if (initialFormCollapsedState === 'full') {
    return 'open';
  }

  if (!dependenciesFilled) {
    return 'collapsed';
  }

  if (isLoading && !lockRef.current.loadedOnce) {
    return 'loading';
  }

  return 'open';
}
