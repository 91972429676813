import { useGuestPayorPaymentIntent, useGuestPayorSetup } from '@melio/ar-domain';
import { useEffect, VFC } from 'react';

type OnboardingActivityProps = {
  paymentRequestLink: string;
  email: string;
  onDone: (vendorId: string, isMissingMcc: boolean, guestPaymentIntentId?: string) => void;
  onError?: ARErrorFunction;
  onClose?: VoidFunction;
  useGuestPaymentIntent?: boolean;
};

export const OnboardingActivity: VFC<OnboardingActivityProps> = ({
  paymentRequestLink,
  onDone,
  onError,
  onClose,
  useGuestPaymentIntent,
}) => {
  const { createGuestPaymentIntent } = useGuestPayorPaymentIntent();
  const { setup } = useGuestPayorSetup({ useGuestPaymentIntent });

  const handleSetup = async () => {
    let guestPaymentIntentId: string | undefined;

    if (useGuestPaymentIntent) {
      const { data } = await createGuestPaymentIntent({ paymentRequestLink });
      ({ guestPaymentIntentId } = data);
    }

    const { data } = guestPaymentIntentId ? await setup({ guestPaymentIntentId }) : await setup({ paymentRequestLink });
    return {
      ...data,
      guestPaymentIntentId,
    };
  };

  useEffect(() => {
    handleSetup()
      .then((data) => onDone(data.vendorId, data.isMissingMcc, data.guestPaymentIntentId))
      .catch((error) => {
        onClose?.();
        onError?.(error as ARPlatformError);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

OnboardingActivity.displayName = 'OnboardingActivity';
