import { useAccountingPlatforms, useLegalDocuments, useUserPreference, useUserPreferences } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useSubscription } from '@melio/subscriptions';
import { useCallback, useEffect, useState } from 'react';

import {
  FISERV_DASHBOARD_TOUR_PREFERENCE_KEY,
  PAY_DASHBOARD_TOUR_PREFERENCE_KEY,
} from '../components/PayDashboardTour';
import {
  FISERV_DASHBOARD_MIGRATED_USER_KEY,
  FISERV_DASHBOARD_MIGRATED_USER_TOUR_PREFERENCE_KEY,
} from '../components/PayDashboardTour/consts';
import { useIsSubscriptionPending } from './useIsSubscriptionPending';

const preferenceKey = 'payDashboardFirstTimeExperience';

export const useFirstTimeExperience = () => {
  const [isFirstTimeExperienceEnabled] = useDevFeature<boolean>(FeatureFlags.NpeFirstTimeExperience, false);
  const [isPayDashboardTourEnabled] = useDevFeature<boolean>(FeatureFlags.IsPayDashboardTourEnabled, false);
  const [isFiservDashboardTourEnabled] = useDevFeature<boolean>(FeatureFlags.IsFiservDashboardTourEnabled, false);
  const [isMigratedUserTourTripEnabled] = usePartnerFeature('MigratedUserTourTripEnabled', false);
  const [isOnboardingModalEnabled] = usePartnerFeature('OnboardingModalEnabled', true);
  //indicate if the user is migrated
  const { data: migratedUserPreference } = useUserPreference({
    id: FISERV_DASHBOARD_MIGRATED_USER_KEY,
    enabled: true,
  });

  const isSubscriptionPending = useIsSubscriptionPending();
  const subscription = useSubscription();

  const [signTermsAndConditionsEnabled] = usePartnerFeature('TermsAndConditions', false);
  const {
    data: legalDocuments,
    isFetching: legalDocumentsFetching,
    isLoading: legalDocumentsLoading,
  } = useLegalDocuments({
    signTermsAndConditionsEnabled,
  });

  const { create } = useUserPreferences({ enabled: false });
  const { data, isLoading: isLoadingUserPreference } = useUserPreference({
    id: preferenceKey,
    enabled: isFirstTimeExperienceEnabled,
  });

  const { isLoading: isLoadingAccountingPlatforms } = useAccountingPlatforms();
  const [isFirstTimeExperience, setIsFirstTimeExperience] = useState(false);

  const {
    settings: { subscriptions: subscriptionsConfig, migrationUserTour },
  } = useConfig();

  const handleShowProductTour = useCallback(() => {
    if (isMigratedUserTourTripEnabled && migrationUserTour.isEnabled && migratedUserPreference?.value === 'true') {
      create({ userPreference: { key: FISERV_DASHBOARD_MIGRATED_USER_TOUR_PREFERENCE_KEY, value: 'true' } });
    } else if (isFiservDashboardTourEnabled) {
      create({ userPreference: { key: FISERV_DASHBOARD_TOUR_PREFERENCE_KEY, value: 'true' } });
    } else if (isPayDashboardTourEnabled) {
      create({ userPreference: { key: PAY_DASHBOARD_TOUR_PREFERENCE_KEY, value: 'true' } });
    }
  }, [
    isFiservDashboardTourEnabled,
    isPayDashboardTourEnabled,
    create,
    isMigratedUserTourTripEnabled,
    migrationUserTour,
    migratedUserPreference,
  ]);

  useEffect(() => {
    if (
      !isFirstTimeExperienceEnabled ||
      isLoadingAccountingPlatforms ||
      isLoadingUserPreference ||
      data ||
      isSubscriptionPending ||
      legalDocumentsFetching ||
      legalDocumentsLoading ||
      legalDocuments?.shouldSignTermsAndConditions
    ) {
      return;
    }

    if ((subscriptionsConfig && !subscriptionsConfig.expectOrgsToBeSubscribed) || !isOnboardingModalEnabled) {
      handleShowProductTour();
    } else {
      setIsFirstTimeExperience(true);
    }
    create({ userPreference: { key: preferenceKey, value: 'true' } });
  }, [
    isLoadingUserPreference,
    isLoadingAccountingPlatforms,
    data,
    create,
    isFirstTimeExperienceEnabled,
    isSubscriptionPending,
    subscription,
    handleShowProductTour,
    isOnboardingModalEnabled,
    subscriptionsConfig,
    legalDocumentsFetching,
    legalDocuments,
    legalDocumentsLoading,
  ]);

  return { isFirstTimeExperience, handleShowProductTour, isLoadingUserPreference };
};
