/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DeliveryMethodType, useDeliveryMethods } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import {
  AddVendorPaypalAccountForm,
  BankAccountFormModel,
  DeliveryMethodCardOnSubmit,
  PaperCheckForm,
} from '../components/Vendors/VendorDeliveryMethodsSection/types';

export const useUpsertVendorDeliveryMethods = (vendorId: string) => {
  const { showMessage } = useSystemMessage();

  const [updatedDeliveryMethods, setUpdatedDeliveryMethods] = useState<
    {
      type: DeliveryMethodType;
      values: DeliveryMethodCardOnSubmit;
    }[]
  >([]);

  const collection = useDeliveryMethods({ vendorId });

  const handleFail = (error: PlatformError) => {
    showMessage({ type: 'error', title: error.message });
  };

  const createBankAccountDeliveryMethod = (data: BankAccountFormModel) =>
    collection.create({ type: DeliveryMethodType.BankAccount, details: { ...data, accountType: 'checking' } });

  const createPaperCheckDeliveryMethod = (data: PaperCheckForm) => {
    const { printName, ...address } = data;
    return collection.create({ type: DeliveryMethodType.PaperCheck, details: { printName, address } });
  };

  const createPaypalDeliveryMethod = (data: AddVendorPaypalAccountForm) =>
    collection.create({
      type: DeliveryMethodType.PaypalBalance,
      details: {
        paypalAccountEmail: data.email,
      },
    });

  const upsertVendorDeliveryMethods = async () => {
    try {
      await Promise.all(
        updatedDeliveryMethods.map(async ({ type, values }) => {
          switch (type) {
            case DeliveryMethodType.BankAccount:
              await createBankAccountDeliveryMethod(values as BankAccountFormModel);
              break;
            case DeliveryMethodType.PaperCheck:
              await createPaperCheckDeliveryMethod(values as PaperCheckForm);
              break;
            case DeliveryMethodType.PaypalBalance:
              await createPaypalDeliveryMethod(values as AddVendorPaypalAccountForm);
              break;
          }
        })
      );
    } catch (error) {
      handleFail(error as PlatformError);
    }
  };

  return {
    updatedDeliveryMethods,
    isDeliveryMethodsMutating: collection.isMutating,
    setUpdatedDeliveryMethods,
    upsertVendorDeliveryMethods,
  };
};
