import { Box } from '@chakra-ui/react';
import { usePaymentLateApprovalTooltip, usePaymentsLateApprovals } from '@melio/ap-domain';
import { Group, Icon, StatusIconSolid, Text, Tooltip, VisuallyHidden } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentLateApprovalIndication = ({ payment, iconFirst }: { payment: Payment; iconFirst?: boolean }) => {
  const { formatMessage } = useMelioIntl();
  const getPaymentsLateApprovals = usePaymentsLateApprovals();

  const paymentsLateApprovals = getPaymentsLateApprovals({ payments: [payment] });
  const paymentLateApproval = paymentsLateApprovals[payment.id];
  const isDebitDateAfterToday = !!paymentLateApproval?.isDebitDateAfterToday;

  const { label: tooltipLabel, title: tooltipTitle } = usePaymentLateApprovalTooltip(payment);

  const label = isDebitDateAfterToday
    ? formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.indication.lateDebitDate')
    : formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.indication.debitDateToday');

  const renderIcon = isDebitDateAfterToday ? (
    <StatusIconSolid size="small" variant="warning" data-testid="late-approval-indication-warning-icon" />
  ) : (
    <Icon size="small" type="info" data-testid="late-approval-indication-info-icon" />
  );

  const renderText = (
    <Text textStyle="body4" color="global.neutral.900">
      {label}
    </Text>
  );

  return iconFirst ? (
    <Group variant="horizontal" spacing="xxs" alignItems="center">
      {renderIcon}
      {renderText}
    </Group>
  ) : (
    <Group variant="horizontal" spacing="xxs" alignItems="center">
      {renderText}
      {tooltipLabel || tooltipTitle ? (
        <Tooltip
          content={
            <>
              <VisuallyHidden>{label} tooltip</VisuallyHidden>
              <Box as="span" display="inline-flex" textStyle="body4Semi">
                {tooltipTitle}
              </Box>
              {tooltipLabel}
            </>
          }
          triggerAriaLabel={tooltipLabel}
          shouldAddTriggerFocus
          data-testid="payment-late-approval-indication-tooltip"
        >
          {renderIcon}
        </Tooltip>
      ) : (
        renderIcon
      )}
    </Group>
  );
};
