import { useMelioIntl } from '@melio/ar-domain';
import { Container, Group, Icon, IconKey, NakedButton, Switch, Text } from '@melio/penny';
import React from 'react';

export type PaymentOptionSwitcherProps = {
  iconType: IconKey;
  title: string;
  subtitle: string;
  value: boolean;
  onChange: (enabled: boolean) => void;
  isUpdating: boolean;
  paymentOptionType: 'card' | 'ACH';
  editFeeAbsorptionLabel?: string;
  onEditFeeAbsorption?: VoidFunction;
};

export const PaymentOptionSwitcher = ({
  iconType,
  title,
  subtitle,
  value,
  onChange,
  isUpdating,
  paymentOptionType,
  editFeeAbsorptionLabel,
  onEditFeeAbsorption,
}: PaymentOptionSwitcherProps) => {
  const { formatMessage } = useMelioIntl();

  const shouldDisplayFeeAbsorptionEditButton = !!(editFeeAbsorptionLabel && onEditFeeAbsorption);

  return (
    <Container border="regular" paddingX="s" paddingY="s">
      <Group justifyContent="space-between">
        <Group alignItems="center">
          <Icon type={iconType} />
          <Group spacing="none" variant="vertical">
            <Text textStyle="body2Semi">{title}</Text>
            <Group spacing="xxs" alignItems="center">
              <Text color="global.neutral.800" textStyle="body4">
                {subtitle}
              </Text>
              {shouldDisplayFeeAbsorptionEditButton && (
                <NakedButton
                  data-testid="edit-global-fee-absorption-settings-button"
                  variant="secondary"
                  onClick={onEditFeeAbsorption}
                  label={editFeeAbsorptionLabel}
                />
              )}
            </Group>
          </Group>
        </Group>
        <Switch
          data-testid={`invoice-payment-option-${paymentOptionType}`}
          aria-hidden
          value={value}
          onChange={onChange}
          label={formatMessage(
            value
              ? 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.switch.on.label'
              : 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.switch.off.label'
          )}
          aria-label={formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.switch.ariaLabel',
            { switchTitle: title }
          )}
          isDisabled={isUpdating}
        />
      </Group>
    </Container>
  );
};
