import { useIsExternalAccountant } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useLocation } from '@melio/platform-utils';
import { useMemo } from 'react';

import { useSubscriptionContext } from '../context/SubscriptionContext';
import { isRouteAllowedForNotSubscribedOrg } from '../ui/utils/blockedStatesAllowedRoutes.utils';
import { useSubscription } from './useSubscription';

export const useGracePeriodEndPaywall = () => {
  const subscription = useSubscription();
  const isExternalAccountant = useIsExternalAccountant();
  const { can } = usePermissions();

  const canCreateSubscription = can({
    subject: 'subscriptions',
    action: 'create',
  });

  const graceEndRedirectRoute = useMemo(() => {
    if (isExternalAccountant) {
      return '/subscription/grace/select-client-plan';
    } else if (canCreateSubscription) {
      return '/subscription/grace/select-your-plan';
    } else {
      return '/subscription/grace/plan-is-required';
    }
  }, [isExternalAccountant, canCreateSubscription]);

  const {
    gracePeriod: { isNotSubscribedPostGracePeriod },
  } = useSubscriptionContext();
  const location = useLocation();

  const isRouteAllowed = isRouteAllowedForNotSubscribedOrg(location.pathname);
  const shouldShowGracePeriodEndPaywall = isNotSubscribedPostGracePeriod && !isRouteAllowed && !subscription;

  return {
    graceEndRedirectRoute,
    shouldShowGracePeriodEndPaywall,
  };
};
