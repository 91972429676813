import { getFeeTypeByDeliveryMethod } from '@melio/ap-domain';
import { IconKey, PillProps } from '@melio/penny';
import { DeliveryMethod, FeeCatalog, formatAddress, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFeePillProps } from '../../../hooks/useFeePillProps';

export const useDeliveryMethodCardProps = ({
  vendor,
  deliveryMethod,
  feesCatalog,
}: {
  vendor: Vendor | undefined;
  deliveryMethod: DeliveryMethod | undefined;
  feesCatalog?: FeeCatalog[];
}): {
  title: string;
  description: string;
  icon: IconKey;
  pillProps?: Array<PillProps & { 'data-testid': string }>;
} => {
  const { formatMessage } = useMelioIntl();

  const deliveryMethodFee =
    deliveryMethod && feesCatalog?.find((fee) => fee.feeType === getFeeTypeByDeliveryMethod(deliveryMethod.type));
  const pillProps = useFeePillProps(deliveryMethodFee);

  if (!vendor || !deliveryMethod) {
    return {
      title: '',
      icon: 'error',
      description: '',
    };
  }

  switch (deliveryMethod.type) {
    case 'managed-account':
      if (deliveryMethod.details.deliveryType === 'check') {
        return {
          title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.title'),
          description: formatMessage(
            'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.description',
            { vendorName: vendor.name }
          ),
          icon: 'paper-check',
        };
      }
      return {
        title: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.title'
        ),
        description: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.description',
          { vendorName: vendor.name }
        ),
        icon: 'bank-out',
        pillProps,
      };
    case 'bank-account':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.description', {
          account: deliveryMethod.details.accountNumberLast4Digits,
        }),
        icon: 'bank-out',
        pillProps,
      };
    case 'paper-check':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.description', {
          vendorName: vendor.name,
          address: formatAddress(deliveryMethod.details.address),
        }),
        icon: 'paper-check',
        pillProps,
      };
    case 'paypal-balance':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paypal-balance.title'),
        description: deliveryMethod.details.paypalAccountEmail,
        icon: 'paypal',
        pillProps,
      };

    default:
      return {
        title: 'not implemented',
        description: 'not implemented',
        icon: 'error',
      };
  }
};
