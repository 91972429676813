import { FileInfo, InvoiceExpandedSummary, ReceivablePayment } from '@melio/ar-domain';
import { Divider, Group, LoadingContainer } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { InvoicePaymentSummeryDetails } from '../../../components';
import { PaymentInformation } from './PaymentInformation';

export type DrawerBodyProps = {
  receivablePayment?: ReceivablePayment;
  expandedInvoice?: InvoiceExpandedSummary;
  isLoadingInvoice?: boolean;
  payeeFile?: FileInfo;
  payorFile?: FileInfo;
  isLoadingPayeeFile?: boolean;
  isLoadingPayorFile?: boolean;
};

export const DrawerBody = forwardRef<DrawerBodyProps>(
  (
    {
      expandedInvoice,
      isLoadingPayorFile,
      payorFile,
      payeeFile,
      isLoadingPayeeFile,
      receivablePayment,
      isLoadingInvoice,
    },
    ref
  ) => {
    if (!receivablePayment) return null;

    const [invoiceDetails] = receivablePayment.invoices ?? [];

    return (
      <Group ref={ref} spacing="xl" variant="vertical">
        <PaymentInformation
          customerName={expandedInvoice?.customer.companyName}
          receivablePayment={receivablePayment}
          payeeFile={payeeFile}
          isLoadingPayeeFile={isLoadingPayeeFile}
          payorFile={payorFile}
          isLoadingPayorFile={isLoadingPayorFile}
        />
        {invoiceDetails ? (
          <>
            <Divider />
            <LoadingContainer isLoading={isLoadingInvoice}>
              {expandedInvoice ? <InvoicePaymentSummeryDetails expandedInvoice={expandedInvoice} /> : null}
            </LoadingContainer>
          </>
        ) : null}
      </Group>
    );
  }
);
