import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { PlansApiClient } from '@melio/platform-api-axios-client';

import { Plan } from './types';
type QueryFN = (id: string) => ReturnType<typeof PlansApiClient.getPlan>;

export type UseGetPlanProps = UseModelApiProps<QueryFN, Plan> & { id: string };

export const usePlan = (props: UseGetPlanProps) =>
  useModelApi<QueryFN, never, never, never>({
    ...props,
    queryKey: ['PlansApi'],
    queryFn: () => PlansApiClient.getPlan(props.id),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
