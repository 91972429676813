/* eslint-disable max-lines */
import { Box, useDisclosure } from '@chakra-ui/react';
import {
  SyncConfirmationModal as AccountingSoftwareConfirmModal,
  SyncConfirmationModalProps,
  SyncMigrationStatusBanner,
} from '@melio/ap-widgets';
import { BrazeContentCard, BrazeContentCardType } from '@melio/in-app-marketing';
import { Container, Group, Tabs, useBreakpoint } from '@melio/penny';
import { OriginFlow, useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import {
  AccountingPlatformConnectionStatus,
  AccountingPlatformSlug,
  getAvailableToConnectAccountingPlatforms,
  useAccount,
  useAccountingPlatforms,
  usePayments,
} from '@melio/platform-api';
import { EngagementEventsEnum, EngagementModal } from '@melio/platform-engagement';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { ContentToSkip, useLocation, useNavigate } from '@melio/platform-utils';
import { SystemMessageDisplay } from '@melio/platform-utils/system-message';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useActivitiesNavigate } from '../../utils';
import { DemoRequestButtonActivity } from '../demo-request-button';
import { MultiAccountBanner } from '../multi-account-banner/MultiAccountBanner.activity';
import {
  PromoteAccountingSoftwareSyncBanner,
  useShowPromoteAccountingSoftwareSyncBanner,
} from '../promote-accounting-software-sync';
import { Sync1099ContractorsModal } from '../sync-1099-contractors-modal';
import { ApprovalsTab, BillsTab, PaymentsTab, VendorsTab } from './components';
import { AccountingSoftwareConnectModal } from './components/BillsTab/components/AccountingSoftwareConnectModal/AccountingSoftwareConnectModal';
import { DashboardHeader } from './components/DashboardHeader/DashboardHeader';
import { FinancingTab } from './components/FinancingTab';
import { FirstTimeExperienceModalWrapper } from './components/FirstTimeExperienceModal/FirstTimeExperienceModalWrapper';
import { FreeAchPromotionModal } from './components/FreeAchPromotionModal/FreeAchPromotionModal';
import { PayDashboardTourWrapper } from './components/PayDashboardTour/PayDashboardTourWrapper';
import { VENDOR_GROUPS_LOCAL_STORAGE_KEY } from './components/VendorsTab/consts';
import { usePayDashboardBanner } from './hooks/useDashboardBanner';
import { useFirstTimeExperience } from './hooks/useFirstTimeExperience';
import { usePaymentsTabNewFailedPayments } from './hooks/useGetNewFailedPayments';
import { useIsSubscriptionPending } from './hooks/useIsSubscriptionPending';
import { useNewApprovalsTabItems } from './hooks/useNewApprovalsTabItems';
import { useNewBillsTabItems } from './hooks/useNewBillsTabItems';
import { useFinancingTabNewFailedLoans, useNewFinancingTabItems } from './hooks/useNewFinancingTabItems';
import { useNewPaymentsTabItems } from './hooks/useNewPaymentsTabItems';
import { usePayDashboardAnalytics } from './hooks/usePayDashboardAnalytics';
import { usePayDashboardTabs } from './hooks/usePayDashboardTabs';
import { useSetPayDashboardDocumentTitle } from './hooks/useSetPayDashboardDocumentTitle';
import { PayDashboardActivityProps } from './payDashboardActivityPropType';
import { PayDashboardTabs } from './types';
import { getSyncNowAnalyticsProperties } from './utils/payment-analytics.utils';

const disconnectedAccountingPlatformStatuses: AccountingPlatformConnectionStatus[] = [
  AccountingPlatformConnectionStatus.Unlinked,
  AccountingPlatformConnectionStatus.Disconnected,
];

export const PayDashboardActivity = withAnalyticsContext<PayDashboardActivityProps>(
  ({
    onAddNewBill,
    onAddNewBillManual,
    onAddNewBillUpload,
    onEditBill,
    onPayPaymentRequest,
    onReviewScannedInvoice,
    onEditPayment,
    onRetryFailedToDeliverPayment,
    onRetryFailedToCollectPayment,
    onRefundPayment,
    onVoidAndRefundPayment,
    onVoidAndResendPayment,
    goToSettingsAccountSoftware,
    onConnectAccountingPlatform,
    onViewPayment,
    onOpenFreeAchPromotionModal,
    onEditBillSubscription,
    redirectToZenworkLoginPage,
    tabsVariant,
    isSyncedWithZenwork,
    isRedirectingToZenwork,
    isAccountingPlatformConnecting,
    bannerToDisplay,
    onCloseCompleteDetailsDialog,
    setAnalyticsProperties,
  }) => {
    const { settings } = useConfig();
    const { isConnectConfirmationEnabled } = settings.accountingSoftware;
    const navigate = useNavigate();
    const { data: account } = useAccount({ id: 'me' });
    const { track } = useAnalytics();
    const { isExtraSmallScreen } = useBreakpoint();
    const [isEngagementProvider] = useDevFeature<boolean>(FeatureFlags.IsEngagementProvider, false);
    const [isFirstTimeExperienceEnabled] = useDevFeature<boolean>(FeatureFlags.NpeFirstTimeExperience, false);

    const [isSyncMigrationProgressIndicationEnabled] = useDevFeature(
      FeatureFlags.PlatformSyncMigrationProgressIndication,
      false
    );

    const { tabs, handleTabChange, selectedTab, shouldShow, isLoading: isTabsLoading } = usePayDashboardTabs();
    const { newPayments: newFailedPayments, markAsSeen: markFailedPaymentsAsSeen } = usePaymentsTabNewFailedPayments({
      limit: 1,
    });
    const { newBillsTabItems, markAsSeen: markNewBillsAsSeen } = useNewBillsTabItems();
    const { newPayments: newApprovalsTabItems, markAsSeen: markNewPendingApprovalsAsSeen } = useNewApprovalsTabItems();
    const { markAsSeen: markNewPaymentAsSeen } = useNewPaymentsTabItems();
    const { markAsSeen: markFailedLoansAsSeen } = useFinancingTabNewFailedLoans();
    const { markAsSeen: markNewLoansAsSeen } = useNewFinancingTabItems();

    const [isFirstTimeExperienceModalOpen, setIsFirstTimeExperienceModalOpen] = useState(false);
    const { isFirstTimeExperience, handleShowProductTour, isLoadingUserPreference } = useFirstTimeExperience();
    const { data: payments } = usePayments({
      params: { limit: 1, expand: 'none', sort: [{ field: 'createdAt', order: 'desc' }] },
    });
    const userHasPayments = useMemo(() => (payments ? payments.length : true), [payments]);
    const [isShowEngagementModal, setIsShowEngagementModal] = useState<boolean>(false);
    const [isFirstTimeExperienceTourtipRunning, setIsFirstTimeExperienceTourtipRunning] = useState<boolean>(true);
    const [isAccountingSoftwareConnectModalOpen, setIsAccountingSoftwareConnectModalOpen] = useState<boolean>(false);
    const [chosenAccountingSlug, setChosenAccountingSlug] = useState<AccountingPlatformSlug | null>(null);
    const { pathname } = useLocation();
    const { navigateToScheduleNewPayment } = useActivitiesNavigate();
    const isSubscriptionPending = useIsSubscriptionPending();
    useSetPayDashboardDocumentTitle();

    const onAddNewPayment = (
      args: { vendorId?: string; originFlow?: OriginFlow; returnUrl?: string; amount?: string } | undefined
    ) => {
      const { vendorId, originFlow, returnUrl, amount } = args || {};

      navigateToScheduleNewPayment({ origin: originFlow, returnUrl: returnUrl || '', vendorId, amount });
    };

    const onFinancingApplicationApply = () => {
      navigate('/financing/application');
    };

    useAnalyticsView('Dashboard');
    setAnalyticsProperties({
      UserMessage: bannerToDisplay,
    });

    const {
      isOpen: isSync1099ContractorsModalOpen,
      onOpen: openSync1099ContractorsModal,
      onClose: closeSync1099ContractorsModal,
    } = useDisclosure();

    const { Banner, analyticsProps: bannerAnalyticsProps } =
      usePayDashboardBanner({ bannerToDisplay, openSync1099ContractorsModal, onCloseCompleteDetailsDialog }) || {};
    const { viewAnalyticsProps } = useShowPromoteAccountingSoftwareSyncBanner({ variant: 'dashboard' });

    useEffect(() => {
      if (!isTabsLoading) {
        track('Dashboard', 'View', {
          PageName: getPageName(),
          TabsExposed: tabs.map((tab) => tab.name),
          Flow: 'dashboard',
          IsBannerShown: !!bannerToDisplay,
          BannerType: bannerToDisplay,
          ...bannerAnalyticsProps,
          ...viewAnalyticsProps,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTabsLoading]);

    useEffect(() => {
      switch (selectedTab) {
        case PayDashboardTabs.Payments:
          markFailedPaymentsAsSeen();
          markNewPaymentAsSeen();
          break;
        case PayDashboardTabs.Bills:
          markNewBillsAsSeen();
          break;
        case PayDashboardTabs.Approvals:
          markNewPendingApprovalsAsSeen();
          break;
        case PayDashboardTabs.Financing:
          markNewLoansAsSeen();
          markFailedLoansAsSeen();
          break;
        default:
          return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    useEffect(() => {
      if (isFirstTimeExperienceEnabled && isFirstTimeExperience) {
        setIsFirstTimeExperienceModalOpen(true);
      }
    }, [isFirstTimeExperienceEnabled, isFirstTimeExperience]);

    useEffect(() => {
      if (!isLoadingUserPreference) {
        if ((!isFirstTimeExperience || !isLoadingUserPreference) && !isSubscriptionPending) {
          setIsShowEngagementModal(!isFirstTimeExperience);
        }
      }
    }, [isFirstTimeExperience, isLoadingUserPreference, isSubscriptionPending]);

    const getSavedVendorGroupsFromLocalStorage = () => {
      const localStorageVendorGroups = localStorage.getItem(VENDOR_GROUPS_LOCAL_STORAGE_KEY);
      return localStorageVendorGroups ? (JSON.parse(localStorageVendorGroups) as string[]) : [];
    };

    usePayDashboardAnalytics({
      newBillsTabItems: newBillsTabItems.length,
      newFailedPayments: newFailedPayments.length,
      newApprovalsTabItems: newApprovalsTabItems.length,
      shouldShowApprovalsTab: !!shouldShow[PayDashboardTabs.Approvals],
    });

    const handleFirstTimeExperienceModalClose = () => {
      handleShowProductTour();
      setIsFirstTimeExperienceModalOpen(false);
      onOpenFreeAchPromotionModal();
    };

    const handleAccountingSoftwareConnectModalOpen = () => {
      setIsAccountingSoftwareConnectModalOpen(true);
    };

    const handleAccountingSoftwareConnectModalContinue = (accountingSlug: AccountingPlatformSlug) => {
      if (isConnectConfirmationEnabled) {
        setChosenAccountingSlug(accountingSlug);
        setIsAccountingSoftwareConnectModalOpen(false);
        return;
      }

      onConnectAccountingPlatform(accountingSlug);
    };

    const handleAccountingSoftwareConnectModalClose = () => {
      setIsAccountingSoftwareConnectModalOpen(false);
    };

    const handleAccountingSoftwareConfirmModalContinue = () => {
      chosenAccountingSlug && onConnectAccountingPlatform(chosenAccountingSlug);
    };

    const handleAccountingSoftwareConfirmModalClose = () => {
      setChosenAccountingSlug(null);
    };

    const { data: accountingPlatforms } = useAccountingPlatforms();
    const accountingPlatform = accountingPlatforms?.find(
      (accountingPlatform) => !disconnectedAccountingPlatformStatuses.includes(accountingPlatform.connectionStatus)
    );

    const getPageName = () => `dashboard-${selectedTab ?? 'unknown'}`;

    const availableAccountingPlatforms = getAvailableToConnectAccountingPlatforms(accountingPlatforms);
    const onPressSchedulePayment = () => {
      track('Dashboard', 'Click', {
        Cta: 'new-payment-main-cta',
        PageName: getPageName(),
        Flow: 'dashboard',
      });

      navigateToScheduleNewPayment({ origin: OriginFlow.JustPay, returnUrl: pathname });
    };

    return (
      <>
        <PayDashboardTourWrapper
          activeTab={selectedTab}
          isFirstTimeExperienceTourtipRunning={isFirstTimeExperienceTourtipRunning || isFirstTimeExperienceModalOpen}
          onIsFirstTimeExperienceTourtipRunningChange={setIsFirstTimeExperienceTourtipRunning}
        />
        <Box backgroundColor="white" data-testid="pay-dashboard-activity">
          {Banner && <Banner />}
          <Sync1099ContractorsModal
            isRedirecting={isRedirectingToZenwork}
            isDashboardView
            onImportContractors={redirectToZenworkLoginPage}
            isSyncedWithZenwork={isSyncedWithZenwork}
            isOpen={isSync1099ContractorsModalOpen}
            onClose={closeSync1099ContractorsModal}
          />
          <Group variant="vertical" spacing="m">
            <MultiAccountBanner />
            <SystemMessageDisplay data-testid="dashboard-notification" />
            <PromoteAccountingSoftwareSyncBanner
              variant="dashboard"
              analyticsProps={{ Flow: 'dashboard-user-message', PageName: getPageName(), EntryPoint: getPageName() }}
            />
            <DashboardHeader
              accountingPlatform={accountingPlatform}
              analyticsProperties={getSyncNowAnalyticsProperties(selectedTab)}
              onClickSchedulePayment={onPressSchedulePayment}
              goToSettingsAccountSoftware={goToSettingsAccountSoftware}
              onAddNewPayment={() => onAddNewPayment({ originFlow: OriginFlow.JustPay, returnUrl: pathname })}
              onAddNewBillUpload={onAddNewBillUpload}
              onAddNewBillManual={onAddNewBillManual}
              onAccountingPlatformConnect={handleAccountingSoftwareConnectModalOpen}
            />
            {isSyncMigrationProgressIndicationEnabled && settings.syncProgressIndicationEnabled && (
              <SyncMigrationStatusBanner />
            )}
            <Container backgroundColor="white" overflow="initial">
              <Group variant="vertical" spacing="none">
                <BrazeContentCard cardType={BrazeContentCardType.DASHBOARD_CONTENT_CARD} />
                <ContentToSkip />
                {selectedTab && (
                  <Tabs
                    activeTab={selectedTab}
                    onChange={handleTabChange}
                    tabs={tabs}
                    data-testid="pay-dashboard-activity-pay-dashboard-tabs-list"
                    variant={tabsVariant}
                  />
                )}
                <Container
                  data-testid="pay-dashboard-activity-pay-dashboard-table"
                  paddingBottom={isExtraSmallScreen ? 's' : 'none'}
                  paddingTop={isExtraSmallScreen ? 's' : 'm'}
                  overflow="initial"
                  height="full"
                >
                  <Routes>
                    <Route index element={<Navigate to={`${PayDashboardTabs.Vendors}`} replace />} />
                    <Route
                      path={`/${PayDashboardTabs.Vendors}/*`}
                      element={
                        <VendorsTab
                          onAddNewPayment={onAddNewPayment}
                          onAddNewBill={onAddNewBill}
                          savedVendorGroups={getSavedVendorGroupsFromLocalStorage()}
                        />
                      }
                    />
                    <Route
                      path={`/${PayDashboardTabs.Bills}/*`}
                      element={
                        <BillsTab
                          onAddNewBillManual={onAddNewBillManual}
                          onAddNewBillUpload={onAddNewBillUpload}
                          onReviewScannedInvoice={onReviewScannedInvoice}
                          onEditBill={onEditBill}
                          onPayPaymentRequest={onPayPaymentRequest}
                          onAccountingPlatformConnect={handleAccountingSoftwareConnectModalOpen}
                        />
                      }
                    />
                    <Route
                      path={`/${PayDashboardTabs.Approvals}/*`}
                      element={
                        <ApprovalsTab
                          onEditPayment={onEditPayment}
                          onRetryFailedToDeliverPayment={onRetryFailedToDeliverPayment}
                          onRetryFailedToCollectPayment={onRetryFailedToCollectPayment}
                          onRefundPayment={onRefundPayment}
                          onVoidAndRefundPayment={onVoidAndRefundPayment}
                          onVoidAndResendPayment={onVoidAndResendPayment}
                        />
                      }
                    />
                    <Route
                      path={`/${PayDashboardTabs.Payments}/*`}
                      element={
                        <PaymentsTab
                          onEditPayment={onEditPayment}
                          onAddNewPayment={onAddNewPayment}
                          onRetryFailedToDeliverPayment={onRetryFailedToDeliverPayment}
                          onRetryFailedToCollectPayment={onRetryFailedToCollectPayment}
                          onRefundPayment={onRefundPayment}
                          onVoidAndRefundPayment={onVoidAndRefundPayment}
                          onVoidAndResendPayment={onVoidAndResendPayment}
                          onEditBillSubscription={onEditBillSubscription}
                        />
                      }
                    />
                    <Route
                      path={`/${PayDashboardTabs.Financing}/*`}
                      element={
                        <FinancingTab
                          onAddNewPayment={() => onAddNewPayment({})}
                          onViewPayment={({ id }) => onViewPayment({ id })}
                          onFinancingApplicationApply={onFinancingApplicationApply}
                        />
                      }
                    />
                  </Routes>
                  {!isExtraSmallScreen && !userHasPayments && account?.organizationId && (
                    <Box position="fixed" right={0} bottom={0} pr="40px" pb="80px">
                      <DemoRequestButtonActivity
                        organizationId={account.organizationId.split('_')[1] as string}
                        email={account.user.email as string}
                        helpCenterLink={settings.helpCenterUrl}
                      />
                    </Box>
                  )}
                </Container>
              </Group>
            </Container>
          </Group>
        </Box>
        {isFirstTimeExperienceEnabled && (
          <FirstTimeExperienceModalWrapper
            onClose={handleFirstTimeExperienceModalClose}
            isOpen={isFirstTimeExperienceModalOpen}
          />
        )}
        <AccountingSoftwareConnectModal
          isOpen={isAccountingSoftwareConnectModalOpen}
          isLoading={isAccountingPlatformConnecting}
          accountingPlatforms={availableAccountingPlatforms ?? []}
          onClose={handleAccountingSoftwareConnectModalClose}
          onConnect={handleAccountingSoftwareConnectModalContinue}
        />
        {!isFirstTimeExperience && <FreeAchPromotionModal onOpen={onOpenFreeAchPromotionModal} />}
        {isEngagementProvider && isShowEngagementModal && !isFirstTimeExperienceTourtipRunning && (
          <EngagementModal eventName={EngagementEventsEnum.DASHBOARD_VIEWED} />
        )}
        <AccountingSoftwareConfirmModal
          isOpen={chosenAccountingSlug !== null}
          isLoading={isAccountingPlatformConnecting}
          accountingSlug={chosenAccountingSlug as SyncConfirmationModalProps['accountingSlug']}
          onClose={handleAccountingSoftwareConfirmModalClose}
          onContinue={handleAccountingSoftwareConfirmModalContinue}
        />
      </>
    );
  }
);
