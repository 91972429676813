import { getCardNetworkLabelKey } from '@melio/ap-domain';
import { FundingSource } from '@melio/platform-api';
import { FormatMessage } from '@melio/platform-i18n';

export const getFundingSourceLabel = (fundingSource: FundingSource, formatMessage: FormatMessage): string => {
  switch (fundingSource.type) {
    case 'bank-account':
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.types.bank.label',
        { lastFourDigits: fundingSource.details.accountNumberLast4Digits }
      );
    case 'flex-account':
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.types.flex.label'
      );
    case 'paypal-balance':
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.types.paypal-balance.label'
      );
    case 'card':
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.types.card.label',
        {
          network: formatMessage(getCardNetworkLabelKey(fundingSource.details.network)),
          lastFourDigits: fundingSource.details.lastFourDigits,
        }
      );
  }
};
