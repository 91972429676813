import { useMelioForm } from '@melio/penny';
import { UseMelioFormProps } from '@melio/penny/dist/components/form/hooks/types';
import { createContext, useContext } from 'react';

import { ManageClientFormFields, ManageClientFormResults } from './manage-client-form.types';

const ManageClientFormContext = createContext<ManageClientFormResults | null>(null);

export const useManageClientFormContext = () => {
  const form = useContext(ManageClientFormContext);
  if (!form) {
    throw new Error('FormContext is not provided');
  }
  return form;
};

export const ManageClientFormContextProvider = ManageClientFormContext.Provider;

export const useManageClientForm = (props: UseMelioFormProps<ManageClientFormFields>) =>
  useMelioForm<ManageClientFormFields>(props);
