/* eslint-disable max-lines */
import {
  _createFormFieldInput,
  FormSearchProps,
  Group,
  Icon,
  Pill,
  Search,
  Select,
  SelectProps,
  Text,
} from '@melio/penny';
import { EventProperties, useAnalytics } from '@melio/platform-analytics';
import { CreateVendorAnalyticsMetadataFlow, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature, VendorDetailsFormType } from '@melio/platform-provider';
import { forwardRef, mergeRefs, useSystemMessage } from '@melio/platform-utils';
import { FocusEventHandler, useEffect, useRef, useState } from 'react';

import { getVendorNameForNotificationMessage } from '../../../utils';
import { InvalidVendorBankAccountDetails, InvalidVendorBankAccountModal } from '../../Vendors';
import { AddVendorDrawerWidget, AddVendorDrawerWidgetProps, AddVendorModalWidget } from '../../Vendors/AddVendor';
import { AddVendorFormFields, Managed } from '../../Vendors/AddVendor/AddVendorForm/types';
import { AddVendorOnDone } from '../../Vendors/types';
import { useVendorSelectOptions } from './useVendorSelectOptions';

export type VendorSelectWidgetProps = Omit<SelectProps, 'options' | 'creatableOption' | 'emptyState'> & {
  eventContextName: string;
  shouldClearField?: boolean;
  analyticsProperties?: EventProperties;
  paymentVendor?: Vendor;
  createVendorFlow?: CreateVendorAnalyticsMetadataFlow;
  statusMessageParentSelector?: string | undefined;
  isSubmitted?: boolean;
};

export const VendorSelectWidget = _createFormFieldInput(
  forwardRef<VendorSelectWidgetProps, 'input'>(
    (
      {
        eventContextName,
        shouldClearField,
        analyticsProperties,
        onChange,
        placeholder,
        paymentVendor,
        createVendorFlow,
        statusMessageParentSelector,
        isSubmitted,
        ...props
      },
      propRef
    ) => {
      const innerRef = useRef<HTMLInputElement>(null);
      const searchFieldRef = useRef<HTMLInputElement>(null);

      const ref = mergeRefs([propRef, innerRef]);

      const [pendingCreationVendorName, setPendingCreationVendorName] = useState<string | null>();
      const [invalidBankAccountModalData, setInvalidBankAccountModalData] = useState<
        InvalidVendorBankAccountDetails | undefined
      >();

      const [isInvalidBankAccountModalEnabled] = usePartnerFeature('InvalidVendorBankAccountModal', false);

      const {
        canSearchBusinesses,
        isFirstLoading,
        isLoading: isLoadingOptions,
        isDisabled,
        sections,
        createVendor,
        isCreatingVendor,
        handleSearch,
        getBusinessById,
        isMyVendor,
        isSelectedOptionManaged,
        getHasUnmanagedLocalVendorWithTheSameName,
        trackView,
        trackClickOption,
        isUpdatingVendorsInProgress,
        setIsUpdatingVendorsInProgress,
      } = useVendorSelectOptions({
        eventContextName,
        isViewMode: props.isViewMode,
        selectedOptionValue: props.value as string,
      });

      const { track, trackMarketing } = useAnalytics();
      const { formatMessage } = useMelioIntl();

      type AddVendorDetailsFormState = {
        isOpen: boolean;
        managed?: Managed;
        defaultFormValues?: {
          companyName: string;
        } & Partial<Pick<AddVendorFormFields, 'accountNumber' | 'confirmAccountNumber' | 'postalCode'>>;
      };

      const [addVendorDetailsFormState, setAddVendorDetailsFormState] = useState<AddVendorDetailsFormState>({
        isOpen: false,
      });

      const { showMessage } = useSystemMessage();

      const {
        vendor: {
          vendorSelect: {
            createVendor: { showVendorDetailsForm, vendorDetailsFormType },
          },
        },
      } = useConfig().settings;

      const handleCreateOption = (inputValue: string) => {
        trackClickOption({ vendorType: 'new-local' });
        track('Vendor', 'Click', {
          Cta: 'add-new-vendor',
          Intent: 'add-vendor-on-the-go',
        });

        if (showVendorDetailsForm) {
          setAddVendorDetailsFormState({ isOpen: true, defaultFormValues: { companyName: inputValue } });
          return;
        }

        // This is so we could display the name of the soon-to-be-created vendor as the placeholder.
        setPendingCreationVendorName(inputValue);

        const analyticsMetadata = createVendorFlow
          ? {
              flow: createVendorFlow,
            }
          : undefined;
        createVendor({ name: inputValue, analyticsMetadata })
          .then(({ id: createdVendorId, ...vendor }) => {
            const vendorName = getVendorNameForNotificationMessage(vendor);

            showMessage({
              type: 'success',
              title: formatMessage('addVendor.createVendorToast.success', {
                vendorName,
              }),
            });
            track('Vendor', 'Status', {
              vendorType: 'local',
              Status: 'success',
              VendorId: createdVendorId,
              ...analyticsProperties,
            });
            trackMarketing('bill-create_create-vendor', { vendorType: 'local', Status: 'success' });
            onChange?.({ target: { value: createdVendorId } } as React.ChangeEvent<HTMLInputElement>);
          })
          .catch((error) => {
            setPendingCreationVendorName(null);
            showMessage({
              type: 'error',
              title: formatMessage('addVendor.createVendorToast.error', {
                companyName: inputValue,
              }),
              action: {
                text: formatMessage('addVendor.createVendorToast.error.action'),
                onAction: (close) => {
                  close();
                  handleCreateOption(inputValue);
                },
                type: 'button',
              },
            });
            track('Vendor', 'Status', { vendorType: 'local', Status: 'failure' });

            throw error;
          });
      };

      const getInputPlaceholder = () => {
        if (isCreatingVendor && pendingCreationVendorName) {
          return pendingCreationVendorName;
        }

        return placeholder;
      };

      const closeAddVendorDetailsForm = () => {
        setAddVendorDetailsFormState({ isOpen: false });
      };

      const clearField = () => {
        //setting "" and null alternately is a workaround - you can't put the same value twich
        onChange?.({ target: { value: props.value === '' ? null : '' } } as React.ChangeEvent<HTMLInputElement>);
      };

      useEffect(() => {
        if (shouldClearField) {
          clearField();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [shouldClearField]);

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = event;

        if (!value) {
          return clearField();
        }

        if (isMyVendor(value)) {
          trackClickOption({ vendorType: 'local', vendorId: value });

          return onChange?.(event);
        }

        const business = getBusinessById(value);
        if (business) {
          trackClickOption({ vendorType: 'directory', selectedBusiness: business });

          setAddVendorDetailsFormState({
            isOpen: true,
            defaultFormValues: {
              companyName: business.business.name,
            },
            managed: {
              self: business.business.self,
              managedBy: business.directoryName,
              isZipCodeNeeded: business.business.merchantZipRequired,
            },
          });
          return;
        }
      };

      const badgeElement = isSelectedOptionManaged ? <Icon size="small" type="verified" color="brand" /> : undefined;

      const creatableOption: FormSearchProps['creatableOption'] = {
        label: (value: string) => formatMessage('form.vendorSelect.createLabel', { value }),
        onClick: handleCreateOption,
        shouldDisplay: (inputValue: string) =>
          Boolean(inputValue && !getHasUnmanagedLocalVendorWithTheSameName(inputValue)) && !isLoadingOptions,
      };

      const handleSearchOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
        const input = event.target?.value;
        if (!input) {
          return clearField();
        }

        if (!props.value) {
          return clearField();
        }
      };

      const handleSelectOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
        const input = event.target?.value;
        if (!input) {
          return clearField();
        }
      };

      const inputProps = {
        'data-component': 'VendorSelect',
        ref,
        ...props,
        options: sections,
        onChange: handleChange,
        onInputChange: handleSearch,
        onSearchTermReset: () => handleSearch(''),
        placeholder: getInputPlaceholder(),
        creatableOption,
        emptyState: { label: formatMessage('form.vendorSelect.emptyState') },
        isDisabled: props.isDisabled || isDisabled,
        isViewMode: props.isViewMode,
        valueRightElement: badgeElement,
        onFocus: trackView,
      };

      const handleSelectCompanyName: AddVendorDrawerWidgetProps['onSelectCompanyName'] = ({
        business,
        companyName,
      }) => {
        if (business) {
          setAddVendorDetailsFormState({
            isOpen: true,
            defaultFormValues: {
              companyName: business.business.name,
            },
            managed: {
              self: business.business.self,
              managedBy: business.directoryName,
              isZipCodeNeeded: business.business.merchantZipRequired,
            },
          });
        } else {
          setAddVendorDetailsFormState({
            isOpen: true,
            defaultFormValues: {
              companyName,
            },
          });
        }
      };

      const handleSwitchToUnmanagedForm = ({
        companyName,
        accountNumber,
        postalCode,
      }: Pick<AddVendorFormFields, 'companyName' | 'accountNumber' | 'postalCode'>) => {
        setAddVendorDetailsFormState({
          ...addVendorDetailsFormState,
          defaultFormValues: {
            companyName: companyName ?? '',
            accountNumber,
            confirmAccountNumber: accountNumber,
            postalCode,
          },
          managed: undefined,
        });
      };

      const handleClose = () => {
        clearField();
        closeAddVendorDetailsForm();
        setTimeout(() => {
          searchFieldRef?.current?.focus();
        });
      };

      const handleDone: AddVendorOnDone = ({ vendor: createdVendor, isBankAccountNotCreated, bankAccountNumber }) => {
        const vendorName = getVendorNameForNotificationMessage(createdVendor);

        showMessage({
          type: 'success',
          title: formatMessage('addVendor.createVendorToast.success', {
            vendorName,
          }),
        });

        onChange?.({ target: { value: createdVendor.id } } as React.ChangeEvent<HTMLInputElement>);
        setIsUpdatingVendorsInProgress(true);
        closeAddVendorDetailsForm();

        if (isInvalidBankAccountModalEnabled && isBankAccountNotCreated && bankAccountNumber) {
          setInvalidBankAccountModalData({
            vendorId: createdVendor.id,
            vendorName: createdVendor.name,
            fullVendorName: vendorName,
            bankAccountNumber,
          });
        }
      };

      const onInvalidVendorBankAccountModalClose = () => {
        setInvalidBankAccountModalData(undefined);
      };

      const onInvalidVendorBankAccountModalSuccess = (vendorName: string) => {
        showMessage({
          type: 'success',
          title: formatMessage('widgets.invalidVendorBankAccountModal.success', {
            vendorName,
          }),
        });

        onInvalidVendorBankAccountModalClose();
      };

      const addVendorWidget =
        vendorDetailsFormType === VendorDetailsFormType.Drawer ? (
          <AddVendorDrawerWidget
            isOpen={addVendorDetailsFormState.isOpen}
            onDone={handleDone}
            onClose={handleClose}
            defaultFormValues={addVendorDetailsFormState.defaultFormValues}
            managed={addVendorDetailsFormState.managed}
            onSelectCompanyName={handleSelectCompanyName}
            onSwitchToUnmanagedForm={handleSwitchToUnmanagedForm}
            analyticsProperties={analyticsProperties}
            createVendorFlow={createVendorFlow}
            pageEntryPoint={eventContextName === 'Payment' ? 'pay' : 'bill-details'}
          />
        ) : (
          <AddVendorModalWidget
            isOpen={addVendorDetailsFormState.isOpen}
            onDone={handleDone}
            onClose={handleClose}
            onSwitchToUnmanagedForm={handleSwitchToUnmanagedForm}
            defaultFormValues={addVendorDetailsFormState.defaultFormValues}
            managed={addVendorDetailsFormState.managed}
            analyticsProperties={analyticsProperties}
            createVendorFlow={createVendorFlow}
          />
        );

      const shouldPassValue = !isFirstLoading && !isUpdatingVendorsInProgress;

      return (
        <>
          {addVendorDetailsFormState.isOpen && addVendorWidget}
          {invalidBankAccountModalData ? (
            <InvalidVendorBankAccountModal
              isOpen
              onClose={onInvalidVendorBankAccountModalClose}
              invalidVendorBankAccountDetails={invalidBankAccountModalData}
              onSuccess={onInvalidVendorBankAccountModalSuccess}
            />
          ) : null}
          {paymentVendor?.name && paymentVendor?.history?.deletedAt ? (
            <DeletedVendorName paymentVendor={paymentVendor} />
          ) : canSearchBusinesses ? (
            <Group variant="vertical" spacing="m">
              <Search
                shouldShowPresetOptions
                {...inputProps}
                ref={searchFieldRef}
                value={shouldPassValue ? props.value : undefined}
                isLoadingField={isFirstLoading}
                isLoading={!isFirstLoading && isLoadingOptions}
                clearButtonAriaLabel={formatMessage('form.vendorSelect.clearButtonAriaLabel')}
                filterOptions={(options) => options}
                statusMessageParentSelector={statusMessageParentSelector}
                onBlur={handleSearchOnBlur}
              />
            </Group>
          ) : (
            <Select {...inputProps} value={props.value} isLoading={isLoadingOptions} onBlur={handleSelectOnBlur} />
          )}
        </>
      );
    }
  )
);

const DeletedVendorName = ({ paymentVendor }: { paymentVendor: Vendor }) => (
  <Group alignItems="center" spacing="xxs">
    <Text textStyle="body2" color={paymentVendor?.history?.deletedAt ? 'global.neutral.800' : 'global.neutral.1000'}>
      {paymentVendor?.name}
    </Text>
    <Pill type="secondary" label="Deleted" status="neutral" />
  </Group>
);

VendorSelectWidget.displayName = 'VendorSelectWidget';
