import { Route, Routes } from 'react-router-dom';
import { GuestPaymentRouter, InvoiceDashboardRouter } from '@melio/ar-app';
import { useNavigate } from '@melio/ar-domain';
import { PartnerGroupEnum } from '@melio/partner-tools';
import { Layout } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { FeatureFlags, useAnonymousFeature, useDevFeature } from '@melio/platform-feature-flags';

import { useResetAccessToken } from '@/hooks/session.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useIsArEnabled } from '@/hooks/useIsArEnabled';
import { NotFoundScreen } from '@/screens/error-screens/NotFound.screen';
import { ScreensEnum } from '@/store/app/app.types';
import { useRouter } from '../../../hooks/router.hooks';
import { DashboardLayout } from '../../layouts/Dashboard.layout';
import { WithAnonymousInitialData, WithInitialData } from '../../utils';

type ARRouterProps = {
  partnerName: PartnerName;
  partnerGroup: PartnerGroupEnum | null;
};

export const ARRouter = withAnalyticsContext<ARRouterProps>(({ partnerGroup, partnerName, setAnalyticsProperties }) => {
  useActiveScreen(ScreensEnum.ARDashboard);
  const { isArEnabled, isLoading: isArEnabledLoading } = useIsArEnabled();
  const [isArOnboardingEnabled] = useDevFeature(FeatureFlags.AROnboarding, false);
  const [isArMtlKycUpliftEnabled] = useDevFeature(FeatureFlags.ArMtlKycUplift, false);
  const { goToSupportSettings, goToSettings } = useArRouterNavigation();
  // TODO: remove this flag once the feature is released to all users (https://meliorisk.atlassian.net/browse/ME-68159)
  const [isNavigateApUsersToDashboardFFEnabled] = useAnonymousFeature(
    FeatureFlags.GuestPayorFlowNavigateApUsersToPayDashboard,
    false,
  );
  // TODO: remove this flag (https://meliorisk.atlassian.net/browse/ME-84833)
  const [useGuestPaymentIntentInPaymentFulfillmentFlow] = useAnonymousFeature(
    FeatureFlags.GuestPaymentIntentInPaymentFulfillmentFlow,
    false,
  );
  const { goToCompleteRequiredDetails } = useRouter();

  setAnalyticsProperties({ ProductName: 'ar' });

  return (
    <Routes>
      <Route
        path="meliome/*"
        element={
          <GuestPaymentRouter
            partnerName={partnerName}
            partnerGroup={partnerGroup}
            WithAnonymousInitialData={WithAnonymousInitialData}
            useResetAccessToken={useResetAccessToken}
            isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardFFEnabled}
            useGuestPaymentIntent={useGuestPaymentIntentInPaymentFulfillmentFlow}
          />
        }
      />
      <Route
        path="*"
        element={
          isArEnabledLoading ? (
            <Layout isLoading />
          ) : isArEnabled ? (
            <InvoiceDashboardRouter
              onVisitSupportSettingsPage={goToSupportSettings}
              onVisitSettingsPage={goToSettings}
              DashboardLayout={DashboardLayout}
              WithInitialData={WithInitialData}
              NotFoundScreen={NotFoundScreen}
              isMtlKycUpliftEnabled={isArMtlKycUpliftEnabled}
              isArOnboardingEnabled={isArOnboardingEnabled}
              onCompleteKycDetails={(returnUrl) => goToCompleteRequiredDetails({ returnUrl })}
            />
          ) : (
            <NotFoundScreen />
          )
        }
      />
    </Routes>
  );
});

const useArRouterNavigation = () => {
  enum Paths {
    SupportSetting = '/settings/support',
    settings = '/settings',
  }
  const navigate = useNavigate<Paths>();

  return {
    goToSupportSettings: () => navigate(Paths.SupportSetting, { state: { returnUrl: '/ar' } }),
    goToSettings: () => navigate(Paths.settings, { state: { returnUrl: '/ar' } }),
  };
};
