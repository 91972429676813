import { Checkbox, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { EntityMatchingCardWidget, EntityMatchingCardWidgetProps } from '../EntityMatchingCard';

export type Entity = {
  id: string;
  entityA: { id: string } & EntityMatchingCardWidgetProps['entityA'];
  entityB: { id: string } & EntityMatchingCardWidgetProps['entityB'];
  isChecked: boolean;
};

export type EntityMatchingTableWidgetProps = {
  entities: Entity[];
  onSelect?: (entityId: string) => void;
  onSelectAll?: (isAllChecked: boolean) => void;
  onCardOpen?: (index: number) => void;
};

export const EntityMatchingTableWidget = forwardRef<EntityMatchingTableWidgetProps>(
  ({ entities, onSelect, onSelectAll, onCardOpen, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const isAllChecked = useMemo(() => entities.every((entity) => entity.isChecked), [entities]);
    const isIndeterminate = useMemo(() => entities.some((entity) => entity.isChecked), [entities]);

    const handleSelectAll = useCallback(() => {
      onSelectAll?.(!isAllChecked);
    }, [isAllChecked, onSelectAll]);

    return (
      <Group
        ref={ref}
        variant="vertical"
        spacing="s"
        data-component="EntityMatchingTableWidget"
        width="full"
        {...props}
      >
        <Group alignItems="center">
          <Checkbox
            isIndeterminate={!isAllChecked && isIndeterminate}
            isChecked={isAllChecked}
            onChange={handleSelectAll}
            data-testid="entity-matching-table-select-all"
            aria-label=""
          >
            <Container paddingLeft="xs">
              <Text textStyle="body3" color="global.neutral.900">
                {formatMessage('widgets.entityMatchingTable.selectAll')}
              </Text>
            </Container>
          </Checkbox>
        </Group>

        <Group variant="vertical" spacing="m">
          {entities.map((entity, index) => (
            <Group alignItems="center" key={entity.id}>
              <Checkbox isChecked={entity.isChecked} onChange={() => onSelect?.(entity.id)} />

              <EntityMatchingCardWidget
                entityA={entity.entityA}
                entityB={entity.entityB}
                isHighlighted={entity.isChecked}
                onCardOpen={() => onCardOpen?.(index)}
              />
            </Group>
          ))}
        </Group>
      </Group>
    );
  }
);

EntityMatchingTableWidget.displayName = 'EntityMatchingTableWidget';
