import { Route, Routes } from 'react-router-dom';
import { PartnerConfigContext, SystemMessageProvider } from '@melio/ar-domain';
import { PartnerName } from '@melio/platform-api';

import { ApConfigurationProvider } from '@/cl/components/PartnerApp/app-providers/ApConfigurationProvider.component';
import { usePartnerConfig } from '@/hooks/partners';
import { ARRouter } from './AR.router';

export const ARRoutes = ({ partnerName }: { partnerName: PartnerName }) => {
  const { partnerConfig, partnerGroup } = usePartnerConfig();
  return (
    <SystemMessageProvider>
      <PartnerConfigContext.Provider value={partnerConfig.config}>
        <Routes>
          <Route element={<ApConfigurationProvider partnerName={partnerName} />}>
            <Route path="*" element={<ARRouter partnerGroup={partnerGroup} partnerName={partnerName} />} />
          </Route>
        </Routes>
      </PartnerConfigContext.Provider>
    </SystemMessageProvider>
  );
};
