import { InvoiceTimeline } from '@melio/ar-domain';

export const getLastSentEmails = (invoiceTimeline?: InvoiceTimeline) => {
  const sortedEmails = invoiceTimeline?.emails.sort((a, b) => b.sentAt.getTime() - a.sentAt.getTime());

  return {
    lastSentEmail: sortedEmails?.[0],
    lastSentReminder: sortedEmails?.find((email) => email.type.startsWith('ar-invoice-reminder')),
  };
};
