import {
  useGuestPayorPaymentRequestDetails,
  useGuestPayorVendorPaymentDetails,
  useUpdatedPaymentRequestLink,
} from '@melio/ar-domain';

import { PaymentRequestStatus } from '../types';

type LoadingResults = {
  isLoading: true;
  paymentRequestLink?: never;
  status?: never;
};

type LoadedResults = {
  isLoading: false;
  paymentRequestLink: string;
  status: PaymentRequestStatus;
};

export type PaymentRequestStatusResult = LoadingResults | LoadedResults;

export const usePaymentRequestStatus = (paymentRequestLink: string): PaymentRequestStatusResult => {
  const paymentRequestDetailsModel = useGuestPayorPaymentRequestDetails({
    paymentRequestLink,
  });
  const vendorPaymentDetailsModel = useGuestPayorVendorPaymentDetails({
    paymentRequestLink,
  });

  const updatedPaymentRequestLinkModel = useUpdatedPaymentRequestLink({
    id: paymentRequestLink,
    enabled: paymentRequestDetailsModel.isError,
  });

  if (
    [paymentRequestDetailsModel, vendorPaymentDetailsModel, updatedPaymentRequestLinkModel].some(
      ({ isLoading }) => isLoading
    )
  ) {
    return { isLoading: true };
  }

  const target = { isLoading: false, paymentRequestLink } as const;

  if (paymentRequestDetailsModel.data?.receivablePaymentDetails?.paidAt) {
    return { ...target, status: 'paid' };
  }

  if (updatedPaymentRequestLinkModel.error?.errorCode == 'invoiceCancelled') {
    return { ...target, status: 'cancelled' };
  }

  if (updatedPaymentRequestLinkModel.data?.updatedPaymentRequestLink) {
    return {
      ...target,
      status: 'updated',
      paymentRequestLink: updatedPaymentRequestLinkModel.data.updatedPaymentRequestLink,
    };
  }

  if (paymentRequestDetailsModel.data && vendorPaymentDetailsModel.data) {
    const { paymentOptions } = vendorPaymentDetailsModel.data;

    if (paymentOptions.isAchAllowed || paymentOptions.isCardAllowed) {
      return { ...target, status: 'unpaid' };
    }

    return { ...target, status: 'no-payment-option' };
  }

  return { ...target, status: 'error' };
};
