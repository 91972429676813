import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { Tier } from '../api';
import { useSubscriptionContext } from '../context';
import { useFreeTrialEligibility } from './useFreeTrialEligibility';
import { useSubscription } from './useSubscription';

const WELCOME_USERS_PROMO = {
  code: 'WELCOME15',
  discount: 0.15,
  appliedForPlans: [Tier.MelioPlus, Tier.MelioPro],
};

type DefaultPromoCode = {
  code: string;
  discount: number;
  appliedForPlans: Tier[];
};

export const useDefaultSubscriptionPromotion = () => {
  const subscription = useSubscription();
  const [showWelcome15ForOrgWhoNeverStartedFreeTrial] = useDevFeature<boolean>(
    FeatureFlags.Welcome15ForOrgWhoNeverStartedFreeTrial,
    false
  );
  const { formatMessage, formatPercents } = useMelioIntl();

  const { isEligibleForFreeTrial, isFetching: isFetchingEligibilityForFreeTrialData } = useFreeTrialEligibility();

  const {
    gracePeriod: { isOrgCreatedBeforeGracePeriodStart },
  } = useSubscriptionContext();

  const isEligibleForWelcomePromo = showWelcome15ForOrgWhoNeverStartedFreeTrial
    ? !isFetchingEligibilityForFreeTrialData && !isOrgCreatedBeforeGracePeriodStart && isEligibleForFreeTrial
    : !isOrgCreatedBeforeGracePeriodStart && !subscription;

  const defaultPromoCode: DefaultPromoCode | undefined = isEligibleForWelcomePromo ? WELCOME_USERS_PROMO : undefined;

  const promotionBanner = isEligibleForWelcomePromo
    ? formatMessage('activities.subscription.plans.selection.promotionBanner.newUser.description', {
        discountPercent: formatPercents(WELCOME_USERS_PROMO.discount),
      })
    : undefined;

  return { defaultPromoCode, promotionBanner, isFetching: isFetchingEligibilityForFreeTrialData };
};
