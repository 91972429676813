import { Payment, PostApprovalDecisionEnum } from '@melio/platform-api';

import { SelectedEntity } from '../../../delete-payment-modal';
import { PayDashboardTabSortableColumn } from '../../types';

export type PaymentsTableMeta = {
  rowSelections: string[];
  onViewClick: (id: Payment['id']) => void;
  onEditPayment: (id: Payment['id']) => void;
  onCancelPayment: (id: Payment['id'], currentEntity: SelectedEntity) => void;
  onSuccessMarkAsUnpaid: (payment: Payment) => void;
  onMarkFailedPaymentAsPaid: (id: Payment['id']) => void;
  onSuccessApprovalDecision: (payments: Payment['id'][], approvalDecisionStatus: PostApprovalDecisionEnum) => void;
  onApprovalDecisionClick: (payments: Payment[], approvalDecisionStatus: PostApprovalDecisionEnum) => void;
};

export const CELLS_IDS = {
  VENDOR_NAME: 'vendorName',
  SCHEDULED_BY: 'scheduledBy',
  BILL: 'bill',
  DEBIT_DATE: 'debitDate',
  STATUS: 'status',
  AMOUNT: 'amount',
  ACTION_BUTTON: 'actionButton',
  MORE_ACTIONS: 'moreActions',
  INVOICE: 'invoice',
} as const;

export type ApprovalsTabSortableCells = Extract<
  (typeof CELLS_IDS)[keyof typeof CELLS_IDS],
  typeof CELLS_IDS.VENDOR_NAME | typeof CELLS_IDS.DEBIT_DATE | typeof CELLS_IDS.AMOUNT
>;

export type ApprovalsTabSortableColumn = PayDashboardTabSortableColumn<ApprovalsTabSortableCells>;
