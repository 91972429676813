import { ServicesApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching, useMutation, useQuery } from '@tanstack/react-query';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

type VerifyTokenPFn = typeof ServicesApiClient.postServicesCaratVerifyCard;

// Define a namespace (this should be a consistent UUID)
const uuid5Namespace = '1b671a64-40d5-491e-99b0-da01ff1f3341';

export const useCaratSession = () => {
  const queryKey = ['carat', 'sessionId'];

  const query = useQuery({
    queryKey,
    enabled: true,
    queryFn: async () => {
      const { data } = await ServicesApiClient.postServicesCaratCredentials(undefined, uuidv4());

      return data.data;
    },
  });

  const isFetching = useIsFetching(queryKey) > 0;

  return {
    ...query,
    isLoading: query.isInitialLoading,
    isFetching,
  };
};

export const useCaratVerification = () => {
  const verificationKeys = ['carat', 'verifyCard'];
  const verification = useMutation(verificationKeys, async (data: Parameters<VerifyTokenPFn>[0]) => {
    const result = await ServicesApiClient.postServicesCaratVerifyCard(
      data,
      undefined,
      uuidv5(JSON.stringify(data), uuid5Namespace)
    );

    return result.data.data;
  });

  return {
    ...verification,
  };
};
