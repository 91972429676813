import { usePartnerFeature } from '@melio/platform-provider';

import { usePayDashboardFilters } from '../../PayDashboardFilterProvider';
import { QueryParamsOptions } from '../Filters/types';
import { useBillsTabFilters } from './useBillsTabFilters';

export const useBillsTabFiltersQueryParams = () => {
  const [isBillsTabFiltersEnabled] = usePartnerFeature('BillsTabFilters', false);
  const filtersContext = usePayDashboardFilters();
  const billsTabFilters = useBillsTabFilters({});

  let filtersQueryParams = {};
  let queryParamsOptions = {} as QueryParamsOptions;

  if (!filtersContext?.filters || !Object.keys(filtersContext.filters).length || !isBillsTabFiltersEnabled) {
    return { filtersQueryParams, activeFilters: {}, hasActiveFilters: false, queryParamsOptions: {} };
  }

  const filters = filtersContext.filters;
  Object.entries(filters).forEach(([key, selectedValues]) => {
    const billsTabFilter = billsTabFilters.find((f) => f.key === key);
    const calculatedQueryParams = billsTabFilter?.calcQueryParams(selectedValues);
    if (billsTabFilter) {
      filtersQueryParams = {
        ...filtersQueryParams,
        ...calculatedQueryParams?.filterQueryParams,
      };
      queryParamsOptions = {
        ...queryParamsOptions,
        ...calculatedQueryParams?.queryParamsOptions,
      };
    }
  });

  return {
    filtersQueryParams,
    queryParamsOptions,
    activeFilters: filters,
    hasActiveFilters: filtersContext.hasActiveFilters,
  };
};
