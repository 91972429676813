import { getCountryCode } from '@melio/ap-widgets';
import { useMelioForm } from '@melio/penny';
import { InternationalIdType, OkiTaxIdType, OrganizationVerifiedPersona } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useDateUtils } from '@melio/platform-utils';
import { DefaultValues } from 'react-hook-form';

import {
  AccountHolderForm,
  InternationalIdTypeReverseMap,
  OwnershipDetails,
  OwnershipDetailsForm,
  Residency,
  TaxIdType,
  TaxIdTypeReverseMap,
  UBO_FROM_ACCOUNT_HOLDER_ID,
} from '../types';
import { useOwnershipDetailsFormSchema } from './useOwnershipDetailsFormSchema';

export const ID_PREFIX = 'new_ubo';

export const getUboId = () => `new_ubo${Date.now().toString()}`;
export const useOwnershipDetailsForm = ({ onDone }: { onDone: VoidFunction }) => {
  const ownershipDetailsFormSchema = useOwnershipDetailsFormSchema();

  const form = useMelioForm<OwnershipDetailsForm>({
    onSubmit: onDone,
    schema: ownershipDetailsFormSchema,
  });

  return {
    form,
  };
};

const useVerifiedPersonaToOwnershipDetails = (): ((
  verifiedPersona: OrganizationVerifiedPersona
) => OwnershipDetails) => {
  const { createDate } = useDateUtils();
  const [shouldShowUboResidencyNewFields] = useDevFeature<boolean>(FeatureFlags.UboResidencyNewFields, false);

  const getUsResidency = (taxId: string, taxIdType: OkiTaxIdType): Residency => ({
    usResidence: 'usResidence',
    taxId,
    taxIdType: TaxIdTypeReverseMap[taxIdType],
  });

  const getInternationalResidency = (idNumber: string, idType: InternationalIdType): Residency => ({
    usResidence: 'nonUsResidence',
    idNumber,
    idType: InternationalIdTypeReverseMap[idType],
  });

  const getResidency = (verifiedPersona: OrganizationVerifiedPersona): Residency => {
    if (shouldShowUboResidencyNewFields) {
      if (verifiedPersona.usResidence && verifiedPersona.taxId && verifiedPersona.taxIdType) {
        return getUsResidency(verifiedPersona.taxId, verifiedPersona.taxIdType);
      }
      if (verifiedPersona.internationalId && verifiedPersona.internationalIdType) {
        return getInternationalResidency(verifiedPersona.internationalId, verifiedPersona.internationalIdType);
      }
    }

    return {
      usResidence: 'usResidence',
      taxId: '',
      taxIdType: TaxIdType.Ssn,
    };
  };

  return (verifiedPersona) => {
    const countryCode = verifiedPersona.address.countryCode && getCountryCode(verifiedPersona.address.countryCode);
    return {
      uboId: verifiedPersona.id,
      ownerFirstName: verifiedPersona.firstName,
      ownerLastName: verifiedPersona.lastName,
      address: {
        line1: verifiedPersona.address.line1 ?? '',
        line2: verifiedPersona.address.line2 ?? '',
        city: verifiedPersona.address.city ?? '',
        state: verifiedPersona.address.state ?? '',
        countryCode: countryCode ?? '',
        postalCode: verifiedPersona.address.postalCode ?? '',
      },
      residencyDetails: getResidency(verifiedPersona),
      ssn: verifiedPersona.taxId,
      ownershipPercentage: verifiedPersona.ownershipPercentage?.toString() ?? '',
      dateOfBirth: verifiedPersona.dateOfBirth ? createDate(verifiedPersona.dateOfBirth) : undefined,
    };
  };
};

export const useGetUBOFormValues = () => {
  const verifiedPersonaToOwnershipDetails = useVerifiedPersonaToOwnershipDetails();

  const getUBOFormValues = ({
    accountHolderFormValues,
    verifiedPersonas,
  }: {
    accountHolderFormValues: AccountHolderForm;
    verifiedPersonas?: OrganizationVerifiedPersona[];
  }): DefaultValues<OwnershipDetails>[] => {
    if (verifiedPersonas?.length) {
      return verifiedPersonas.map(verifiedPersonaToOwnershipDetails);
    }

    if (accountHolderFormValues.isUbo) {
      return [
        {
          uboId: UBO_FROM_ACCOUNT_HOLDER_ID,
          ownerFirstName: accountHolderFormValues.firstName,
          ownerLastName: accountHolderFormValues.lastName,
          address: accountHolderFormValues.address,
          ssn: accountHolderFormValues.taxId,
          ownershipPercentage: '',
          dateOfBirth: accountHolderFormValues.dayOfBirth,
          residencyDetails: { ...accountHolderFormValues.residencyDetails },
        },
      ];
    }

    return [
      {
        uboId: getUboId(),
        ownerFirstName: '',
        ownerLastName: '',
        address: {
          line1: '',
          postalCode: '',
          state: '',
          city: '',
        },
        ssn: '',
        ownershipPercentage: '',
        dateOfBirth: undefined,
        residencyDetails: { usResidence: 'usResidence', taxId: '' },
      },
    ];
  };

  return getUBOFormValues;
};
