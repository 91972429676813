import { addWildcardToRoutes, useFlowRouting, useNavigate, withOutlet, withRouteId } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { PaymentDrawerActivity as _PaymentDrawerActivity, PaymentsTabActivity } from '../../activities';

const PaymentDrawerActivity = withRouteId(_PaymentDrawerActivity, 'rpdId');

export type PaymentsTableFlowProps = {
  onError?: ARErrorFunction;
};

export const PaymentsTableFlow = forwardRef<PaymentsTableFlowProps>(({ onError, ...props }, ref) => {
  const { Paths, goHome, goToPreviewPayment } = usePaymentsTableFlow({ withSearchparams: true });

  return (
    <Routes>
      <Route
        path={Paths.Home}
        element={withOutlet(
          <PaymentsTabActivity
            onViewPayment={(id: string) => goToPreviewPayment(id)}
            ref={ref}
            {...props}
            onError={onError}
          />
        )}
      >
        <Route
          path={Paths.ViewPayment}
          element={<PaymentDrawerActivity onClose={() => goHome({ keepSystemMessage: true })} />}
        />
      </Route>
    </Routes>
  );
});

enum Paths {
  Home = '',
  CreateReport = 'report',
  ViewPayment = ':id',
}

export const usePaymentsTableFlow = (...args: Parameters<typeof useNavigate<Paths>>) => {
  const { navigate, createCallback } = useFlowRouting<Paths>(...args);
  const createNavigateWithId = (path: Paths) => (id: string) =>
    navigate(path, { pathParams: { id }, keepSystemMessage: true });

  const goToPreviewPayment = createNavigateWithId(Paths.ViewPayment);
  const goHome = createCallback(Paths.Home);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goHome,
    goToPreviewPayment,
  };
};
