import {
  addWildcardToRoutes,
  useNavigate,
  withLocalization,
  withOutlet,
  withSystemMessageProvider,
} from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { AcceptedPaymentMethodsActivity, EditInvoicePaymentOptionsDialogActivity } from '../../activities';

export const AcceptedPaymentMethodsFlow = withSystemMessageProvider(
  withLocalization(
    forwardRef((props, ref) => {
      const { Paths, goToEditInvoiceCustomPaymentDetails, goToHome } = useNavigation();

      return (
        <Routes>
          <Route
            path={Paths.Home}
            element={withOutlet(
              <AcceptedPaymentMethodsActivity
                onEditCustomPaymentDetails={goToEditInvoiceCustomPaymentDetails}
                ref={ref}
                {...props}
              />
            )}
          >
            <Route
              path={Paths.EditInvoiceCustomPaymentDetails}
              element={<EditInvoicePaymentOptionsDialogActivity onDone={goToHome} onClose={goToHome} />}
            />
          </Route>
        </Routes>
      );
    })
  )
);

const useNavigation = () => {
  enum Paths {
    Home = '',
    EditInvoiceCustomPaymentDetails = 'edit-custom-payment-details',
  }

  const navigate = useNavigate<Paths>();

  return {
    Paths: addWildcardToRoutes(Paths),
    goToHome: () => navigate(Paths.Home),
    goToEditInvoiceCustomPaymentDetails: () => navigate(Paths.EditInvoiceCustomPaymentDetails),
  };
};
