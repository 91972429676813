import {
  getInternationalFormattedIdentifierData,
  getManagedAccountDeliveryMethodType,
  isFXCurrency,
  useInternationalCountryDisplayName,
} from '@melio/ap-domain';
import { Card, DeliveryMethod, DeliveryPreferenceType, formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useDeliveryMethodDescription = ({
  deliveryMethod,
  vendorName,
  deliveryPreferenceType,
}: {
  deliveryMethod: DeliveryMethod;
  vendorName: string;
  deliveryPreferenceType?: DeliveryPreferenceType | null;
}) => {
  const { formatMessage } = useMelioIntl();
  const { countryDisplayName } = useInternationalCountryDisplayName(deliveryMethod);

  switch (deliveryMethod.type) {
    case 'bank-account': {
      if (deliveryPreferenceType === DeliveryPreferenceType.Rtp) {
        return formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.rtp',
          { accountNumber: deliveryMethod.details.accountNumberLast4Digits || '' }
        );
      }
      if (deliveryMethod.isManaged) {
        return formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.managed'
        );
      }
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.bankAccount',
        { accountNumber: deliveryMethod.details.accountNumberLast4Digits || '' }
      );
    }
    case 'paper-check':
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.check',
        {
          address: formatAddress(deliveryMethod.details.address),
        }
      );
    case 'domestic-wire-account':
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.domesticWireAccount',
        {
          accountNumber: deliveryMethod.details.accountNumber.slice(-4) || '',
        }
      );
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${
          (deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'
        }.description`,
        { vendorName }
      );

    case 'virtual-account':
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.unilateral'
      );
    case 'virtual-card':
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.suvc',
        {
          email: deliveryMethod.details.accountEmail,
        }
      );
    case 'biller-account':
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.rpps'
      );
    case 'managed-account': {
      const managedDeliveryMethodType = getManagedAccountDeliveryMethodType(deliveryMethod.details);
      switch (managedDeliveryMethodType) {
        case 'bank-account':
          return formatMessage(
            'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.managed'
          );
        case 'paper-check':
          return formatMessage(
            'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.managed.check'
          );
        default:
          return formatMessage(
            'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.rpps'
          );
      }
    }
    case 'international-account': {
      if (isFXCurrency(deliveryMethod.details.currency)) {
        return formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.internationalFx',
          getInternationalFormattedIdentifierData(deliveryMethod, countryDisplayName)
        );
      }
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod.international',
        getInternationalFormattedIdentifierData(deliveryMethod, countryDisplayName)
      );
    }
    default:
      return '';
  }
};
