import { Button, Collapse, Container, Divider, Group, Icon, IconKey, NakedButton, Pill, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export type DeliveryMethodSectionCardProps = {
  isExpanded: boolean;
  onAdd: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  isDisabled?: boolean;
  icon?: IconKey;
  label: string;
  pillProps?: {
    label: string;
  };
  description?: string;
  children: React.ReactNode;
  testId?: string;
};
export const DeliveryMethodSectionCard = ({
  isExpanded,
  onAdd,
  onCancel,
  onSubmit,
  label,
  icon,
  children,
  pillProps,
  isDisabled,
  description,
  testId,
}: DeliveryMethodSectionCardProps) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container border="regular" paddingX="m" paddingY="s" data-testid={testId}>
      <Group alignItems="center" justifyContent="space-between" spacing="none">
        <Group alignItems="center">
          {icon && <Icon type={icon} isDisabled={isDisabled} />}
          <Group variant="vertical" spacing="none">
            <Group alignItems="center">
              <Text color={isDisabled ? 'semantic.text.disabled' : undefined} data-testid="delivery-method-card-label">
                {label}
              </Text>
              {pillProps && (
                <Pill
                  label={pillProps.label}
                  status="brand"
                  type="secondary"
                  isDisabled={isDisabled}
                  data-testid="delivery-method-card-fee"
                />
              )}
            </Group>
            {description && (
              <Group>
                <Text color="semantic.text.secondary" textStyle="body3" data-testid="delivery-method-card-description">
                  {description}
                </Text>
              </Group>
            )}
          </Group>
        </Group>
        <Group>
          {!isExpanded && (
            <NakedButton
              data-testid="delivery-method-card-label-add-button"
              isDisabled={isDisabled}
              label={
                description
                  ? formatMessage('activities.deliveryMethodsWidget.deliveryMethodSectionCard.edit')
                  : formatMessage('activities.deliveryMethodsWidget.deliveryMethodSectionCard.add')
              }
              onClick={onAdd}
              variant="secondary"
            />
          )}
        </Group>
      </Group>
      <Collapse in={isExpanded && !isDisabled}>
        <Container paddingTop="m">
          <Divider />
        </Container>
        <Container paddingTop="m">
          <Group>{children}</Group>
        </Container>
        <Container paddingTop="m">
          <Group alignItems="center" justifyContent="space-between">
            <Button
              data-testid="delivery-method-card-label-cancel-button"
              label={formatMessage('activities.deliveryMethodsWidget.deliveryMethodSectionCard.cancel')}
              onClick={onCancel}
              variant="secondary"
            />
            <Button
              data-testid="delivery-method-card-label-save-button"
              label={formatMessage('activities.deliveryMethodsWidget.deliveryMethodSectionCard.save')}
              onClick={onSubmit}
            />
          </Group>
        </Container>
      </Collapse>
    </Container>
  );
};
