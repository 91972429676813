import { Subscription } from '../api/entities';

export const getSubscriptionAllowedActions = (subscription: Subscription) => {
  const nextSubscriptionPlanId = subscription.nextSubscription?.planId;
  const currentSubscriptionPlanId = subscription.planId;

  const isNextSubscriptionPending =
    !!currentSubscriptionPlanId && !!nextSubscriptionPlanId && nextSubscriptionPlanId !== currentSubscriptionPlanId;

  return {
    updatePaymentMethod: !isNextSubscriptionPending,
    reactivate: isNextSubscriptionPending,
  };
};
