import { BusinessResultItem, MsnBusinessItem, useBusinesses, useSearchBusinesses } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useDebounceCallback } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { BUSINESS_SEARCH_DEBOUNCE } from '../utils';
import { getSelectOptionsFromBusinesses, getSelectOptionsFromBusinessItems } from './BusinessSearch.utils';

export const useBusinessSearchOptions = (shouldUseNewBusinessesSearch = false) => {
  const {
    settings: {
      showCheckMarkIconForManagedVendor,
      vendor: {
        forms: { shouldUseSearchVendorSectionLabel },
      },
    },
  } = useConfig();
  const { formatMessage } = useMelioIntl();
  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );
  const [isNewBusinessesSearchEnabled] = usePartnerFeature('NewBusinessesSearch', false);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isPreparingOptions, setIsPreparingOptions] = useState<boolean>(false);

  const shouldSearch = searchTerm.length > 2;

  const { isLoading: isLoadingBusinesses, data: businesses } = useBusinesses({
    params: { searchTerm },
    enabled:
      shouldSearch &&
      isSearchBusinessesInDirectoriesSupported &&
      !(isNewBusinessesSearchEnabled && shouldUseNewBusinessesSearch),
  });
  const { isLoading: isLoadingBusinessItems, data: businessItems } = useSearchBusinesses({
    params: { searchTerm },
    enabled: shouldSearch && isNewBusinessesSearchEnabled && shouldUseNewBusinessesSearch,
  });

  useEffect(() => {
    if (businesses && businesses.length > 0 && isPreparingOptions) {
      setIsPreparingOptions(false);
    }
  }, [businesses, isPreparingOptions]);

  useEffect(() => {
    if (businessItems?.msn && businessItems.msn.length > 0 && isPreparingOptions) {
      setIsPreparingOptions(false);
    }
  }, [businessItems, isPreparingOptions]);

  useEffect(() => {
    if ((!businesses || businesses.length === 0) && !isLoadingBusinesses && isPreparingOptions) {
      setIsPreparingOptions(false);
    }
  }, [businesses, isLoadingBusinesses, isPreparingOptions]);

  useEffect(() => {
    if ((!businessItems || businessItems.msn.length === 0) && !isLoadingBusinessItems && isPreparingOptions) {
      setIsPreparingOptions(false);
    }
  }, [businessItems, isLoadingBusinessItems, isPreparingOptions]);

  const handleSearch = useDebounceCallback((query: string) => {
    if (query !== '') {
      setIsPreparingOptions(true);
    }
    setSearchTerm(query);
  }, BUSINESS_SEARCH_DEBOUNCE);

  const selectOptions = useMemo(() => {
    if (businesses && businesses.length > 0) {
      return getSelectOptionsFromBusinesses(businesses);
    }

    if (businessItems && businessItems.msn.length > 0) {
      return getSelectOptionsFromBusinessItems(businessItems.msn);
    }

    return [];
  }, [businessItems, businesses]);

  const options = useMemo(() => {
    if (!shouldUseSearchVendorSectionLabel) {
      return selectOptions;
    }

    const metadata = {
      icon: showCheckMarkIconForManagedVendor ? ('verified' as const) : undefined,
      label: isNewBusinessesSearchEnabled
        ? formatMessage('widgets.businessSearch.businessNetworkTitle.msn')
        : formatMessage('widgets.businessSearch.businessNetworkTitle'),
    };

    return [
      {
        options: selectOptions,
        metadata,
      },
    ];
  }, [
    selectOptions,
    isNewBusinessesSearchEnabled,
    shouldUseSearchVendorSectionLabel,
    showCheckMarkIconForManagedVendor,
    formatMessage,
  ]);

  const getBusinessByOptionValue = (value: string): BusinessResultItem | MsnBusinessItem | undefined => {
    if (isNewBusinessesSearchEnabled) {
      return businessItems?.msn.find(({ business }) => business.id === value);
    }

    return businesses?.find(({ business }) => business.self === value);
  };

  const getSelectedItemAnalyticsProps = (selected?: BusinessResultItem | MsnBusinessItem) => {
    const fiservDirectoryItems = (businesses || []).filter((option) => option.directoryName === 'Fiserv');
    const msnDirectoryItems = businessItems?.msn || [];

    let itemPosition;
    let countResults;
    let billersCount;
    let cta;
    const vendorType = selected ? 'directory' : 'new-local';

    if (isNewBusinessesSearchEnabled) {
      itemPosition = selected
        ? selectOptions.findIndex(({ value }) => value === (selected as MsnBusinessItem).business.id) + 1
        : null;
      countResults = msnDirectoryItems.length;
      billersCount = msnDirectoryItems.length;
      cta = selected ? (selected as MsnBusinessItem).business.name : 'add-new';
    } else {
      itemPosition = selected
        ? selectOptions.findIndex(({ value }) => value === (selected as BusinessResultItem).business.self) + 1
        : null;
      countResults = (businesses || []).length;
      billersCount = fiservDirectoryItems.length;
      cta = selected ? (selected as BusinessResultItem).business.name : 'add-new';
    }

    return {
      SearchValue: searchTerm,
      VendorType: vendorType,
      CountResults: countResults,
      BillersCount: billersCount,
      ItemPosition: itemPosition,
      Cta: cta,
    };
  };

  return {
    isLoading: isLoadingBusinesses || isLoadingBusinessItems || isPreparingOptions,
    options,
    handleSearch,
    shouldSearch,
    getBusinessByOptionValue,
    getSelectedItemAnalyticsProps,
  };
};
