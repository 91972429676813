import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { OwnershipDetails, OwnershipDetailsForm } from '../types';
import { useFxCommonValidations } from '../utils/formUtils';

type ContextOptionsFromValueUbos = {
  ownershipPercentage: string;
};

type ContextOptionsFromValue = {
  ubos: ContextOptionsFromValueUbos[];
};

type ContextOptionsFrom = {
  value: ContextOptionsFromValue;
};

type ContextOptions = {
  index: number;
  from: ContextOptionsFrom[];
};

const getOwnershipPercentageUbos = (from: ContextOptionsFrom[]): ContextOptionsFromValueUbos[] =>
  from
    .filter(({ value: { ubos } }: ContextOptionsFrom) => !!ubos)
    .map(({ value: { ubos } }: ContextOptionsFrom) => ubos)
    .at(0) || [];

const getOwnershipPercentageValues = (ownershipPercentageUbos: ContextOptionsFromValueUbos[]): number[] => {
  const ownershipPercentageValues = ownershipPercentageUbos.map((item: ContextOptionsFromValueUbos) =>
    parseInt(item.ownershipPercentage || '0', 10)
  );
  return ownershipPercentageValues;
};

const getOwnershipPercentageTotal = (from: ContextOptionsFrom[]): number => {
  const ownershipPercentageUbos = getOwnershipPercentageUbos(from);
  const ownershipPercentageValues = getOwnershipPercentageValues(ownershipPercentageUbos);
  return ownershipPercentageValues.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
};

export const useOwnershipDetailsFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const [showUboResidencyNewFields] = useDevFeature<boolean>(FeatureFlags.UboResidencyNewFields, false);

  const { firstNameSchema, lastNameSchema, ssnSchema, dateOfBirthSchema, addressSchema, residencySchema } =
    useFxCommonValidations();
  let uboSchema = yup.object().shape({
    uboId: yup.string().required(),
    ownerFirstName: firstNameSchema,
    ownerLastName: lastNameSchema,
    ssn: yup.string().nullable(),
    ownershipPercentage: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.required'
        )
      )
      .test(
        'in-range',
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.inRange'
        ),
        (val) => {
          if (!val) {
            return false;
          }

          const parsedVal = parseInt(val, 10);
          return parsedVal >= 25;
        }
      )
      .test(
        'shouldNotExceed100',
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.shouldNotExceed100'
        ),
        (val, context) => {
          if (!val) {
            return false;
          }
          const { index, from = [] } = context.options as ContextOptions;
          const valAsNumber = parseInt(val, 10);
          if (index === 0) {
            return valAsNumber <= 100;
          }
          const ownershipPercentageTotal = getOwnershipPercentageTotal(from);
          return ownershipPercentageTotal <= 100;
        }
      ),
    address: addressSchema,
    dateOfBirth: dateOfBirthSchema,
    residencyDetails: residencySchema,
  }) as yup.SchemaOf<OwnershipDetails>;

  if (!showUboResidencyNewFields) {
    uboSchema = uboSchema.concat(
      yup.object().shape({
        ssn: ssnSchema,
      })
    );
  }
  const schema = yup.object().shape({
    ubos: yup.array().of(uboSchema),
  }) as yup.SchemaOf<OwnershipDetailsForm>;

  return schema;
};
