import { isQuickBooksOnlineAccountingPlatform, useAccountingPlatforms } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

export const useVendorUniqueNameEnabled = () => {
  const [isPlatformMultipleVendorsWithTheSameNameEnabled] = usePartnerFeature(
    'PlatformMultipleVendorsWithTheSameName',
    false
  );
  const { activeAccountingPlatform } = useAccountingPlatforms();
  const isQuickBooksOnline = isQuickBooksOnlineAccountingPlatform(activeAccountingPlatform);
  const isVendorUniqueNameEnabled = isPlatformMultipleVendorsWithTheSameNameEnabled && isQuickBooksOnline;

  return isVendorUniqueNameEnabled;
};
