import { useIsAccountingFirm } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { PlanCapabilities, usePlanCapabilities } from './usePlanCapabilities';
import { usePlanName } from './usePlanName';
import { usePlansTiers } from './usePlansTiers';
export type PlanInfo = {
  planName: string;
  planDescription: string;
  capabilities: PlanCapabilities;
};

export type UsePlanInfoOptionsProps = {
  withOnboardingPlansExperimentOn?: boolean;
};

export const usePlanInfo = (planId?: string, options?: UsePlanInfoOptionsProps) => {
  const { formatMessage } = useMelioIntl();
  const { getPlan } = usePlansTiers();
  const isAccountingFirm = useIsAccountingFirm();
  const { getPlanCapabilities } = usePlanCapabilities({
    withOnboardingPlansExperimentOn: options?.withOnboardingPlansExperimentOn,
  });

  const plan = planId ? getPlan(planId) : undefined;
  const planName = usePlanName(plan);

  if (!plan) {
    return {
      planName: '',
      planDescription: '',
      capabilities: {
        title: '',
        list: [],
      },
    };
  }

  const capabilities = getPlanCapabilities(plan);

  const planDescription = isAccountingFirm
    ? formatMessage('activities.subscription.plans.accountingFirm.description')
    : formatMessage(`activities.subscription.plans.${plan.tier}.description`);

  return {
    planName,
    planDescription,
    capabilities: {
      title: formatMessage(`activities.subscription.plans.${plan.tier}.capabilities.title`),
      list: capabilities,
    },
  };
};
