import { Form } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Tier } from '@melio/subscriptions';

import { StepManager } from '../../hooks';
import { useGetAccountingFirmClientPlanByTier } from '../../hooks/useGetAccountingFirmClientPlanByTier';
import { SubscriptionBillingPayor } from '../../types';
import { CollapsibleCardSection } from '../CollapsibleCardSection/CollapsibleCardSection.component';
import { ManageClientFormResults, ManageClientFormStep, ManageClientFormStepPayload } from './manage-client-form.types';
import { ManageClientFormContextProvider } from './ManageClientFormContext';
import {
  ManageClientBasicDetailsStep,
  ManageClientBillingDetailsStep,
  ManageClientPlanSelectionStep,
} from './ManageClientFormSteps';
import { ManageClientPlanReviewAndConfirmStep } from './ManageClientFormSteps/ManageClientPlanReviewAndConfirmStep';
import { useManageClientFormAnalytics } from './useManageClientFormAnalytics';

type ManageClientFormProps = {
  form: ManageClientFormResults;
  isSaving: boolean;
  stepManager: StepManager<ManageClientFormStep, ManageClientFormStepPayload>;
  labels: {
    submit: string;
  };
};

export const ManageClientForm = withAnalyticsContext<ManageClientFormProps>(
  ({ isSaving, form, stepManager, setAnalyticsProperties, labels }) => {
    const { formatMessage } = useMelioIntl();
    const defaultAccountingFirmClientPlan = useGetAccountingFirmClientPlanByTier(Tier.MelioAccountingClientCore);

    const {
      formProps,
      submitButtonProps: { onClick: onSubmitForm },
      watch,
      formState,
    } = form;
    const { steps, expandSelectedStep, goToNextStep, focusErrorStep, expandedStep: activeStep } = stepManager;

    const { trackClick } = useManageClientFormAnalytics({
      watch,
      formState,
      activeStep,
      currentPlan: defaultAccountingFirmClientPlan,
      setAnalyticsProperties,
    });

    const handleContinue = () => {
      goToNextStep();
    };

    const onExpandChange = (expanded: boolean, expandedStepId: ManageClientFormStep) => {
      if (expanded) {
        expandSelectedStep(expandedStepId);
      }
    };

    const handleSubmit = () => {
      trackClick('confirm-and-submit', 'submit');
      onSubmitForm();
    };

    const isFirmBilled = watch('whoPays') === SubscriptionBillingPayor.AccountingFirm;

    const defaultStepMapping: Record<ManageClientFormStep, Required<ManageClientFormStepPayload>> = {
      [ManageClientFormStep.BasicDetails]: {
        title: formatMessage(`activities.accountants.manageClient.form.basicDetails.title.text`),
        description: formatMessage(`activities.accountants.manageClient.form.basicDetails.description.text`),
        stepId: ManageClientFormStep.BasicDetails,
        component: ManageClientBasicDetailsStep,
      },
      [ManageClientFormStep.BillingDetails]: {
        title: formatMessage(`activities.accountants.manageClient.form.billingDetails.title.text`),
        description: formatMessage(`activities.accountants.manageClient.form.billingDetails.description.text`),
        stepId: ManageClientFormStep.BillingDetails,
        component: ManageClientBillingDetailsStep,
      },
      [ManageClientFormStep.PlanSelection]: {
        title: formatMessage(`activities.accountants.manageClient.form.planSelection.title.text`),
        description: formatMessage(`activities.accountants.manageClient.form.planSelection.description.text`),
        stepId: ManageClientFormStep.PlanSelection,
        component: ManageClientPlanSelectionStep,
      },
      [ManageClientFormStep.ReviewAndConfirm]: {
        title: formatMessage(`activities.accountants.manageClient.form.reviewAndConfirm.title.text`),
        description: formatMessage(
          isFirmBilled
            ? `activities.accountants.manageClient.form.reviewAndConfirm.description.text.firmBilled`
            : `activities.accountants.manageClient.form.reviewAndConfirm.description.text.clientBilled`
        ),
        stepId: ManageClientFormStep.ReviewAndConfirm,
        component: ManageClientPlanReviewAndConfirmStep,
      },
    };

    return (
      <ManageClientFormContextProvider value={form}>
        <Form {...formProps}>
          {steps.map(({ isExpanded, isDisabled, isFilled, stepId, ...stepProps }) => {
            const {
              title,
              description,
              component: StepFromComponent,
            } = { ...defaultStepMapping[stepId], ...stepProps };

            const isSubmitStep = steps[steps.length - 1]?.stepId === stepId;
            const submitLabel = isSubmitStep
              ? labels.submit
              : formatMessage(`activities.accountants.manageClient.form.continueButton.label`);

            return (
              <CollapsibleCardSection
                key={stepId}
                id={stepId}
                title={title}
                description={description}
                isExpanded={isExpanded}
                isDisabled={isDisabled}
                isFilled={isFilled}
                onExpandChange={(expanded) => onExpandChange(expanded, stepId)}
              >
                <StepFromComponent
                  submitLabel={submitLabel}
                  focusErrorStep={(error: boolean) => focusErrorStep(stepId, error)}
                  onContinue={isSubmitStep ? handleSubmit : handleContinue}
                  isSaving={isSaving}
                />
              </CollapsibleCardSection>
            );
          })}
        </Form>
      </ManageClientFormContextProvider>
    );
  }
);
