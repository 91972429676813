import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { useMemo } from 'react';

import { ManageClientFormStep, ManageClientFormStepPayload } from '../../../components/ManageClientForm';
import { useStepManager } from '../../../hooks';

export const useNewFirmClientStepManager = ({ defaultActiveId }: { defaultActiveId?: ManageClientFormStep }) => {
  const isSubscriptionEnabled = useIsSubscriptionsEnabled();

  const stepsData = useMemo(
    () => [
      {
        stepId: ManageClientFormStep.BasicDetails,
      },
      ...(isSubscriptionEnabled
        ? [
            {
              stepId: ManageClientFormStep.BillingDetails,
            },
            {
              stepId: ManageClientFormStep.PlanSelection,
            },
            {
              stepId: ManageClientFormStep.ReviewAndConfirm,
            },
          ]
        : []),
    ],
    [isSubscriptionEnabled]
  );

  const { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep } = useStepManager<
    ManageClientFormStep,
    ManageClientFormStepPayload
  >({
    data: stepsData,
    defaultActiveId,
  });

  return { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep };
};
