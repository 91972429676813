import { Card, Group, Skeleton as _Skeleton, SkeletonCircle } from '@melio/penny';

export function MethodSkeleton(props: { type: 'delivery-method' | 'funding-source' }) {
  return (
    <Card data-testid={`payment-flow-form-${props.type}-card-skeleton`} variant="flat" paddingX="s" paddingY="s">
      <Group width="full" variant="horizontal" alignItems="center">
        <IconSkeleton />
        <Group width="full" variant="vertical" spacing="xxxs">
          <TitleSkeleton />
          <DescriptionSkeleton />
        </Group>
      </Group>
    </Card>
  );
}

export function DescriptionSkeleton() {
  return (
    <div style={{ width: 180, paddingTop: 4, paddingBottom: 4 }}>
      <_Skeleton height="12px" />
    </div>
  );
}

export function TitleSkeleton() {
  return (
    <div style={{ width: 130, paddingTop: 4, paddingBottom: 4 }}>
      <_Skeleton height="16px" />
    </div>
  );
}

export function IconSkeleton() {
  return (
    <div>
      <SkeletonCircle diameter="24px" />
    </div>
  );
}
