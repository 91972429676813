import { EditVendorDrawerWidget } from '@melio/ap-widgets';
import { UpdateVendorOnDone } from '@melio/ap-widgets/src/components/Vendors/types';
import { Button, Container, FormSelectNewOption, SelectNew, SelectNewOption } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod, FeeCatalog, useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import { useLoadingStateContext } from '../../../../LoadingStates';
import { MethodSkeleton } from '../../MethodSkeleton';
import { DeliveryMethodSectionProps } from '../types';
import { DeliveryMethodCardContents } from './components/DeliveryMethodCardContents';
import { EmptyState } from './components/EmptyState';

export const DeliveryMethodSectionWithDrawer = ({
  vendorId,
  onDeliveryMethodChange,
  paymentSettings,
  deliveryMethodId,
  feesCatalog,
}: DeliveryMethodSectionProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const [isEditVendorDrawerOpen, setIsEditVendorDrawerOpen] = useState<boolean>(false);
  const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: vendorId });
  const loading = useLoadingStateContext();

  const isLoading = isVendorLoading || loading.includes('dm');
  if (isLoading || !vendor) {
    return <MethodSkeleton type="delivery-method" />;
  }

  const { id, deliveryMethods } = vendor;

  const selectedDeliveryMethod = deliveryMethods.find((deliveryMethod) => deliveryMethod.id === deliveryMethodId);

  const handleDone: UpdateVendorOnDone = ({ vendor }) => {
    showMessage({
      type: 'success',
      title: formatMessage('widgets.editVendor.toast.success.withName', {
        vendorName: vendor.contact?.name || vendor.name,
      }),
    });
    handleClose();
  };

  const handleOpen = () => {
    setIsEditVendorDrawerOpen(true);
  };

  const handleClose = () => {
    setIsEditVendorDrawerOpen(false);
  };

  const isEligible = (deliveryMethod: DeliveryMethod): boolean => {
    if (!paymentSettings?.restrictions) {
      return false; // loading still
    }

    if (!paymentSettings.restrictions.dmEligibility) {
      return true; // no dm restrictions
    }

    return paymentSettings.restrictions.dmEligibility.find((edm) => edm.id === deliveryMethod.id)?.eligible || false;
  };

  return (
    <>
      {deliveryMethods.length === 0 ? (
        <EmptyState onClick={handleOpen} />
      ) : (
        <SelectNew
          value={selectedDeliveryMethod}
          options={deliveryMethods.map((dm) => toOption(dm, !isEligible(dm)))}
          onChange={(event) => {
            const deliveryMethod = event.target.value as unknown as DeliveryMethod;
            track('DeliveryMethod', 'Click', {
              Intent: 'switch-delivery-method',
              Cta: `switch-to-${deliveryMethod.type}`,
              DeliveryMethodId: deliveryMethod?.id,
            });
            onDeliveryMethodChange(deliveryMethod);
          }}
          shouldHideClearButton
          valueRenderer={(option) => <ValueRenderer {...option} vendor={vendor} feesCatalog={feesCatalog} />}
          optionRenderer={(option) => <OptionRenderer {...option} vendor={vendor} feesCatalog={feesCatalog} />}
          data-testid="delivery-method-dropdown"
          footer={
            <SelectNew.Footer>
              <Button
                isFullWidth
                data-testid="delivery-method-dropdown-create-new"
                onClick={handleOpen}
                label={formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.selector.manage')}
                variant="secondary"
              />
            </SelectNew.Footer>
          }
        />
      )}
      <EditVendorDrawerWidget
        isOpen={isEditVendorDrawerOpen}
        vendorId={id}
        pageEntryPoint="pay"
        onDone={handleDone}
        onClose={handleClose}
      />
    </>
  );
};

type OptionValueRendererProps = FormSelectNewOption<DeliveryMethod> & { vendor?: Vendor; feesCatalog?: FeeCatalog[] };

const ValueRenderer = (props: OptionValueRendererProps) => (
  <Container width="full" paddingY="s" data-testid={`delivery-method-dropdown-selected-${props.value.id}`}>
    <DeliveryMethodCardContents
      deliveryMethod={props.value}
      vendor={props.vendor}
      isDisabled={!!props.disabled?.isDisabled}
      feesCatalog={props.feesCatalog}
    />
  </Container>
);

const OptionRenderer = (props: OptionValueRendererProps) => (
  <Container width="full" data-testid={`delivery-method-dropdown-item-${props.value.id}`}>
    <DeliveryMethodCardContents
      deliveryMethod={props.value}
      vendor={props.vendor}
      isDisabled={!!props.disabled?.isDisabled}
      feesCatalog={props.feesCatalog}
    />
  </Container>
);

const toOption = (deliveryMethod: DeliveryMethod, isDisabled: boolean): SelectNewOption<DeliveryMethod> => ({
  value: deliveryMethod,
  label: deliveryMethod.type,
  disabled: isDisabled ? { isDisabled: true } : undefined,
  testId: `${deliveryMethod.id}`,
});
