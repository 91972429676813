import { Control } from '@melio/penny';
import { AccountingPlatform, AccountingPlatformBillLineItemLabel, isXeroAccountingPlatform } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { AddBillV2FormDefaultValues, AddBillV2FormValues, AddBillV2SetValue, RegisterFieldFn } from '../../../../types';
import { LineItemsController } from '../../../../utils';
import { CategoryBasedBillLineItemsForm } from '../../CategoryBasedBillLineItemsForm/CategoryBasedBillLineItemsForm';
import { ItemBasedBillLineItemsForm } from '../../ItemBasedBillLineItemsForm/ItemBasedBillLineItemsForm';
import { LineItemsPaywallWrapper } from '../../LineItemsPaywallWrapper';
import { UnSyncedLineItemsForm } from '../../UnSyncedLineItemsForm/UnSyncedLineItemsForm';
import { XeroSyncedLineItemsForm } from '../../XeroSyncedLineItemsForm/XeroSyncedLineItemsForm';

type Props = {
  formControl: Control<AddBillV2FormValues>;
  lineItemsController: LineItemsController;
  formDefaultValues?: AddBillV2FormDefaultValues;
  billLineItemLabels?: AccountingPlatformBillLineItemLabel[];
  activeAccountingPlatform?: AccountingPlatform;
  isConnectedToAccountingPlatform?: boolean;
  currency?: string;
  setValue: AddBillV2SetValue;
  registerField: RegisterFieldFn;
};

export const LineItemsForm = ({
  formControl,
  lineItemsController,
  formDefaultValues,
  currency,
  billLineItemLabels,
  activeAccountingPlatform,
  isConnectedToAccountingPlatform,
  setValue,
  registerField,
}: Props) => {
  const isXero = isXeroAccountingPlatform(activeAccountingPlatform);
  const { formatMessage } = useMelioIntl();
  const { isHidden, isExpanded, isEligible, toggleExpanded } = lineItemsController;

  if (!isConnectedToAccountingPlatform) {
    return (
      <UnSyncedLineItemsForm
        currency={currency}
        formControl={formControl}
        registerField={registerField}
        isHidden={isHidden}
        onExpandLineItems={toggleExpanded}
        isLineItemsExpanded={isExpanded}
        isLineItemsEligible={isEligible}
      />
    );
  }

  if (isXero) {
    return (
      <XeroSyncedLineItemsForm
        formControl={formControl}
        currency={currency}
        registerField={registerField}
        isHidden={isHidden}
        setValue={setValue}
        isLineItemsExpanded={isExpanded}
        onExpandLineItems={toggleExpanded}
        isLineItemsEligible={isEligible}
      />
    );
  }

  const billLineItemLabelOptions = billLineItemLabels?.[0]?.options;

  return (
    <LineItemsPaywallWrapper
      isHidden={isHidden}
      isLineItemsExpanded={isEligible && isExpanded}
      title={formatMessage('activities.addBillV2.lineItems.synced.sectionTitle')}
      onExpandLineItems={toggleExpanded}
      data-testid="add-bill-v2-synced-line-items-title"
    >
      <CategoryBasedBillLineItemsForm
        formControl={formControl}
        formDefaultValues={formDefaultValues}
        registerField={registerField}
        isHidden={isHidden}
        currency={currency}
        billLineItemLabelOptions={billLineItemLabelOptions}
        activeAccountingPlatform={activeAccountingPlatform}
      />
      <ItemBasedBillLineItemsForm
        formControl={formControl}
        formDefaultValues={formDefaultValues}
        registerField={registerField}
        setValue={setValue}
        currency={currency}
        isHidden={isHidden}
        billLineItemLabelOptions={billLineItemLabelOptions}
      />
    </LineItemsPaywallWrapper>
  );
};
