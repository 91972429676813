import {
  BillerAccountDeliveryMethodCard,
  ManagedAchDeliveryMethodCard,
  ManagedVirtualCardDeliveryMethodCard,
} from '@melio/ap-widgets';
import { DeliveryMethod } from '@melio/platform-api';
import { Vendor } from '@melio/platform-api';

type ManagedDeliveryMethodProps = {
  vendor: Vendor;
  deliveryMethods: DeliveryMethod[];
};

export const ManagedDeliveryMethod = ({ vendor, deliveryMethods }: ManagedDeliveryMethodProps) => {
  if (!deliveryMethods) {
    return null;
  }

  const managedDeliveryMethod = getManagedDeliveryMethod(deliveryMethods);
  if (managedDeliveryMethod?.type === 'virtual-card') {
    return <ManagedVirtualCardDeliveryMethodCard vendor={vendor} virtualCard={managedDeliveryMethod} />;
  }

  if (managedDeliveryMethod?.type === 'biller-account' || managedDeliveryMethod?.type === 'managed-account') {
    return <BillerAccountDeliveryMethodCard />;
  }

  if (managedDeliveryMethod?.type === 'bank-account') {
    return <ManagedAchDeliveryMethodCard />;
  }

  return null;
};

const getManagedDeliveryMethod = (deliveryMethods: DeliveryMethod[]) =>
  deliveryMethods?.find((deliveryMethod) => deliveryMethod.isManaged);
