import { InvoicesApiInstance, InvoicesApiPreviewInvoiceEmailIdRequest } from '@melio/ar-api-axios-client';
import { useQuery } from '@tanstack/react-query';

export const useInvoicePreviewEmail = (requestParams: InvoicesApiPreviewInvoiceEmailIdRequest) => {
  const query = useQuery(['invoicePreviewEmail', requestParams], () =>
    InvoicesApiInstance.previewInvoiceEmailId(requestParams).then((response) => response.data.data)
  );

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
