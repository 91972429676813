import {
  checkCountryRiskLevel,
  getIsChangingDeliveryMethodIsDisabled,
  isCurrencyCloudInternationalDm,
  isFXCurrency,
  isTransferRequireForCurrency as isTransferFieldsRequireForCurrency,
} from '@melio/ap-domain';
import { isSelectedDmRequiresConfirmation } from '@melio/ap-widgets';
import { Currency, getCountries } from '@melio/international-payments-utils';
import { DeliveryMethod, DeliveryMethodType, Payment, Vendor } from '@melio/platform-api';

type SkipDeliveryMethodStepParams = {
  actionToPerform: 'schedule' | 'edit';
  isDeliveryMethodSelectionStepVisited: boolean;
  deliveryMethod?: DeliveryMethod | null;
  deliveryMethodIdFromState?: string;
  enablePromoteAch?: boolean;
  enabledInternationalUSDWise?: boolean;
};

const getSkipDeliveryMethodStep = ({
  actionToPerform,
  isDeliveryMethodSelectionStepVisited,
  deliveryMethod,
  deliveryMethodIdFromState,
  enablePromoteAch = false,
  enabledInternationalUSDWise = false,
}: SkipDeliveryMethodStepParams) => {
  const isVirtualAccount = deliveryMethod?.type === DeliveryMethodType.VirtualAccount;

  if (enabledInternationalUSDWise && deliveryMethod?.type === 'international-account') {
    if (isCurrencyCloudInternationalDm(deliveryMethod)) {
      return false;
    }
  }
  if (deliveryMethod && getIsChangingDeliveryMethodIsDisabled(deliveryMethod)) {
    return true;
  }

  const isScheduledButNotVisited = actionToPerform === 'schedule' && !isDeliveryMethodSelectionStepVisited;
  // require delivery method step when selected delivery method requires confirmation
  if (
    isScheduledButNotVisited &&
    deliveryMethod &&
    isSelectedDmRequiresConfirmation({
      enablePromoteAch,
      selectedDeliveryMethod: deliveryMethod,
    })
  ) {
    return false;
  }

  const skipWhenNewDmCreatedInVendorDashboard = deliveryMethodIdFromState && isScheduledButNotVisited;
  const skipWhenDmExistOnVendor = deliveryMethod && !isVirtualAccount && isScheduledButNotVisited;

  return skipWhenNewDmCreatedInVendorDashboard || skipWhenDmExistOnVendor;
};

export const getSkipVendorEmail = (vendor?: Vendor) => vendor?.isManaged;

export const getVendorEmail = (vendor?: Vendor) => vendor?.contact?.email || '';

export type ActionToPerform = 'schedule' | 'edit';
export type GetConditionalStepType = {
  actionToPerform: ActionToPerform;
  isDeliveryMethodSelectionStepVisited: boolean;
  deliveryMethod?: DeliveryMethod | null;
  deliveryMethodIdFromState?: string;
  isFinancingSelected?: boolean;
  shouldDisplayMemoToVendor: (deliveryMethod: DeliveryMethod) => boolean;
  hasInvoice?: boolean;
  vendorId?: string;
  paymentsData?: Payment[];
  enablePromoteAch?: boolean;
  enabledInternationalUSDWise?: boolean;
};

export const getConditionalSteps = ({
  actionToPerform,
  isDeliveryMethodSelectionStepVisited,
  deliveryMethodIdFromState,
  deliveryMethod,
  isFinancingSelected,
  paymentsData,
  vendorId,
  hasInvoice = false,
  shouldDisplayMemoToVendor,
  enablePromoteAch,
  enabledInternationalUSDWise = false,
}: GetConditionalStepType) => {
  const skipDeliveryMethodStep = getSkipDeliveryMethodStep({
    actionToPerform,
    isDeliveryMethodSelectionStepVisited,
    deliveryMethod,
    deliveryMethodIdFromState,
    enablePromoteAch,
    enabledInternationalUSDWise,
  });
  const hasMemoToVendorStep = !deliveryMethod || shouldDisplayMemoToVendor(deliveryMethod);
  const isInternationalDM = deliveryMethod?.type === DeliveryMethodType.InternationalAccount;

  const hasSelectDeliveryDateStep = () => {
    if (!isInternationalDM) {
      return true;
    }

    return !isFXCurrency(deliveryMethod?.details?.currency);
  };

  const hasPaymentPurposeStep = () => {
    if (!isInternationalDM) {
      return false;
    }

    if (isCurrencyCloudInternationalDm(deliveryMethod)) {
      return true;
    }
    const currency = deliveryMethod?.details?.currency as Currency;
    const bankCountryCode = deliveryMethod?.details.identifierDetails.bankCountryCode;
    const country = getCountries().find((c) => c.code === bankCountryCode);
    if (checkCountryRiskLevel({ country, paymentsData, hasInvoice, vendorId })) {
      return true;
    }

    return isTransferFieldsRequireForCurrency(currency);
  };

  return {
    hasDeliveryMethodStep: !skipDeliveryMethodStep,
    hasMemoToVendorStep,
    hasPaymentPurposeStep: hasPaymentPurposeStep(),
    hasRepaymentTermsStep: isFinancingSelected,
    hasSelectDeliveryDateStep: hasSelectDeliveryDateStep(),
  };
};
