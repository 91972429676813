import { Button, Container, FloatingMenu, Group, Icon, Pill, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { kebabCase, lowerCase, toLower } from 'lodash';
import { useState } from 'react';

import { usePayDashboardFilters } from '../../PayDashboardFilterProvider';
import { TabFilter } from './types';

type Props = {
  tabFilters: TabFilter<string>[];
};

export const Filters = ({ tabFilters }: Props) => {
  const filtersContext = usePayDashboardFilters();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const [isMenuOpen, setIsMenuOpen] = useState<Record<string, boolean>>({});
  const [multiSelectedItems, setMultiSelectedItems] = useState<string[]>([]);

  const getActiveFiltersByKey = (filters: Record<string, string[]>, key: string): string[] =>
    filters[key]?.map((value) => value) || [];

  const selectActiveFiltersByKey = (key: string) => {
    if (!filtersContext?.filters) {
      return;
    }
    const filters = filtersContext.filters;
    const activeFilters = getActiveFiltersByKey(filters, key);
    setMultiSelectedItems(activeFilters);
  };

  const handleSingleItemClick = (key: string, value: string, label: string) => {
    const filterLabel = tabFilters.find((filter) => filter.key === key)?.label;
    filtersContext?.applyFilter(key, value ? [value] : []);
    setIsMenuOpen((prev) => ({ ...prev, [key]: false }));
    track('Dashboard', 'Click', {
      Intent: 'filter',
      FilterValue: kebabCase(toLower(filterLabel)),
      FilterField: kebabCase(toLower(label)),
    });
  };

  const handleMenuClick = (key: string) => {
    selectActiveFiltersByKey(key);
    setIsMenuOpen((prev) => ({ ...prev, [key]: !!prev[key] }));
  };

  const handleCloseMenu = (key: string) => {
    selectActiveFiltersByKey(key);
    setIsMenuOpen((prev) => ({ ...prev, [key]: false }));
  };

  const handleOpenChange = (key: string, isOpen: boolean, menuLabel?: string) => {
    selectActiveFiltersByKey(key);
    setIsMenuOpen((prev) => ({ ...prev, [key]: isOpen }));

    if (isOpen) {
      track('Dashboard', 'Click', {
        Intent: 'filter',
        FilterValue: kebabCase(lowerCase(menuLabel)),
        Cta: `filters_${kebabCase(lowerCase(menuLabel))}`,
      });
    } else {
      track('Dashboard', 'Click', {
        Intent: 'filter',
        FilterValue: kebabCase(lowerCase(menuLabel)),
        Cta: 'exit',
      });
    }
  };

  const handleMultiSelectionItemClick = (value: string) => {
    if (multiSelectedItems.includes(value)) {
      setMultiSelectedItems(multiSelectedItems.filter((item) => item !== value));
    } else {
      setMultiSelectedItems([...multiSelectedItems, value]);
    }
  };

  const handleClearFilters = (key: string) => {
    const filterLabel = tabFilters.find((filter) => filter.key === key)?.label;
    setMultiSelectedItems([]);
    track('Dashboard', 'Click', {
      Intent: 'filter',
      Cta: `clear`,
      FilterValue: `${kebabCase(lowerCase(filterLabel))}`,
    });
    filtersContext?.applyFilter(key, []);
    handleCloseMenu(key);
  };

  const handleApplyFilters = (key: string) => {
    const filterLabel = tabFilters.find((filter) => filter.key === key)?.label;
    const selectedOptions = tabFilters.find((filter) => filter.key === key)?.options;
    const selectedOptionsLabels =
      selectedOptions?.filter((option) => multiSelectedItems.includes(option.value)).map((option) => option.label) ||
      [];
    track('Dashboard', 'Click', {
      Intent: 'filter',
      FilterValue: kebabCase(toLower(filterLabel)),
      FilterField: selectedOptionsLabels.map((item) => kebabCase(toLower(item))).sort((a, b) => a.localeCompare(b)),
      Cta: 'apply',
    });
    filtersContext?.applyFilter(key, multiSelectedItems);
    handleCloseMenu(key);
  };

  const renderSingleSelectionMenuItems = (filter: TabFilter<string>) => {
    const options = filter.options;
    const isNothingSelected = !filtersContext?.filters?.[filter.key];
    return (
      <FloatingMenu.DropdownList>
        {options.map((option) => (
          <FloatingMenu.SelectionItem
            key={option.value}
            onClick={() =>
              handleSingleItemClick(filter.key, option.selectWhenNothingSelected ? '' : option.value, option.label)
            }
            isSelected={
              isNothingSelected && option.selectWhenNothingSelected
                ? true
                : filtersContext?.filters?.[filter.key]?.includes(option.value)
            }
            data-testid={`filter-option-${option.value}`}
          >
            {option.label}
          </FloatingMenu.SelectionItem>
        ))}
      </FloatingMenu.DropdownList>
    );
  };

  const renderMultiSelectionMenuItems = (filter: TabFilter<string>) => {
    const options = filter.options;

    return (
      <FloatingMenu.DropdownList>
        {options.map((option) => (
          <FloatingMenu.SelectionItem
            key={option.value}
            isMulti
            onClick={() => handleMultiSelectionItemClick(option.value)}
            isSelected={multiSelectedItems.includes(option.value)}
            data-testid={`filter-option-${option.value}`}
          >
            {option.backgroundColor ? (
              <Container paddingLeft="xs">
                <Pill status={option.backgroundColor} type="secondary" label={option.label} />
              </Container>
            ) : (
              option.label
            )}
          </FloatingMenu.SelectionItem>
        ))}
      </FloatingMenu.DropdownList>
    );
  };

  const renderFooter = (key: string) => (
    <FloatingMenu.Footer>
      <Group>
        <Button
          variant="tertiary"
          label={formatMessage('activities.payDashboard.paymentsTab.filters.clear')}
          onClick={() => handleClearFilters(key)}
          data-testid="clear-filters-btn"
          size="small"
          isDisabled={multiSelectedItems.length === 0}
        />

        <Button
          label={formatMessage('activities.payDashboard.paymentsTab.filters.apply')}
          onClick={() => handleApplyFilters(key)}
          data-testid="apply-filters-btn"
          size="small"
        />
      </Group>
    </FloatingMenu.Footer>
  );

  return (
    <Group spacing="s" data-testid="filters-menus-container" alignItems="center">
      <Text textStyle="body3Semi">{formatMessage('activities.payDashboard.paymentsTab.filters.title')}</Text>
      {tabFilters.map((filter) => (
        <FloatingMenu
          key={filter.key}
          title={filter.label}
          isOpen={isMenuOpen[filter.key] || false}
          onOpenChange={(isOpen) => handleOpenChange(filter.key, isOpen, filter.label)}
          data-testid={`filter-menu-${filter.key}`}
          trigger={
            <Button
              onClick={() => handleMenuClick(filter.key)}
              variant="tertiary"
              label={filter.label}
              data-testid={`filter-menu-trigger-${filter.key}`}
              rightElement={
                <Icon
                  size="small"
                  type={isMenuOpen[filter.key] ? 'caret-up' : 'caret-down'}
                  color="inherit"
                  aria-hidden
                />
              }
            />
          }
          content={
            filter.type === 'single' ? renderSingleSelectionMenuItems(filter) : renderMultiSelectionMenuItems(filter)
          }
          footer={filter.type === 'multi' ? renderFooter(filter.key) : null}
        ></FloatingMenu>
      ))}
    </Group>
  );
};
