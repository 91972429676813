// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@tanstack/react-query';

export const useRemoteText = (url: string) => {
  const fetchData = async () => {
    const response = await fetch(url);
    const html = await response.text();
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const data = doc.body.textContent || '';
    return data;
  };

  const query = useQuery([url], fetchData);

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
