import {
  PostVendorsCopyDeliveryMethodRequest,
  PostVendorsCopyDeliveryMethodResponse,
  VendorsApiClient,
} from '@melio/platform-api-axios-client';
//eslint-disable-next-line no-restricted-imports
import { useMutation } from '@tanstack/react-query';

import type { ApiError } from '../../core';

export function useCopyDeliveryMethodOwnedToLocalVendor() {
  const { mutateAsync, ...rest } = useMutation<
    PostVendorsCopyDeliveryMethodResponse,
    ApiError,
    PostVendorsCopyDeliveryMethodRequest
  >(async ({ deliveryMethodId }) => {
    const response = await VendorsApiClient.copyDeliveryMethodFromOwnedVendorToLocalVendor(
      deliveryMethodId.toString(),
      { deliveryMethodId }
    );
    return response.data;
  });

  return {
    copyDeliveryMethodOwnedToLocalVendor: mutateAsync,
    ...rest,
  };
}
export type { PostVendorsCopyDeliveryMethodResponseData } from '@melio/platform-api-axios-client';
