import { Box } from '@chakra-ui/react';
import { Container, Group, Illustration } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { ExternalOrganizations } from '../../types';
import { WarningBanner } from './components/WarningBanner';

type Props = {
  isLoading: boolean;
  isRegistering: boolean;
  companyName?: string | null;
  isQuickBooksDesktop?: boolean;
  externalOrganizations: ExternalOrganizations;
  accountingPlatformName: string;
  onClose: VoidFunction;
  onCancel: VoidFunction;
  onSubmitOrganization: (externalOrganizationId: string) => void;
};

export const AccountingPlatformConfirmConnectScreen = ({
  isLoading,
  isRegistering,
  companyName,
  isQuickBooksDesktop,
  externalOrganizations = [],
  accountingPlatformName,
  onClose,
  onCancel,
  onSubmitOrganization,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { companyName: externalCompanyName, externalId } = externalOrganizations[0] || {};
  const onSubmit = () => {
    if (externalId) {
      onSubmitOrganization(externalId);
    }
  };

  return (
    <NewSinglePaymentStepLayout
      data-component="AccountingPlatformConnectActivity.ConfirmConnectScreen"
      data-testid="accounting-platform-connect-confirm-connect-screen"
      isLoading={isLoading}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <Box />
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <Container width="full">
            <Group justifyContent="center" variant="horizontal">
              <NewSinglePaymentStepLayout.SecondaryNextButton
                data-testid="accounting-platform-organization-cancel"
                label={formatMessage('activities.accountingPlatformConnectCallback.screens.confirmOrganization.cancel')}
                isDisabled={isRegistering}
                onClick={onCancel}
              />
              <NewSinglePaymentStepLayout.NextButton
                data-testid="accounting-platform-organization-confirm-connect"
                label={formatMessage(
                  'activities.accountingPlatformConnectCallback.screens.confirmOrganization.connect'
                )}
                isLoading={isRegistering}
                onClick={onSubmit}
              />
            </Group>
          </Container>
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <SystemMessageDisplay data-testid="accounting-platform-notification" />
      <Container paddingTop="xxl">
        <Group spacing="m" variant="vertical" alignItems="center">
          <Illustration type="sync-user" size="small" />
          <NewSinglePaymentStepLayout.Title paddingTop="xl">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.confirmOrganization.title', {
              accountingPlatformName,
            })}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description>
            {formatMessage('activities.accountingPlatformConnectCallback.screens.confirmOrganization.description', {
              companyName,
              externalCompanyName,
              accountingPlatformName,
            })}
          </NewSinglePaymentStepLayout.Description>
          {isQuickBooksDesktop && <WarningBanner />}
        </Group>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
