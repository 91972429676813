import { useNonEntityMutation } from '@melio/api-client';
import { PayorApiClient } from '@melio/ar-api-axios-client';

import { PromiseFunctionReturnType } from '../../api-client';
import { PostPayorOnboardingRequest, PostPayorSetupRequest } from './types';

export type UseGuestPayorOnboardingProps = {
  useGuestPaymentIntent?: boolean;
};

export const useGuestPayorSetup = ({ useGuestPaymentIntent }: UseGuestPayorOnboardingProps = {}) => {
  type MutationFn = typeof PayorApiClient.postPayorOnboarding | typeof PayorApiClient.postPayorSetup;

  const setup = useNonEntityMutation<
    MutationFn,
    PostPayorOnboardingRequest | PostPayorSetupRequest,
    PromiseFunctionReturnType<MutationFn>
  >(useGuestPaymentIntent ? PayorApiClient.postPayorSetup : PayorApiClient.postPayorOnboarding, [
    'PayorApi',
    'model',
    'postPayorSetup',
    'default',
  ]);

  return {
    setup: setup.mutateAsync,
  };
};
