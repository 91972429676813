import { useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, Group, IconButton, Tooltip } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { useState } from 'react';

type ButtonsProps = {
  onCreateInvoice: VoidFunction;
  onCreateCustomer: VoidFunction;
  onVisitSupportSettingsPage: VoidFunction;
  onVisitSettingsPage: VoidFunction;
};

export const HeaderToolBarMobile: React.FC<ButtonsProps> = ({
  onCreateCustomer,
  onCreateInvoice,
  onVisitSupportSettingsPage,
  onVisitSettingsPage,
}) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    settings: { isEmbeddedExperience, hideHeaderNavigation },
  } = useConfig();

  const actionMenuItems = [
    {
      label: formatMessage('ar.dashboard.activities.tabsLayout.actionsMenu.createInvoice.label'),
      onClick: onCreateInvoice,
      icon: 'add-bill' as const,
      dataTestId: 'dashboard-create-invoice-button',
    },
    {
      label: formatMessage('ar.dashboard.activities.tabsLayout.actionsMenu.addCustomer.label'),
      onClick: onCreateCustomer,
      icon: 'user-add' as const,
      dataTestId: 'dashboard-create-customer-button',
    },
  ];

  return (
    <Group spacing="xxs" variant="horizontal">
      <Tooltip
        dontDescribeChild
        content={formatMessage('ar.dashboard.activities.tabsLayout.supportButton.tooltip.label')}
      >
        <IconButton
          onClick={onVisitSupportSettingsPage}
          icon="help-circle"
          data-testid="dashboard-support-icon-button"
          variant="naked"
          aria-label={formatMessage('ar.dashboard.activities.tabsLayout.supportButton.tooltip.label')}
        />
      </Tooltip>
      {isEmbeddedExperience && hideHeaderNavigation && (
        <Tooltip
          dontDescribeChild
          content={formatMessage('ar.dashboard.activities.tabsLayout.settingsButton.tooltip.label')}
        >
          <IconButton
            aria-label={formatMessage('ar.dashboard.activities.tabsLayout.settingsButton.tooltip.label')}
            variant="naked"
            size="large"
            icon="settings"
            data-testid="settings-button"
            onClick={onVisitSettingsPage}
          />
        </Tooltip>
      )}
      <ActionsDropdownMenu
        label={formatMessage('ar.dashboard.activities.tabsLayout.actionsMenu.label')}
        isOpen={isMenuOpen}
        onOpenChange={setIsMenuOpen}
        items={actionMenuItems}
        trigger={
          <IconButton
            onClick={() => setIsMenuOpen(true)}
            icon="add"
            variant="brand"
            data-testid="dashboard-actions-dropdown-menu-button"
            aria-label={formatMessage('ar.dashboard.activities.tabsLayout.createButton.mobile.ariaLabel')}
          />
        }
      />
    </Group>
  );
};

HeaderToolBarMobile.displayName = 'HeaderToolBarMobile';
