import { Box } from '@chakra-ui/react';
import { Button, Container, Divider, FloatingMenu, Group, Icon, Menu, NavigationItem, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';

import { FilterMenuOptionsMulti } from './FilterMenuOptionsMulti';
import { FilterMenuOptionsSingle } from './FilterMenuOptionsSingle';
import { NestedFiltersProps } from './types';
import { getFilterByKey } from './utils';

export const NestedFiltersDesktop = <T extends string>({
  filters,
  activeFilters,
  applyAllFilters,
}: NestedFiltersProps<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [visibleFilterKey, setVisibleFilterKey] = useState<T | undefined>();
  const [selectedOptions, setSelectedOptions] = useState<{ [key in T]?: string[] }>({});

  useEffect(() => {
    if (isMenuOpen) {
      setVisibleFilterKey(filters[0]?.key);
      setSelectedOptions({ ...activeFilters });
    } else {
      setVisibleFilterKey(undefined);
      setSelectedOptions({});
    }
  }, [isMenuOpen, setVisibleFilterKey, filters, activeFilters]);

  const onCancel = () => {
    setIsMenuOpen(false);
  };

  const onApply = () => {
    applyAllFilters(selectedOptions);
    setIsMenuOpen(false);
  };

  const handleSingleItemClick = (filterKey: T, optionValue?: string) => {
    setSelectedOptions({ ...selectedOptions, [filterKey]: optionValue ? [optionValue] : [] });
  };

  const handleMultiSelectionItemClick = (filterKey: T, optionValue: string) => {
    const filterValues = selectedOptions?.[filterKey];
    if (filterValues?.includes(optionValue)) {
      setSelectedOptions({
        ...selectedOptions,
        [filterKey]: filterValues.filter((option) => option !== optionValue) ?? [],
      });
    } else {
      setSelectedOptions({
        ...selectedOptions,
        [filterKey]: [...(filterValues ?? []), optionValue],
      });
    }
  };

  const renderTrigger = () => (
    <Button
      variant="tertiary"
      size="medium"
      label={formatMessage('filter.button.trigger.text')}
      leftElement={<Icon size="small" type="filter" aria-hidden />}
      data-testid="filter-trigger"
    />
  );

  const renderMenuItems = () => (
    <Container paddingX="s" paddingY="s">
      <Group variant="vertical" spacing="none">
        {filters.map((filter) => {
          const isSelected = visibleFilterKey === filter.key;
          return (
            <FloatingMenu.Item
              key={filter.key}
              data-testid={`filter-menu-item-${filter.key}`}
              onClick={() => setVisibleFilterKey(filter.key)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              paddingX="none"
              paddingY="none"
              minHeight={36}
            >
              <NavigationItem isSelected={isSelected} isFullWidth>
                {filter.label}
              </NavigationItem>
            </FloatingMenu.Item>
          );
        })}
      </Group>
    </Container>
  );

  const renderMenuItemOptions = () => {
    if (!visibleFilterKey) {
      return null;
    }
    const visibleFilter = getFilterByKey(filters, visibleFilterKey);
    if (!visibleFilter) {
      return null;
    }
    return (
      <Group variant="vertical" spacing="xs" height="full">
        <Container paddingX="s" paddingTop="s" paddingBottom="xs">
          <Text textStyle="body2Semi">{visibleFilter.label}</Text>
        </Container>
        <Divider variant="horizontal" />
        <Container overflow="auto" height="full">
          {visibleFilter.type === 'multi' ? (
            <FilterMenuOptionsMulti
              options={visibleFilter.options}
              selected={selectedOptions?.[visibleFilter.key] ?? []}
              onChange={(value: string) => handleMultiSelectionItemClick(visibleFilter.key, value)}
            />
          ) : (
            <FilterMenuOptionsSingle
              options={visibleFilter.options}
              selected={selectedOptions?.[visibleFilter.key] ?? []}
              onChange={(value) => handleSingleItemClick(visibleFilter.key, value)}
            />
          )}
        </Container>
      </Group>
    );
  };

  return (
    <Menu
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      trigger={renderTrigger()}
      maxHeight={560}
      data-testid="nested-filter-menu"
    >
      <Container paddingX="none" paddingY="none">
        <Group variant="vertical" height="full" spacing="none">
          <Container paddingX="none" paddingY="none" overflow="hidden">
            <Group variant="horizontal" spacing="none" height="full">
              <Box width={184}>{renderMenuItems()}</Box>
              <Divider variant="vertical" />
              <Box width={312}>{renderMenuItemOptions()}</Box>
            </Group>
          </Container>
          <Divider variant="horizontal" />
          <Container paddingX="s" paddingY="s">
            <Group variant="horizontal" justifyContent="space-between">
              <Button
                onClick={onCancel}
                variant="tertiary"
                label={formatMessage('filter.button.cancel')}
                data-testid="nested-filter-menu-cancel"
              />
              <Button
                onClick={onApply}
                variant="primary"
                label={formatMessage('filter.button.apply')}
                data-testid="nested-filter-menu-apply"
              />
            </Group>
          </Container>
        </Group>
      </Container>
    </Menu>
  );
};
