import { OriginFlow } from '@melio/platform-analytics';
import { AccountingPlatformSlug } from '@melio/platform-api';

import { BillsTabProps } from './components/BillsTab/BillsTab';
import { FinancingTabProps } from './components/FinancingTab/FinancingTab';
import { PaymentsTabProps } from './components/PaymentsTab/PaymentsTab';

export enum PayDashboardActivityBanner {
  KYCCompleteDetails = 'kyc-complete-details',
  KYCVerificationFailed = 'kyc-verification-failed',
  PreSeason1099Banner = 'pre-season-1099-banner',
  Season1099Banner = 'season-1099-banner',
  OrgOwesUsMoneyBanner = 'org-owes-us-money-banner',
}

export type PayDashboardActivityProps = {
  onAddNewBill: ({
    originFlow,
    returnUrl,
    vendorId,
    amount,
  }: {
    originFlow?: OriginFlow;
    returnUrl: string;
    vendorId?: string;
    amount?: string;
  }) => void;
  onAddNewBillManual: BillsTabProps['onAddNewBillManual'];
  onAddNewBillUpload: BillsTabProps['onAddNewBillUpload'];
  onPayPaymentRequest: BillsTabProps['onPayPaymentRequest'];
  onReviewScannedInvoice: BillsTabProps['onReviewScannedInvoice'];
  onEditPayment: PaymentsTabProps['onEditPayment'];
  onEditBill: BillsTabProps['onEditBill'];
  onRetryFailedToDeliverPayment: PaymentsTabProps['onRetryFailedToDeliverPayment'];
  onRetryFailedToCollectPayment: PaymentsTabProps['onRetryFailedToCollectPayment'];
  onRefundPayment: PaymentsTabProps['onRefundPayment'];
  onVoidAndRefundPayment: PaymentsTabProps['onVoidAndRefundPayment'];
  onVoidAndResendPayment: PaymentsTabProps['onVoidAndResendPayment'];
  goToSettingsAccountSoftware: VoidFunction;
  onConnectAccountingPlatform: (accountingSlug: AccountingPlatformSlug) => void;
  onViewPayment: FinancingTabProps['onViewPayment'];
  onOpenFreeAchPromotionModal: () => boolean;
  onEditBillSubscription: PaymentsTabProps['onEditBillSubscription'];
  tabsVariant: 'default' | 'neutral';
  isAccountingPlatformConnecting: boolean;
  bannerToDisplay?: PayDashboardActivityBanner | undefined;
  onCloseCompleteDetailsDialog: VoidFunction;
  openSync1099ContractorsModal?: VoidFunction;
  redirectToZenworkLoginPage: VoidFunction;
  isSyncedWithZenwork: boolean;
  isRedirectingToZenwork: boolean;
};
