import { Icon } from '@melio/penny';
import { BankAccount, DeliveryMethod, DeliveryMethodType, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { MethodCard } from '../../../../MethodCard/MethodCard.widget';
import { AddDeliveryMethodActionButton, DeliveryMethodActionsMenu } from '../components/DeliveryMethodActions';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';

type AchDeliveryMethodProps = {
  vendor: Vendor;
  ach: DeliveryMethod | null;
  onClick: VoidFunction;
};

export const AchDeliveryMethodCard = ({ vendor, ach, onClick }: AchDeliveryMethodProps) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const caption = (() => {
    if (!ach) {
      return {
        secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.ach.comment'),
      };
    }

    return {
      primary: formatMessage('widgets.addOrUpdateDeliveryMethod.ach.info.info1', {
        companyName: vendor.name,
        bankAccount: (ach.details as BankAccount).accountNumberLast4Digits,
      }),
      secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.ach.info.info2', {
        updatedDate: formatDate(ach.history.updatedAt),
      }),
    };
  })();

  return (
    <MethodCard
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.ach.title')}
      icon={<Icon type="bank-out" />}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.ach.feeText')}
      testIds={{
        root: `MethodCard-${DeliveryMethodType.BankAccount}`,
        header: `method-card-type-${DeliveryMethodType.BankAccount}`,
      }}
      actionElement={
        ach ? (
          <DeliveryMethodActionsMenu
            actions={[
              {
                label: formatMessage('widgets.addOrUpdateDeliveryMethod.edit'),
                onClick,
                dataTestId: 'edit-delivery-method-item',
              },
            ]}
          />
        ) : (
          <AddDeliveryMethodActionButton
            onClick={onClick}
            aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.ach.add.ariaLabel')}
            data-testid={`add-delivery-method-card-${DeliveryMethodType.BankAccount}`}
          />
        )
      }
    >
      <DeliveryMethodCaption primary={caption.primary} secondary={caption.secondary} />
    </MethodCard>
  );
};
